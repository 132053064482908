import React, { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorMessage from '../../../../components/errorMessage';
import {
  addressMaxLength,
  email,
  emailMaxLength,
  kioskCodeMaxLength,
  nameMaxLength,
  onlyAlphabet,
  validMessage,
  validatePhoneNumber,
} from '../../../../utils/patterns/regex.pattern';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { ApiServiceContext, routes } from '../../../../utils/shared.module';
import { end_points } from '../../../../services/end_point/end_points';
import { Link, useNavigate } from 'react-router-dom';
import PhoneInputWithRef from '../../../../utils/phoneInputWithRef';
import { editType, userRole } from '../../../../assets/global';
import { createIconKiosk } from '../../../../utils/imagepath';

const schema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Kiosk name is required')
    .matches(onlyAlphabet, validMessage.onlyAlphabet)
    .max(nameMaxLength, `Name cannot exceed ${nameMaxLength} characters`),
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .matches(email, validMessage.email)
    .max(emailMaxLength, `Email cannot exceed ${emailMaxLength} characters`),
  phone: yup
    .string()
    .trim()
    .required('Mobile number is required')
    .test('is-valid-phone', 'Invalid phone number', (value) =>
      validatePhoneNumber(value),
    ),
  code: yup
    .string()
    .trim()
    .required('Kiosk code is required')
    .max(nameMaxLength, `Kiosk code cannot exceed ${nameMaxLength} characters`),
  address: yup
    .string()
    .trim()
    .required('Kiosk address is required')
    .max(
      addressMaxLength,
      `Kiosk address cannot exceed ${addressMaxLength} characters`,
    ),
  status: yup.string().trim().required('Kiosk status is required'),
});

const KioskCreate = () => {
  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitted, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      code: '',
      address: '',
      status: 'active',
    },
  });
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { postData } = useContext(ApiServiceContext);
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    try {
      showLoader();
      const { phone, ...rest } = data;
      const payload = {
        ...rest,
        mobile: `+${phone}`,
      };
      const response = await postData(end_points.kioskCreateApi.url, payload);
      if (response.status === 200) {
        navigate(routes.kioskList.path);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const formInvalid = isSubmitted && !isValid;

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Create Kiosk */}
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="card create-card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="create-header">
                        <h4>Create Kiosk</h4>
                        <img src={createIconKiosk} alt="Icon"></img>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="create-form">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="form-wrap">
                            <Controller
                              name="name"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  {...field}
                                  className={`form-control ${
                                    errors.name ? 'is-invalid' : ''
                                  }`}
                                  onBlur={() => trigger('name')}
                                  onChange={(event: any) => {
                                    field.onChange(event);
                                    trigger('name');
                                  }}
                                  onKeyDown={(event) => {
                                    const regex = onlyAlphabet;
                                    if (
                                      !regex.test(event.key) &&
                                      event.key !== 'Backspace'
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                  placeholder="Kiosk Name*"
                                  maxLength={nameMaxLength}
                                />
                              )}
                            />
                            <ErrorMessage value={errors.name} />
                          </div>

                          <div className="form-wrap">
                            <Controller
                              name="email"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.email ? 'is-invalid' : ''
                                  }`}
                                  placeholder="Kiosk email*"
                                  {...field}
                                  maxLength={emailMaxLength}
                                  onBlur={() => trigger('email')}
                                  onChange={(event: any) => {
                                    field.onChange(event);
                                    trigger('email');
                                  }}
                                />
                              )}
                            />
                            <ErrorMessage value={errors.email} />
                          </div>
                          <div
                            className={`form-wrap ${formInvalid ? 'is-invalid' : ''} mt-3`}
                          >
                            <Controller
                              name="phone"
                              control={control}
                              render={({ field, fieldState }) => (
                                <div>
                                  <PhoneInputWithRef
                                    {...field}
                                    placeholder="Enter phone number"
                                    country={'in'}
                                    value={field.value}
                                    onBlur={() => trigger('phone')}
                                    onChange={(event: any) => {
                                      field.onChange(event);
                                      trigger('phone');
                                    }}
                                    inputProps={{
                                      name: 'phone',
                                      required: true,
                                      // autoFocus: true,
                                      className: `form-control ${fieldState.invalid ? 'is-invalid' : ''}`,
                                    }}
                                  />
                                </div>
                              )}
                            />
                            <ErrorMessage value={errors.phone} />
                          </div>
                          <div className="form-wrap">
                            <Controller
                              name="code"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.code ? 'is-invalid' : ''
                                  }`}
                                  placeholder="Kiosk Code*"
                                  {...field}
                                  maxLength={kioskCodeMaxLength}
                                  onBlur={() => trigger('code')}
                                  onChange={(event: any) => {
                                    field.onChange(event);
                                    trigger('code');
                                  }}
                                />
                              )}
                            />

                            <ErrorMessage value={errors.code} />
                          </div>
                          <div className="form-wrap">
                            <Controller
                              name="address"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.address ? 'is-invalid' : ''
                                  }`}
                                  placeholder="Kiosk Address*"
                                  {...field}
                                  onBlur={() => trigger('address')}
                                  onChange={(event: any) => {
                                    field.onChange(event);
                                    trigger('address');
                                  }}
                                  maxLength={addressMaxLength}
                                />
                              )}
                            />

                            <ErrorMessage value={errors.address} />
                          </div>
                          <div className="form-wrap-status">
                            <h4>Kiosk Status</h4>
                            <div className="status-create-radio">
                              <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <label className="custom_radio">
                                      <input
                                        {...field}
                                        type="radio"
                                        value="active"
                                        checked={field.value === 'active'}
                                        onChange={() =>
                                          field.onChange('active')
                                        }
                                      />
                                      <span className="checkmark" /> Active
                                    </label>
                                    <label className="custom_radio">
                                      <input
                                        {...field}
                                        type="radio"
                                        value="inactive"
                                        checked={field.value === 'inactive'}
                                        onChange={() =>
                                          field.onChange('inactive')
                                        }
                                      />
                                      <span className="checkmark" /> In-Active
                                    </label>
                                  </>
                                )}
                              />

                              <ErrorMessage value={errors.status} />
                            </div>
                          </div>
                          <div className="form-wrap-btn">
                            <button type="submit" className="btn btn-secondary">
                              Submit
                            </button>
                            <Link
                              to={routes.kioskList.path}
                              className="btn btn-light"
                            >
                              Cancel
                            </Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Create Kiosk */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default KioskCreate;
