import React from 'react';
import { noRecordsFound } from '../../../../../assets/global';

function TeleExaminationHistory({ details = [] }) {
  const history: any = details?.[0];

  return (
    <div
      className="tab-pane fade"
      id="tele_examination"
      role="tabpanel"
      aria-labelledby="tele-examination"
    >
      <div className="row">
        <div className="col-md-6">
          <div className="examination-card">
            <h4>Tele Examination</h4>
            <div className="examination-details">
              {/* <p>on auscultation: B/L Normal Lungs Sounds</p>
               */}
              {history?.description || noRecordsFound}
            </div>
          </div>
        </div>
        {/* <div className="col-md-6">
          <div className="examination-card">
            <h4>Nursing Care Text</h4>
            <div className="examination-details">
              <p>on auscultation: B/L Normal Lungs Sounds</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default TeleExaminationHistory;
