import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  avatar29,
  defaultProfileTwoImage,
  doctor01,
  logo,
} from '../../../utils/imagepath';
import { routes } from '../../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../core/redux/loginSlice';
import { setMobileSidebar } from '../../../core/redux/sidebarSlice';
import { userRole } from '../../../assets/global';
import Dashboard from './../../feature/admin/dashboard/index';
import ProfileModel from './profileModel';
import { SpinnerContext } from '../../../components/spinner/spinner';
import { ApiServiceContext } from '../../../utils/shared.module';
import { end_points } from '../../../services/end_point/end_points';
import moment from 'moment';
import { setNotifyCountBool } from '../../../core/redux/commonSlice';

const Header: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname: any = location?.pathname;
  const isDoctorLogin = pathname.split('/')[1] === 'doctor';
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { getData, postData } = useContext(ApiServiceContext);
  const [notificationData, setNotificationData] = useState<any>({});
  const findPath = pathname.split('/')[1];
  const [clicked, setClicked] = useState<any>('');
  const profileCloseRef: any = useRef<any>(null);
  const findDoctorPath = pathname.split('/')[2];
  const findDoctorModule = pathname.split('/')[1];
  const fullname = useSelector((state: any) => state?.login?.userName);
  const userDetails = useSelector((state: any) => state?.login?.userDetails);
  const handleMobileSidebar = () => {
    dispatch(setMobileSidebar(!mobileSidebarData));
  };
  const loginUser = useSelector((state: any) => state?.login?.userDetails);
  const notifyCountBool = useSelector(
    (state: any) => state?.common?.notifyCountBool,
  );

  const mobileSidebarData = useSelector(
    (state: any) => state.sidebar.mobileSidebar,
  );
  const fromType = location?.state?.fromType;
  const handleLogout = (data: any) => {
    dispatch(logout(data));
  };
  const closeSymbol = () => (
    <input title="close" type="checkbox" ref={profileCloseRef} />
  );
  const frontDeskPaths = [
    routes.frontDeskDoctorsList.path,
    routes.frontDeskPatientList.path,
    routes.frontDeskPatientRegistration.path,
    routes.frontDeskCalendar.path,
    routes.frontDeskTodayConsult.path,
    routes.frontDeskPastConsult.path,
    routes.frontDeskNotification.path,
  ];
  const doctorPaths = [
    routes.doctorDashboard.path,
    routes.pastConsults.path,
    routes.appointmentDetails.path,
    routes.joinConsult.path,
    routes.scheduledSlots.path,
    routes.doctorNotification.path,
  ];

  const getNotificationList = async (search?: any) => {
    try {
      showLoader();
      const urls = end_points.getNotification.url;
      const response = await getData(urls);
      if (response?.status == 200) {
        console.log('headerrrrrr', response.data);
        setNotificationData(response.data);
      }
    } catch (error: any) {
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (loginUser && Object.keys(loginUser).length > 0) {
      getNotificationList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (profileCloseRef.current) {
      profileCloseRef.current.checked = false;
    }
  }, [pathname]);
  useEffect(() => {
    let notify = JSON.parse(JSON.stringify(notificationData));
    console.log(notificationData, 'notification', notifyCountBool);
    if (notifyCountBool === 'ALL') {
      getNotificationList();
      dispatch(setNotifyCountBool(0));
    } else if (notify?.unreadCount && notifyCountBool <= notify?.unreadCount) {
      notify.unreadCount =
        notify?.unreadCount > 0 ? Number(notify.unreadCount) - 1 : 0;
      dispatch(setNotifyCountBool(notify?.unreadCount));
    }

    setNotificationData(notify);
  }, [notifyCountBool]);

  const sideMenubar: any = () => {
    const shouldRenderSidebar: any = frontDeskPaths.includes(pathname);
    const shouldRenderDoctorSidebar: any = doctorPaths.includes(pathname);
    if (shouldRenderDoctorSidebar) {
      return (
        <div className="sidebar" id="sidebar">
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                <li>
                  <Link
                    className={pathname.includes('dashboard') ? 'active' : ''}
                    to={routes.doctorDashboard.path}
                  >
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      pathname.includes('past-consults') ? 'active' : ''
                    }
                    to={routes.pastConsults.path}
                  >
                    Past Consults
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      pathname.includes('scheduled-slots') ? 'active' : ''
                    }
                    to={routes.scheduledSlots.path}
                  >
                    Scheduled Slots
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    } else if (shouldRenderSidebar) {
      return (
        <>
          {shouldRenderSidebar ? (
            <div className="sidebar" id="sidebar">
              <div className="sidebar-inner slimscroll">
                <div id="sidebar-menu" className="sidebar-menu">
                  <ul>
                    <li>
                      <Link
                        className={pathname.includes('patient') ? 'active' : ''}
                        to={routes.frontDeskPatientList.path}
                      >
                        Patients
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes(
                            routes.frontDeskPastConsult.activeLink,
                          )
                            ? 'active'
                            : ''
                        }
                        to={routes.frontDeskPastConsult.path}
                      >
                        Past Consults
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes(
                            routes.frontDeskTodayConsult.activeLink,
                          )
                            ? 'active'
                            : ''
                        }
                        to={routes.frontDeskTodayConsult.path}
                      >
                        Today’s Consults
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes(
                            routes.frontDeskDoctorsList.activeLink,
                          ) ||
                          pathname.includes(routes.frontDeskCalendar.activeLink)
                            ? 'active'
                            : ''
                        }
                        to={routes.frontDeskDoctorsList.path}
                      >
                        Doctors
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
        </>
      );
    }
  };
  return (
    <div>
      {/* Header */}
      <div className="header">
        {findPath === userRole.FrontDesk &&
        !pathname.includes(routes.frontDeskList.path) ? (
          <div className="header-left">
            <Link to={routes.frontDeskPatientList.path} className="logo">
              <img src={logo} alt="Logo" />
            </Link>
            <Link
              to={routes.frontDeskPatientList.path}
              className="logo logo-small"
            >
              <img src={logo} alt="Logo" />
            </Link>
          </div>
        ) : findPath === userRole.Doctor ? (
          <div className="header-left">
            <Link to={routes.doctorDashboard.path} className="logo">
              <img src={logo} alt="Logo" />
            </Link>
            <Link to={routes.doctorDashboard.path} className="logo logo-small">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
        ) : (
          <div className="header-left">
            <Link to={routes.dashboard.path} className="logo">
              <img src={logo} alt="Logo" />
            </Link>
            <Link to={routes.dashboard.path} className="logo logo-small">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
        )}
        <Link
          to="#"
          className="mobile_btn"
          id="mobile_btn"
          onClick={handleMobileSidebar}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        {findPath === 'admin' && (
          <div className="sidebar" id="sidebar">
            <div className="sidebar-inner slimscroll">
              <div id="sidebar-menu" className="sidebar-menu">
                <ul>
                  <li>
                    <Link
                      to={routes.dashboard.path}
                      className={pathname.includes('dashboard') ? 'active' : ''}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={routes.kioskList.path}
                      className={pathname.includes('kiosk') ? 'active' : ''}
                    >
                      Kiosk
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={routes.paramedicList.path}
                      className={pathname.includes('paramedic') ? 'active' : ''}
                    >
                      Paramedic
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={routes.doctorsList.path}
                      className={pathname.includes('doctor') ? 'active' : ''}
                    >
                      Doctors
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={routes.frontDeskList.path}
                      className={
                        pathname.includes('front-desk') ? 'active' : ''
                      }
                    >
                      Front Desk
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
        {/* Menu Bar */}
        {sideMenubar()}

        {/* /Menu Bar */}
        {/* Header Right Menu */}

        {pathname !== '/dashboard' &&
          pathname !== routes.frontDeskDoctorsList.path &&
          pathname !== routes.frontDeskPatientList.path &&
          pathname !== routes.frontDeskPatientRegistration.path &&
          pathname !== routes.frontDeskCalendar.path &&
          pathname !== '/critical-patient-list' && (
            <ul className="nav user-menu">
              {((!pathname.includes('doctor') &&
                pathname !== '/admin/dashboard' &&
                !fromType &&
                !pathname.includes('front-desk') &&
                !pathname.includes('paramedic') &&
                pathname !== '/past-consults' &&
                pathname !== '/appointment-details' &&
                pathname !== '/logout') ||
                fromType === userRole.Kiosk) && (
                <li className="nav-item ">
                  <Link
                    to="#"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    Kiosk
                  </Link>
                  <div className="dropdown-menu dropdown-menu-end">
                    <Link
                      to={routes.paramedicList.path}
                      className="dropdown-item"
                    >
                      Paramedic
                    </Link>
                    <Link
                      to={routes.doctorsList.path}
                      className="dropdown-item"
                    >
                      Doctors
                    </Link>
                    <Link
                      to={routes.frontDeskList.path}
                      className="dropdown-item"
                    >
                      Front Desk
                    </Link>
                    {/* <Link to="#" className="dropdown-item">
                        Patients
                      </Link> */}
                  </div>
                </li>
              )}
              {(findDoctorPath?.includes('doctor') ||
                fromType === userRole.Doctor) &&
                pathname !== '/doctor-dashboard' && (
                  <>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        Doctor
                      </Link>
                      <div className="dropdown-menu dropdown-menu-end">
                        <Link
                          to={routes.kioskList.path}
                          className="dropdown-item"
                        >
                          Kiosk
                        </Link>
                        <Link
                          to={routes.paramedicList.path}
                          className="dropdown-item"
                        >
                          Paramedic
                        </Link>
                        <Link
                          to={routes.frontDeskList.path}
                          className="dropdown-item"
                        >
                          Front Desk
                        </Link>
                      </div>
                    </li>
                  </>
                )}
              {(pathname.includes('front-desk') ||
                fromType === userRole.FrontDesk) &&
                pathname != routes.frontDeskPastConsult.path &&
                pathname != routes.frontDeskNotification.path &&
                pathname != routes.frontDeskTodayConsult.path && (
                  <li className="nav-item ">
                    <Link
                      to="#"
                      className="dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      Front Desk
                    </Link>
                    <div className="dropdown-menu dropdown-menu-end">
                      <Link
                        to={routes.kioskList.path}
                        className="dropdown-item"
                      >
                        Kiosk
                      </Link>
                      <Link
                        to={routes.doctorsList.path}
                        className="dropdown-item"
                      >
                        Doctor
                      </Link>
                      <Link
                        to={routes.paramedicList.path}
                        className="dropdown-item"
                      >
                        Paramedic
                      </Link>
                      {/* <Link to="#" className="dropdown-item">
                      Patients
                    </Link> */}
                    </div>
                  </li>
                )}
              {(pathname.includes('paramedic') ||
                fromType === userRole.Paramedic) && (
                <li className="nav-item ">
                  <Link
                    to="#"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    Paramedic
                  </Link>
                  <div className="dropdown-menu dropdown-menu-end">
                    <Link to={routes.kioskList.path} className="dropdown-item">
                      Kiosk
                    </Link>
                    <Link
                      to={routes.doctorsList.path}
                      className="dropdown-item"
                    >
                      Doctor
                    </Link>
                    <Link
                      to={routes.frontDeskList.path}
                      className="dropdown-item"
                    >
                      Front Desk
                    </Link>
                    {/* <Link to="#" className="dropdown-item">
                      Patients
                    </Link> */}
                  </div>
                </li>
              )}
              {findDoctorModule === 'doctor' && (
                <>
                  <li className="nav-item clinic-nav-item">
                    <a href="javascript:void(0);">
                      <img
                        src={
                          userDetails?.profile_image != null
                            ? userDetails?.profile_image
                            : defaultProfileTwoImage
                        }
                        className="doctor-menu-img"
                        alt="Doctor"
                      />
                      <p>
                        Logged in as: <span>Dr. {userDetails?.fullname}</span>
                      </p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={
                        findDoctorModule === 'doctor'
                          ? routes.doctorNotification.path
                          : findDoctorModule === 'front-desk'
                            ? routes.frontDeskNotification.path
                            : findDoctorModule === 'admin'
                              ? routes.adminNotification.path
                              : '#'
                      }
                      className="notification-link"
                    >
                      <i className="feather icon-bell"></i>
                      <span className="badge badge-primary">
                        {notificationData?.unreadCount}
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <nav>
                      <div id="menuToggle">
                        {closeSymbol()}
                        <span />
                        <span />
                        <span />
                        <ul id="menu">
                          <li>
                            <Link
                              to="#"
                              data-bs-target="#profile_modal"
                              data-bs-toggle="modal"
                              onClick={() => {
                                setClicked((prev: any) => !prev);
                              }}
                            >
                              <i className="feather icon-user" /> Profile
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              onClick={() => {
                                handleLogout(userRole.Doctor);
                              }}
                            >
                              <i className="feather icon-log-out" /> Logout
                            </Link>
                          </li>
                          <li className="version-nav-item">Version 2.0.1.0</li>
                        </ul>
                      </div>
                    </nav>
                  </li>
                </>
              )}
              {pathname !== '/doctor-dashboard' &&
                pathname !== '/past-consults' &&
                pathname !== '/appointment-details' &&
                pathname !== '/logout' && (
                  <>
                    {/* Search Bar */}
                    {/* <li className="nav-item">
                      <Link to="#">
                        <i className="fas fa-search" />
                      </Link>
                    </li> */}
                    {/* /Search Bar */}
                  </>
                )}
              {/* {<li className="nav-item">
                    <Link
                      to={
                        findDoctorModule === 'doctor'
                          ? routes.doctorNotification.path
                          : findDoctorModule === 'front-desk'
                            ? routes.frontDeskNotification.path
                            : findDoctorModule === 'admin'
                              ? routes.adminNotification.path
                              : '#'
                      }
                      className="notification-link"
                    >
                      <i className="feather icon-bell"></i>
                      <span className="badge badge-primary">
                        {notificationData?.unreadCount}
                      </span>
                    </Link>
                  </li>} */}
              {findPath === 'admin' && (
                <>
                  <li className="nav-item">
                    <nav>
                      <div id="menuToggle">
                        {closeSymbol()}
                        <span />
                        <span />
                        <span />
                        <ul id="menu">
                          <li>
                            <Link
                              to="#"
                              onClick={() => {
                                handleLogout(userRole.Admin);
                              }}
                            >
                              <i className="feather icon-log-out" /> Logout
                            </Link>
                          </li>
                          <li className="version-nav-item">Version 2.0.1.0</li>
                        </ul>
                      </div>
                    </nav>
                  </li>
                </>
              )}
            </ul>
          )}
        {pathname === '/dashboard' && (
          <ul className="nav user-menu">
            {/* Search Bar */}
            <li className="nav-item">
              <div className="top-nav-search">
                {/* <form action="#" className="top-nav-form">
                  <i className="fas fa-search" />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                </form> */}
              </div>
            </li>
            {/* /Search Bar */}
            <li className="nav-item">
              <Link to="#" className="header-profile">
                {fullname}
              </Link>
            </li>
            <li className="nav-item">
              <nav>
                <div id="menuToggle">
                  {closeSymbol()}
                  <span />
                  <span />
                  <span />
                  <ul id="menu">
                    {/* <li>
                      <Link to="#">
                        <i className="feather icon-user" /> Profile
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="#"
                        onClick={() => {
                          handleLogout(userRole.Admin);
                        }}
                      >
                        <i className="feather icon-log-out" /> Logout
                      </Link>
                    </li>
                    <li className="version-nav-item">Version 2.0.1.2</li>
                  </ul>
                </div>
              </nav>
            </li>
          </ul>
        )}
        {(pathname === routes.frontDeskDoctorsList.path ||
          pathname === routes.frontDeskPatientList.path ||
          pathname === routes.frontDeskTodayConsult.path ||
          pathname === routes.frontDeskPastConsult.path ||
          pathname === routes.frontDeskPatientRegistration.path ||
          pathname === routes.frontDeskCalendar.path ||
          pathname === routes.frontDeskNotification.path) && (
          <ul className="nav user-menu">
            {userDetails?.kioskDetails?.name && (
              <li className="nav-item clinic-nav-item">
                <Link to="#">
                  <p>
                    Clinic <span>{userDetails?.kioskDetails?.name}</span>
                  </p>
                </Link>
              </li>
            )}
            <li className="nav-item clinic-nav-item">
              <Link to="#">
                <p>
                  Logged in as <span>{fullname}</span>
                </p>
                <img
                  src={
                    userDetails?.profile_image != null
                      ? userDetails?.profile_image
                      : defaultProfileTwoImage
                  }
                  alt="Profile"
                />
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={
                  findDoctorModule === 'doctor'
                    ? routes.doctorNotification.path
                    : findDoctorModule === 'front-desk'
                      ? routes.frontDeskNotification.path
                      : findDoctorModule === 'admin'
                        ? routes.adminNotification.path
                        : '#'
                }
                className="notification-link"
              >
                <i className="feather icon-bell"></i>
                <span className="badge badge-primary">
                  {notificationData?.unreadCount}
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <nav>
                <div id="menuToggle">
                  {closeSymbol()}
                  <span />
                  <span />
                  <span />
                  <ul id="menu">
                    {/* <li>
                      <Link to="#">
                        <i className="feather icon-user" /> Profile
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="#"
                        onClick={() => {
                          handleLogout(userRole.FrontDesk);
                        }}
                      >
                        <i className="feather icon-log-out" /> Logout
                      </Link>
                    </li>
                    <li className="version-nav-item">Version 2.0.1.3</li>
                  </ul>
                </div>
              </nav>
            </li>
          </ul>
        )}

        {/* /Header Right Menu */}
        {(pathname === '/critical-patient-list' ||
          pathname === '/doctor-dashboard' ||
          pathname === '/past-consults' ||
          pathname === '/appointment-details') && (
          <>
            {/* Menu Bar */}
            <div className="sidebar" id="sidebar">
              <div className="sidebar-inner slimscroll">
                <div id="sidebar-menu" className="sidebar-menu">
                  <ul>
                    <li>
                      <Link to={routes.doctorDashboard.path}>Dashboard</Link>
                    </li>
                    <li>
                      <Link to={routes.pastConsults.path}>Past Consults</Link>
                    </li>
                    <li>
                      <Link to="#">Scheduled Slots</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Menu Bar */}
          </>
        )}
        {pathname === '/critical-patient-list' && (
          <>
            {/* Header Right Menu */}
            <ul className="nav user-menu">
              <li className="nav-item clinic-nav-item">
                <Link to="#">
                  <img
                    src={doctor01}
                    className="doctor-menu-img"
                    alt="Doctor"
                  />
                  <p>
                    Logged in as: <span>Dr. William Woodworth</span>
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <nav>
                  <div id="menuToggle">
                    {closeSymbol()}
                    <span />
                    <span />
                    <span />
                    <ul id="menu">
                      {/* <li>
                        <Link to="#">
                          <i className="feather-user" /> Profile
                        </Link>
                      </li> */}
                      <li>
                        <Link to={routes.logout.path}>
                          <i className="feather-log-out" /> Logout
                        </Link>
                      </li>
                      <li className="version-nav-item">Version 2.0.1.4</li>
                    </ul>
                  </div>
                </nav>
              </li>
            </ul>
            {/* /Header Right Menu */}
          </>
        )}
      </div>
      {/* /Header */}
      <ProfileModel time={clicked} profileCloseRef={profileCloseRef} />
    </div>
  );
};

export default Header;
