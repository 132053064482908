import React, { useContext, useEffect, useRef, useState } from 'react';
import { end_points } from '../../services/end_point/end_points';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SpinnerContext } from '../spinner/spinner';
import { ApiServiceContext, yup } from '../../utils/shared.module';
import { Calendar } from 'primereact/calendar';
import { calendarIconWhite } from '../../utils/imagepath';
import ErrorMessage from '../errorMessage';
import { useDispatch, useSelector } from 'react-redux';
import {
  dateFormat_DD_MM_YYYY,
  dateTimeFormat_HH_mm_ss_24Hr,
} from '../../utils/constant';
import { Dropdown } from 'primereact/dropdown';
import { setDoctorSlotEditData } from '../../core/redux/commonSlice';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
interface doctorCreateSlotModelProps {
  getAppointmentDetails: any;
  closeRef: any;
  slotModel: boolean;
  setSlotModel: any;
}
const defaultValues = {
  end_date: null,
  start_date: null,
  days: [],
  begin_time: null,
  end_time: null,
  slot_duration_minutes: { name: '', code: '' },
};
const mainSlotSchema = yup.object().shape({
  start_date: yup
    .string()
    .trim()
    .required('Appointment start date is required'),
  end_date: yup.string().trim().required('Appointment end date is required'),
  days: yup
    .array()
    .min(1, 'Select at least one day')
    .of(yup.string().oneOf(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'])),
  begin_time: yup.string().required('Start time is required'),
  end_time: yup.string().required('End time is required'),
  slot_duration_minutes: yup
    .object({
      name: yup.string().trim().required('Slot duration minutes is required'),
    })
    .nullable()
    .required('Slot duration minutes is required'),
});
const DoctorCreateSlotModel: React.FC<doctorCreateSlotModelProps> = ({
  getAppointmentDetails,
  closeRef,
  slotModel,
  setSlotModel,
}) => {
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { getData, postData, putData } = useContext(ApiServiceContext);

  const editData = useSelector((state: any) => state?.common?.editData);
  const doctorSlotEditData = useSelector(
    (state: any) => state?.common?.doctorSlotEditData,
  );

  const dispatch = useDispatch();
  const today = new Date();
  today.setDate(today.getDate());
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    trigger,
    getValues,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(mainSlotSchema),
    defaultValues: defaultValues,
  });
  const allSlotDurationOptions = [
    { code: '5', name: '5 Minutes' },
    { code: '10', name: '10 Minutes' },
    { code: '15', name: '15 Minutes' },
    { code: '20', name: '20 Minutes' },
    { code: '25', name: '25 Minutes' },
    { code: '30', name: '30 Minutes' },
    { code: '35', name: '35 Minutes' },
    { code: '40', name: '40 Minutes' },
    { code: '45', name: '45 Minutes' },
    { code: '50', name: '50 Minutes' },
    { code: '55', name: '55 Minutes' },
    { code: '60', name: '60 Minutes' },
  ];
  const [isBeginCalendarVisible, setIsBeginCalendarVisible] = useState(false);
  const [isEndCalendarVisible, setIsEndCalendarVisible] = useState(false);
  const [slotDurationOptions, setSlotDurationOptions] = useState(
    allSlotDurationOptions,
  );
  const [isEdit, setIsEdit] = useState(false);
  const beginTime = watch('begin_time');
  const endTime = watch('end_time');
  const location = useLocation();
  const path = location?.pathname;
  const findPath = path.split('/')[1];

  useEffect(() => {
    if (errors.begin_time) {
      setIsBeginCalendarVisible(false);
    }
    if (errors.end_time) {
      setIsEndCalendarVisible(false);
    }
  }, [errors.begin_time, errors.end_time]);

  useEffect(() => {
    if (beginTime && endTime) {
      const begin = moment(beginTime, 'HH:mm:ss');
      const end = moment(endTime, 'HH:mm:ss');
      const diffMinutes = end.diff(begin, 'minutes');

      const filteredOptions = allSlotDurationOptions.filter(
        (option) => parseInt(option.code) <= diffMinutes,
      );
      setSlotDurationOptions(filteredOptions);
    }
  }, [beginTime, endTime]);

  const onSubmit = async (data: any) => {
    showLoader();
    try {
      const payload: any = {
        end_date: dateFormat_DD_MM_YYYY(data.end_date),
        start_date: dateFormat_DD_MM_YYYY(data.start_date),
        days: Days_All.reduce((acc: any, day: any) => {
          acc[day.toLowerCase()] = data.days.includes(day) ? 'true' : 'false';
          return acc;
        }, {}),
        begin_time: dateTimeFormat_HH_mm_ss_24Hr(data.begin_time),
        end_time: dateTimeFormat_HH_mm_ss_24Hr(data.end_time),
        slot_duration_minutes: data?.slot_duration_minutes?.code,
      };
      if (doctorSlotEditData && Object.keys(doctorSlotEditData).length > 0) {
        payload.doctor_id = editData?._id;
      }
      let url;
      if (doctorSlotEditData && Object.keys(doctorSlotEditData).length > 0) {
        url = end_points.updateDoctorSlotApi.url;
        url += `/${doctorSlotEditData?.id}`;
      } else {
        url = end_points.createDoctorSlotApi.url;
        if (findPath === 'doctor') {
          url += `/:doctorId`;
        } else {
          url += `/${editData?._id}`;
        }
      }
      const response = await postData(url, payload);
      if (response.status === 200) {
        if (closeRef.current) {
          closeRef.current.click();
          getAppointmentDetails();
        }
      }
    } catch (error) {
      console.warn(error);
    } finally {
      hideLoader();
    }
  };

  const Days_All = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const getWeekDaysBetweenDates = (start: any, end: any) => {
    const days = [];
    let currentDate = new Date(start);
    currentDate.setHours(0, 0, 0, 0);
    let endDate = new Date(end);
    endDate.setHours(23, 59, 59, 999);

    while (currentDate <= endDate) {
      days.push(currentDate.getDay());
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return Array.from(new Set(days));
  };

  const [validDays, setValidDays] = useState<any>([]);
  const [coversFullWeek, setCoversFullWeek] = useState(false);

  const startDate = watch('start_date');
  const endDate = watch('end_date');

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const days = getWeekDaysBetweenDates(start, end);
      setValidDays(days);
      setCoversFullWeek(days.length === 7);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (beginTime && !isEdit) {
      setValue('end_time', null);
    } else {
      setIsEdit(false);
    }
  }, [beginTime, setValue]);

  useEffect(() => {
    if (startDate && !isEdit) {
      setValue('end_date', null);
    } else {
      setIsEdit(false);
    }
  }, [startDate, setValue]);

  useEffect(() => {
    if (endDate && !isEdit) {
      setValue('days', []);
    } else {
      setIsEdit(false);
    }
  }, [endDate, setValue]);

  const handleAllDaysChange = () => {
    const currentDays = getValues('days');
    if (currentDays.length === validDays.length) {
      setValue('days', []);
    } else {
      const selectedDays = validDays.map((day: any) => Days_All[day]);
      setValue('days', selectedDays);
    }
  };

  const handleIndividualDayChange = (day: any) => {
    const currentDays = getValues('days');
    const newValue = currentDays.includes(day)
      ? currentDays.filter((d: any) => d !== day)
      : [...currentDays, day];
    setValue('days', newValue);

    if (newValue.length === validDays.length) {
      setValue('all_days', true);
    } else {
      setValue('all_days', false);
    }
  };

  const pathDate = (dateString: any) => {
    if (dateString) {
      const [year, month, day] = dateString.split('-');
      return new Date(year, month - 1, day);
    }
  };

  useEffect(() => {
    if (doctorSlotEditData && Object.keys(doctorSlotEditData).length > 0) {
      const startDate = pathDate(doctorSlotEditData.Slot_start_date);
      const endDate = pathDate(doctorSlotEditData.Slot_end_date);
      setIsEdit(true);
      setValue('start_date', startDate);
      setValue('end_date', endDate);
      const selectedDays = Object.keys(doctorSlotEditData.days)
        .filter((day) => doctorSlotEditData.days[day] === true)
        .map((day) =>
          Days_All.find((d) => d.toLowerCase().startsWith(day.slice(0, 3))),
        );
      setValue('days', selectedDays);
      const validDays = selectedDays.map((day: any) => Days_All.indexOf(day));
      setValidDays(validDays);
      setCoversFullWeek(validDays.length === 7);
      setValue(
        'begin_time',
        new Date(`1970-01-01T${doctorSlotEditData.begin_time}`),
      );
      setValue(
        'end_time',
        new Date(`1970-01-01T${doctorSlotEditData.end_time}`),
      );
      setValue(
        'slot_duration_minutes',
        slotDurationOptions.find(
          (option) =>
            option.code === doctorSlotEditData.slot_duration_minutes.toString(),
        ),
      );
    }
    return () => {
      reset(defaultValues);
    };
  }, [doctorSlotEditData]);

  const handleCalendarBlur = (field: any, value: any, setValue: any) => {
    if (!value) {
      const currentTime = new Date();
      field.onChange(currentTime);
      setValue(currentTime);
    }
  };

  return (
    <div
      className="modal custom-modal fade"
      id="create_slot"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content doctor-slot-modal-content">
          <div className="modal-body doctor-slot-body">
            <div className="doctor-slot-header">
              {doctorSlotEditData &&
              Object.keys(doctorSlotEditData).length > 0 ? (
                <h4>Update Slot</h4>
              ) : (
                <h4>New Slot</h4>
              )}
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="doctor-slot-input">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="form-wrap">
                      <label>Start from the date</label>
                      <div className="doctor-slot-date">
                        <div className="form-wrap">
                          <div className="form-wrap-date">
                            <Controller
                              name="start_date"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Calendar
                                  key={slotModel ? 'open' : 'close'}
                                  appendTo="self"
                                  className="scheduled-slot-datepicker"
                                  placeholder="Select date*"
                                  value={value ? new Date(value) : null}
                                  minDate={new Date()}
                                  onChange={(date) => {
                                    onChange(date);
                                  }}
                                  inputClassName={
                                    errors?.start_date ? 'select-error' : ''
                                  }
                                />
                              )}
                            />
                            <b>
                              <img src={calendarIconWhite} alt="Calendar" />
                            </b>
                          </div>
                          <p>
                            <ErrorMessage value={errors.start_date} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-wrap">
                      <label>End on the date</label>
                      <div className="doctor-slot-date">
                        <div className="form-wrap">
                          <div className="form-wrap-date">
                            <Controller
                              name="end_date"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Calendar
                                  key={slotModel ? 'open' : 'close'}
                                  appendTo="self"
                                  className="scheduled-slot-datepicker"
                                  placeholder="Select date*"
                                  value={value ? new Date(value) : null}
                                  minDate={
                                    startDate ? new Date(startDate) : new Date()
                                  }
                                  onChange={(date) => {
                                    onChange(date);
                                  }}
                                  inputClassName={
                                    errors?.end_date ? 'select-error' : ''
                                  }
                                />
                              )}
                            />
                            <b>
                              <img src={calendarIconWhite} alt="Calendar" />
                            </b>
                          </div>
                          <p>
                            <ErrorMessage value={errors.end_date} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-wrap select-days">
                      <h6>Select Days</h6>
                      <ul className="nav">
                        <li className="custom-checkbox">
                          <label className="check">
                            <Controller
                              name="all_days"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="checkbox"
                                  onChange={() => {
                                    handleAllDaysChange();
                                    field.onChange(!field.value);
                                    trigger('days');
                                  }}
                                  checked={
                                    validDays.length == 7 &&
                                    getValues('days').length ===
                                      validDays.length
                                  }
                                />
                              )}
                            />
                            <span className="box react-box-right" /> All Days
                          </label>
                        </li>
                        {Days_All?.map((day, index) => (
                          <li className="custom-checkbox" key={index}>
                            <Controller
                              name="days"
                              control={control}
                              render={({ field }) => (
                                <label className="check">
                                  <input
                                    type="checkbox"
                                    value={day}
                                    checked={field?.value?.includes(day)}
                                    disabled={!validDays.includes(index)}
                                    onChange={() => {
                                      handleIndividualDayChange(day);
                                      trigger('days');
                                    }}
                                  />
                                  <span className="box react-box-right" /> {day}
                                </label>
                              )}
                            />
                          </li>
                        ))}
                      </ul>
                      <ErrorMessage value={errors.days} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="form-wrap form-flex-wrap">
                      <label>Begin at</label>
                      <div className="doctor-slot-date">
                        <Controller
                          name="begin_time"
                          control={control}
                          render={({ field }) => (
                            <Calendar
                              {...field}
                              id="calendar-timeonly"
                              className={`${errors.begin_time ? 'is-invalid' : ''}`}
                              timeOnly
                              placeholder="00:00"
                              showTime
                              hourFormat="24"
                              onShow={() => setIsBeginCalendarVisible(true)}
                              onHide={() => setIsBeginCalendarVisible(false)}
                              onFocus={() => setIsBeginCalendarVisible(true)}
                              onBlur={(e) => {
                                setIsBeginCalendarVisible(false);
                                handleCalendarBlur(
                                  field,
                                  field.value,
                                  field.onChange,
                                );
                              }}
                              panelClassName={
                                isBeginCalendarVisible
                                  ? 'p-datepicker-visible'
                                  : 'p-datepicker-hidden'
                              }
                              onChange={(e) => {
                                const selectedTime: any = e.value;
                                const today = new Date();
                                const startDate = getValues('start_date');
                                const endDate = getValues('end_date');

                                if (
                                  startDate &&
                                  endDate &&
                                  new Date(startDate).toDateString() ===
                                    today.toDateString() &&
                                  new Date(endDate).toDateString() ===
                                    today.toDateString()
                                ) {
                                  const currentTime =
                                    today.getHours() * 60 + today.getMinutes();
                                  const selectedTimeInMinutes =
                                    selectedTime.getHours() * 60 +
                                    selectedTime.getMinutes();

                                  if (selectedTimeInMinutes < currentTime) {
                                    return;
                                  }
                                }

                                field.onChange(selectedTime);
                              }}
                            />
                          )}
                        />
                        <ErrorMessage value={errors.begin_time} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-wrap form-flex-wrap">
                      <label>End at</label>
                      <div className="doctor-slot-date">
                        <Controller
                          name="end_time"
                          control={control}
                          render={({ field }) => (
                            <Calendar
                              {...field}
                              id="calendar-timeonly"
                              className={`${errors.end_time ? 'is-invalid' : ''}`}
                              timeOnly
                              placeholder="00:00"
                              showTime
                              hourFormat="24"
                              onShow={() => setIsEndCalendarVisible(true)}
                              onHide={() => setIsEndCalendarVisible(false)}
                              onFocus={() => setIsEndCalendarVisible(true)}
                              onBlur={(e) => {
                                setIsEndCalendarVisible(false);
                                handleCalendarBlur(
                                  field,
                                  field.value,
                                  field.onChange,
                                );
                              }}
                              panelClassName={
                                isEndCalendarVisible
                                  ? 'p-datepicker-visible'
                                  : 'p-datepicker-hidden'
                              }
                              onChange={(e: any) => {
                                const value = e.value;
                                if (beginTime && value < beginTime) {
                                  setValue('end_time', beginTime);
                                } else {
                                  field.onChange(value);
                                }
                              }}
                            />
                          )}
                        />
                        <ErrorMessage value={errors.end_time} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="form-wrap">
                      <label>Slot Duration</label>
                      <Controller
                        name="slot_duration_minutes"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Dropdown
                              {...field}
                              value={field.value}
                              onBlur={() => trigger('slot_duration_minutes')}
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger('slot_duration_minutes');
                              }}
                              options={slotDurationOptions}
                              optionLabel="name"
                              placeholder="Select slot duration*"
                              className={`w-100 ${errors.slot_duration_minutes ? 'is-invalid' : ''}`}
                            />
                          </>
                        )}
                      />
                      <ErrorMessage
                        value={errors.slot_duration_minutes?.name}
                      />
                    </div>
                  </div>
                </div>
                <div className="doctor-slot-btn">
                  {doctorSlotEditData &&
                  Object.keys(doctorSlotEditData).length > 0 ? (
                    <button type="submit" className="btn btn-secondary">
                      Update
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-secondary">
                      Save
                    </button>
                  )}
                  <button
                    data-bs-dismiss="modal"
                    type="button"
                    className="btn btn-light"
                    ref={closeRef}
                    onClick={() => {
                      reset(defaultValues);
                      setValue('start_date', null);
                      setValue('end_date', null);
                      dispatch(setDoctorSlotEditData(undefined));
                      setSlotModel(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorCreateSlotModel;
