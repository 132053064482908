import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import store from '../core/redux/store';
import { routes } from '../utils/routes';

function UnAuthRoute({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const auth = store?.getState()?.login?.token;
    if (auth) navigate(routes.dashboard.path);
  }, [location]);
  return children;
}

export default UnAuthRoute;
