import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { edit01, edit02 } from '../../../../utils/imagepath';
import {
  ApiServiceContext,
  routes,
  yup,
  yupResolver,
} from '../../../../utils/shared.module';
import {
  email,
  emailMaxLength,
  nameMaxLength,
  onlyAlphabet,
  validatePhoneNumber,
  validMessage,
} from '../../../../utils/patterns/regex.pattern';
import { Controller, useForm } from 'react-hook-form';
import ErrorMessage from '../../../../components/errorMessage';
import PhoneInputWithRef from '../../../../utils/phoneInputWithRef';
import { useSelector } from 'react-redux';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { end_points } from '../../../../services/end_point/end_points';
import { validatePhoneNumberwithPlus } from '../../../../utils/constant';
import { editType, userRole } from '../../../../assets/global';
import { DropDownPopup } from '../../../../components/dropdownPopup';

const ParamedicEdit = () => {
  const editData = useSelector((state: any) => state?.common?.editData);
  const navigate = useNavigate();
  const { getData, putData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const [listData, setListData] = useState<any>([]);
  const [activeStatus, setActiveStatus] = useState('');
  const [showed, setShowed] = useState<boolean>(false);

  const fdEditValidation = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required('Name is required')
      .matches(onlyAlphabet, validMessage.onlyAlphabet)
      .max(nameMaxLength, `Name cannot exceed ${nameMaxLength} characters`),
    email: yup
      .string()
      .trim()
      .required('Email is required')
      .matches(email, validMessage.email)
      .max(emailMaxLength, `Email cannot exceed ${emailMaxLength} characters`),
    ph_no: yup
      .string()
      .trim()
      .required('Mobile number is required')
      .test('is-valid-phone', 'Invalid phone number', (value) =>
        validatePhoneNumber(value),
      ),
  });

  const {
    handleSubmit,
    setValue,
    control,
    trigger,
    formState: { errors, isSubmitted, isValid },
  } = useForm({
    resolver: yupResolver(fdEditValidation),
    defaultValues: {
      name: '',
      email: '',
      ph_no: '',
    },
  });
  const formInvalid = isSubmitted && !isValid;

  const getEditData = async () => {
    try {
      showLoader();
      let url = end_points.adminPagesGetById.url;
      url += `/${editData?._id}`;
      const response = await getData(url);
      if (response.status === 200) {
        const apiData = response.data.data;
        setListData(apiData);
        setValue('name', apiData?.fullname);
        setValue('email', apiData?.email);
        setValue('ph_no', apiData?.mobile);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getEditData();
  }, []);

  useEffect(() => {
    setActiveStatus(listData.status);
  }, [listData]);

  const onSubmit = async (data: any) => {
    try {
      showLoader();
      let url = end_points.adminPagesUpdate.url;
      url += `/paramedic`;
      url += `/${editData?._id}`;
      const payload = {
        fullname: data?.name,
        email: data?.email,
        mobile: validatePhoneNumberwithPlus(data?.ph_no),
        status: activeStatus,
      };

      const response = await putData(url, payload);
      if (response?.status === 200) {
        navigate(routes.paramedicList.path);
      }
    } catch (error: any) {
    } finally {
      hideLoader();
    }
  };

  const handleStatus = async (e: any) => {
    setActiveStatus(e.target.checked ? 'active' : 'inactive');
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Edit Paramedic */}
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="card edit-card">
                <div className="card-body pb-0">
                  <div className="edit-toggle">
                    <div className="status-toggle">
                      {activeStatus === 'active' ? (
                        <p>In Active Paramedic</p>
                      ) : (
                        <p>Active Paramedic</p>
                      )}
                      <input
                        type="checkbox"
                        id="status"
                        checked={activeStatus === 'active'}
                        className="check-input"
                        onChange={(e) => {
                          if (
                            editData?.status === 'active' &&
                            editData?.associates?.length > 0 &&
                            !e.target.checked
                          ) {
                            activeStatus === 'active'
                              ? setShowed(true)
                              : setShowed(false);
                          } else handleStatus(e);
                        }}
                      />
                      <label htmlFor="status" className="checktoggle">
                        checkbox
                      </label>
                      {showed && (
                        <DropDownPopup
                          showed={showed}
                          title="Inactive a paramedic, corresponding kiosk will be unassociated."
                          setShowed={setShowed}
                          onClick={() => {
                            setActiveStatus('inactive');
                            setShowed(false);
                          }}
                        />
                      )}
                    </div>
                    <div className="edit-toggle-btn">
                      <Link
                        to={'#'}
                        onClick={handleSubmit(onSubmit)}
                        className="btn btn-secondary"
                      >
                        Save
                      </Link>
                    </div>
                  </div>
                  <div className="edit-inner-body">
                    <div className="row">
                      <div className="col-lg-5 d-flex">
                        <div className="edit-inner-box w-100 custom-input-cls">
                          <div className="edit-header custom-input-cls">
                            <Controller
                              name="name"
                              control={control}
                              render={({ field, fieldState }) => (
                                <input
                                  className={`form-control ${fieldState.invalid ? 'is-invalid' : ''}`}
                                  type="text"
                                  placeholder="Name"
                                  {...field}
                                  onBlur={() => trigger('name')}
                                  onChange={(event: any) => {
                                    field.onChange(event);
                                    trigger('name');
                                  }}
                                  onKeyDown={(event) => {
                                    const regex = onlyAlphabet;
                                    if (
                                      !regex.test(event.key) &&
                                      event.key !== 'Backspace'
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                  maxLength={nameMaxLength}
                                />
                              )}
                            />
                            <ErrorMessage
                              value={errors.name}
                              className="d-inline-flex mt-2"
                            />
                            <ul className="nav mt-2">
                              <li>#{listData?.reg_id}</li>
                              <li>
                                <p>
                                  <span
                                    className={
                                      activeStatus === 'active'
                                        ? 'status-active'
                                        : 'status-inactive'
                                      // : 'status-disabled'
                                    }
                                  />
                                  {activeStatus === 'active'
                                    ? 'Active'
                                    : 'In Active'}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7 d-flex">
                        <div className="edit-details w-100">
                          <div className="edit-list edit-list-info">
                            <ul>
                              <li>
                                <img src={edit02} alt="Icon" />
                                <div className="w-100">
                                  <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <input
                                          {...field}
                                          type="text"
                                          placeholder="Email"
                                          className={`form-control ${
                                            errors.email ? 'is-invalid' : ''
                                          }`}
                                          maxLength={emailMaxLength}
                                          onBlur={() => trigger('email')}
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('email');
                                          }}
                                        />
                                        {errors.email && (
                                          <small className="text-danger d-inline-flex mt-2">
                                            {errors.email.message}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  />
                                </div>
                              </li>
                              <li>
                                <img src={edit01} alt="Icon" />
                                <div
                                  className={`form-wrap custom-form-wrap ${formInvalid ? 'is-invalid' : ''}`}
                                >
                                  <Controller
                                    name="ph_no"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                      <div>
                                        <PhoneInputWithRef
                                          {...field}
                                          placeholder="Enter phone number"
                                          country={'in'}
                                          value={field.value}
                                          onBlur={() => trigger('ph_no')}
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('ph_no');
                                          }}
                                          inputProps={{
                                            name: 'ph_no',
                                            required: true,
                                            // autoFocus: true,
                                            className: `form-control ${fieldState.invalid ? 'is-invalid' : ''}`,
                                          }}
                                        />
                                        {fieldState.invalid && (
                                          <small className="text-danger d-inline-flex mt-2">
                                            {errors.ph_no?.message}
                                          </small>
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Edit Paramedic */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default ParamedicEdit;
