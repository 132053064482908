import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ApiServiceContext } from '../../../../../../utils/shared.module';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import { end_points } from '../../../../../../services/end_point/end_points';
import { useSelector } from 'react-redux';
import ErrorMessage from '../../../../../../components/errorMessage';
import { onlyNumber } from '../../../../../../utils/patterns/regex.pattern';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import {
  durationOptions,
  durationTypeOptions,
} from '../../../../../../assets/global';
import { capitalizeFirstLetter } from '../../../../../../utils/constant';
import InputDateTextFormat from './inputDateTextFormat';

export const conditions: any = {
  Father: [
    { name: 'Diabetes', isChecked: false, date: '' },
    { name: 'Hypertension', isChecked: false, date: '' },
    { name: 'Heart Disease', isChecked: false, date: '' },
    { name: 'Thyroid Disease', isChecked: false, date: '' },
    { name: 'Liver Disease', isChecked: false, date: '' },
    { name: 'Seizure', isChecked: false, date: '', frequency: null, per: null },
  ],
  Mother: [
    { name: 'Diabetes', isChecked: false, date: '' },
    { name: 'Hypertension', isChecked: false, date: '' },
    { name: 'Heart Disease', isChecked: false, date: '' },
    { name: 'Thyroid Disease', isChecked: false, date: '' },
    { name: 'Liver Disease', isChecked: false, date: '' },
    { name: 'Seizure', isChecked: false, date: '', frequency: null, per: null },
  ],
};

const createValidationSchema = (conditions: any) => {
  return yup.object().shape(
    Object.keys(conditions).reduce((acc: any, parent: string) => {
      conditions[parent].forEach((condition: any) => {
        if (condition.isChecked) {
          if (condition.name === 'Seizure') {
            acc[`${parent}_${condition.name}_date`] = yup
              .string()
              .required(`${condition.name} date is required`);
            acc[`${parent}_${condition.name}_frequency`] = yup
              .object({
                name: yup.number().required('Frequency is required'),
              })
              .nullable()
              .required('Frequency is required');
            acc[`${parent}_${condition.name}_per`] = yup
              .object({
                name: yup.string().trim().required('Limit is required'),
              })
              .nullable()
              .required('Limit is required');
          } else {
            acc[`${parent}_${condition.name}_date`] = yup
              .string()
              .required(`${condition.name} date is required`);
          }
        } else {
          if (condition.name === 'Seizure') {
            acc[`${parent}_${condition.name}_date`] = yup.string().optional();
            acc[`${parent}_${condition.name}_frequency`] = yup
              .object()
              .optional()
              .nullable();
            acc[`${parent}_${condition.name}_per`] = yup
              .object()
              .optional()
              .nullable();
          } else {
            acc[`${parent}_${condition.name}_date`] = yup.string().optional();
          }
        }
      });
      return acc;
    }, {}),
  );
};

const generateDefaultValues = (conditions: any) => {
  return Object.keys(conditions).reduce((acc: any, parent: string) => {
    conditions[parent].forEach((condition: any) => {
      acc[`${parent}_${condition.name}_isChecked`] = false;
      if (condition.name === 'Seizure') {
        acc[`${parent}_${condition.name}_date`] = '';
        acc[`${parent}_${condition.name}_frequency`] =
          {
            name: condition.frequency || undefined,
            code: condition.frequency || undefined,
          } || null;
        acc[`${parent}_${condition.name}_per`] =
          { name: condition.per, code: condition.per } || null;
      } else {
        acc[`${parent}_${condition.name}_date`] = '';
      }
    });
    acc['haveFatherSelect'] = 'No';
    acc['haveMotherSelect'] = 'No';
    return acc;
  }, {});
};
const isFamilyDieaseSchema = yup.object().shape({
  haveFamilyDiease: yup.string().required('Please Choose'),
  haveFatherSelect: yup.string().required('Please mention father disease'),
});
const isFamilyMotherDieaseSchema = yup.object().shape({
  haveMotherSelect: yup.string().required('Please mention mother disease'),
});
interface FamilyHistoryDieaseProps {
  onSave?: Function;
}
const FamilyHistoryDiease: React.FC<FamilyHistoryDieaseProps> = ({
  onSave = () => {},
}) => {
  const patient_Id = useSelector(
    (state: any) => state?.common?.editData?.patient_id,
  );

  const { getData, postData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const [medicalHistory, setMedicalHistory] = useState<any>(conditions);
  const [hasFamilyHistory, setHasFamilyHistory] = useState<string | null>('No');
  const [hasFatherHistory, setHasFatherHistory] = useState<string | null>('No');
  const [hasMotherHistory, setHasMotherHistory] = useState<string | null>('No');

  let validationSchema = createValidationSchema(medicalHistory);
  if (hasFatherHistory === 'Yes') {
    validationSchema.concat(isFamilyDieaseSchema);
  }
  if (hasMotherHistory === 'Yes') {
    validationSchema.concat(isFamilyMotherDieaseSchema);
  }

  const defaultValues = generateDefaultValues(conditions);
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  // console.log(getValues(), errors, medicalHistory, 'medical');

  const createDetails = (items: any = []) => {
    return items?.map((item: any) => {
      const details: any = {
        name: item.name.toLowerCase().replace(/ /g, '_'),
        is_check: item.isChecked ? 'yes' : 'no',
      };
      if (item.isChecked) {
        details.since = item.date || '';
        if (item.frequency)
          details.frequency = item.frequency?.code || item.frequency;
        if (item.per) details.per = item.per?.code || item.per;
      }
      return details;
    });
    // ?.filter((detail: any) => detail.is_check === 'yes') || []
  };

  const onSubmit = async (data: any) => {
    try {
      showLoader();

      const payload = {
        label: [
          {
            familyhistoryofdisease: hasFamilyHistory?.toLowerCase(),
            parent:
              hasFamilyHistory === 'Yes'
                ? Object.keys(medicalHistory).map((parentType) => {
                    const parentItems = medicalHistory[parentType];
                    const details =
                      parentType === 'Mother'
                        ? (hasMotherHistory === 'Yes' &&
                            createDetails(parentItems)) ||
                          []
                        : (hasFatherHistory === 'Yes' &&
                            createDetails(parentItems)) ||
                          [];
                    return {
                      name: parentType.toLowerCase(),
                      is_check:
                        parentType === 'Mother'
                          ? hasMotherHistory === 'Yes'
                            ? 'yes'
                            : 'no'
                          : hasFatherHistory === 'Yes'
                            ? 'yes'
                            : 'no',
                      details,
                    };
                  })
                : [],
          },
        ],
      };

      let url = end_points.pastFamilyhistoryofCreateApi.url; // Add the appropriate URL
      if (patient_Id) {
        url += `/${patient_Id}`;
      }
      const response = await postData(url, payload);
      if (response.status === 200) {
        // Handle success
        onSave();
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleFamilyHistoryChange = (value: string) => {
    setHasFamilyHistory(value);
    if (value === 'No') {
      setHasFatherHistory('No');
      setHasMotherHistory('No');
    }
  };

  const handleRadioChange = (parent: any, condition: any, value: any) => {
    const newHistory = { ...medicalHistory };
    newHistory[parent] = newHistory[parent].map((c: any) => {
      return c.name === condition.name
        ? { ...c, isChecked: value === 'Yes' }
        : c;
    });
    setMedicalHistory(newHistory);

    if (value === 'No') {
      clearErrors(`${parent}_${condition.name}_date`);
      setValueBasedOndataz(newHistory);
      if (condition.name === 'Seizure') {
        clearErrors(`${parent}_${condition.name}_frequency`);
        clearErrors(`${parent}_${condition.name}_per`);
      }
    }
  };

  const handleDateChange = (parent: any, condition: any, value: any) => {
    const newHistory = { ...medicalHistory };
    newHistory[parent] = newHistory[parent].map((c: any) =>
      c.name === condition.name ? { ...c, date: value } : c,
    );
    // setValueBasedOndataz(newHistory);
    setMedicalHistory(newHistory);
  };

  const handleFrequencyChange = (parent: any, condition: any, value: any) => {
    const newHistory = { ...medicalHistory };
    newHistory[parent] = newHistory[parent].map((c: any) =>
      c.name === condition.name ? { ...c, frequency: value } : c,
    );
    setMedicalHistory(newHistory);
  };

  const handlePerChange = (parent: any, condition: any, value: any) => {
    const newHistory = { ...medicalHistory };
    newHistory[parent] = newHistory[parent].map((c: any) =>
      c.name === condition.name ? { ...c, per: value } : c,
    );
    setMedicalHistory(newHistory);
  };
  const updateConditions = (labelRes: any) => {
    const updatedConditions = { ...conditions };

    labelRes?.parent?.forEach((parent: any) => {
      const parentKey = parent.name === 'father' ? 'Father' : 'Mother';

      parent?.details?.forEach((detail: any) => {
        const condition = updatedConditions[parentKey].find(
          (cond: any) =>
            cond.name.toLowerCase() === detail?.name?.replace('_', ' '),
        );
        if (condition) {
          condition.isChecked = detail?.is_check === 'yes';
          condition.date = detail?.since;

          if (detail?.name === 'seizure') {
            condition.frequency =
              detail?.frequency?.name || detail?.frequency || null;
            condition.per = detail?.per?.name || detail?.per || null;
          }
        }
      });
    });

    return updatedConditions;
  };
  const setValueBasedOndataz = (mainResponseData: any) => {
    Object.keys(mainResponseData).forEach((parent) => {
      conditions[parent].forEach((condition: any) => {
        setValue(
          `${parent}_${condition.name}_isChecked`,
          condition.isChecked || false,
        );

        if (condition.name === 'Seizure') {
          setValue(`${parent}_${condition.name}_date`, condition.date || '');
          setValue(
            `${parent}_${condition.name}_frequency`,
            { name: condition.frequency, code: condition.frequency } || null,
          );
          setValue(
            `${parent}_${condition.name}_per`,
            {
              name: capitalizeFirstLetter(condition.per),
              code: condition.per,
            } || null,
          );
        } else {
          setValue(`${parent}_${condition.name}_date`, condition.date || '');
        }
      });
    });
  };
  const getFamilyDiseaseHistoty = async () => {
    try {
      showLoader();
      let url = end_points.pastFamilyDiseaseGetApi.url;
      if (patient_Id) {
        url += `/${patient_Id}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        const labelRes = response.data?.data?.label?.[0] || [];
        const mainResponseData = updateConditions(labelRes);
        // const newHistory = response.data?.data?.label[0]?.pasthistory?.map(
        //   (item: any) => {
        //     if (item.name === 'Seizure') {
        //       return {
        //         name: item.name,
        //         isChecked: item.is_check === 'yes',
        //         date: item.since,
        //         frequency: item.frequency,
        //         per: item.per,
        //       };
        //     } else
        //       return {
        //         name: item.name,
        //         isChecked: item.is_check === 'yes',
        //         date: item.since,
        //       };
        //   },
        // );
        setValueBasedOndataz(mainResponseData);
        const getParentCheckStatus = (parents: any, parentName: any) => {
          const parent = parents.find((p) => p.name === parentName);
          return parent ? (parent.is_check === 'yes' ? 'Yes' : 'No') : 'No';
        };

        const fatherCheckStatus = getParentCheckStatus(
          labelRes?.parent || [],
          'father',
        );
        const motherCheckStatus = getParentCheckStatus(
          labelRes?.parent || [],
          'mother',
        );

        setValue('haveFatherSelect', fatherCheckStatus);
        setValue('haveMotherSelect', motherCheckStatus);
        setValue(
          'haveFamilyDiease',
          labelRes?.familyhistoryofdisease === 'yes' ? 'Yes' : 'No',
        );

        setHasFatherHistory(fatherCheckStatus);
        setHasMotherHistory(motherCheckStatus);
        setHasFamilyHistory(
          labelRes?.familyhistoryofdisease === 'yes' ? 'Yes' : 'No',
        );
        setMedicalHistory(mainResponseData);
      }
    } catch (e) {
      console.log(e);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    patient_Id && getFamilyDiseaseHistoty();
  }, [patient_Id]);
  return (
    <div
      className="tab-pane fade"
      id="family_history"
      role="tabpanel"
      aria-labelledby="family_history"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="card medical-history-card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="past-surgeries past-medical-info">
                  <ul className="nav">
                    <li>
                      <h6>Family History of Disease</h6>
                    </li>
                    <li>
                      <Controller
                        name="haveFamilyDiease"
                        control={control}
                        defaultValue={'No'}
                        render={({ field }) => (
                          <div className="custom-radio patient-radio-btn">
                            <label className="custom_radio">
                              <input
                                type="radio"
                                name="familyHistory"
                                className="form-control"
                                value="Yes"
                                checked={hasFamilyHistory === 'Yes'}
                                onChange={() => {
                                  handleFamilyHistoryChange('Yes');
                                  field.onChange('Yes');
                                }}
                              />
                              <span className="checkmark" /> <b>Yes</b>
                            </label>
                            <label className="custom_radio">
                              <input
                                type="radio"
                                name="familyHistory"
                                className="form-control"
                                value="No"
                                checked={hasFamilyHistory === 'No'}
                                onChange={() => {
                                  handleFamilyHistoryChange('No');
                                  field.onChange('No');
                                  reset();
                                  setValue('haveFatherSelect', 'No');
                                  setValue('haveMotherSelect', 'No');
                                  setHasFatherHistory('No');
                                  setHasMotherHistory('No');
                                  clearErrors();
                                  setMedicalHistory(conditions);
                                  setValueBasedOndataz(conditions);
                                }}
                              />
                              <span className="checkmark" /> <b>No</b>
                            </label>
                          </div>
                        )}
                      />
                      <ErrorMessage value={errors.haveFamilyDiease} />
                    </li>
                  </ul>
                </div>

                {hasFamilyHistory === 'Yes' && (
                  <>
                    <div className="past-surgeries past-surgeries-left">
                      <div className="family-history-info">
                        <ul className="nav family-history-nav-top">
                          <li>
                            <h5>Father</h5>
                          </li>
                          <li>
                            <Controller
                              name="haveFatherSelect"
                              control={control}
                              defaultValue={'No'}
                              render={({ field }) => (
                                <div className="custom-radio patient-radio-btn">
                                  <label className="custom_radio">
                                    <input
                                      type="radio"
                                      className="form-control"
                                      name="fatherHistory"
                                      value="Yes"
                                      checked={hasFatherHistory === 'Yes'}
                                      onChange={() => {
                                        setHasFatherHistory('Yes');
                                        field.onChange('Yes');
                                      }}
                                    />
                                    <span className="checkmark" /> <b>Yes</b>
                                  </label>
                                  <label className="custom_radio">
                                    <input
                                      type="radio"
                                      name="fatherHistory"
                                      className="form-control"
                                      value="No"
                                      checked={hasFatherHistory === 'No'}
                                      onChange={() => {
                                        setHasFatherHistory('No');
                                        field.onChange('No');
                                        setMedicalHistory((prev: any) => {
                                          return {
                                            ...prev,
                                            Father: conditions.Father,
                                          };
                                        });
                                      }}
                                    />
                                    <span className="checkmark" /> <b>No</b>
                                  </label>
                                </div>
                              )}
                            />
                            <ErrorMessage value={errors.haveFatherSelect} />
                          </li>
                          <li className="sub-history">
                            {hasFatherHistory === 'Yes' && (
                              <>
                                {medicalHistory.Father.map((condition: any) => (
                                  <ul
                                    key={condition.name}
                                    className="nav family-history-nav"
                                  >
                                    <li>
                                      <p>{condition.name}</p>
                                    </li>
                                    <li>
                                      <div className="surgerie-flex">
                                        <Controller
                                          name={`Father_${condition.name}_isChecked`}
                                          control={control}
                                          render={({ field }) => (
                                            <div className="custom-radio patient-radio-btn">
                                              <label className="custom_radio">
                                                <input
                                                  type="radio"
                                                  value="Yes"
                                                  className="form-control"
                                                  checked={condition.isChecked}
                                                  onChange={() => {
                                                    field.onChange('Yes');
                                                    handleRadioChange(
                                                      'Father',
                                                      condition,
                                                      'Yes',
                                                    );
                                                  }}
                                                />
                                                <span className="checkmark" />{' '}
                                                <b>Yes</b>
                                              </label>
                                              <label className="custom_radio">
                                                <input
                                                  type="radio"
                                                  value="No"
                                                  className="form-control"
                                                  checked={!condition.isChecked}
                                                  onChange={() => {
                                                    field.onChange('No');
                                                    handleRadioChange(
                                                      'Father',
                                                      condition,
                                                      'No',
                                                    );
                                                  }}
                                                />
                                                <span className="checkmark" />{' '}
                                                <b>No</b>
                                              </label>
                                            </div>
                                          )}
                                        />
                                        {/* {errors[
                                          `Father_${condition.name}_date`
                                        ] && (
                                          <p className="error">
                                            {
                                              errors[
                                                `Father_${condition.name}_date`
                                              ]?.message
                                            }
                                          </p>
                                        )} */}
                                        {condition.isChecked && (
                                          <div className="since-input">
                                            <span>Since</span>
                                            <Controller
                                              name={`Father_${condition.name}_date`}
                                              control={control}
                                              render={({ field }) => (
                                                <div className="d-flex flex-column">
                                                  <InputDateTextFormat
                                                    field={field}
                                                    // index={index}
                                                    condition={condition}
                                                    handleDateChange={
                                                      handleDateChange
                                                    }
                                                    controlName={`Father_${condition.name}_date`}
                                                    className={`form-control datetimepicker ${
                                                      errors[
                                                        `Father_${condition.name}_date`
                                                      ]
                                                        ? 'is-invalid'
                                                        : ''
                                                    }`}
                                                    setValue={setValue}
                                                    type={'Father'}
                                                    trigger={trigger}
                                                  />
                                                  <ErrorMessage
                                                    value={
                                                      errors[
                                                        `Father_${condition.name}_date`
                                                      ]
                                                    }
                                                  />
                                                </div>

                                                // <input
                                                //   type="text"
                                                //   value={condition.date}
                                                //   className="form-control"
                                                //   // placeholder="MM/YYYY"
                                                //   onChange={(e) => {
                                                //     field.onChange(
                                                //       e.target.value,
                                                //     );
                                                //     handleDateChange(
                                                //       'Father',
                                                //       condition,
                                                //       e.target.value,
                                                //     );
                                                //   }}
                                                // />
                                              )}
                                            />
                                          </div>
                                        )}

                                        {condition.name === 'Seizure' &&
                                          condition.isChecked && (
                                            <>
                                              <div className="since-input">
                                                <span>Frequency</span>
                                                <Controller
                                                  name={`Father_${condition.name}_frequency`}
                                                  control={control}
                                                  render={({ field }) => (
                                                    // <input
                                                    //   type="text"
                                                    //   className="form-control"
                                                    //   placeholder="No. of Times"
                                                    //   onKeyDown={(event) => {
                                                    //     const regex =
                                                    //       onlyNumber;
                                                    //     if (
                                                    //       !regex.test(
                                                    //         event.key,
                                                    //       ) &&
                                                    //       event.key !==
                                                    //         'Backspace'
                                                    //     ) {
                                                    //       event.preventDefault();
                                                    //     }
                                                    //   }}
                                                    //   value={
                                                    //     condition.frequency
                                                    //   }
                                                    //   onChange={(e) => {
                                                    //     field.onChange(
                                                    //       e.target.value,
                                                    //     );
                                                    // handleFrequencyChange(
                                                    //   'Father',
                                                    //   condition,
                                                    //   e.target.value,
                                                    // );
                                                    // }}
                                                    // />
                                                    <div className="d-flex flex-column">
                                                      <Dropdown
                                                        {...field}
                                                        placeholder="No. of Times"
                                                        options={
                                                          durationOptions
                                                        }
                                                        value={field?.value}
                                                        onBlur={() =>
                                                          trigger(
                                                            `Father_${condition.name}_frequency`,
                                                          )
                                                        }
                                                        className={`patient-reg-select ${errors[`Father_${condition.name}_frequency`] ? 'is-invalid' : ''}`}
                                                        optionLabel="name"
                                                        onChange={(e) => {
                                                          field.onChange(
                                                            e.target.value,
                                                          );
                                                          handleFrequencyChange(
                                                            'Father',
                                                            condition,
                                                            e.target.value,
                                                          );
                                                        }}
                                                      />
                                                      <ErrorMessage
                                                        value={
                                                          errors[
                                                            `Father_${condition.name}_frequency`
                                                          ]?.name
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                />
                                              </div>
                                              <div className="since-input per-since-input">
                                                <span>Per</span>
                                                <Controller
                                                  name={`Father_${condition.name}_per`}
                                                  control={control}
                                                  render={({ field }) => (
                                                    // <input
                                                    //   type="text"
                                                    //   placeholder="Per"
                                                    //   className="form-control"
                                                    //   value={condition.per}
                                                    //   onChange={(e) => {
                                                    //     field.onChange(
                                                    //       e.target.value,
                                                    //     );
                                                    // handlePerChange(
                                                    //   'Father',
                                                    //   condition,
                                                    //   e.target.value,
                                                    // );
                                                    //   }}
                                                    // />
                                                    <div className="d-flex flex-column">
                                                      <Dropdown
                                                        {...field}
                                                        placeholder="Month"
                                                        options={
                                                          durationTypeOptions
                                                        }
                                                        value={field?.value}
                                                        onBlur={() =>
                                                          trigger(
                                                            `Father_${condition.name}_per`,
                                                          )
                                                        }
                                                        className={`patient-reg-select ${errors[`Father_${condition.name}_per`] ? 'is-invalid' : ''}`}
                                                        optionLabel="name"
                                                        onChange={(e) => {
                                                          field.onChange(
                                                            e.target.value,
                                                          );
                                                          handlePerChange(
                                                            'Father',
                                                            condition,
                                                            e.target.value,
                                                          );
                                                        }}
                                                      />
                                                      <ErrorMessage
                                                        value={
                                                          errors[
                                                            `Father_${condition.name}_per`
                                                          ]?.name
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                />
                                              </div>
                                            </>
                                          )}
                                      </div>
                                    </li>
                                  </ul>
                                ))}
                              </>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="past-surgeries past-surgeries-left">
                      <div className="family-history-info">
                        <ul className="nav family-history-nav-top">
                          <li>
                            <h5>Mother</h5>
                          </li>
                          <li>
                            <Controller
                              name="haveMotherSelect"
                              control={control}
                              defaultValue={'No'}
                              render={({ field }) => (
                                <div className="custom-radio patient-radio-btn">
                                  <label className="custom_radio">
                                    <input
                                      type="radio"
                                      name="motherHistory"
                                      className="form-control"
                                      value="Yes"
                                      checked={hasMotherHistory === 'Yes'}
                                      onChange={() => {
                                        setHasMotherHistory('Yes');
                                        field.onChange('Yes');
                                      }}
                                    />
                                    <span className="checkmark" /> <b>Yes</b>
                                  </label>
                                  <label className="custom_radio">
                                    <input
                                      type="radio"
                                      name="motherHistory"
                                      className="form-control"
                                      value="No"
                                      checked={hasMotherHistory === 'No'}
                                      onChange={() => {
                                        setHasMotherHistory('No');
                                        field.onChange('No');
                                        setMedicalHistory((prev: any) => {
                                          return {
                                            ...prev,
                                            Mother: conditions.Mother,
                                          };
                                        });
                                      }}
                                    />
                                    <span className="checkmark" /> <b>No</b>
                                  </label>
                                </div>
                              )}
                            />
                            <ErrorMessage value={errors.haveMotherSelect} />
                          </li>
                          <li className="sub-history">
                            {hasMotherHistory === 'Yes' && (
                              <>
                                {medicalHistory.Mother.map((condition: any) => (
                                  <ul
                                    className="nav family-history-nav"
                                    key={condition.name}
                                  >
                                    <li>
                                      <p>{condition.name}</p>
                                    </li>
                                    <li>
                                      <div className="surgerie-flex">
                                        <Controller
                                          name={`Mother_${condition.name}_isChecked`}
                                          control={control}
                                          render={({ field }) => (
                                            <div className="custom-radio patient-radio-btn">
                                              <label className="custom_radio">
                                                <input
                                                  type="radio"
                                                  value="Yes"
                                                  className="form-control"
                                                  checked={condition.isChecked}
                                                  onChange={() => {
                                                    field.onChange('Yes');
                                                    handleRadioChange(
                                                      'Mother',
                                                      condition,
                                                      'Yes',
                                                    );
                                                  }}
                                                />
                                                <span className="checkmark" />{' '}
                                                <b>Yes</b>
                                              </label>
                                              <label className="custom_radio">
                                                <input
                                                  type="radio"
                                                  value="No"
                                                  className="form-control"
                                                  checked={!condition.isChecked}
                                                  onChange={() => {
                                                    field.onChange('No');
                                                    handleRadioChange(
                                                      'Mother',
                                                      condition,
                                                      'No',
                                                    );
                                                  }}
                                                />
                                                <span className="checkmark" />{' '}
                                                <b>No</b>
                                              </label>
                                            </div>
                                          )}
                                        />
                                        {/* {errors[
                                          `Mother_${condition.name}_date`
                                        ] && (
                                          <p className="error">
                                            {
                                              errors[
                                                `Mother_${condition.name}_date`
                                              ]?.message
                                            }
                                          </p>
                                        )} */}
                                        {condition.isChecked && (
                                          <div className="since-input">
                                            <span>Since</span>
                                            <Controller
                                              name={`Mother_${condition.name}_date`}
                                              control={control}
                                              render={({ field }) => (
                                                <div className="d-flex flex-column">
                                                  <InputDateTextFormat
                                                    field={field}
                                                    // index={index}
                                                    condition={condition}
                                                    handleDateChange={
                                                      handleDateChange
                                                    }
                                                    controlName={`Mother_${condition.name}_date`}
                                                    className={`form-control datetimepicker ${
                                                      errors[
                                                        `Mother_${condition.name}_date`
                                                      ]
                                                        ? 'is-invalid'
                                                        : ''
                                                    }`}
                                                    setValue={setValue}
                                                    type={'Mother'}
                                                    trigger={trigger}
                                                  />
                                                  <ErrorMessage
                                                    value={
                                                      errors[
                                                        `Mother_${condition.name}_date`
                                                      ]
                                                    }
                                                  />
                                                </div>

                                                // <input

                                                //   type="text"
                                                //   className="form-control"
                                                //   value={condition.date}
                                                //   onChange={(e) => {
                                                //     field.onChange(
                                                //       e.target.value,
                                                //     );
                                                //     handleDateChange(
                                                //       'Mother',
                                                //       condition,
                                                //       e.target.value,
                                                //     );
                                                //   }}
                                                // />
                                              )}
                                            />
                                          </div>
                                        )}
                                        {/* {condition.name === 'Seizure' &&
                                          condition.isChecked && (
                                            <>
                                              <input
                                                type="number"
                                                placeholder="Frequency"
                                                value={condition.frequency}
                                                onChange={(e) =>
                                                  handleFrequencyChange(
                                                    'Mother',
                                                    condition,
                                                    e.target.value,
                                                  )
                                                }
                                              />
                                              <input
                                                type="text"
                                                placeholder="Per"
                                                value={condition.per}
                                                onChange={(e) =>
                                                  handlePerChange(
                                                    'Mother',
                                                    condition,
                                                    e.target.value,
                                                  )
                                                }
                                              />
                                            </>
                                          )} */}
                                        {condition.name === 'Seizure' &&
                                          condition.isChecked && (
                                            <>
                                              <div className="since-input">
                                                <span>Frequency</span>
                                                <Controller
                                                  name={`Mother_${condition.name}_frequency`}
                                                  control={control}
                                                  render={({ field }) => (
                                                    // <input
                                                    //   type="text"
                                                    //   placeholder="No. of Times"
                                                    //   onKeyDown={(event) => {
                                                    //     const regex =
                                                    //       onlyNumber;
                                                    //     if (
                                                    //       !regex.test(
                                                    //         event.key,
                                                    //       ) &&
                                                    //       event.key !==
                                                    //         'Backspace'
                                                    //     ) {
                                                    //       event.preventDefault();
                                                    //     }
                                                    //   }}
                                                    //   className="form-control"
                                                    //   value={
                                                    //     condition.frequency
                                                    //   }
                                                    //   onChange={(e) => {
                                                    //     field.onChange(
                                                    //       e.target.value,
                                                    //     );
                                                    // handleFrequencyChange(
                                                    //   'Mother',
                                                    //   condition,
                                                    //   e.target.value,
                                                    // );
                                                    //   }}
                                                    // />
                                                    <div className="d-flex flex-column">
                                                      <Dropdown
                                                        {...field}
                                                        placeholder="No. of Times"
                                                        options={
                                                          durationOptions
                                                        }
                                                        value={field?.value}
                                                        onBlur={() =>
                                                          trigger(
                                                            `Mother_${condition.name}_frequency`,
                                                          )
                                                        }
                                                        className={`patient-reg-select ${errors[`Mother_${condition.name}_frequency`]?.name ? 'is-invalid' : ''}`}
                                                        optionLabel="name"
                                                        onChange={(e) => {
                                                          field.onChange(
                                                            e.target.value,
                                                          );
                                                          handleFrequencyChange(
                                                            'Mother',
                                                            condition,
                                                            e.target.value,
                                                          );
                                                        }}
                                                      />
                                                      <ErrorMessage
                                                        className="d-block"
                                                        value={
                                                          errors[
                                                            `Mother_${condition.name}_frequency`
                                                          ]?.name
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                />

                                                {/* `Mother_${condition.name}_frequency` */}
                                              </div>
                                              <div className="since-input per-since-input">
                                                <span>Per</span>
                                                <Controller
                                                  name={`Mother_${condition.name}_per`}
                                                  control={control}
                                                  render={({ field }) => (
                                                    // <input
                                                    //   type="text"
                                                    //   placeholder="Per"
                                                    //   className="form-control"
                                                    //   value={condition.per}
                                                    //   onChange={(e) => {
                                                    //     field.onChange(
                                                    //       e.target.value,
                                                    //     );
                                                    // handlePerChange(
                                                    //   'Mother',
                                                    //   condition,
                                                    //   e.target.value,
                                                    // );
                                                    //   }}
                                                    // />
                                                    <div className="d-flex flex-column">
                                                      <Dropdown
                                                        {...field}
                                                        placeholder="Month"
                                                        options={
                                                          durationTypeOptions
                                                        }
                                                        value={field?.value}
                                                        onBlur={() =>
                                                          trigger(
                                                            `Mother_${condition.name}_per`,
                                                          )
                                                        }
                                                        className={`patient-reg-select ${errors[`Mother_${condition.name}_per`]?.name ? 'is-invalid' : ''}`}
                                                        optionLabel="name"
                                                        onChange={(e) => {
                                                          field.onChange(
                                                            e.target.value,
                                                          );
                                                          handlePerChange(
                                                            'Mother',
                                                            condition,
                                                            e.target.value,
                                                          );
                                                        }}
                                                      />
                                                      <ErrorMessage
                                                        value={
                                                          errors[
                                                            `Mother_${condition.name}_per`
                                                          ]?.name
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                />
                                                {/* <ErrorMessage
                                                  value={
                                                    errors[
                                                      `Mother_${condition.name}_per`
                                                    ]?.name
                                                  }
                                                /> */}
                                              </div>
                                            </>
                                          )}
                                      </div>
                                    </li>
                                  </ul>
                                ))}
                              </>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}

                <div className="past-medical-btn">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FamilyHistoryDiease;
