import moment from 'moment';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  dateTextSlashRegex,
  monthYearSlashRegex,
  onlyNumber,
} from '../../../../../../utils/patterns/regex.pattern';

interface InputDateTextFormatProps {
  field: any;
  setValue: any;
  handleDateChange: any;
  condition: any;
  index?: any;
  type?: string;
  className?: string;
  controlName: string;
  trigger: any;
}
const InputDateTextFormat: React.FC<InputDateTextFormatProps> = ({
  field,
  setValue,
  handleDateChange,
  condition,
  index,
  type,
  className,
  controlName,
  trigger,
}) => {
  return (
    <input
      type="text"
      {...field}
      className={className ? className : 'form-control datetimepicker'}
      placeholder="MM/YYYY"
      value={field.value}
      onBlur={() => controlName && trigger(controlName)}
      onKeyDown={(event) => {
        const regex = onlyNumber;
        if (
          !regex.test(event.key) &&
          event.key !== 'Backspace' &&
          event.key !== 'Delete'
        ) {
          event.preventDefault();
        }
        const value = field.value;
        if (
          event.key === 'Backspace' &&
          value.length === 3 &&
          value[2] === '/'
        ) {
          setValue(controlName, value.slice(0, 2));
          event.preventDefault();
        }
      }}
      onChange={(e) => {
        let value = e.target.value;

        // Handle the first digit of the month
        if (value.length === 1) {
          const firstDigit = Number(value);
          if (firstDigit > 2) {
            // If the first digit is greater than 2, set it to '0'
            value = `0${firstDigit}`;
          }
        }

        // Automatically add a slash after the month if it's valid
        if (value.length === 2 && !value.includes('/')) {
          const month = Number(value);
          if (month >= 1 && month <= 12) {
            value = `${value}/`;
          } else {
            value = '0'; // Reset if the month is invalid
          }
        }

        // Handle the year part
        if (value.length > 3 && value.includes('/')) {
          const [month, year] = value.split('/');
          if (year && year.length > 4) {
            value = `${month}/${year.slice(0, 4)}`;
          }
          if (year && Number(year) > 2024) {
            value = `${month}/2024`;
          }
          const inputDate = moment(`${month}/01/${year}`, 'MM/DD/YYYY');
          if (inputDate.isAfter(moment(), 'month')) {
            value = moment().format('MM/YYYY');
          }
        }

        // Limit the input to 7 characters (MM/YYYY)
        if (value.length > 7) {
          value = value.slice(0, 7);
        }

        // Update the form field value
        field.onChange(value);

        setValue(controlName, value);
        if (controlName) trigger(controlName);
        // Call handleDateChange with the correct value
        if (type) {
          handleDateChange(type, condition, value);
        } else handleDateChange(index, value);
      }}
    />
  );
};

export default InputDateTextFormat;

interface DateInputProps {
  field: any;
  placeholder?: string;
  className?: string;
  controlName?: any;
}

export const DateInput: React.FC<DateInputProps> = ({
  field,
  placeholder = 'DD/MM/YYYY',
  className,
  controlName,
}) => {
  const { trigger } = useFormContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters

    if (value.length <= 2) {
      // Handle day input
      const firstDigit = Number(value[0]);

      if (firstDigit > 3) {
        // If the first digit is greater than 3, reset it to '0'
        value = `0${value[0]}`;
      } else if (firstDigit === 3 && Number(value[1]) > 1) {
        // If the first digit is 3, the second digit must be 0 or 1
        value = `3`;
      }
    } else if (value.length > 2 && value.length <= 4) {
      // Handle month input after day is complete
      const day = value.slice(0, 2);
      let month = value.slice(2, 4);

      if (month.length === 1) {
        const firstDigit = Number(month[0]);

        if (firstDigit > 1) {
          // If the first digit is greater than 1, reset it to '0'
          month = `0${month[0]}`;
        } else if (firstDigit === 1 && Number(month[1]) > 2) {
          // If the first digit is 1, the second digit must be 0, 1, or 2
          month = `1`;
        }
      }

      value = `${day}/${month}`;
    } else if (value.length > 4) {
      // Handle year input after day and month are complete
      const day = value.slice(0, 2);
      const month = value.slice(2, 4);
      let year = value.slice(4, 8);
      const currentYear = moment().year();
      if (year && Number(year) > currentYear) {
        year = String(currentYear);
      }

      value = `${day}/${month}/${year}`;

      // Check if the date is in the future and reset to today if necessary
      const inputDate = moment(value, 'DD/MM/YYYY');
      if (inputDate.isAfter(moment(), 'day')) {
        value = moment().format('DD/MM/YYYY');
      }
    }

    // Limit the input to 10 characters (DD/MM/YYYY)
    if (value.length > 10) {
      value = value.slice(0, 10);
    }

    // Validate the full date (DD/MM/YYYY)
    if (value.length === 10) {
      const [day, month, year] = value.split('/').map(Number);
      const isValidDate = (
        day: number,
        month: number,
        year: number,
      ): boolean => {
        const maxDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Leap year check for February
        if (
          month === 2 &&
          ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0)
        ) {
          return day <= 29;
        }

        return day <= maxDaysInMonth[month - 1];
      };

      if (!isValidDate(day, month, year)) {
        value = ''; // Reset the value if the date is invalid
      }
    }
    field.onChange(value); // Update form field value
    trigger(controlName);
  };
  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   let value = e.target.value;
  //   // Validate the full date (DD/MM/YYYY)
  //   if (value.length === 10) {
  //     const [year, month, day] = value.split('-').map(Number);

  //     const isValidDate = (
  //       year: number,
  //       month: number,
  //       day: number,
  //     ): boolean => {
  //       const maxDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  //       // Leap year check for February
  //       if (
  //         month === 2 &&
  //         ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0)
  //       ) {
  //         return day <= 29;
  //       }

  //       return day <= maxDaysInMonth[month - 1];
  //     };

  //     if (!isValidDate(year, month, day)) {
  //       value = ''; // Reset the value if the date is invalid
  //     }
  //   }

  //   field.onChange(value); // Update form field value
  //   trigger(controlName);
  // };

  return (
    <div>
      <input
        {...field}
        type="text"
        className={className}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyDown={(event) => {
          const regex = onlyNumber;
          if (
            !regex.test(event.key) &&
            event.key !== 'Backspace' &&
            event.key !== 'Delete'
          ) {
            event.preventDefault();
          }
        }}
        maxLength={10}
        onBlur={() => {
          trigger(controlName);
        }}
      />
    </div>
  );
};
