import React, { useContext, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CustomPaginator from './customPaginator';
import { useDispatch } from 'react-redux';
import { setEditData } from '../../core/redux/commonSlice';
import { SpinnerContext } from '../spinner/spinner';
import { scheduleType, userRole } from '../../assets/global';
import { Row } from 'primereact/row';

interface Props {
  column: any;
  data: any;
  totalRecords: number;
  rowClassName?: string;
  currentPage: number;
  setCurrentPage: any;
  rows?: number;
  setRows?: any;
  onRowDoubleClick?: Function;
  onRowClickSetState?: boolean;
  type?: string;
  onClickNavigate?: Function;
  sortable?: boolean;
  footer?: any;
}

const PrimeDataTable: React.FC<Props> = ({
  column,
  data = [],
  totalRecords,
  onRowDoubleClick = () => {},
  onClickNavigate = () => {},
  rowClassName = '',
  currentPage = 1,
  setCurrentPage,
  rows = 10,
  setRows,
  onRowClickSetState = true,
  type = 'normal',
  sortable = true,
  footer = null,
}) => {
  const { isLoading } = useContext(SpinnerContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const totalPages = Math.ceil(totalRecords / rows);
  const dispatch = useDispatch();
  const onPageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };
  const onRowClick = (event: any) => {
    setSelectedRow(event?.data);
    if (type === 'PATIENTLIST') {
      onClickNavigate(event?.data);
    } else if (onRowClickSetState) {
      dispatch(setEditData(event?.data));
      if (type !== userRole.Kiosk) onClickNavigate();
    }
  };
  const customEmptyMessage = () => (
    <div className="text-center p-3">
      {!isLoading && <h5>No records found</h5>}
    </div>
  );
  return (
    <>
      <div className="card table-card">
        <div className="table-responsive-custom">
          <DataTable
            value={data}
            className="table datatable"
            totalRecords={totalRecords}
            paginator={false}
            onRowClick={rowClassName ? onRowClick : () => {}}
            onRowDoubleClick={() => {
              if (type === userRole.Kiosk) {
                onClickNavigate();
              }
            }}
            rowClassName={(data) => {
              if (selectedRow && selectedRow === data) {
                return 'active';
              }
              return rowClassName;
            }}
            emptyMessage={customEmptyMessage}
            // footer={footer ? footer : null}
          >
            {column?.map((col: any) => (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={col.body}
                sortable={
                  sortable === false
                    ? false
                    : col.sortable === false
                      ? false
                      : true
                }
                sortField={col.sortField ? col.sortField : col.field}
                className={col.className ? col.className : ''}
              />
            ))}
          </DataTable>
          <CustomPaginator
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
          {footer ? footer() : null}
        </div>
      </div>
    </>
  );
};

export default PrimeDataTable;
