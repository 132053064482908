import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../utils/routes';
import { capitalizeFirstLetter } from '../../utils/constant';
import { editType, userRole } from '../../assets/global';

const SuccessComponent = () => {
  const pageWrapperRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const updateHeight = () => {
    if (pageWrapperRef.current) {
      const height = window.innerHeight;
      pageWrapperRef.current.style.minHeight = `${height}px`;
    }
  };
  const location = useLocation();
  const showPath = location?.state?.showPath;
  const fromType = location?.state?.fromType;

  const navigateToo = (type: string) => {
    return type === userRole.Doctor
      ? routes.doctorsList.path
      : type === userRole.Kiosk
        ? routes.kioskList.path
        : type === userRole.FrontDesk
          ? routes.frontDeskList.path
          : type === userRole.Paramedic
            ? routes.paramedicList.path
            : '';
  };
  useEffect(() => {
    updateHeight();
    window.addEventListener('resize', updateHeight);
    setTimeout(() => {
      navigate(navigateToo(fromType), {
        state: { showPath: showPath, fromType: fromType },
      });
    }, 2000);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper page-wrapper-info" ref={pageWrapperRef}>
        <div className="content container-fluid pb-0">
          {/* Success Doctor */}
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12">
              <div className="card success-card">
                <div className="card-body">
                  <div className="success-info">
                    <i className="far fa-circle-check" />
                    <p>
                      {fromType === userRole.FrontDesk
                        ? 'Front Desk'
                        : capitalizeFirstLetter(fromType)}{' '}
                      {showPath ? showPath : editType.added} Successfully
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Success Doctor */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default SuccessComponent;
