import React, { useContext, useEffect, useState } from 'react';
import ErrorMessage from '../../../../../../components/errorMessage';
import {
  ApiServiceContext,
  yupResolver,
} from '../../../../../../utils/shared.module';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import {
  Controller,
  useForm,
  useFieldArray,
  FormProvider,
} from 'react-hook-form';
import * as yup from 'yup';
import {
  characterLength_50,
  onlyAlphabet,
} from '../../../../../../utils/patterns/regex.pattern';
import { end_points } from '../../../../../../services/end_point/end_points';
import { useSelector } from 'react-redux';
import { DateInput } from './inputDateTextFormat';
const initialValues = {
  surgeries: 'No',
  surgeryDetails: [{ surgeryType: '', date: '', transfusion: 'No' }],
};

const validationSchema = yup.object().shape({
  surgeries: yup.string().required('Surgeries field is required'),
  surgeryDetails: yup.array().of(
    yup.object().shape({
      surgeryType: yup.string().required('Surgery type is required'),
      date: yup.string().required('Date is required'),
      transfusion: yup.string().required('Blood transfusion is required'),
    }),
  ),
});

interface PastSurgeriesProps {
  onSave?: Function;
}
const PastSurgeries: React.FC<PastSurgeriesProps> = ({ onSave = () => {} }) => {
  const { getData, putData, postData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const patient_Id = useSelector(
    (state: any) => state?.common?.editData?.patient_id,
  );
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const {
    handleSubmit,
    control,
    watch,
    reset,
    clearErrors,
    trigger,
    setValue,
    formState: { errors },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'surgeryDetails',
  });

  const onSubmit = async (data: any) => {
    // console.log(data, 'past');

    try {
      showLoader();
      const payload = {
        label:
          data.surgeries === 'Yes'
            ? data?.surgeryDetails?.map((item: any) => {
                return {
                  pastsurgeries: data.surgeries === 'Yes' ? 'yes' : 'no',
                  typeofsurgery: item.surgeryType,
                  date: item.date,
                  bloodtransfusion: item.transfusion === 'Yes' ? 'yes' : 'no',
                };
              })
            : [
                {
                  pastsurgeries: data.surgeries === 'Yes' ? 'yes' : 'no',
                },
              ],
      };
      let url = end_points.pastSurgeryCreateApi.url; // Add the appropriate URL
      if (patient_Id) {
        url += `/${patient_Id}`;
      }
      const response = await postData(url, payload);
      if (response.status === 200) {
        //
        onSave();
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const pastSurgeryWatch = watch('surgeries');
  const getPatientPastSurgery = async () => {
    try {
      showLoader();
      let url = end_points.getPatientPastSurgeryListApi.url;
      url += `/${patient_Id}`;
      const response = await getData(url);
      if (response.status === 200) {
        const res = response.data.data.label;
        setValue('surgeries', res?.[0]?.pastsurgeries === 'yes' ? 'Yes' : 'No');
        setValue(
          'surgeryDetails',
          res?.map((item: any) => {
            return {
              surgeryType: item.typeofsurgery,
              date: item.date,
              transfusion: item.bloodtransfusion === 'yes' ? 'Yes' : 'No',
            };
          }),
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    patient_Id && getPatientPastSurgery();
  }, [patient_Id]);
  return (
    <div
      className="tab-pane fade"
      id="past_surgeries"
      role="tabpanel"
      aria-labelledby="past-surgeries"
    >
      <div className="row">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-12">
              <div className="card medical-history-card">
                <div className="card-body">
                  <div className="past-surgeries past-medical-info">
                    <ul className="nav">
                      <li>
                        <h6>Past Surgeries</h6>
                      </li>
                      <li>
                        <div className="custom-radio patient-radio-btn">
                          <Controller
                            name="surgeries"
                            control={control}
                            render={({ field }) => (
                              <>
                                <label className="custom_radio">
                                  <input
                                    {...field}
                                    type="radio"
                                    value="Yes"
                                    checked={field.value === 'Yes'}
                                  />
                                  <span className="checkmark" /> Yes
                                </label>
                                <label className="custom_radio">
                                  <input
                                    {...field}
                                    type="radio"
                                    value="No"
                                    onClick={() => {
                                      reset(initialValues);
                                      clearErrors();
                                      remove();
                                    }}
                                    checked={field.value === 'No'}
                                  />
                                  <span className="checkmark" /> No
                                </label>
                              </>
                            )}
                          />
                          <ErrorMessage value={errors.surgeries} />
                        </div>
                      </li>
                    </ul>
                  </div>
                  {pastSurgeryWatch === 'Yes' && (
                    <>
                      <div className="past-surgeries past-surgeries-left">
                        {fields.map((item, index) => (
                          <div key={item.id} className="appendectomy-details">
                            <div className="appendectomy-info">
                              <div className="appendectomy-input d-flex justify-content-between">
                                <Controller
                                  name={`surgeryDetails[${index}].surgeryType`}
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      type="text"
                                      className={`form-control ${
                                        errors.surgeryDetails?.[index]
                                          ?.surgeryType
                                          ? 'is-invalid'
                                          : ''
                                      }`}
                                      onBlur={() =>
                                        trigger(
                                          `surgeryDetails[${index}].surgeryType`,
                                        )
                                      }
                                      onKeyDown={(event) => {
                                        const regex = onlyAlphabet;
                                        if (
                                          !regex.test(event.key) &&
                                          event.key !== 'Backspace'
                                        ) {
                                          event.preventDefault();
                                        }
                                      }}
                                      maxLength={characterLength_50}
                                      placeholder="Surgery Type"
                                    />
                                  )}
                                />

                                {fields.length > 1 &&
                                  fields.length === index + 1 && (
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() => remove(index)}
                                    >
                                      Remove Surgery
                                    </button>
                                  )}
                              </div>
                              <ErrorMessage
                                value={
                                  errors.surgeryDetails?.[index]?.surgeryType
                                }
                              />
                              <ul className="nav appendectomy-nav">
                                <li>Date</li>
                                <li>
                                  <Controller
                                    name={`surgeryDetails[${index}].date`}
                                    control={control}
                                    render={({ field }) => (
                                      <DateInput
                                        field={field}
                                        placeholder={'DD/MM/YYYY'}
                                        className={`form-control datetimepicker ${
                                          errors.surgeryDetails?.[index]?.date
                                            ? 'is-invalid'
                                            : ''
                                        }`}
                                      />
                                      // <input
                                      //   {...field}
                                      //   type="date"
                                      //   className={`form-control datetimepicker ${
                                      //     errors.surgeryDetails?.[index]?.date
                                      //       ? 'is-invalid'
                                      //       : ''
                                      //   }`}
                                      //   placeholder="DD/MM/YYYY"
                                      // />
                                    )}
                                  />
                                  <ErrorMessage
                                    value={errors.surgeryDetails?.[index]?.date}
                                  />
                                </li>
                              </ul>
                              <ul className="nav appendectomy-nav">
                                <li>Blood Transfusion</li>
                                <li>
                                  <div className="custom-radio patient-radio-btn">
                                    <Controller
                                      name={`surgeryDetails[${index}].transfusion`}
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <label className="custom_radio">
                                            <input
                                              {...field}
                                              type="radio"
                                              value="Yes"
                                              checked={field.value === 'Yes'}
                                            />
                                            <span className="checkmark" /> Yes
                                          </label>
                                          <label className="custom_radio">
                                            <input
                                              {...field}
                                              type="radio"
                                              value="No"
                                              checked={field.value === 'No'}
                                            />
                                            <span className="checkmark" /> No
                                          </label>
                                        </>
                                      )}
                                    />
                                    <ErrorMessage
                                      value={
                                        errors.surgeryDetails?.[index]
                                          ?.transfusion
                                      }
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        ))}
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() =>
                            append({
                              surgeryType: '',
                              date: '',
                              transfusion: 'No',
                            })
                          }
                        >
                          Add Surgery
                        </button>
                      </div>
                    </>
                  )}
                  <div className="past-medical-btn">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default PastSurgeries;
