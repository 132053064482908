import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../../../utils/routes';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from '../../../../utils/shared.module';
import { end_points } from '../../../../services/end_point/end_points';
import { Controller, useForm } from 'react-hook-form';
import {
  email,
  emailMaxLength,
  nameMaxLength,
  onlyAlphabet,
  onlyNumber,
  registerNoMaxLength,
  validMessage,
  validatePhoneNumber,
} from '../../../../utils/patterns/regex.pattern';
import ErrorMessage from '../../../../components/errorMessage';
import PhoneInputWithRef from '../../../../utils/phoneInputWithRef';
import { editType, userRole } from '../../../../assets/global';
import { createIconDoctor } from '../../../../utils/imagepath';
const doctorSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Doctor name is required')
    .matches(onlyAlphabet, validMessage.onlyAlphabet)
    .max(nameMaxLength, `Name cannot exceed ${nameMaxLength} characters`),
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .matches(email, validMessage.email)
    .max(emailMaxLength, `Email cannot exceed ${emailMaxLength} characters`),
  phone: yup
    .string()
    .trim()
    .required('Mobile number is required')
    .test('is-valid-phone', 'Invalid phone number', (value) =>
      validatePhoneNumber(value),
    ),
  registration_number: yup
    .string()
    .trim()
    .required('Registration number is required')
    .max(
      registerNoMaxLength,
      `Registration number cannot exceed ${registerNoMaxLength} characters`,
    ),
  degree: yup
    .object({
      name: yup.string().required('Degree is required'),
      code: yup.string().optional(),
      id: yup.string().optional(),
    })
    .required('Degree is required'),
});
const DoctorCreate = () => {
  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitted, isValid },
  } = useForm({
    resolver: yupResolver(doctorSchema),
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      registration_number: '',
      degree: {
        name: '',
        code: '',
        id: '',
      },
    },
  });

  const { showLoader, hideLoader } = useContext(SpinnerContext);

  const [degreeDataList, setDegreeDataList] = useState<any>([]);
  const { postData, getData } = useContext(ApiServiceContext);

  const navigate = useNavigate();
  const getProfessionalData = async () => {
    try {
      showLoader();
      const response = await getData(end_points.degreeListApi.url);
      if (response.status === 200) {
        const professionalData = response?.data?.data?.map((degree: any) => {
          return { id: degree?.id, name: degree?.name, code: degree?.id };
        });
        setDegreeDataList(professionalData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const onSubmit = async (data: any) => {
    try {
      showLoader();
      let url = end_points.adminPagesCreate.url;
      url += `/doctor`;
      const { phone, degree, name, registration_number, ...rest } = data;
      const payload = {
        ...rest,
        doctor_reg_no: registration_number,
        mobile: `+${phone}`,
        professional_degree_id: degree?.id,
        fullname: name,
      };
      const response = await postData(url, payload);
      if (response.status === 200) {
        navigate(routes.doctorsList.path);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const formInvalid = isSubmitted && !isValid;

  useEffect(() => {
    getProfessionalData();
  }, []);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Create Doctor */}
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="card create-card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="create-header">
                        <h4>Create Doctor</h4>
                        <img src={createIconDoctor} alt="Icon"></img>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="create-form">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="form-wrap">
                            <Controller
                              name="name"
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="text"
                                  className={`form-control ${
                                    errors.name ? 'is-invalid' : ''
                                  }`}
                                  placeholder="Doctor name*"
                                  onBlur={() => trigger('name')}
                                  onChange={(event: any) => {
                                    field.onChange(event);
                                    trigger('name');
                                  }}
                                  onKeyDown={(event) => {
                                    const regex = onlyAlphabet;
                                    if (
                                      !regex.test(event.key) &&
                                      event.key !== 'Backspace'
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                  autoComplete="false"
                                />
                              )}
                            />
                            <ErrorMessage
                              className="d-inline-flex mt-2"
                              value={errors.name}
                            />
                          </div>
                          <div className="form-wrap">
                            <Controller
                              name="email"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.email ? 'is-invalid' : ''
                                  }`}
                                  placeholder="Doctor email*"
                                  autoComplete="false"
                                  {...field}
                                  maxLength={emailMaxLength}
                                  onBlur={() => trigger('email')}
                                  onChange={(event: any) => {
                                    field.onChange(event);
                                    trigger('email');
                                  }}
                                />
                              )}
                            />
                            <ErrorMessage
                              className="d-inline-flex mt-2"
                              value={errors.email}
                            />
                          </div>
                          <div
                            className={`form-wrap ${formInvalid ? 'is-invalid' : ''} mt-3`}
                          >
                            <Controller
                              name="phone"
                              control={control}
                              render={({ field, fieldState }) => (
                                <div>
                                  <PhoneInputWithRef
                                    {...field}
                                    placeholder="Doctor phone number*"
                                    country={'in'}
                                    value={field.value}
                                    onBlur={() => trigger('phone')}
                                    onChange={(event: any) => {
                                      field.onChange(event);
                                      trigger('phone');
                                    }}
                                    inputProps={{
                                      name: 'phone',
                                      required: true,
                                      // autoFocus: true,
                                      className: `form-control ${fieldState.invalid ? 'is-invalid' : ''}`,
                                    }}
                                  />
                                </div>
                              )}
                            />
                            <ErrorMessage
                              className="d-inline-flex mt-2"
                              value={errors.phone}
                            />
                          </div>
                          {/* <div className="form-wrap">
                            <Controller
                              name="fullname"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.fullname ? 'is-invalid' : ''
                                  }`}
                                  placeholder="Doctor User Name*"
                                  autoComplete="false"
                                  {...field}
                                />
                              )}
                            />
                            <ErrorMessage
                              className="d-inline-flex mt-2"
                              value={errors.fullname}
                            />
                          </div> */}
                          <div className="form-wrap">
                            <Controller
                              name="registration_number"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className={`form-control ${
                                    errors.registration_number
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  placeholder="Registration number*"
                                  {...field}
                                  autoComplete="false"
                                  maxLength={registerNoMaxLength}
                                  onBlur={() => trigger('registration_number')}
                                  onChange={(event: any) => {
                                    field.onChange(event);
                                    trigger('registration_number');
                                  }}
                                  onKeyDown={(event) => {
                                    const regex = onlyNumber;
                                    if (
                                      !regex.test(event.key) &&
                                      event.key !== 'Backspace'
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              )}
                            />
                            <ErrorMessage
                              className="d-inline-flex mt-2"
                              value={errors.registration_number}
                            />
                          </div>
                          {/* <div className="form-wrap">
                            <Controller
                              name="new_password"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control ${
                                    errors?.new_password ? 'is-invalid' : ''
                                  }`}
                                  type={'password'}
                                  value={value}
                                  placeholder="New Password"
                                  onChange={onChange}
                                  maxLength={passwordMaxLength}
                                  autoComplete="false"
                                />
                              )}
                            />
                            <ErrorMessage value={errors?.new_password} />
                          </div>
                          <div className="form-wrap">
                            <Controller
                              name="confirm_new_password"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control ${
                                    errors?.confirm_new_password
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  type={'password'}
                                  value={value}
                                  placeholder="Re-enter Password"
                                  onChange={onChange}
                                  maxLength={passwordMaxLength}
                                  autoComplete="false"
                                />
                              )}
                            />
                            <ErrorMessage
                              value={errors?.confirm_new_password}
                            />
                          </div> */}
                          <div className="form-wrap-status professional-wrap-status">
                            <h4>Professional Degree</h4>
                            <div className="degree-select">
                              <Controller
                                name="degree"
                                control={control}
                                rules={{ required: 'Degree is required' }}
                                render={({ field }) => (
                                  <Dropdown
                                    className={`w-100 custom-invalid-cls ${
                                      errors.degree?.name ? 'is-invalid' : ''
                                    }`}
                                    {...field}
                                    options={degreeDataList}
                                    optionLabel="name"
                                    filter
                                    placeholder="Select a degree"
                                  />
                                )}
                              />
                            </div>
                            <ErrorMessage
                              className="d-inline-flex mt-2"
                              value={errors?.degree?.name}
                            />
                          </div>
                          <div className="form-wrap-btn">
                            <button type="submit" className="btn btn-secondary">
                              Submit
                            </button>
                            <Link
                              to={routes.doctorsList.path}
                              className="btn btn-light"
                            >
                              Cancel
                            </Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Create Doctor */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default DoctorCreate;
