import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../../utils/routes';
import PrimeDataTable from '../../../../components/dataTable';
import { ApiServiceContext } from '../../../../utils/shared.module';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { end_points } from '../../../../services/end_point/end_points';
import ListHeader from '../../../../components/listHeader';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter } from '../../../../utils/constant';
const viewTypes: any = ['All', 'Front Desk', 'Paramedic'];

const KioskUserList = () => {
  const { getData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const [listData, setListData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [filterType, setFilterType] = useState<string>('All');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(10);
  const editData = useSelector((state: any) => state?.common?.editData);

  const columns = [
    {
      header: '#ID',
      field: 'userDetails.reg_id',
      key: 'userDetails.reg_id',
      sortField: 'userDetails.reg_id',
      body: (data: any) => <Link to={'#'}>{data?.userDetails?.reg_id}</Link>,
    },
    {
      header: 'Name',
      field: 'userDetails.fullname',
      key: 'userDetails.fullname',
      sortField: 'userDetails.fullname',
    },
    {
      header: 'Email ID',
      field: 'userDetails.email',
      key: 'userDetails.email',
      sortField: 'userDetails.email',
    },
    {
      header: 'Phone No',
      field: 'userDetails.mobile',
      key: 'userDetails.mobile',
      sortField: 'userDetails.mobile',
    },
    {
      header: 'User Type',
      field: 'type',
      key: 'type',
      sortField: 'type',
      body: (data: any) => capitalizeFirstLetter(data?.type),
    },
    {
      header: 'Status',
      field: 'userDetails.status',
      key: 'userDetails.status',
      sortField: 'userDetails.status',
      body: (data: any) => {
        const status: string = capitalizeFirstLetter(data?.userDetails?.status);
        return (
          <div className="status-data">
            {status === 'Active' && (
              <p>
                <span className="status-active"></span>
                {status}
              </p>
            )}
            {status === 'Disabled' && (
              <p>
                <span className="status-disabled"></span>
                {status}
              </p>
            )}
            {status === 'Inactive' && (
              <p>
                <span className="status-inactive"></span>
                In Active
              </p>
            )}
          </div>
        );
      },
    },
  ];

  const getUserList = async (page: number = 1, limit: number = 10) => {
    try {
      showLoader();
      let url = end_points.KioskUserListApi.url;
      let id = editData?._id;
      if (id) {
        url += `/${id}`;
      }
      if (page && limit) {
        url += `?page=${page}&limit=${limit}`;
      }
      if (filterType) {
        let filter =
          filterType === 'Front Desk'
            ? 'front-desk'
            : filterType === 'Paramedic'
              ? 'paramedic'
              : 'ALL';
        url += `&type=${filter?.toLocaleLowerCase()}`;
      }
      if (searchQuery) {
        url += `&searchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        const data = response?.data?.data?.associates;
        setListData(data);
        setTotalRecords(response?.data?.data?.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };
  const handleSearch = (search: any = '') => {
    setSearchQuery(search);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (searchQuery || filterType || currentPage) {
      getUserList(currentPage, rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, filterType, currentPage]);

  return (
    <div>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          <ListHeader
            title={'KIOSK USER LIST'}
            viewTypes={viewTypes}
            filterType={filterType}
            setFilterType={setFilterType}
            setCurrentPage={setCurrentPage}
            handleSearch={handleSearch}
            createRoute={routes.kioskCreate.path}
            type="kioskUserList"
          />

          <PrimeDataTable
            column={columns}
            data={listData}
            rows={rows}
            setRows={setRows}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalRecords={totalRecords}
            rowClassName={'highlight'}
            onRowClickSetState={false}
            onClickNavigate={() => {
              //  navigate(routes.kioskEdit.path);
            }}
          />

          {/* /Kiosk Table */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </div>
  );
};

export default KioskUserList;
