import React, { useState, useEffect, useRef, useContext } from 'react';
import '../videoCall/app.css';
import { ApiServiceContext } from '../../../../../utils/shared.module';
import { end_points } from '../../../../../services/end_point/end_points';
import OT from '@opentok/client';
import { setSessionDetails } from '../../../../../core/redux/commonSlice';
import { useDispatch, useSelector } from 'react-redux';

const VideoCall = () => {
  const sessionDetail = useSelector(
    (state: any) => state?.common?.sessionDetails,
  );
  const [sessionId, setSessionId] = useState<any>(null);
  const [token, setToken] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { putData } = useContext(ApiServiceContext);
  const publisherRef = useRef<any>(null);
  const sessionRef = useRef<any>(null);
  const subscriberRef = useRef<any>([]);
  const VONAGE_API_KEY = '47894791'; // Replace with your actual API key
  const dispatch = useDispatch();
  const ids = localStorage.getItem('AID') || '';
  const pids = localStorage.getItem('PID') || '';

  const startCall = async () => {
    try {
      let url = end_points.testVideoCall.url;
      url += `/${ids}`; // AppoinmentId
      const payload = {
        paramedic_id: pids,
      };
      const response: any = await putData(url, payload);
      if (response.status === 200) {
        const { session_id, vonage_token } = response.data.data;
        setSessionId(session_id);
        setToken(vonage_token);
        console.log('response.data.data', response.data.data);
      } else {
        throw new Error('Failed to fetch session data');
      }
    } catch (err) {
      setError('Failed to fetch or validate session');
      console.error('Fetch Session Error:', err);
    }
  };

  useEffect(() => {
    console.log('workinggg');
    if (OT) {
      const otSession = OT.initSession(
        sessionDetail?.vonage_api_key,
        sessionId,
      );
      console.log('otSession', otSession);
      // Handle stream created
      otSession.on('streamCreated', (event: any) => {
        console.log('Stream created:', event.stream);
        // Notify the receiver or handle the event
      });

      // Initialize and publish the stream
      const otPublisher = OT.initPublisher('publisher', {
        insertMode: 'append',
        width: '100%',
        height: '100%',
      });
      publisherRef.current = otPublisher;

      otSession.connect(token, (error: any) => {
        if (error) {
          console.error('Failed to connect to session:', error);
          return;
        }
        otSession.publish(otPublisher, (publishError: any) => {
          if (publishError) {
            console.error('Failed to publish:', publishError);
          } else {
            console.log('Publisher started:', otPublisher.stream);
          }
        });
      });

      return () => {
        if (otSession) {
          otSession.disconnect();
        }
      };
    }
  }, [sessionId, token]);

  return (
    <div className="video-container">
      <button onClick={startCall} disabled={loading}>
        {loading ? 'Calling...' : 'Start Call'}
      </button>
      <div id="publisher" className="video-item" />
      <div id="subscriber" className="video-item">
        {/* Placeholder for subscriber streams */}
      </div>
    </div>
  );
};

export default VideoCall;
