export enum userRole {
  Doctor = 'doctor',
  Paramedic = 'paramedic',
  FrontDesk = 'front-desk',
  Admin = 'admin',
  Kiosk = 'kiosk',
  Patient = 'patient',
}

export enum editType {
  update = 'Update',
  added = 'Added',
}

export enum scheduleType {
  schedule = 'schedule',
  reschedule = 'reschedule',
  delayed = 'delayed',
  cancelled = 'cancelled',
  completed = 'completed',
  pending = 'pending',
  waiting = 'waiting',
}
export enum consultType {
  upcoming = 'upcoming',
  past = 'past',
  scheduled = 'scheduled',
  criticalPatient = 'criticalPatient',
}
export const noRecordsFound = 'No records found';
// export const durationOptions = [
//   { name: 1, code: 1 },
//   { name: 2, code: 2 },
//   { name: 3, code: 3 },
// ];
export const durationOptions = Array.from({ length: 30 }, (_, i) => ({
  name: i + 1,
  code: i + 1,
}));

export const durationTypeOptions = [
  { name: 'Week', code: 'week' },
  { name: 'Month', code: 'month' },
  { name: 'Year', code: 'year' },
];
