import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../../../utils/routes';
import SearchFromApi from '../../../../components/search';
import PrimeDataTable from '../../../../components/dataTable';
import { ApiServiceContext } from '../../../../utils/shared.module';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { end_points } from '../../../../services/end_point/end_points';
import { setEditData } from '../../../../core/redux/commonSlice';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';

const viewTypes: any = ['All', 'Active', 'Inactive'];
const ParamedicList = () => {
  const { getData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const [show, setShow] = useState<boolean>(false);

  const [listData, setListData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterType, setFilterType] = useState<string>('All');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = [
    {
      header: '#ID',
      field: 'reg_id',
      key: 'reg_id',
      sortField: 'reg_id',
      body: (data: any) => (
        <Link
          to={routes.paramedicView.path}
          onClick={() => {
            dispatch(setEditData(data));
          }}
        >
          {data?.reg_id}
        </Link>
      ),
    },
    {
      header: 'Name',
      field: 'fullname',
      key: 'fullname',
      sortField: 'fullname',
    },
    {
      header: 'Email ID',
      field: 'email',
      key: 'email',
      sortField: 'email',
      body: (rowData: any) => truncateAddress(rowData.email),
    },
    {
      header: 'Phone No',
      field: 'mobile',
      key: 'mobile',
      sortField: 'mobile',
    },
    {
      header: 'Status',
      field: 'status',
      key: 'status',
      sortField: 'status',
      body: (data: any) => (
        <div className="status-data">
          {data?.status === 'active' && (
            <p>
              <span className="status-active"></span>
              Active
            </p>
          )}
          {data?.status === 'inactive' && (
            <p>
              <span className="status-inactive"></span>
              In Active
            </p>
          )}
          {/* {data?.status === 'Busy' && (
            <p>
              <span className="status-inactive"></span>
              {data?.status}
            </p>
          )} */}
        </div>
      ),
    },
    {
      header: 'Action',
      sortable: false,
      body: (rowData: any) => (
        <div className="data-slot">
          <Tooltip title="Edit" color="grey">
            <a href={'#'} onClick={() => handleEdit(rowData, 'edit')}>
              <i className="feather icon-edit"></i>
            </a>
          </Tooltip>
        </div>
      ),
    },
  ];
  const handleEdit = (data: any, type: any) => {
    dispatch(setEditData(data));
    if (type === 'edit') {
      navigate(routes.paramedicView.path);
    } else {
      navigate(routes.paramedicCreate.path);
    }
  };
  const truncateAddress = (address: any) => {
    if (address.length > 30) {
      return address.substring(0, 30) + '...';
    }
    return address;
  };
  const getParamedicList = async (page: number = 1, limit: number = 10) => {
    try {
      showLoader();
      let url = end_points.adminPagesList.url;
      url += `/paramedic`;
      if (page && limit) {
        url += `?page=${page}&limit=${limit}`;
      }
      if (filterType) {
        url += `&view=${filterType?.toLocaleLowerCase()}`;
      }
      if (searchQuery) {
        url += `&searchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response.data.data);
        setTotalRecords(response.data.total_records);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const handleSearch = (search: any = '') => {
    setSearchQuery(search);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (searchQuery || filterType || currentPage) {
      getParamedicList(currentPage, rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, filterType, currentPage]);
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Page Header */}
          <div className="page-header">
            <h4 className="page-title">PARAMEDIC LIST</h4>
            <div className="page-list">
              <ul className="nav">
                <li>
                  <div className="page-dropdown">
                    <Link
                      to="#"
                      className="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      onClick={() => {
                        setShow((prev) => !prev);
                      }}
                    >
                      Showing : {filterType}
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-end ${show ? 'show' : ''}`}
                    >
                      {viewTypes?.map((viewType: string) => {
                        return (
                          <Link
                            to="#"
                            className={`dropdown-item ${filterType === viewType ? 'active' : ''}`}
                            onClick={() => {
                              setFilterType(viewType);
                              setCurrentPage(1);
                            }}
                          >
                            {viewType}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </li>
                <li>
                  <SearchFromApi callback={handleSearch} />
                </li>
                <li>
                  <div className="page-header-btn">
                    <Link
                      to={routes.paramedicCreate.path}
                      className="btn btn-secondary"
                    >
                      Create
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* /Page Header */}
          {/* Paramedic Table */}
          <PrimeDataTable
            column={columns}
            data={listData}
            rows={rows}
            setRows={setRows}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalRecords={totalRecords}
            rowClassName={'highlight'}
            // onClickNavigate={() => {
            //   navigate(routes.paramedicView.path);
            // }}
          />
          {/* /Paramedic Table */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default ParamedicList;
