import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routes } from '../utils/routes';
import store from '../core/redux/store';

const Authenticate = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = store?.getState()?.login?.token;
  const { userDetails } = useSelector((state) => state.login);
  const twoFaEnabled = userDetails?.two_factor_authentication;
  const twoFaStatus = userDetails?.two_factor_authentication_status;
  const path = location?.pathname;
  const currentPath = path.split('/')[1];

  useEffect(() => {
    if (!auth) {
      if (currentPath === 'front-desk') {
        return navigate(routes.frontDesklogin.path, { replace: true });
      } else if (currentPath === 'doctor') {
        return navigate(routes.doctorLogin.path, { replace: true });
      } else if (
        !currentPath.startsWith('logout') ||
        currentPath != 'front-desk' ||
        currentPath != 'doctor'
      ) {
        return navigate(routes.login.path, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);
  return props.children;
};

export default Authenticate;
