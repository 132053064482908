import React, { useContext, useState } from 'react';
import { login04, logoBig } from '../../../utils/imagepath';
import { useNavigate } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import { routes } from '../../../utils/routes';
import parsePhoneNumberFromString from 'libphonenumber-js';
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from '../../../utils/shared.module';
import { Controller, useForm } from 'react-hook-form';
import PhoneInputWithRef from '../../../utils/phoneInputWithRef';
import { SpinnerContext } from '../../../components/spinner/spinner';
import { end_points } from '../../../services/end_point/end_points';
import { useDispatch } from 'react-redux';
import { setPhoneNumber } from '../../../core/redux/commonSlice';
import { userRole } from '../../../assets/global';

const validatePhoneNumber = (value: string) => {
  if (!value.startsWith('+')) {
    value = `+${value}`;
  }
  const phoneNumber = parsePhoneNumberFromString(value);
  return phoneNumber ? phoneNumber.isValid() : false;
};

const phoneValidation = yup.object().shape({
  ph_no: yup
    .string()
    .trim()
    .required('Mobile number is required')
    .test('is-valid-phone', 'Invalid phone number', (value) =>
      validatePhoneNumber(value),
    ),
});

const ForgotPassword = () => {
  const { postData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [apiError, setApiError] = useState(false);
  const path = location?.pathname;
  const findType = path.split('/')[1];

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors, isSubmitted, isValid },
  } = useForm({
    resolver: yupResolver(phoneValidation),
    defaultValues: {
      ph_no: '',
    },
  });

  const onSubmit = async (data: any) => {
    try {
      showLoader();
      let url;
      if (findType === userRole.FrontDesk || findType === userRole.Doctor) {
        url = end_points.userForgotPasswordOTP.url;
      } else {
        url = end_points.forgotPasswordOTP.url;
      }
      let payload;
      if (findType === userRole.FrontDesk || findType === userRole.Doctor) {
        payload = {
          number: `+${data?.ph_no}`,
          type: `${findType === userRole.Doctor ? userRole.Doctor : userRole.FrontDesk}`,
        };
      } else {
        payload = {
          number: `+${data?.ph_no}`,
        };
      }
      dispatch(setPhoneNumber(`+${data?.ph_no}`));
      const response = await postData(url, payload);
      if (response?.status === 200) {
        setApiError(false);
        if (findType === userRole.FrontDesk) {
          navigate(routes.frontDeskforgotPasswordOtp.path);
        } else if (findType === userRole.Doctor) {
          navigate(routes.doctorforgotPasswordOtp.path);
        } else {
          navigate(routes.forgotPasswordOtp.path);
        }
      }
    } catch (error: any) {
      setApiError(true);
      setApiErrorMessage(error?.response?.data?.message);
    } finally {
      hideLoader();
    }
  };

  const formInvalid = isSubmitted && !isValid;

  return (
    <>
      {/* Logo */}
      <div className="register-logo">
        <div className="white-logo">
          <img src={logoBig} alt="Logo" />
        </div>
      </div>
      {/* /Logo */}
      {/* Phone OTP */}
      <div className="w-100">
        <div className="content container-fluid pb-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-box register-box">
                <div className="login-right">
                  <div className="login-right-wrap">
                    <div className="password-header">
                      <h2>Forget Password ?</h2>
                      <img src={login04} alt="Icon" />
                      <p>
                        Enter your registered mobile number and we’ll send you
                        an OTP
                      </p>
                    </div>
                    {/* Form */}
                    <form
                      className="login-form"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div
                        className={`form-wrap ${formInvalid ? 'is-invalid' : ''}`}
                      >
                        <Controller
                          name="ph_no"
                          control={control}
                          render={({ field, fieldState }) => (
                            <div>
                              <PhoneInputWithRef
                                {...field}
                                placeholder="Enter phone number"
                                country={'in'}
                                value={field.value}
                                onBlur={() => trigger('ph_no')}
                                onChange={(event: any) => {
                                  field.onChange(event);
                                  trigger('ph_no');
                                }}
                                inputProps={{
                                  name: 'ph_no',
                                  required: true,
                                  // autoFocus: true,
                                  className: `form-control ${fieldState.invalid ? 'is-invalid' : ''}`,
                                }}
                              />
                              {fieldState.invalid && (
                                <small className="text-danger mt-2">
                                  {errors.ph_no?.message}
                                </small>
                              )}
                            </div>
                          )}
                        />
                      </div>
                      <div className="password-btn">
                        <button type="submit" className="btn btn-black">
                          Send OTP
                        </button>
                      </div>
                    </form>
                    {/* /Form */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Phone OTP */}
    </>
  );
};

export default ForgotPassword;
