import React, { createContext, useContext, useRef } from 'react';
import axios, { AxiosInstance, AxiosResponse, CancelTokenSource } from 'axios';
import store from '../../core/redux/store';
import { logout, setTenantId } from '../../core/redux/loginSlice';
import { useDispatch } from 'react-redux';
import { SpinnerContext } from '../../components/spinner/spinner';
import { toast } from 'react-toastify';
import { end_points } from '../end_point/end_points';
import { debounce } from '../../utils/debounce';

export const AxiosContext = createContext<any>({});

const AxiosProvider = (props: { children: any }) => {
  const axiosInstance: AxiosInstance = axios.create();
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);
  const dispatch = useDispatch();
  const path = window.location?.pathname;
  const findPath = path.split('/')[1];
  const hideLoadersApi: Array<string | undefined> = [];

  axiosInstance.interceptors.request.use(
    async (config: any) => {
      if (!hideLoadersApi.includes(config.url)) {
        // showLoader();
      }

      const token = store?.getState()?.login?.token;
      const tenantId = store?.getState()?.login?.tenantId;
      config.baseURL = API_URL;

      if (
        config.url.startsWith(end_points.frontDeskPatientRegisterApi.url) ||
        config.url.startsWith(end_points.upadteProfileDetailsApi.url) ||
        config.url.startsWith(end_points.screenShotSave.url) ||
        config.url.startsWith(end_points.vedioRecordingApi.url) ||
        config.url.startsWith(end_points.pastAttachmentCreateApi.url)
      ) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
          token: `${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
          'tenant-id': tenantId,
        };
      } else {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
          token: `${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'tenant-id': tenantId,
        };
      }

      return config;
    },
    (error) => {
      // hideLoader();
      return Promise.reject(error);
    },
  );
  axiosInstance.interceptors.response.use(
    async (response: AxiosResponse) => {
      // hideLoader();
      await handleResponseStatus(response);
      return response;
    },
    async (error) => {
      // hideLoader();
      await handleResponseStatus(error.response);
      return error?.response || error;
    },
  );
  const handleUnauthorised = debounce((findPath: string) => {
    toast.error('Unauthorised');
    setTimeout(() => {
      if (findPath === 'admin') {
        store.dispatch(logout('admin'));
      } else if (findPath === 'doctor') {
        store.dispatch(logout('doctor'));
      } else {
        store.dispatch(logout('front-desk'));
      }
      store.dispatch(setTenantId(undefined));
    }, 1500);
  }, 500);
  const handleInvalidTenant = debounce((msg: string) => {
    setTimeout(() => {
      toast.error(msg);
    }, 300);
  }, 500);

  const handleResponseStatus = async (response: any) => {
    if (response) {
      switch (response.status) {
        case 200:
          // if (
          //   !response.config.url.startsWith(
          //     end_points.rescheduledAppointmentApi.url,
          //   ) &&
          //   !response.config.url.startsWith(end_points.createAppointmentApi.url)
          // )
          toast.success(response.data.message, {
            autoClose: 2000,
          });

          break;
        case 422:
          if (response.config.url.startsWith(end_points.getTenant.url)) {
            handleInvalidTenant(response?.data?.errors?.[0]?.msg);
          } else toast.error(response?.data?.errors?.[0]?.msg);
          break;
        case 404:
          toast.error(response?.data?.message);
          break;
        case 409:
          toast.error(response?.data?.message);
          break;
        case 400:
          toast.error(response?.data?.message);
          break;
        case 500:
          toast.error('Internal server error');
          break;
        case 401:
        case 403:
          handleUnauthorised(findPath);
          break;
        default:
          break;
      }
    }
  };

  return (
    <AxiosContext.Provider value={{ axiosInstance }}>
      {props.children}
    </AxiosContext.Provider>
  );
};

export default AxiosProvider;
