import React from 'react';

import {
  capitalizeFirstLetter,
  capitalizeWords,
} from '../../../../../utils/constant';
import moment from 'moment';

function PastFamilyHistory({ details = [] }) {
  const history: any = details;

  return (
    <div
      className="tab-pane fade"
      id="family_history"
      role="tabpanel"
      aria-labelledby="family-history"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="card past-card">
            <div className="card-body">
              <div className="past-header">
                <h4>Family History of Disease</h4>
              </div>
              <div className="vitals-list appendectomy-list">
                {history?.familyhistoryofdisease === 'yes' ? (
                  history?.parent?.map((parent: any) => {
                    return (
                      <ul className="nav">
                        <li>
                          <h6>{capitalizeFirstLetter(parent?.name)}</h6>
                        </li>
                        <li>
                          <div className="vitals-since">
                            <p className="text-light-danger">
                              {capitalizeFirstLetter(parent?.is_check)}
                            </p>
                          </div>
                        </li>
                        {parent?.details?.map((val: any) => {
                          return (
                            <>
                              <li className="appendectomy-sub-list">
                                <ul className="nav">
                                  <li>
                                    {capitalizeWords(
                                      val?.name?.replace('_', ''),
                                    )}
                                  </li>
                                  <li>
                                    <div className="vitals-since">
                                      <p className="vitals-text">
                                        <span>
                                          {capitalizeFirstLetter(val?.is_check)}
                                        </span>{' '}
                                        {val?.is_check === 'yes' && (
                                          <>
                                            Since{' '}
                                            {moment(
                                              val?.since,
                                              'MM/YYYY',
                                            ).format('MMMM, YYYY')}
                                          </>
                                        )}
                                      </p>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    );
                  })
                ) : (
                  <ul className="nav">
                    <li>{'No records found'}</li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PastFamilyHistory;
