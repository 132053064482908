import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../../utils/routes';
import PrimeDataTable from '../../../../components/dataTable';
import { useDispatch, useSelector } from 'react-redux';
import { end_points } from '../../../../services/end_point/end_points';
import { ApiServiceContext } from '../../../../utils/shared.module';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import SearchFromApi from '../../../../components/search';
import SuccessModel from '../../../../components/frontdeskPatientModel/successModel';
import ScheduleModel from '../../../../components/frontdeskPatientModel/scheduleModel';
import { setEditData } from '../../../../core/redux/commonSlice';
import {
  capitalizeFirstLetter,
  dateFormat_DD_MM_YYYY,
  patientScheduleSlotFormat,
} from '../../../../utils/constant';
import { scheduleType } from '../../../../assets/global';
import moment from 'moment';

const viewTypes: any = [
  'All',
  'New Patients',
  'Revisiting',
  'Scheduled',
  'Completed',
];
const PatientList = () => {
  const { getData, postData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);

  const [listData, setListData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [filterType, setFilterType] = useState<string>('All');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [doctorListOptions, setDoctorListOptions] = useState<any>([]);
  const [successModel, setSuccessModel] = useState<string>('');
  const [scheduleModel, setScheduleModel] = useState<any>({
    type: '',
    model: false,
  });
  const successRef = useRef<any>(null);
  const scheduleRef = useRef<any>(null);
  const [rows, setRows] = useState<number>(10);
  const dispatch = useDispatch();
  const pendingRef = useRef<any>(null);
  const delayedRef = useRef<any>(null);
  const appoinmentStatus: any = useSelector(
    (state: any) => state?.common?.appoinmentStatusUpdate,
  );
  const availableDoctorStatus: any = useSelector(
    (state: any) => state?.common?.availableDoctorUpdate,
  );
  const columns = [
    {
      header: 'ID',
      field: 'reg_id',
      key: 'reg_id',
      body: (data: any) => <span className="data-id">{data?.reg_id}</span>,
    },
    {
      header: 'Name',
      field: 'fullname',
      key: 'fullname',
    },
    {
      header: 'Phone No',
      field: 'mobile',
      key: 'mobile',
    },
    {
      header: 'Aadhaar No',
      field: 'aadhaar_no',
      key: 'aadhaar_no',
      body: (data: any) => (data?.aadhaar_no ? data.aadhaar_no : '-'),
    },
    {
      header: 'Consult Type',
      field: 'consultType',
      key: 'consultType',
      body: (data: any) => (
        <span className="data-id">
          {data?.appointment_type
            ? capitalizeFirstLetter(data?.appointment_type)
            : '-'}
        </span>
      ),
    },
    {
      header: 'Appointment',
      field: 'appointments',
      key: 'appointments',
      body: (data: any) => {
        const slotTime = data?.time_slot;
        if (!slotTime) {
          return (
            <div className="schedule-data">
              <Link
                to="#"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#schedule_modal"
                onFocus={() => {
                  if (scheduleRef.current && scheduleModel.model) {
                    scheduleRef.current.click();
                  }
                }}
                onClick={() => {
                  if (data) {
                    const refData = JSON.parse(JSON.stringify(data));
                    dispatch(setEditData(refData));
                    const model = JSON.parse(
                      JSON.stringify({
                        type: scheduleType.schedule,
                        model: true,
                      }),
                    );
                    setScheduleModel(model);
                  }
                }}
              >
                Schedule
              </Link>
            </div>
          );
        }
        return (
          <Link to={'#'}>
            {slotTime
              ? patientScheduleSlotFormat(data?.appointment_date, slotTime)
              : '-'}
          </Link>
        );
      },
    },
    {
      header: 'Action',
      field: 'action',
      key: 'action',
      body: (data: any) => {
        switch (data?.appointment_status) {
          case 'pending':
            return (
              <span
                className="pending-text"
                ref={pendingRef}
                data-bs-toggle="modal"
                data-bs-target="#schedule_modal"
                onClick={() => {
                  dispatch(setEditData(data));
                  setScheduleModel({
                    type: scheduleType.reschedule,
                    model: true,
                  });
                }}
              >
                Pending
              </span>
            );
          case 'waiting':
            // return <span className="waiting-text">Waiting</span>;
            return (
              <span
                className="waiting-text"
                ref={delayedRef}
                data-bs-toggle="modal"
                data-bs-target="#schedule_modal"
                onClick={() => {
                  dispatch(setEditData(data));
                  setScheduleModel({ type: scheduleType.waiting, model: true });
                }}
              >
                Waiting
              </span>
            );
          case 'delayed':
            return (
              <span
                className="delayed-text"
                ref={delayedRef}
                data-bs-toggle="modal"
                data-bs-target="#schedule_modal"
                onClick={() => {
                  dispatch(setEditData(data));
                  setScheduleModel({ type: scheduleType.delayed, model: true });
                }}
              >
                Delayed
              </span>
            );
          case 'scheduled':
            return (
              <Link
                to="#"
                className="scheduled-text"
                // testing
                // ref={delayedRef}
                // data-bs-toggle="modal"
                // data-bs-target="#schedule_modal"
                // onClick={() => {
                //   dispatch(setEditData(data));
                //   setScheduleModel(scheduleType.delayed);
                // }}
              >
                Scheduled
              </Link>
            );
          case 'cancelled':
            return (
              <Link to="#" className="cancelled-text">
                Cancelled
              </Link>
            );
          default:
            return (
              <span>
                {data?.appointment_status
                  ? capitalizeFirstLetter(data?.appointment_status)
                  : '-'}
              </span>
            );
        }
      },
    },
    {
      header: 'Doctor',
      field: 'doctor',
      key: 'doctor',
      body: (data: any) => {
        const doctorName = data?.doctorDetails?.fullname;
        return doctorName ? doctorName : '-';
      },
    },
  ];

  const getDoctorsList = async () => {
    try {
      showLoader();
      let payload: any = {};
      const selectedDate = moment().toDate();
      if (selectedDate) {
        payload.selectedDate = dateFormat_DD_MM_YYYY(selectedDate);
      }
      const response = await postData(
        end_points.getAvailableDoctorsListApi.url,
        payload,
      );
      if (response.status === 200) {
        setDoctorListOptions(response?.data?.data);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      hideLoader();
    }
  };
  const getPatientList = async (
    page: number = 1,
    limit: number = 10,
    bool = true,
  ) => {
    try {
      bool && showLoader();
      let url = end_points.frontDeskPatientListApi.url;
      if (page && limit) {
        url += `?page=${page}&limit=${limit}`;
      }
      if (filterType) {
        url += `&view=${filterType?.toLocaleLowerCase()}`;
      }
      if (searchQuery) {
        url += `&searchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.data?.patientsList);
        setTotalRecords(response?.data?.data?.totalRecords);
        setScheduleModel({ type: '', model: false });
      }
    } catch (error) {
      console.error(error);
    } finally {
      bool && hideLoader();
    }
  };
  const handleSearch = (search: any = '') => {
    setSearchQuery(search);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (searchQuery || filterType || currentPage) {
      getPatientList(currentPage, rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, filterType, currentPage]);

  useEffect(() => {
    getDoctorsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType]);

  //socket based status update
  useEffect(() => {
    if (appoinmentStatus && appoinmentStatus.status.appointment_id) {
      setListData((prevListData: any[]) =>
        prevListData.map((item) =>
          item.appointment_id === appoinmentStatus.status.appointment_id
            ? {
                ...item,
                appointment_status: appoinmentStatus.status.appointment_status,
              }
            : item,
        ),
      );
    }
    getPatientList(currentPage, rows, false);
  }, [appoinmentStatus]);

  //socket based available doctor update
  useEffect(() => {
    if (availableDoctorStatus) {
      console.log('availableDoctorStatus._id', availableDoctorStatus._id);
      setDoctorListOptions((prevDoctorListOptions: any) => {
        const doctorIndex = prevDoctorListOptions.findIndex(
          (doctor: any) => doctor.id === availableDoctorStatus._id,
        );

        if (doctorIndex !== -1) {
          // Doctor found in the list
          if (availableDoctorStatus.availability_status === 'offline') {
            // Remove the doctor from the list if status is offline
            return prevDoctorListOptions.filter(
              (doctor: any) => doctor.id !== availableDoctorStatus._id,
            );
          }
        } else {
          // Doctor not found in the list
          if (availableDoctorStatus.availability_status === 'online') {
            // Add the doctor to the list if status is online
            return [
              ...prevDoctorListOptions,
              {
                id: availableDoctorStatus._id,
                fullname: availableDoctorStatus.fullname,
                availability_status: availableDoctorStatus.availability_status,
                // Add other necessary fields here if required
              },
            ];
          }
        }

        // If no changes are made, return the original list
        return prevDoctorListOptions;
      });
    }
  }, [availableDoctorStatus]);

  // useEffect(() => {
  //   console.log('doctorListOptions', doctorListOptions);
  // }, [doctorListOptions]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Page Header */}
          <div className="page-header">
            <h4 className="page-title">Patient List</h4>
            <div className="page-list">
              <ul className="nav">
                <li>
                  <div className="page-header-btn">
                    <Link
                      to={routes.frontDeskPatientRegistration.path}
                      className="btn btn-black"
                    >
                      Create
                    </Link>
                  </div>
                </li>
                <li>
                  <SearchFromApi callback={handleSearch} />
                </li>
              </ul>
            </div>
          </div>
          {/* /Page Header */}
          {/* Patient List Table */}
          <div className="row">
            <div className="col-md-12">
              <div className="patient-nav-list">
                <ul className="nav">
                  {viewTypes?.map((filter: any, index: number) => (
                    <li key={index}>
                      <Link
                        to={'#'}
                        className={`${filter === (filterType === 'New' ? 'New Patients' : filterType) ? 'active' : ''}`}
                        onClick={() => {
                          if (filter === 'New Patients') {
                            setFilterType('New');
                          } else setFilterType(filter);
                        }}
                      >
                        {filter}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-10 col-xl-10 col-lg-12 d-flex">
              <div className="w-100">
                <PrimeDataTable
                  column={columns}
                  data={listData}
                  rows={rows}
                  sortable={false}
                  setRows={setRows}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalRecords={totalRecords}
                  onRowClickSetState={false}
                  rowClassName={'highlight'}
                  type="PATIENTLIST"
                  // onClickNavigate={(data: any) => {
                  //   if (data?.appointment_status === scheduleType.pending) {
                  //     if (pendingRef?.current) pendingRef.current.click();
                  //   } else if (
                  //     data?.appointment_status === scheduleType.delayed
                  //   ) {
                  //     if (delayedRef?.current) delayedRef.current.click();
                  //   }
                  // }}
                />
              </div>
            </div>

            <div className="col-xxl-2 col-xl-2 col-lg-12 d-flex">
              <div className="card patient-table-card w-100">
                <div className="card-body">
                  <div className="patient-table-list">
                    <h6>Available Doctors</h6>
                    <ul>
                      {doctorListOptions?.length > 0 &&
                        doctorListOptions?.map((doctor: any) => {
                          return (
                            <li key={doctor?.id}>
                              <Link
                                to={routes.frontDeskCalendar.path}
                                onClick={() => {
                                  dispatch(setEditData(doctor));
                                }}
                              >
                                {doctor?.fullname}
                              </Link>
                            </li>
                          );
                        })}
                      {/* <li>
                        <Link to="#" className="active">
                          Abhinav Nayyar
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Patient List Table */}
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* Schedule */}
      {/* {scheduleModel && ( */}
      <ScheduleModel
        setSuccessModel={setSuccessModel}
        scheduleModel={scheduleModel}
        setScheduleModel={setScheduleModel}
        scheduleRef={scheduleRef}
        SuccessCall={() => {
          getPatientList(currentPage, rows);
        }}
      />
      {/* )} */}
      {/* /Schedule */}

      {/* Success */}
      {/* <SuccessModel
        successModel={successModel}
        successRef={successRef}
        setSuccessModel={setSuccessModel}
      /> */}
      {/* /Success */}
    </>
  );
};

export default PatientList;
