import React, { useContext, useRef, useState } from 'react';
import { bodyDiagram } from '../../../../../../utils/imagepath';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { end_points } from '../../../../../../services/end_point/end_points';
import { ApiServiceContext } from '../../../../../../utils/shared.module';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import {
  pastMedicalHistorySchema,
  pastSurgeriesSchema,
  pastHospitalizationSchema,
  bodyPainDiagramSchema,
} from './medicalHistoryModelSchema';
import { Link } from 'react-router-dom';
import PastMedicalHistorty from './pastMedicalHistorty';
import PastSurgeries from './pastSurgeries';
import Hospitilization from './hospitilization';
import PainDiagramForm from './bodyPainDiagram';
import PastSocialHistory from './pastSocialHistory';
import FamilyHistoryDiease from './familyHistoryDiease';
import UploadHistoryDocuments from './uploadHistoryDocuments';
function MedicalHistoryModel() {
  const { getData, putData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const [activeTab, setActiveTab] = useState('past_medical');
  const medicalRef = useRef<any>(null);
  const surgeryRef = useRef<any>(null);
  const hospitalizationRef = useRef<any>(null);
  const personalRef = useRef<any>(null);
  const familyRef = useRef<any>(null);

  const bodyPainRef = useRef<any>(null);
  const attachmentRef = useRef<any>(null);
  const closeModelRef = useRef<any>(null);

  //   const getValidationSchema = () => {
  //     switch (activeTab) {
  //       case 'past_surgeries':
  //         return pastSurgeriesSchema;
  //       case 'past_hospitalization':
  //         return pastHospitalizationSchema;
  //       case 'pain_diagram':
  //         return bodyPainDiagramSchema;
  //       default:
  //         return pastMedicalHistorySchema;
  //     }
  //   };
  //   const getInitialValues = () => {
  //     switch (activeTab) {
  //       case 'past_surgeries':
  //         return {};
  //       case 'past_hospitalization':
  //         return pastHospitalizationSchema;
  //       case 'pain_diagram':
  //         return bodyPainDiagramSchema;
  //       default:
  //         const value = { history: '', diabetes: '', hypertension: '' };
  //         return value;
  //     }
  //   };

  //   const {
  //     handleSubmit,
  //     setValue,
  //     trigger,
  //     control,
  //     formState: { errors, isSubmitted, isValid },
  //   } = useForm({
  //     resolver: yupResolver(getValidationSchema()),
  //     defaultValues: getInitialValues(),
  //   });
  //   const onSubmit = async (data: any) => {
  //     try {
  //       showLoader();
  //       const payload = { ...data };
  //       let url = end_points.adminPagesUpdate.url;

  //       switch (activeTab) {
  //         case 'past_surgeries':
  //           url += '/surgeries';
  //           break;
  //         case 'past_hospitalization':
  //           url += '/hospitalization';
  //           break;
  //         case 'pain_diagram':
  //           url += '/pain-diagram';
  //           break;
  //         default:
  //           url += '/medical-history';
  //       }

  //       const response = await putData(url, payload);
  //     } catch (error) {
  //       console.error(error);
  //     } finally {
  //       hideLoader();
  //     }
  //   };
  return (
    <div
      className="modal custom-modal fade"
      id="medical_history"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="medical-modal-header">
            <h4>Medical History</h4>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              ref={closeModelRef}
            >
              <span>×</span>
            </button>
          </div>
          <div className="medical-modal-body modal-body">
            {/* Medical Tabs */}
            <div className="appointment-tabs">
              <ul className="nav nav-tabs" id="medicalTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    href="#past_medical"
                    className="nav-link active"
                    id="past-medical"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-controls="past_medical"
                    aria-selected={activeTab === 'past_medical'}
                    onClick={() => setActiveTab('past_medical')}
                    ref={medicalRef}
                  >
                    Past Medical History
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    href="#past_surgeries"
                    className="nav-link"
                    id="past-surgeries"
                    data-bs-toggle="tab"
                    role="tab"
                    ref={surgeryRef}
                    aria-controls="past_surgeries"
                    aria-selected={activeTab === 'past_surgeries'}
                    onClick={() => setActiveTab('past_surgeries')}
                  >
                    Past Surgeries
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    href="#past_hospitalization"
                    className="nav-link"
                    id="past-hospitalization"
                    data-bs-toggle="tab"
                    role="tab"
                    ref={hospitalizationRef}
                    aria-controls="past_hospitalization"
                    aria-selected={activeTab === 'past_hospitalization'}
                    onClick={() => setActiveTab('past_hospitalization')}
                  >
                    Past Hospitalization
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    href="#social_history"
                    className="nav-link"
                    id="social-history"
                    data-bs-toggle="tab"
                    role="tab"
                    ref={personalRef}
                    aria-controls="social_history"
                    aria-selected={activeTab === 'social_history'}
                    onClick={() => setActiveTab('social_history')}
                  >
                    Personal /Social history
                  </a>
                </li>
                {/* family_history */}
                {/* <li className="nav-item" role="presentation">
                  <a
                    href="#family_history"
                    className="nav-link"
                    id="family_history"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-controls="family_history"
                    aria-selected={activeTab === 'family_history'}
                    onClick={() => setActiveTab('family_history')}
                  >
                    Family history of disease
                  </a>
                </li> */}
                <li className="nav-item" role="presentation">
                  <a
                    href="#family_history"
                    className="nav-link"
                    id="family-history"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-controls="family_history"
                    // aria-selected="false"s
                    ref={familyRef}
                    aria-selected={activeTab === 'family_history'}
                    onClick={() => setActiveTab('family_history')}
                  >
                    Family history of disease
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    href="#pain_diagram"
                    className="nav-link"
                    id="pain-diagram"
                    data-bs-toggle="tab"
                    role="tab"
                    ref={bodyPainRef}
                    aria-controls="pain_diagram"
                    aria-selected={activeTab === 'pain_diagram'}
                    onClick={() => setActiveTab('pain_diagram')}
                  >
                    Body Pain Diagram
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    href="#attachment_tab"
                    className="nav-link"
                    id="attachment-tab"
                    data-bs-toggle="tab"
                    role="tab"
                    ref={attachmentRef}
                    aria-controls="attachment_tab"
                    // aria-selected="false"
                    aria-selected={activeTab === 'attachment_tab'}
                    onClick={() => setActiveTab('attachment_tab')}
                  >
                    Attachment
                  </a>
                </li>
              </ul>
            </div>
            {/* /Medical Tabs */}
            {/* Medical History */}
            <div className="tab-content" id="medicalTabContent">
              {/* Past Medical History */}
              {/* <form onSubmit={handleSubmit(onSubmit)}> */}
              {/* {activeTab === 'past_medical' &&  */}
              <PastMedicalHistorty
                onSave={() => {
                  if (surgeryRef.current) {
                    surgeryRef.current.click();
                  }
                }}
              />
              {/* // } */}
              {/* /Past Medical History */}
              {/* Past Surgeries */}
              {/* {activeTab === 'past_surgeries' && */}
              <PastSurgeries
                onSave={() => {
                  if (hospitalizationRef.current) {
                    hospitalizationRef.current.click();
                  }
                }}
              />
              {/* } */}

              {/* /Past Surgeries */}
              {/* Past Hospitalization */}
              {/* {activeTab === 'past_hospitalization' &&  */}
              <Hospitilization
                onSave={() => {
                  if (personalRef.current) {
                    personalRef.current.click();
                  }
                }}
              />
              {/* } */}
              <PastSocialHistory
                onSave={() => {
                  if (familyRef.current) {
                    familyRef.current.click();
                  }
                }}
              />
              {/* /Past Hospitalization */}
              <FamilyHistoryDiease
                onSave={() => {
                  if (bodyPainRef.current) {
                    bodyPainRef.current.click();
                  }
                }}
              />
              {/* Body Pain Diagram */}
              {/* {activeTab === 'pain_diagram' &&  */}
              <PainDiagramForm
                onSave={() => {
                  if (attachmentRef.current) {
                    attachmentRef.current.click();
                  }
                }}
              />
              {/* } */}
              <UploadHistoryDocuments
                onSave={() => {
                  // if (closeModelRef.current) {
                  //   closeModelRef.current.click();
                  // }
                }}
              />
              {/* </form> */}
              {/* /Body Pain Diagram */}
            </div>

            {/* /Medical History */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MedicalHistoryModel;
