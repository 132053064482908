import React from 'react';
import { bodyDiagram } from '../../../../../utils/imagepath';

function BodyPainDiagram({ details = [] }) {
  const data: any = details;
  return (
    <div
      className="tab-pane fade"
      id="pain_diagram"
      role="tabpanel"
      aria-labelledby="pain-diagram"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="card diagram-card">
            <div className="card-body">
              <div className="past-header diagram-header">
                <h4>Body Pain Diagram</h4>
              </div>
              <div className="row">
                {data?.length > 0 ? (
                  <>
                    <div className="col-xl-7 col-lg-12">
                      <div className="body-diagram-img">
                        <img
                          src={bodyDiagram}
                          className="img-fluid"
                          alt="Diagram"
                        />
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-12">
                      <div className="diagram-list">
                        <ul className="nav">
                          {[
                            'Face',
                            'Neck',
                            'Thyroid',
                            'Left Breast',
                            'Right Breast',
                            'Spleen',
                            'Liver',
                            'Stomach',
                            'Abdomen',
                            'Pelvis',
                            'Genitals',
                            'Thighs',
                            'Legs',
                            'Arms',
                            'Back of head',
                            'Naps',
                            'Upper Back',
                            'Middle Back',
                            'Lower Back',
                            'Back of Thighs',
                            'Calves',
                            'Feet',
                          ].map((part, index) => (
                            <li key={index}>
                              <div className="custom-checkbox diagram-checkbox">
                                <label className="check">
                                  <input
                                    type="checkbox"
                                    value={part}
                                    checked={data?.includes(part)}
                                  />
                                  <span className="box" />
                                  <p>{`${index + 1} - ${part}`}</p>
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-xl-12 col-lg-12 ps-5 pb-4 ">
                    <ul className="nav">
                      <li>{'No records found'}</li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BodyPainDiagram;
