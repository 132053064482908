import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import AddDrugModel from './addDrugModel';
import PrimeDataTable from '../../../../../../components/dataTable';
import { Controller, useForm } from 'react-hook-form';
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from '../../../../../../utils/shared.module';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import {
  setPrescriptionDrugTableData,
  setSymtomsFlowData,
} from '../../../../../../core/redux/commonSlice';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import { end_points } from '../../../../../../services/end_point/end_points';
import moment from 'moment';
import {
  capitalizeFirstLetter,
  capitalizeWords,
  dateFormat_YYYY_MM_DD,
} from '../../../../../../utils/constant';
import PrescriptionModel from './prescriptionModel';
import { toast } from 'react-toastify';

interface SymptomsContext {
  referSpecialist: any;
}

const prescriptionValidation = yup.object().shape({
  frequencyDate: yup.string().trim().optional().nullable(),
});
const prescriptionSpecialityValidation = yup.object().shape({
  frequencyDate: yup.string().trim().optional().nullable(),
  speciality: yup
    .object({
      name: yup.string().required('Speciality is required'),
    })
    .nullable()
    .test(
      'required-when-refer-specialist-yes',
      'Speciality is required',
      function (value) {
        const { referSpecialist } = this.options.context as SymptomsContext;
        if (referSpecialist === 'Yes') {
          return value !== null && value.name !== '';
        }
        return true;
      },
    ),
});

const PrescriptionCall = () => {
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [rows, setRows] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showSelfCare, setShowSelfCare] = useState(false);

  const [referSpecialist, setReferSpecialist] = useState('No');
  const [referFutherAdvice, setReferFutherAdvice] = useState('No');
  const edit = useSelector((state: any) => state?.common?.editData);
  const appoitment_Id = useSelector((state: any) => state.common?.editData);
  const joinId = useSelector(
    (state: any) => state.common?.flowData?.joinCallData?._id,
  );

  const [appoitmentId, setAppoitmentId] = useState<any>('');
  const [editData, setEditData] = useState<any>('');
  const mainSchema =
    referSpecialist === 'Yes'
      ? prescriptionSpecialityValidation
      : prescriptionValidation;
  const savedData: any = useSelector((state: any) => state?.common?.flowData);
  const {
    handleSubmit,
    control,
    trigger,
    setError,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(mainSchema),
    defaultValues: {
      frequencyDate: null,
    },
    context: { referSpecialist },
  });

  const drug = useSelector(
    (state: any) => state.common.prescriptionDrugTableData,
  );
  const [drugData, setdrugDataList] = useState<any>([]);
  const [isDrugHere, setIsDrugHere] = useState<boolean>(false);
  useEffect(() => {
    const drugData = drug && JSON.parse(JSON.stringify(drug));

    setdrugDataList(drugData);
    setIsDrugHere(false);
    return () => {
      setdrugDataList([]);
    };
  }, [drug]);
  // useEffect(() => {
  //   return () => {
  //     dispatch(setPrescriptionDrugTableData([]));
  //   };
  // }, []);
  const handleEdit = (data: any, type: any) => {
    setEditData(data.rowIndex);
  };
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { getData, postData, putData } = useContext(ApiServiceContext);
  const dispatch = useDispatch();

  const columns = [
    {
      header: 'Medicine',
      field: 'medicine',
      key: 'medicine',
    },
    {
      header: 'Route',
      field: 'route',
      key: 'route',
    },
    {
      header: 'Dosage',
      field: 'dosage',
      key: 'dosage',
    },
    {
      header: 'Frequency',
      field: 'days',
      key: 'days',
    },
    {
      header: 'Duration',
      field: 'duration',
      key: 'duration',
    },
    {
      header: 'Timings',
      field: 'timings',
      key: 'timings',
      body: (data: any) => {
        const time = data?.timings?.map((timing: any) => {
          return timing?.name?.charAt(0)?.toUpperCase();
          // capitalizeFirstLetter(timing?.name);
        });
        return time?.map(
          (a: any, index: any) =>
            `${a}${time?.length === index + 1 ? '' : '|'}`,
        );
      },
    },
    {
      header: 'Instruction',
      field: 'instructions',
      key: 'instructions',
    },
    {
      header: 'Edit',
      sortable: false,
      body: (rowData: any, rowIndex: number) => (
        <div className="data-slot">
          <Tooltip title="Edit" color="grey">
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#add_drug"
              onClick={() => handleEdit(rowIndex, 'edit')}
            >
              <i className="feather icon-edit"></i>
            </Link>
          </Tooltip>
        </div>
      ),
    },
    {
      header: 'Delete',
      sortable: false,
      body: (rowData: any, rowIndex: any) => (
        <div className="data-slot">
          <Tooltip title="Delete" color="grey">
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete_slot"
              onClick={() => handleEdit(rowIndex, 'delete')}
            >
              <i className="feather icon-trash"></i>
            </Link>
          </Tooltip>
        </div>
      ),
    },
  ];

  const specialityOptions = [
    { name: 'General Surgery', code: 'general surgery' },
    { name: 'Internal Medicine', code: 'internal medicine' },
    { name: 'Dental Medicine', code: 'dental medicine' },
  ];
  const saveRef = useRef<any>(null);
  // useEffect(() => {
  //   if (drugData?.length > 0 && saveRef.current&&isDrugHere) {
  //     saveRef.current.click();
  //   }
  // }, [drugData]);
  const onSubmit = async (data: any) => {
    try {
      if (drugData?.length > 0) {
        showLoader();
        let ids = edit?._id || joinId;
        let url = end_points.updatePrescriptionListApi.url;
        url += `/${ids}`;
        const payload = {
          prescription: {
            self_care: data?.selfCare,
            referSpecialist: referSpecialist?.toLowerCase(),
            referFutherAdvice: referFutherAdvice?.toLowerCase(),
            speciality: data?.speciality?.name || '',
            follow_up_date: dateFormat_YYYY_MM_DD(data?.frequencyDate) || '',
            drugs: drugData,
          },
        };
        const response = await putData(url, payload);
        if (response.status === 200) {
          const newData = { ...savedData, pres: payload };
          dispatch(setSymtomsFlowData(newData));
          setIsDrugHere(true);
          setAppoitmentId('');
        }
      } else {
        toast.error('Add atleast one drug');
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    const data = savedData?.pres?.prescription;
    if (data) {
      if (drug?.length === data?.drugs?.length) {
        setIsDrugHere(true);
      } else {
        setIsDrugHere(false);
      }
      if (data?.follow_up_date) {
        const date = moment(data?.follow_up_date, 'YYYY-MM-DD').toDate();
        setValue('frequencyDate', date);
      }

      setReferFutherAdvice(capitalizeFirstLetter(referFutherAdvice));
      if (data?.referSpecialist === 'yes') {
        setValue('speciality', {
          name: capitalizeWords(data?.speciality),
          code: data?.speciality?.toLowerCase(),
        });
        setReferSpecialist(capitalizeFirstLetter(data?.referSpecialist));
      }
    }
  }, [savedData?.pres]);
  return (
    <div
      className="tab-pane fade"
      id="prescription_tab"
      role="tabpanel"
      aria-labelledby="prescription-tab"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="prescription-card">
            <div className="prescription-list">
              <Link
                to={'#'}
                data-bs-toggle="modal"
                data-bs-target="#add_drug"
                onClick={() => {
                  setEditData('');
                }}
              >
                Add Drug
              </Link>
              &nbsp;
              {isDrugHere ? (
                <Link
                  to={'#'}
                  data-bs-toggle="modal"
                  data-bs-target="#prescription_preview"
                  onClick={() => {
                    const id =
                      appoitment_Id &&
                      JSON.parse(JSON.stringify(appoitment_Id));
                    setAppoitmentId(id);
                  }}
                >
                  Prescription Preview
                </Link>
              ) : (
                <Tooltip title="Save to preview" color="gray">
                  <Link to={'#'} style={{ cursor: 'not-allowed' }}>
                    Prescription Preview
                  </Link>
                </Tooltip>
              )}
            </div>
            {/* <div className="prescription-table">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Medicine</th>
                      <th>Route</th>
                      <th>Dosage</th>
                      <th>Frequency</th>
                      <th>Duration</th>
                      <th>Timings</th>
                      <th>Instruction</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>XYZ Name</td>
                      <td>Oral</td>
                      <td>2 per day</td>
                      <td>3 times</td>
                      <td>2 weeks</td>
                      <td>9.40 am</td>
                      <td>Instruction</td>
                      <td className="data-link">
                        <Link to={'#'}>
                          <i className="feather-edit" />
                        </Link>
                      </td>
                      <td className="data-link">
                        <Link
                          to={'#'}
                          data-bs-toggle="modal"
                          data-bs-target="#delete_drug"
                        >
                          <i className="feather-trash" />
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
            <PrimeDataTable
              column={columns}
              data={drugData?.length > 0 ? drugData : []}
              rows={rows}
              setRows={setRows}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onRowClickSetState={false}
              totalRecords={totalRecords}
              rowClassName={'highlight'}
            />
            <form id="prescriptionForm" onSubmit={handleSubmit(onSubmit)}>
              <div className="prescription-form-top">
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="self-care-info">
                      <div className="self-care-add">
                        <Link
                          to={'#'}
                          className="self-care-link"
                          onClick={() => setShowSelfCare(!showSelfCare)}
                        >
                          Self Care
                        </Link>
                      </div>
                      {showSelfCare && (
                        <div className="symptoms-search">
                          <div className="symptoms-input">
                            <Controller
                              name="selfCare"
                              control={control}
                              render={({ field }) => (
                                <textarea
                                  className={`form-control ${
                                    errors.selfCare ? 'is-invalid' : ''
                                  }`}
                                  value={field.value}
                                  onBlur={() => trigger('selfCare')}
                                  onChange={(event: any) => {
                                    field.onChange(event);
                                    trigger('selfCare');
                                  }}
                                  autoComplete="false"
                                  placeholder="Please enter the description about self-care"
                                  maxLength={100}
                                />
                              )}
                            />
                          </div>
                        </div>
                      )}
                      <div className="refer-specialist">
                        <p>Refer to specialist ?</p>
                        <div className="custom-radio patient-radio-btn">
                          <label className="custom_radio">
                            <input
                              type="radio"
                              name="refer_specialist"
                              value="Yes"
                              checked={referSpecialist === 'Yes'}
                              onChange={() => setReferSpecialist('Yes')}
                            />
                            <span className="checkmark" /> Yes
                          </label>
                          <label className="custom_radio">
                            <input
                              type="radio"
                              name="refer_specialist"
                              value="No"
                              checked={referSpecialist === 'No'}
                              onChange={() => setReferSpecialist('No')}
                            />
                            <span className="checkmark" /> No
                          </label>
                        </div>
                        {referSpecialist === 'Yes' && (
                          <div className="refer-select-info">
                            <div className="symptoms-select">
                              <Controller
                                name="speciality"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <Dropdown
                                      {...field}
                                      value={field.value}
                                      onBlur={() => trigger('speciality')}
                                      onChange={(event: any) => {
                                        field.onChange(event);
                                        trigger('speciality');
                                      }}
                                      options={specialityOptions}
                                      optionLabel="name"
                                      placeholder="Select Speciality"
                                      className={`patient-reg-select ${errors.speciality ? 'is-invalid' : ''}`}
                                    />
                                    {errors.speciality && (
                                      <small className="text-danger d-inline-flex mt-2">
                                        {errors.speciality.name?.message ||
                                          errors.speciality.message}
                                      </small>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="refer-specialist">
                        <p>
                          Refer to higher center for further advice management?
                        </p>
                        <div className="custom-radio patient-radio-btn">
                          <label className="custom_radio">
                            <input
                              type="radio"
                              name="refer_management"
                              value="Yes"
                              checked={referFutherAdvice === 'Yes'}
                              onChange={() => setReferFutherAdvice('Yes')}
                            />
                            <span className="checkmark" /> Yes
                          </label>
                          <label className="custom_radio">
                            <input
                              type="radio"
                              name="refer_management"
                              value="No"
                              checked={referFutherAdvice === 'No'}
                              onChange={() => setReferFutherAdvice('No')}
                            />
                            <span className="checkmark" /> No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="self-care-calender">
                      <Controller
                        name="frequencyDate"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Calendar
                              appendTo="self"
                              className="w-100"
                              placeholder="Followup date"
                              value={field.value}
                              minDate={new Date()}
                              onChange={(event: any) => {
                                field.onChange(event);
                                trigger('frequencyDate');
                              }}
                              inputClassName={`form-control ${
                                errors.frequencyDate ? '' : ''
                              }`}
                            />
                          );
                        }}
                      />
                      <i className="feather icon-calendar"></i>
                      <small className="text-danger d-inline-flex mt-2">
                        {errors?.frequencyDate?.message}
                      </small>
                    </div>
                  </div>
                  <div className="symptoms-save text-start mt-2 mb-4">
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      form="prescriptionForm"
                      ref={saveRef}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <AddDrugModel
        editData={editData}
        cancel={() => {
          setEditData('');
        }}
        setIsDrugHere={setIsDrugHere}
      />
      <div
        className="modal custom-modal fade"
        id="delete_slot"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="medical-modal-header pb-0">
              <button type="button" className="close" data-bs-dismiss="modal">
                <span>×</span>
              </button>
            </div>
            <div className="modal-body drug-modal-body pt-2">
              <div className="modal-form">
                <div className="form-group modal-text">
                  <h5>Delete Prescription?</h5>
                  <p>Are you sure you want to delete the prescription?</p>
                </div>
                <div className="modal-form-btn text-center mt-4">
                  <button
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      const updatedList = drugData?.filter(
                        (_: any, index: number) => index !== editData,
                      );
                      setIsDrugHere(false);
                      dispatch(
                        setPrescriptionDrugTableData(
                          updatedList?.[0] !== null ? updatedList : [],
                        ),
                      );
                    }}
                  >
                    Delete
                  </button>
                  <button className="btn btn-danger" data-bs-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PrescriptionModel
        appoitmentId={appoitmentId}
        showPreview={isDrugHere ? 'OPEN' : 'FALSE'}
        setAppoitmentId={setAppoitmentId}
      />
    </div>
  );
};

export default PrescriptionCall;
