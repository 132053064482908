import React, { useContext, useEffect, useRef, useState } from 'react';
import { defaultProfileImage } from '../../../../utils/imagepath';
import { Dropdown } from 'primereact/dropdown';
import {
  ApiServiceContext,
  routes,
  yup,
  yupResolver,
} from '../../../../utils/shared.module';
import {
  aadharMaxLength,
  addressMaxLength,
  alphaNumeric,
  email,
  emailMaxLength,
  nameMaxLength,
  onlyAlphabet,
  onlyNumber,
  passwordMaxLength,
  passwordPattern,
  pincodeMaxLength,
  validatePhoneNumber,
} from '../../../../utils/patterns/regex.pattern';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { end_points } from '../../../../services/end_point/end_points';
import PhoneInputWithRef from '../../../../utils/phoneInputWithRef';
import { Calendar } from 'primereact/calendar';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { userRole } from '../../../../assets/global';
import { convertDate } from '../../../../utils/formatter/formatter';
import { useSelector } from 'react-redux';

const PatientRegistration = () => {
  const patientRegValidation = yup.object().shape({
    title: yup
      .object({
        name: yup.string().trim().required('Title is required'),
      })
      .nullable()
      .required('Title is required'),
    maritalStatus: yup
      .object({
        name: yup.string().trim().required('Marital status is required'),
      })
      .nullable()
      .required('Marital status is required'),
    gender: yup
      .object({
        name: yup.string().trim().required('Gender is required'),
      })
      .nullable()
      .required('Gender is required'),
    bloodGroup: yup
      .object({
        name: yup.string().trim().required('Blood group is required'),
      })
      .nullable()
      .required('Blood group is required'),
    genMedicine: yup.string().trim().optional(),
    country: yup.string().trim().required('Country is required'),
    firstName: yup.string().trim().required('First name is required'),
    lastName: yup.string().trim().required('Last name is required'),
    email: yup
      .string()
      .email('Enter valid email')
      .trim()
      .matches(email, 'Enter valid email')
      .required('Email is required'),
    ph_no: yup
      .string()
      .trim()
      .required('Mobile number is required')
      .test('is-valid-phone', 'Invalid phone number', (value) =>
        validatePhoneNumber(value),
      ),
    password: yup
      .string()
      .trim()
      .required('Enter password')
      .matches(
        passwordPattern,
        'Password must be at least 5 to 10 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character(~`!@#$%^&*()-_+={}[]|;:"<>,./?)',
      )
      .trim(),
    dob: yup.string().trim().required('Choose date of birth'),
    allergies: yup.string().trim().optional(),
    addressLaneOne: yup.string().trim().required('Address lane 1 is required'),
    addressLaneTwo: yup.string().trim().optional(),
    state: yup.string().trim().required('State is required'),
    city: yup.string().trim().required('City is required'),
    pincode: yup
      .string()
      .trim()
      .required('Pincode is required')
      .min(6, 'Pincode must be six digits'),
    aadhaarNo: yup.string().trim().optional(),
    profile_image: yup
      .mixed()
      .optional()
      .nullable()
      // .required('Please upload an image!')
      .test('fileSize', 'File size is too large', (value: any) => {
        if (!value) return true;
        if (value.size > 5 * 1024 * 1024) {
          return false;
        }
        return true;
      }),
  });

  const {
    handleSubmit,
    control,
    trigger,
    setError,
    setValue,
    clearErrors,
    formState: { errors, isSubmitted, isValid },
  } = useForm({
    resolver: yupResolver(patientRegValidation),
    defaultValues: {
      title: { name: '' },
      firstName: '',
      email: '',
      ph_no: '',
      aadhaarNo: '',
    },
  });

  const [confirm, setConfirm] = useState(false);
  const [profileFile, setProfileFile] = useState('');
  const [image, setImage] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmError, setConfirmError] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [genMedicineData, setGenMedicineData] = useState<any>({});
  const navigate = useNavigate();
  const [countryListData, setCountryListData] = useState<any>({});
  const formInvalid = isSubmitted && !isValid;
  const { getData, postData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleConfirmBox = (e: any) => {
    setConfirm(e.target.checked);
  };
  const titleOptions = [
    { name: 'Mr', code: 'Mr' },
    { name: 'Mrs', code: 'Mrs' },
    { name: 'Sh', code: 'Sh' },
    { name: 'Smt', code: 'Smt' },
    { name: 'Dr', code: 'Dr' },
  ];
  const genderOptions = [
    { name: 'Male', code: 'male' },
    { name: 'Female', code: 'female' },
    { name: 'Others', code: 'others' },
  ];
  const maritalOptions = [
    { name: 'Married', code: 'married' },
    { name: 'Single', code: 'single' },
    { name: 'Divorced', code: 'divorced' },
    { name: 'Widowed', code: 'widowed' },
  ];
  const bloodGroupOptions = [
    { name: 'A-', code: 'A-' },
    { name: 'A+', code: 'A+' },
    { name: 'B-', code: 'B-' },
    { name: 'B+', code: 'B+' },
    { name: 'AB-', code: 'AB-' },
    { name: 'AB+', code: 'AB+' },
    { name: 'O-', code: 'O-' },
    { name: 'O+', code: 'O+' },
  ];

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        const fileURL = URL.createObjectURL(file);
        setProfileFile(fileURL);
        setError('profile_image', {
          type: 'manual',
          message: 'File size is too large',
        });
        return;
      } else {
        setImage(file);
        clearErrors('profile_image');
      }
      const fileURL = URL.createObjectURL(file);
      setProfileFile(fileURL);
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getCountryList = async (search?: any) => {
    try {
      showLoader();
      const urls = end_points.getCountryList.url;
      const response = await getData(urls);
      if (response?.status == 200) {
        const countryList = response?.data.map((country: any) => ({
          label: country.name,
          value: country.name,
        }));
        setCountryListData(countryList);
        const india = countryList?.find(
          (country: any) => country.value === 'India',
        );
        setValue('country', india ? india?.value : '');
      }
    } catch (error: any) {
    } finally {
      hideLoader();
    }
  };

  const getGenMedicineList = async (search?: any) => {
    try {
      showLoader();
      const urls = end_points.getGenMedicineList.url;
      const response = await getData(urls);
      if (response?.status == 200) {
        const medicineList = response?.data.map((medicine: any) => ({
          label: medicine.name,
          value: medicine.id,
        }));
        setGenMedicineData(medicineList);
      }
    } catch (error: any) {
    } finally {
      hideLoader();
    }
  };

  const onSubmit = async (data: any) => {
    if (!confirm) {
      setConfirmError(true);
      setErrorMessage('Kindly confirm and proceed');
      return;
    } else {
      setConfirmError(false);
    }
    try {
      showLoader();
      let url = end_points.frontDeskPatientRegisterApi.url;
      url += `/${userRole.Patient}`;
      const formData = new FormData();
      formData.append(`title`, data?.title?.name);
      formData.append(`gender`, data?.gender?.code);
      formData.append(`bloodgroup`, data?.bloodGroup?.name);
      formData.append(`generalmedicine`, data?.genMedicine);
      formData.append(`country`, data?.country);
      formData.append(`firstname`, data?.firstName);
      formData.append(`lastname`, data?.lastName);
      formData.append('fullname', `${data?.firstName} ${data?.lastName}`);
      formData.append(`type`, 'patient');
      formData.append(`email`, data?.email);
      formData.append(`mobile`, `+${data?.ph_no}`);
      formData.append(`password`, data?.password);
      formData.append(`dob`, convertDate(data?.dob));
      formData.append(`maritalstatus`, data?.maritalStatus?.code);
      formData.append(`allergies`, data?.allergies);
      formData.append(`addressLane1`, data?.addressLaneOne);
      formData.append(`addressLane2`, data?.addressLaneTwo);
      formData.append(`state`, data?.state);
      formData.append(`city`, data?.city);
      formData.append(`pincode`, data?.pincode);
      formData.append(`aadhaar_no`, data?.aadhaarNo);
      formData.append(`profile_image`, image);

      const response = await postData(url, formData);
      if (response?.status === 200) {
        navigate(routes.frontDeskPatientList.path);
      }
    } catch (error: any) {
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getCountryList();
    getGenMedicineList();
  }, []);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container">
          {/* Patient Registration */}
          <div className="patient-register-info">
            <div className="patient-register-header">
              <h4>PATIENT REGISTRATION</h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-6 col-md-12 d-flex">
                  <div className="card patient-register-card w-100">
                    <div className="card-body pb-3">
                      <div className="patient-register-form">
                        <div className="patient-register-form-wrap">
                          <div className="row">
                            <div className="col-md-3">
                              <div className="patient-register-img">
                                <img
                                  src={profileFile || defaultProfileImage}
                                  alt="Profile"
                                  onClick={handleImageClick}
                                  style={{
                                    cursor: 'pointer',
                                    width: '100px',
                                    height: '100px',
                                  }}
                                />
                                <Controller
                                  name="profile_image"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <input
                                        type="file"
                                        ref={fileInputRef}
                                        multiple={false}
                                        accept="image/jpeg, image/png, image/jpg"
                                        id="profile_image"
                                        style={{ display: 'none' }}
                                        onChange={(e) => {
                                          handleFileChange(e);
                                          field.onChange(e.target.files?.[0]);
                                        }}
                                      />
                                    </>
                                  )}
                                />
                                {errors.profile_image && (
                                  <small className="text-danger">
                                    {errors.profile_image.message}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-9">
                              <div className="patient-register-input">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-wrap">
                                      <Controller
                                        name="title"
                                        control={control}
                                        render={({ field }) => (
                                          <>
                                            <Dropdown
                                              {...field}
                                              value={field.value}
                                              onBlur={() => trigger('title')}
                                              onChange={(event: any) => {
                                                field.onChange(event);
                                                trigger('title');
                                              }}
                                              options={titleOptions}
                                              optionLabel="name"
                                              placeholder="Title*"
                                              className={`w-100 patient-reg-select ${errors.title ? 'is-invalid' : ''}`}
                                            />
                                            {errors.title && (
                                              <small className="text-danger d-inline-flex mt-2">
                                                {errors.title.name?.message ||
                                                  errors.title.message}
                                              </small>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-wrap">
                                      <Controller
                                        name="firstName"
                                        control={control}
                                        render={({ field }) => (
                                          <>
                                            <input
                                              {...field}
                                              type="text"
                                              placeholder="First name*"
                                              className={`form-control ${
                                                errors.firstName
                                                  ? 'is-invalid'
                                                  : ''
                                              }`}
                                              onBlur={() =>
                                                trigger('firstName')
                                              }
                                              onChange={(event: any) => {
                                                field.onChange(event);
                                                trigger('firstName');
                                              }}
                                              onKeyDown={(event) => {
                                                const regex = onlyAlphabet;
                                                if (
                                                  !regex.test(event.key) &&
                                                  event.key !== 'Backspace'
                                                ) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              maxLength={nameMaxLength}
                                            />
                                            {errors.firstName && (
                                              <small className="text-danger d-inline-flex mt-2">
                                                {errors.firstName.message}
                                              </small>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-wrap">
                                      <Controller
                                        name="lastName"
                                        control={control}
                                        render={({ field }) => (
                                          <>
                                            <input
                                              {...field}
                                              type="text"
                                              placeholder="Last name*"
                                              className={`form-control ${
                                                errors.lastName
                                                  ? 'is-invalid'
                                                  : ''
                                              }`}
                                              onBlur={() => trigger('lastName')}
                                              onChange={(event: any) => {
                                                field.onChange(event);
                                                trigger('lastName');
                                              }}
                                              onKeyDown={(event) => {
                                                const regex = onlyAlphabet;
                                                if (
                                                  !regex.test(event.key) &&
                                                  event.key !== 'Backspace'
                                                ) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              maxLength={nameMaxLength}
                                            />
                                            {errors.lastName && (
                                              <small className="text-danger d-inline-flex mt-2">
                                                {errors.lastName.message}
                                              </small>
                                            )}
                                          </>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="patient-register-input">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-wrap">
                                  <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <input
                                          {...field}
                                          type="text"
                                          placeholder="Email*"
                                          className={`form-control ${
                                            errors.email ? 'is-invalid' : ''
                                          }`}
                                          onBlur={() => trigger('email')}
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('email');
                                          }}
                                          maxLength={emailMaxLength}
                                          autoComplete="false"
                                        />
                                        {errors.email && (
                                          <small className="text-danger d-inline-flex mt-2">
                                            {errors.email.message}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div
                                  className={`form-wrap ${formInvalid ? 'is-invalid' : ''}`}
                                >
                                  <Controller
                                    name="ph_no"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                      <div>
                                        <PhoneInputWithRef
                                          {...field}
                                          placeholder="Enter phone number*"
                                          country={'in'}
                                          value={field.value}
                                          onBlur={() => trigger('ph_no')}
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('ph_no');
                                          }}
                                          inputProps={{
                                            name: 'ph_no',
                                            required: true,
                                            className: `form-control ${fieldState.invalid ? 'is-invalid' : ''}`,
                                          }}
                                        />
                                        {fieldState.invalid && (
                                          <small className="text-danger d-inline-flex mt-2">
                                            {errors.ph_no?.message}
                                          </small>
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-wrap pass-group">
                                  <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <input
                                          {...field}
                                          type={
                                            passwordVisible
                                              ? 'text'
                                              : 'password'
                                          }
                                          placeholder="Password*"
                                          className={`form-control ${
                                            errors.password ? 'is-invalid' : ''
                                          }`}
                                          onBlur={() => trigger('password')}
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('password');
                                          }}
                                          maxLength={passwordMaxLength}
                                        />
                                        <span
                                          className={`fas ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} toggle-password ${errors.password ? 'toggle-password-icon-right' : 'toggle-password-icon'}`}
                                          onClick={togglePasswordVisibility}
                                        />
                                        {errors.password && (
                                          <>
                                            {errors.password.type ===
                                              'required' && (
                                              <small className="text-danger">
                                                {errors.password.message}
                                              </small>
                                            )}

                                            {errors.password.type ===
                                              'matches' && (
                                              <small className="text-danger">
                                                <ul className="custom-ul-react">
                                                  <li>
                                                    Password must be at least 5
                                                    to 10 characters long.
                                                  </li>
                                                  <li>
                                                    Contain one uppercase
                                                    letter.
                                                  </li>
                                                  <li>
                                                    Contain one lowercase
                                                    letter.
                                                  </li>
                                                  <li>Contain one number.</li>
                                                  <li>
                                                    Contain one special
                                                    character
                                                    (~`!@#$%^&amp;*()-_+={}
                                                    []|;:&quot;&lt;&gt;,./?)
                                                  </li>
                                                </ul>
                                              </small>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-wrap form-wrap-calendar-input">
                                  <Controller
                                    name="dob"
                                    control={control}
                                    render={({ field }) => (
                                      <Calendar
                                        appendTo="self"
                                        className="w-100"
                                        placeholder="Date of birth*"
                                        maxDate={yesterday}
                                        // onBlur={() => trigger('dob')}
                                        onChange={(event: any) => {
                                          field.onChange(event);
                                          trigger('dob');
                                        }}
                                        inputClassName={`form-control ${
                                          errors.dob ? 'is-invalid' : ''
                                        }`}
                                      />
                                    )}
                                  />
                                  <small className="text-danger d-inline-flex mt-2">
                                    {errors?.dob?.message}
                                  </small>
                                </div>
                                <div className="form-wrap">
                                  <Controller
                                    name="maritalStatus"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <Dropdown
                                          {...field}
                                          value={field.value}
                                          onBlur={() =>
                                            trigger('maritalStatus')
                                          }
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('maritalStatus');
                                          }}
                                          options={maritalOptions}
                                          optionLabel="name"
                                          placeholder="Marital status*"
                                          className={`w-100 patient-reg-select ${errors.maritalStatus ? 'is-invalid' : ''}`}
                                        />
                                        {errors.maritalStatus && (
                                          <small className="text-danger d-inline-flex mt-2">
                                            {errors.maritalStatus.name
                                              ?.message ||
                                              errors.maritalStatus.message}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-wrap">
                                  <Controller
                                    name="gender"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <Dropdown
                                          {...field}
                                          value={field.value}
                                          onBlur={() => trigger('gender')}
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('gender');
                                          }}
                                          options={genderOptions}
                                          optionLabel="name"
                                          placeholder="Gender*"
                                          className={`w-100 patient-reg-select ${errors.gender ? 'is-invalid' : ''}`}
                                        />
                                        {errors.gender && (
                                          <small className="text-danger d-inline-flex mt-2">
                                            {errors.gender.name?.message ||
                                              errors.gender.message}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  />
                                </div>
                                <div className="form-wrap">
                                  <Controller
                                    name="bloodGroup"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <Dropdown
                                          {...field}
                                          value={field.value}
                                          onBlur={() => trigger('bloodGroup')}
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('bloodGroup');
                                          }}
                                          options={bloodGroupOptions}
                                          optionLabel="name"
                                          placeholder="Blood group*"
                                          className={`w-100 patient-reg-select ${errors.bloodGroup ? 'is-invalid' : ''}`}
                                        />
                                        {errors.bloodGroup && (
                                          <small className="text-danger d-inline-flex mt-2">
                                            {errors.bloodGroup.name?.message ||
                                              errors.bloodGroup.message}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 d-flex">
                  <div className="card patient-register-card w-100">
                    <div className="card-body pb-0">
                      <div className="patient-register-form">
                        <div className="patient-register-form-wrap">
                          <div className="patient-register-input">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-wrap">
                                  <Controller
                                    name="genMedicine"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <Dropdown
                                          {...field}
                                          value={field.value}
                                          onBlur={() => trigger('genMedicine')}
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('genMedicine');
                                          }}
                                          options={genMedicineData}
                                          optionLabel="label"
                                          placeholder="General medicine"
                                          className={`w-100 patient-reg-select ${errors.genMedicine ? 'is-invalid' : ''}`}
                                        />
                                        {errors.genMedicine && (
                                          <small className="text-danger d-inline-flex mt-2">
                                            {errors.genMedicine.message}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-wrap">
                                  <Controller
                                    name="allergies"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <input
                                          {...field}
                                          type="text"
                                          placeholder="Allergies"
                                          className={`form-control ${
                                            errors.allergies ? 'is-invalid' : ''
                                          }`}
                                          onBlur={() => trigger('allergies')}
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('allergies');
                                          }}
                                          maxLength={addressMaxLength}
                                        />
                                        {errors.allergies && (
                                          <small className="text-danger d-inline-flex mt-2">
                                            {errors.allergies.message}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-wrap">
                                  <Controller
                                    name="addressLaneOne"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <input
                                          {...field}
                                          type="text"
                                          placeholder="Address lane 1*"
                                          className={`form-control ${
                                            errors.addressLaneOne
                                              ? 'is-invalid'
                                              : ''
                                          }`}
                                          onBlur={() =>
                                            trigger('addressLaneOne')
                                          }
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('addressLaneOne');
                                          }}
                                          maxLength={addressMaxLength}
                                        />
                                        {errors.addressLaneOne && (
                                          <small className="text-danger d-inline-flex mt-2">
                                            {errors.addressLaneOne.message}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-wrap">
                                  <Controller
                                    name="addressLaneTwo"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <input
                                          {...field}
                                          type="text"
                                          placeholder="Address lane 2"
                                          className={`form-control ${
                                            errors.addressLaneTwo
                                              ? 'is-invalid'
                                              : ''
                                          }`}
                                          onBlur={() =>
                                            trigger('addressLaneTwo')
                                          }
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('addressLaneTwo');
                                          }}
                                          maxLength={addressMaxLength}
                                        />
                                        {errors.addressLaneTwo && (
                                          <small className="text-danger d-inline-flex mt-2">
                                            {errors.addressLaneTwo.message}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-wrap">
                                  <Controller
                                    name="country"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <Dropdown
                                          {...field}
                                          value={field.value}
                                          onBlur={() => trigger('country')}
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('country');
                                          }}
                                          options={countryListData}
                                          optionLabel="label"
                                          placeholder="Country*"
                                          className={`w-100 patient-reg-select ${errors.country ? 'is-invalid' : ''}`}
                                        />
                                        {errors.country && (
                                          <small className="text-danger d-inline-flex mt-2">
                                            {errors.country.message}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-wrap">
                                  <Controller
                                    name="state"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <input
                                          {...field}
                                          type="text"
                                          placeholder="State*"
                                          className={`form-control ${
                                            errors.state ? 'is-invalid' : ''
                                          }`}
                                          onBlur={() => trigger('state')}
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('state');
                                          }}
                                          onKeyDown={(event) => {
                                            const regex = onlyAlphabet;
                                            if (
                                              !regex.test(event.key) &&
                                              event.key !== 'Backspace'
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          maxLength={nameMaxLength}
                                        />
                                        {errors.state && (
                                          <small className="text-danger d-inline-flex mt-2">
                                            {errors.state.message}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-wrap">
                                  <Controller
                                    name="city"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <input
                                          {...field}
                                          type="text"
                                          placeholder="City*"
                                          className={`form-control ${
                                            errors.city ? 'is-invalid' : ''
                                          }`}
                                          onBlur={() => trigger('city')}
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('city');
                                          }}
                                          onKeyDown={(event) => {
                                            const regex = onlyAlphabet;
                                            if (
                                              !regex.test(event.key) &&
                                              event.key !== 'Backspace'
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          maxLength={nameMaxLength}
                                        />
                                        {errors.city && (
                                          <small className="text-danger d-inline-flex mt-2">
                                            {errors.city.message}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-wrap">
                                  <Controller
                                    name="pincode"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <input
                                          {...field}
                                          type="text"
                                          placeholder="Pincode*"
                                          className={`form-control ${
                                            errors.pincode ? 'is-invalid' : ''
                                          }`}
                                          onBlur={() => trigger('pincode')}
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('pincode');
                                          }}
                                          onKeyDown={(event) => {
                                            const regex = onlyNumber;
                                            if (
                                              !regex.test(event.key) &&
                                              event.key !== 'Backspace'
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          maxLength={pincodeMaxLength}
                                          minLength={pincodeMaxLength}
                                        />
                                        {errors.pincode && (
                                          <small className="text-danger d-inline-flex mt-2">
                                            {errors.pincode.message}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-wrap">
                                  <Controller
                                    name="aadhaarNo"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <input
                                          {...field}
                                          type="text"
                                          placeholder="Aadhaar number"
                                          className={`form-control ${
                                            errors.aadhaarNo ? 'is-invalid' : ''
                                          }`}
                                          onBlur={() => trigger('aadhaarNo')}
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('aadhaarNo');
                                          }}
                                          onKeyDown={(event) => {
                                            const regex = onlyNumber;
                                            if (
                                              !regex.test(event.key) &&
                                              event.key !== 'Backspace'
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          maxLength={aadharMaxLength}
                                        />
                                        {errors.aadhaarNo && (
                                          <small className="text-danger d-inline-flex mt-2">
                                            {errors.aadhaarNo.message}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="patient-register-proceed">
                    <div className="custom-radio">
                      <label className="custom_radio">
                        <input
                          type="checkbox"
                          name="confirm"
                          onClick={handleConfirmBox}
                        />
                        <span className="checkmark" /> Confirm Details and
                        proceed
                      </label>
                    </div>
                    {confirmError && (
                      <p className="text-danger">{errorMessage}</p>
                    )}
                  </div>
                  <div className="patient-register-btn">
                    <button type="submit" className="btn btn-secondary">
                      Register
                    </button>
                    <button
                      type="submit"
                      className="btn btn-light"
                      onClick={() => {
                        navigate(routes.frontDeskPatientList.path);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/* /Patient Registration */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default PatientRegistration;
