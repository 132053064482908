import React from 'react';

function DeleteDrugModel() {
  return (
    <div
      className="modal custom-modal fade"
      id="delete_drug"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="medical-modal-header pb-0">
            <button type="button" className="close" data-bs-dismiss="modal">
              <span>×</span>
            </button>
          </div>
          <div className="modal-body drug-modal-body">
            <div className="modal-form">
              <div className="form-group modal-text">
                <h5>Delete Drug?</h5>
                <p>Are you sure you want to delete drug you selected.</p>
              </div>
              <div className="modal-form-btn text-center mt-4">
                <button className="btn btn-primary" data-bs-dismiss="modal">
                  Delete
                </button>
                <button className="btn btn-danger" data-bs-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteDrugModel;
