export const kioskList = [
  {
    id: "#129845",
    key: "1",
    kioskName: "China Town",
    kioskCode: "CT",
    address: "Willsen Park",
    status: "Active",
  },
  {
    id: "#129845",
    key: "2",
    kioskName: "China Town",
    kioskCode: "CT",
    address: "Willsen Park",
    status: "Disabled",
  },
  {
    id: "#129845",
    key: "3",
    kioskName: "China Town",
    kioskCode: "CT",
    address: "Willsen Park",
    status: "Inactive",
  },
  {
    id: "#129845",
    key: "4",
    kioskName: "China Town",
    kioskCode: "CT",
    address: "Willsen Park",
    status: "Active",
  },
  {
    id: "#129845",
    key: "5",
    kioskName: "China Town",
    kioskCode: "CT",
    address: "Willsen Park",
    status: "Disabled",
  },
  {
    id: "#129845",
    key: "6",
    kioskName: "China Town",
    kioskCode: "CT",
    address: "Willsen Park",
    status: "Inactive",
  },
  {
    id: "#129845",
    key: "7",
    kioskName: "China Town",
    kioskCode: "CT",
    address: "Willsen Park",
    status: "Active",
  },
];
