import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  editData: '',
  phoneNumber: '',
  pathFrom: '',
  associateToData: {},
  userId: '',
  doctorSlotEditData: undefined,
  prescriptionDrugTableData: [],
  vitalsData: '',
  newScheduleDoctor: '',
  notifyCountBool: undefined,
  flowData: {},
};

const CommonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setEditData: (state, { payload }) => {
      state.editData = payload;
    },
    setPhoneNumber: (state, { payload }) => {
      state.phoneNumber = payload;
    },
    setPathFrom: (state, { payload }) => {
      state.pathFrom = payload;
    },
    setAssociateToData: (state, { payload }) => {
      state.associateToData = payload;
    },
    setUserId: (state, { payload }) => {
      state.userId = payload;
    },
    setDoctorSlotEditData: (state, { payload }) => {
      state.doctorSlotEditData = payload;
    },
    setPrescriptionDrugTableData: (state, { payload }) => {
      state.prescriptionDrugTableData = payload;
    },
    setAppoinmentStatusUpdate: (state, { payload }) => {
      state.appoinmentStatusUpdate = payload;
    },
    setAvailableDoctorUpdate: (state, { payload }) => {
      state.availableDoctorUpdate = payload;
    },
    setSessionDetails: (state, { payload }) => {
      state.sessionDetails = payload;
    },
    setCallJoined: (state, { payload }) => {
      state.callJoined = payload;
    },
    setVitalsUpdated: (state, { payload }) => {
      state.vitalsData = payload;
    },
    setScheduleDoctorUpdate: (state, { payload }) => {
      state.newScheduleDoctor = payload;
    },
    setNotifyCountBool: (state, { payload }) => {
      state.notifyCountBool = payload;
    },
    setSymtomsFlowData: (state, { payload }) => {
      console.log(payload, 'symtoms');

      state.flowData = payload;
    },
    setPastData: (state, { payload }) => {
      state.pastData = payload;
    },
  },
});

export const {
  setEditData,
  setPhoneNumber,
  setPathFrom,
  setPastData,
  setAssociateToData,
  setUserId,
  setActiveMenu,
  setDoctorSlotEditData,
  setPrescriptionDrugTableData,
  setAppoinmentStatusUpdate,
  setScheduleDoctorUpdate,
  setAvailableDoctorUpdate,
  setSessionDetails,
  setCallJoined,
  setVitalsUpdated,
  setNotifyCountBool,
  setSymtomsFlowData,
} = CommonSlice.actions;

export default CommonSlice.reducer;
