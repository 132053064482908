import moment from 'moment';
import React from 'react';
import { capitalizeFirstLetter } from '../../../../../utils/constant';

function PastMedicalHistory({ patient_Id = '', details = [] }) {
  const medicalHistory = details;

  return (
    <div
      className="tab-pane fade show active"
      id="past_medical"
      role="tabpanel"
      aria-labelledby="past-medical"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="card past-card">
            <div className="card-body">
              <div className="past-header">
                <h4>Past Medical History</h4>
              </div>
              <div className="vitals-list">
                {medicalHistory?.length > 0 ? (
                  medicalHistory?.map((item: any) => {
                    return (
                      <ul className="nav">
                        <li>{item?.name}</li>
                        <li>
                          {item?.isChecked?.toLowerCase() === 'yes' ? (
                            <div className="vitals-since">
                              <p className="vitals-text">
                                <span className="text-light-danger">
                                  {capitalizeFirstLetter(item?.isChecked)}
                                </span>{' '}
                                Since{' '}
                                {moment(item?.date, 'MM/YYYY').format(
                                  'MMMM, YYYY',
                                )}
                              </p>
                            </div>
                          ) : (
                            <div className="vitals-since">
                              <p>
                                <span className="text-primary">
                                  {capitalizeFirstLetter(item?.isChecked)}
                                </span>
                              </p>
                            </div>
                          )}
                        </li>
                      </ul>
                    );
                  })
                ) : (
                  // <tr>
                  <ul className="nav">
                    <li>{'No records found'}</li>
                  </ul>
                  // </tr>
                )}
                {/* <tr>
                        <td className="f-500">Hypertension</td>
                        <td className="text-primary">No</td>
                      </tr>
                      <tr>
                        <td className="f-500">Heart Disease</td>
                        <td className="text-primary">No</td>
                      </tr>
                      <tr>
                        <td className="f-500">Thyroid Disease</td>
                        <td className="text-primary">No</td>
                      </tr>
                      <tr>
                        <td className="f-500">Liver Disease</td>
                        <td className="text-light-danger">Yes</td>
                      </tr>
                      <tr>
                        <td className="f-500">Seizure</td>
                        <td className="text-light-danger">Yes</td>
                      </tr> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PastMedicalHistory;
