import React from 'react';
import {
  defaultProfileTwoImage,
  patient01,
} from '../../../../../utils/imagepath';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  calculateAge,
  capitalizeFirstLetter,
} from '../../../../../utils/constant';

interface props {
  setShowMedical: (show: boolean) => void;
  showMedical: boolean;
}
const PatientDetailsSidebar: React.FC<props> = ({
  setShowMedical,
  showMedical = false,
}) => {
  const patientDetail: any = useSelector(
    (state: any) => state.common.editData?.patient,
  );
  const pastPatientDetails: any = useSelector(
    (state: any) => state.common.pastData?.patient,
  );
  const patientDetails = pastPatientDetails
    ? pastPatientDetails
    : patientDetail;
  return (
    <div className="card-body">
      <div className="dash-header doctor-dash-header">
        <h4>Patient Details</h4>
      </div>
      <div className="doctor-dashboard-img">
        <img
          src={
            patientDetails?.profile_image
              ? patientDetails?.profile_image
              : defaultProfileTwoImage
          }
          className="img-fluid"
          alt="Patient"
        />
      </div>
      <div className="doctor-online">
        <h6>{patientDetails?.fullname}</h6>
      </div>
      <div className="patient-header-list patient-header-info">
        <h6>
          <span>Patient ID:</span> {patientDetails?.reg_id}
        </h6>
        <ul>
          <li>
            <p>
              {capitalizeFirstLetter(patientDetails?.gender)} |{' '}
              {calculateAge(patientDetails?.dob)}
            </p>
          </li>
          <li>
            <p>{patientDetails?.email}</p>
          </li>
        </ul>
        {!showMedical && (
          <div className="patient-header-btn">
            <Link
              to="#"
              className="btn btn-secondary"
              onClick={() => {
                setShowMedical(true);
              }}
            >
              Medical History
            </Link>
          </div>
        )}
      </div>
    </div>

    // <div className="card">
    //   <div className="card-body">
    //     <div className="dash-header doctor-dash-header">
    //       <h4>Patient Details</h4>
    //     </div>

    //     <div className="doctor-online">
    //       <h6>Surinder Kumar Sekhri</h6>
    //       <ul className="patient-header-list">
    //         <li>
    //           <p>
    //             <span>Patient ID:</span> 256
    //           </p>
    //         </li>
    //       </ul>
    //     </div>
    //       <div className="row">
    //         <button
    //           type="button"
    //           className="btn btn-primary"
    //           onClick={() => {
    //             setShowMedical(true);
    //           }}
    //         >
    //           Medical History
    //         </button>
    //       </div>
    //   </div>
    // </div>
  );
};

export default PatientDetailsSidebar;
