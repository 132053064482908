import React, { useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
interface props {
  show: boolean;
  children: React.ReactNode;
  message: string;
}
const PopOverText: React.FC<props> = ({ message, show = {}, children }) => {
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);

  const handleMouseOver = (event: any) => {
    if (show) {
      setTarget(event.target);
      setShowPopover(true);
    }
  };

  const handleMouseOut = () => {
    setShowPopover(false);
  };
  return (
    <>
      <Overlay
        show={showPopover}
        target={target}
        placement="top"
        containerPadding={20}
      >
        <Popover id="popover-basic">
          <Popover.Body>{message}</Popover.Body>
        </Popover>
      </Overlay>
      <span
        className="d-inline-block"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {children}
      </span>
    </>
  );
};

export default PopOverText;
