import React, { useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { bodyDiagram } from '../../../../../../utils/imagepath';
import { ApiServiceContext } from '../../../../../../utils/shared.module';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import { end_points } from '../../../../../../services/end_point/end_points';
import { useSelector } from 'react-redux';
import { capitalizeWords } from '../../../../../../utils/constant';

// Define Yup validation schema
const bodyPainDiagramSchema = yup.object().shape({
  bodyParts: yup
    .array()
    .of(yup.string())
    .required('Please select at least one body part.'),
});
interface PainDiagramFormProps {
  onSave: Function;
}
const PainDiagramForm: React.FC<PainDiagramFormProps> = ({
  onSave = () => {},
}) => {
  const { putData, postData, getData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const patient_Id = useSelector(
    (state: any) => state?.common?.editData?.patient_id,
  );

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(bodyPainDiagramSchema),
    defaultValues: { bodyParts: [] },
  });

  const onSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        label: [
          {
            name: data.bodyParts.map(
              (part: any) => part,
              // ?.toLowerCase()
            ),
          },
        ],
      };
      let url = end_points.pastBodyPainCreateApi.url;
      if (patient_Id) {
        url += `/${patient_Id}`;
      }
      const response = await postData(url, payload);
      if (response.status === 200) {
        // console.log(response, 'response');
        onSave();
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const getBodyPainData = async () => {
    try {
      showLoader();
      let url = end_points.pastBodyPainListApi.url;
      if (patient_Id) {
        url += `/${patient_Id}`;
      }
      const payload = {
        patient_id: patient_Id,
      };

      const response = await getData(url, payload);
      if (response.status === 200) {
        setValue('bodyParts', response.data.data?.label?.[0]?.name);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    patient_Id && getBodyPainData();
  }, [patient_Id]);
  return (
    <div
      className="tab-pane fade"
      id="pain_diagram"
      role="tabpanel"
      aria-labelledby="pain-diagram"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="card medical-history-card diagram-card">
            <div className="card-body">
              <div className="past-header diagram-header">
                <h4 className="past-title">Body Pain Diagram</h4>
              </div>
              <div className="row">
                <div className="col-xl-7 col-lg-12">
                  <div className="body-diagram-img">
                    <img
                      src={bodyDiagram}
                      className="img-fluid"
                      alt="Diagram"
                    />
                  </div>
                </div>
                <div className="col-xl-5 col-lg-12">
                  <div className="diagram-list">
                    <Controller
                      name="bodyParts"
                      control={control}
                      render={({ field }) => (
                        <ul className="nav">
                          {[
                            'Face',
                            'Neck',
                            'Thyroid',
                            'Left Breast',
                            'Right Breast',
                            'Spleen',
                            'Liver',
                            'Stomach',
                            'Abdomen',
                            'Pelvis',
                            'Genitals',
                            'Thighs',
                            'Legs',
                            'Arms',
                            'Back of head',
                            'Naps',
                            'Upper Back',
                            'Middle Back',
                            'Lower Back',
                            'Back of Thighs',
                            'Calves',
                            'Feet',
                          ].map((part, index) => (
                            <li key={index}>
                              <div className="custom-checkbox diagram-checkbox">
                                <label className="check">
                                  <input
                                    type="checkbox"
                                    value={part}
                                    checked={field.value?.includes(part)}
                                    onChange={(e) => {
                                      const checked = e.target.checked;
                                      const value = e.target.value;
                                      if (checked) {
                                        field.onChange([...field.value, value]);
                                      } else {
                                        field.onChange(
                                          field.value.filter(
                                            (item) => item !== value,
                                          ),
                                        );
                                      }
                                    }}
                                  />
                                  <span className="box" />
                                  <p>{`${index + 1} - ${part}`}</p>
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    />
                    {errors.bodyParts && (
                      <small className="text-danger d-inline-flex mt-2">
                        {errors.bodyParts.message}
                      </small>
                    )}
                  </div>
                </div>
              </div>
              <div className="past-medical-btn">
                <button
                  disabled={!isDirty}
                  onClick={handleSubmit(onSubmit)}
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PainDiagramForm;
