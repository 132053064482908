import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { defaultProfileTwoImage, doctor01 } from '../../../utils/imagepath';
import ErrorMessage from '../../../components/errorMessage';
import { end_points } from '../../../services/end_point/end_points';
import { ApiServiceContext } from '../../../utils/shared.module';
import { SpinnerContext } from '../../../components/spinner/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDetails } from '../../../core/redux/loginSlice';

const ProfileModel = ({
  time = '',
  profileCloseRef,
}: {
  profileCloseRef: React.RefObject<any> | null;
  time: string;
}) => {
  const schema = yup.object().shape({
    profileImage: yup.mixed().required('Profile image is required'),
    name: yup.string().required('Name is required'),
    designation: yup
      .object()
      .shape({
        code: yup.string().required('Designation is required'),
        name: yup.string().required('Designation is required'),
        id: yup.string().optional(),
      })
      .required('Designation is required'),
  });
  const [degreeDataList, setDegreeDataList] = useState<any>([]);
  const { postData, putData, getData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const userDetails = useSelector((state: any) => state?.login?.userDetails);
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      profileImage: '',
      name: userDetails?.fullname,
      designation: { code: '', name: '', id: '' },
    },
  });
  const dispatch = useDispatch();
  const closeRef = useRef<any>(null);
  const profleImage: any = watch('profileImage');
  const previewImage: any = profleImage && URL.createObjectURL(profleImage);
  const getProfessionalData = async () => {
    try {
      showLoader();
      const response = await getData(end_points.degreeListApi.url);
      if (response.status === 200) {
        const professionalData = response?.data?.data?.map((degree: any) => {
          return { id: degree?.id, name: degree?.name, code: degree?.id };
        });
        const isUserDegree = response?.data?.data?.find(
          (degree: any) => degree?.id === userDetails?.professional_degree_id,
        );
        setValue('designation', {
          name: isUserDegree?.name,
          code: isUserDegree?.id,
          id: isUserDegree?.id,
        });
        setDegreeDataList(professionalData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData();

      let url = end_points.upadteProfileDetailsApi.url;
      if (userDetails?.id) {
        url += `/${userDetails?.id}`;
      }
      formData.append('fullname', data?.name);
      formData.append('professional_degree_id', data?.designation?.id);
      if (profleImage) {
        formData.append('profile_image', profleImage);
      }
      const response = await putData(url, formData);
      if (response.status === 200) {
        const userData = userDetails && JSON.parse(JSON.stringify(userDetails));
        userData.profile_image = response?.data?.data?.profile_image;
        userData.fullname = response?.data?.data?.fullname;
        userData.professional_degree_id =
          response?.data?.data?.professional_degree_id;

        dispatch(setUserDetails(userData));
        if (closeRef.current) {
          closeRef.current.setAttribute('data-bs-dismiss', 'modal');
          closeRef.current.click();
          closeRef.current.removeAttribute('data-bs-dismiss');
          if (profileCloseRef?.current) {
            profileCloseRef.current.checked = false;
          }
        }
      } else {
        setValue('profileImage', '');
        trigger('profileImage');
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (
      degreeDataList?.length === 0 &&
      userDetails &&
      Object.keys(userDetails)?.length > 0
    ) {
      getProfessionalData();
    }
    return () => {
      setValue('profileImage', '');
      trigger('profileImage');
    };
  }, [time]);
  return (
    <>
      <div
        className="modal custom-modal fade"
        id="profile_modal"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          ref={closeRef}
        >
          <div className="modal-content profile-modal-content">
            <div className="modal-body profile-modal-body">
              <div className="profile-modal-title">
                <h4>Doctor’s Profile</h4>
              </div>
              <div className="profile-modal-img">
                <img
                  src={
                    previewImage
                      ? previewImage
                      : userDetails?.profile_image
                        ? userDetails?.profile_image
                        : defaultProfileTwoImage
                  }
                  className="img-fluid"
                  alt="Doctor"
                />
                <label className="file-upload">
                  <Controller
                    name="profileImage"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e: any) => {
                          let file: any = e?.target?.files?.[0];
                          onChange(file);
                        }}
                        onBlur={onBlur}
                        ref={ref}
                        className={`form-control ${errors.profileImage ? 'is-invalid' : ''}`}
                      />
                    )}
                  />

                  <span>Edit Picture</span>
                </label>
                <ErrorMessage value={errors.profileImage} />
              </div>
              <div className="profile-modal-form">
                <form action="#">
                  <div className="form-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-4">
                        <label>Name</label>
                      </div>
                      <div className="col-md-8">
                        <div className="profile-modal-input">
                          <input
                            type="text"
                            {...register('name')}
                            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                          />
                          <ErrorMessage value={errors.name} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-4">
                        <label>Designation</label>
                      </div>
                      <div className="col-md-8">
                        <div className="profile-modal-input">
                          <Controller
                            name="designation"
                            control={control}
                            render={({ field }) => (
                              <Dropdown
                                {...field}
                                value={field.value}
                                options={degreeDataList}
                                optionLabel="name"
                                onChange={(event: any) => {
                                  field.onChange(event);
                                }}
                                placeholder="Select Designation"
                                className={`w-100 patient-reg-select ${errors.designation?.name ? 'is-invalid' : ''}`}
                              />
                            )}
                          />
                          <ErrorMessage value={errors.designation?.name} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile-modal-btn">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileModel;
