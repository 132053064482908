import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { kioskList } from '../../../../core/json/kiosk-list';
import { routes } from '../../../../utils/routes';
import SearchFromApi from '../../../../components/search';
import PrimeDataTable from '../../../../components/dataTable';
import { end_points } from '../../../../services/end_point/end_points';
import { ApiServiceContext } from '../../../../utils/shared.module';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { setAssociateToData } from '../../../../core/redux/commonSlice';
import { toast } from 'react-toastify';

const KioskAssociate = () => {
  const { getData, putData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const editData = useSelector((state: any) => state?.common?.editData);
  const associateData = useSelector(
    (state: any) => state?.common?.associateToData,
  );
  const path = useSelector((state: any) => state?.common?.pathFrom);
  const [listData, setListData] = useState<any>(kioskList);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [filterType, setFilterType] = useState<string>('All');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(10);
  const [selectedId, setSelectedId] = useState(null);
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [apiError, setApiError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = [
    {
      header: '#ID',
      field: 'reg_id',
      key: 'reg_id',
      sortField: 'reg_id',
      body: (data: any) => (
        <Link to={routes.kioskUserList.path}>{data?.reg_id}</Link>
      ),
    },
    {
      header: 'Kiosk Name',
      field: 'name',
      key: 'name',
      sortField: 'name',
    },
    {
      header: 'Kiosk Code',
      field: 'code',
      key: 'code',
      sortField: 'code',
    },
    {
      header: 'Address',
      field: 'address',
      key: 'address',
      sortField: 'address',
      body: (rowData: any) => truncateAddress(rowData.address),
    },
    {
      header: 'Status',
      field: 'status',
      key: 'status',
      sortField: 'status',
      body: (data: any) => (
        <div className="status-data">
          {data?.status === 'active' && (
            <p>
              <span className="status-active"></span>
              Active
            </p>
          )}
          {data?.status === 'disabled' && (
            <p>
              <span className="status-disabled"></span>
              disabled
            </p>
          )}
          {data?.status === 'inactive' && (
            <p>
              <span className="status-inactive"></span>
              inactive
            </p>
          )}
        </div>
      ),
    },
    // {
    //   header: 'Kiosk',
    //   field: 'code',
    //   key: 'code',
    //   sortField: 'code',
    //   // body: (data: any) => (
    //   //   <>{data?.associates?.length>0?data?.associates:'Unassociated'}</>
    //   // )
    // },
    {
      header: 'Select',
      key: 'select',
      field: 'select',
      body: (data: any) => (
        <div className="custom-radio">
          <label className="custom_radio">
            <input
              type="radio"
              name="kiosk"
              checked={selectedId === data._id}
              onChange={() => handleRadioChange(data._id)}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      ),
    },
  ];

  const truncateAddress = (address: any) => {
    if (address.length > 30) {
      return address.substring(0, 30) + '...';
    }
    return address;
  };

  const getKioskAssociateList = async (
    page: number = 1,
    limit: number = 10,
  ) => {
    try {
      showLoader();
      let url = end_points.kioskListApi.url;
      url += `?view=active`;
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response.data.data);
        setTotalRecords(response.data.total_records);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    const initialSelected = listData.find(
      (item: any) =>
        item.associates &&
        item.associates.length > 0 &&
        item._id === editData?.associates?.[0]?.kiosk_id,
    );
    if (initialSelected) {
      setSelectedId(initialSelected._id);
    }
  }, [editData, listData]);

  const handleRadioChange = (id: any) => {
    setSelectedId(id);
  };
  const onSubmit = async (data: any) => {
    if (selectedId === null) {
      toast.info('Please select a kiosk');
      return;
    }
    try {
      showLoader();
      let url =
        path === 'front-desk'
          ? end_points.kioskAssociateFrontdeskUpdateApi.url
          : end_points.kioskAssociateParamedicUpdateApi.url;
      url += `/${selectedId}`;
      const payload = {
        type: path,
        user_id: associateData?._id,
      };

      const response = await putData(url, payload);
      if (response?.status === 200) {
        dispatch(setAssociateToData(undefined));
        if (path === 'front-desk') {
          navigate(routes.frontDeskList.path);
        } else {
          navigate(routes.paramedicList.path);
        }
      }
    } catch (error: any) {
      setApiError(true);
      setApiErrorMessage(error?.response?.data?.errors[0]?.msg);
    } finally {
      hideLoader();
    }
  };

  const handleSearch = (search: any = '') => {
    setSearchQuery(search);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (searchQuery || filterType || currentPage) {
      getKioskAssociateList(currentPage, rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, filterType, currentPage]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Page Header */}
          <div className="page-header">
            <h4 className="page-title">KIOSK LIST</h4>
            <div className="page-list">
              <ul className="nav">
                <li>
                  <SearchFromApi callback={handleSearch} />
                </li>
              </ul>
            </div>
          </div>
          {/* Page /Header */}
          {/* Kiosk Table */}

          <PrimeDataTable
            column={columns}
            data={listData}
            rows={rows}
            setRows={setRows}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalRecords={totalRecords}
            rowClassName={'highlight'}
            onRowClickSetState={false}
            footer={() => {
              if (listData?.length === 0) {
                return null;
              }
              return (
                <div className="table-btn">
                  <Link
                    to="#"
                    type="submit"
                    onClick={onSubmit}
                    className="btn btn-secondary"
                  >
                    Save
                  </Link>
                </div>
              );
            }}
          />
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default KioskAssociate;
