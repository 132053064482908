import React from 'react';
import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const DocumentPopup = ({ url = '' }) => {
  const attach: any = url;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {attach && (
        <td className="iframe-btn">
          <Button className="btn btn-primary" onClick={handleShow}>
            View
          </Button>
          <Modal show={show} onHide={handleClose}>
            <Modal.Body>
              <Modal.Header closeButton>
              </Modal.Header>
              <iframe
                src={attach}
                title="Document"
                width="100%"
                height="400px"
              />
                <button className="btn btn-primary" onClick={handleClose}>Close</button>
            </Modal.Body>
          </Modal>
        </td>
      )}
    </>
  );
};

export default DocumentPopup;
