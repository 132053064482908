import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const render = (
  AppComponent: React.ComponentType,
  ToastContainerComponent: React.ComponentType,
) => {
  root.render(
    <>
      <AppComponent />
      <ToastContainerComponent />
    </>,
  );
};

// Initial render
render(App, ToastContainer);

// Hot Module Replacement
if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp, ToastContainer);
  });
}
