import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
interface props {
  showed: boolean;
  setShowed: (value: boolean) => void;
  onClick: () => void;
  title: string;
}
export const DropDownPopup: React.FC<props> = ({
  showed = false,
  setShowed,
  onClick = () => {},
  title = '',
}) => {
  const behalfRef = useRef<any>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (behalfRef?.current && !behalfRef?.current?.contains(event?.target)) {
        setShowed(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <div
        className={'custom-checkbox custom-checkbox-left custom-checkbox-line'}
        ref={behalfRef}
      >
        <label
          data-bs-toggle={`${showed === true ? 'dropdown' : ''}`}
          className={`check ${showed === true ? ' dropdown-toggle show' : ''}`}
        ></label>
        <div className="front-desk-dropdown-menu-right">
        <div
          className={`dropdown-menu front-desk-dropdown-right front-desk-dropdown dropdown-menu-end ${showed ? 'show' : ''}`}
        >
          <p>{title}</p>
          <div className="front-desk-dropdown-btn">
            <Link to="#" onClick={onClick} className="btn btn-secondary">
              Yes
            </Link>
            <Link
              to="#"
              className="btn btn-light"
              onClick={() => {
                setShowed(false);
              }}
            >
              No
            </Link>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};
