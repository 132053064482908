import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../utils/routes';
import { logoBig } from '../../../utils/imagepath';

const Welcome = () => {
  const pageWrapperRef = useRef<HTMLDivElement>(null);
  const path = location?.pathname;
  const findPath = path.split('/')[1];
  const updateHeight = () => {
    if (pageWrapperRef.current) {
      const height = window.innerHeight;
      pageWrapperRef.current.style.minHeight = `${height}px`;
    }
  };

  useEffect(() => {
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  return (
    <>
      {/* Welcome */}
      <div className="welcome-content">
        <div className="welcome-info">
          <div className="welcome-logo">
            <img src={logoBig} alt="Logo" />
          </div>
        </div>
      </div>
      {/* /Welcome */}
      {/* Page Wrapper */}
      <div className="page-wrapper welcome-wrapper" ref={pageWrapperRef}>
        <div className="content">
          <div className="welcome-text">
            <h6>YOUR HEALTH</h6>
            <h1>IS OUR PRIORITY</h1>
            <div className="welcome-btn">
              {findPath === 'admin' ? (
                <Link to={routes.login.path} className="btn btn-secondary">
                  Login
                </Link>
              ) : findPath === 'doctor' ? (
                <Link
                  to={routes.doctorLogin.path}
                  className="btn btn-secondary"
                >
                  Login
                </Link>
              ) : (
                <Link
                  to={routes.frontDesklogin.path}
                  className="btn btn-secondary"
                >
                  Login
                </Link>
              )}
              {findPath === 'admin' && (
                <Link to={routes.register.path} className="btn btn-primary">
                  Register
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default Welcome;
