import React, { useContext, useState } from 'react';
import { login03, logoBig } from '../../../utils/imagepath';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../utils/routes';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { ApiServiceContext } from '../../../utils/shared.module';
import { SpinnerContext } from '../../../components/spinner/spinner';
import { end_points } from '../../../services/end_point/end_points';
import { setPhoneNumber, setUserId } from '../../../core/redux/commonSlice';
import { maskMobileNumber } from '../../../utils/constant';
import { userRole } from '../../../assets/global';

const ForgotPasswordOtp = () => {
  const { postData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const [otp, setOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [validError, setValidError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [apiError, setApiError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const path = location?.pathname;
  const findType = path.split('/')[1];

  const mobileNumber = useSelector((state: any) => state.common.phoneNumber);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (otp === '') {
      setApiError(false);
      setValidError(false);
      setErrorMessage(true);
      return false;
    } else if (otp.length < 6) {
      setApiError(false);
      setErrorMessage(false);
      setValidError(true);
    } else {
      try {
        setValidError(false);
        setErrorMessage(false);
        showLoader();
        let url;
        if (findType === userRole.FrontDesk || findType === userRole.Doctor) {
          url = end_points.userForgotPasswordOTPVerify.url;
        } else {
          url = end_points.forgotPasswordOTPVerify.url;
        }
        let payload;
        if (findType === userRole.FrontDesk || findType === userRole.Doctor) {
          payload = {
            number: mobileNumber,
            code: otp,
            type: `${findType === userRole.Doctor ? userRole.Doctor : userRole.FrontDesk}`,
          };
        } else {
          payload = {
            number: mobileNumber,
            code: otp,
          };
        }
        const response = await postData(url, payload);
        if (response?.status === 200) {
          setApiError(false);
          dispatch(setPhoneNumber(undefined));
          if (findType === userRole.FrontDesk) {
            navigate(routes.frontDeskconfirmPassword.path);
          } else if (findType === userRole.Doctor) {
            navigate(routes.doctorconfirmPassword.path);
          } else {
            navigate(routes.login.path);
          }
          dispatch(setUserId(response.data.data._id));
        }
      } catch (error: any) {
        setApiError(true);
        setApiErrorMessage(error?.response?.data?.message);
      } finally {
        hideLoader();
      }
    }
  };

  return (
    <>
      {/* Logo */}
      <div className="register-logo">
        <div className="white-logo">
          <img src={logoBig} alt="Logo" />
        </div>
      </div>
      {/* /Logo */}
      {/* OTP */}
      <div className="w-100">
        <div className="content container-fluid pb-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-box register-box">
                <div className="login-right">
                  <div className="login-right-wrap">
                    <div className="password-header">
                      <h2>Reset with OTP</h2>
                      <img src={login03} alt="Icon" />
                      <h5>OTP Sent</h5>
                      <p>
                        Enter OTP sent on your registered mobile number ending
                        in {maskMobileNumber(mobileNumber)}
                      </p>
                    </div>
                    {/* Form */}
                    <form
                      onSubmit={(e) => handleSubmit(e)}
                      className="digit-group login-form-control custom-error"
                      data-group-name="digits"
                      data-autosubmit="false"
                      autoComplete="off"
                    >
                      <div className="otp-box">
                        <OtpInput
                          value={otp}
                          onChange={(value) => {
                            const numericValue = value.replace(/\D/g, '');
                            setOtp(numericValue);
                          }}
                          numInputs={6}
                          inputType={'text'}
                          inputStyle="twofa_otpinput"
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>
                      <div className="password-btn">
                        <button type="submit" className="btn btn-black">
                          LOGIN
                        </button>
                      </div>
                      {errorMessage && (
                        <div
                          className="alert alert-danger text-center"
                          role="alert"
                        >
                          {'OTP is Required'}
                        </div>
                      )}
                      {validError && (
                        <div
                          className="alert alert-danger text-center"
                          role="alert"
                        >
                          {'Enter valid OTP'}
                        </div>
                      )}
                    </form>
                    {/* /Form */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /OTP */}
    </>
  );
};

export default ForgotPasswordOtp;
