import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PrimeDataTable from '../../../../components/dataTable';
import { end_points } from '../../../../services/end_point/end_points';
import { ApiServiceContext } from '../../../../utils/shared.module';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import SearchFromApi from '../../../../components/search';
import { setEditData } from '../../../../core/redux/commonSlice';
import { scheduleType } from '../../../../assets/global';
import {
  capitalizeFirstLetter,
  patientScheduleSlotFormat,
} from '../../../../utils/constant';
interface props {
  type: string;
}
const TodayConsult: React.FC<props> = ({ type }) => {
  const { getData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);

  const [listData, setListData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [filterType, setFilterType] = useState<string>('All');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(10);

  const columns = [
    {
      header: 'ID',
      field: 'patient.reg_id',
      key: 'patient.reg_id',
      body: (data: any) => (
        <span className="data-id">{data?.patient?.reg_id}</span>
      ),
    },
    {
      header: 'Name',
      field: 'patient.fullname',
      key: 'patient.fullname',
    },
    {
      header: 'Phone No',
      field: 'patient.mobile',
      key: 'patient.mobile',
    },
    {
      header: 'Aadhaar No',
      field: 'patient.aadhaar_no',
      key: 'patient.aadhaar_no',
      body: (data: any) =>
        data?.patient?.aadhaar_no ? data?.patient?.aadhaar_no : '-',
    },
    {
      header: 'Consult Type',
      field: 'patient?.appointments?.[0]?.appointment_type',
      key: 'patient?.appointments?.[0]?.appointment_type',
      body: (data: any) => (
        <>
          {capitalizeFirstLetter(
            data?.patient?.appointments?.[0]?.appointment_type,
          )}
        </>
      ),
    },
    {
      header: 'Appointment',
      field: 'appointment',
      key: 'appointment',
      body: (data: any) => {
        const appoitment = data?.patient?.appointments?.[0];
        const slotTime = appoitment?.time_slot;

        if (!slotTime) {
          return (
            <Link
              to="#"
              className="btn btn-primary"
              // data-bs-toggle="modal"
              // data-bs-target="#schedule_modal"
              onClick={() => {
                // dispatch(setEditData(data));
                // setScheduleModel(scheduleType.schedule);
              }}
            >
              Schedule
            </Link>
          );
        }
        return (
          <Link
            to={'#'}
            // data-bs-toggle="modal"
            // data-bs-target="#schedule_modal"
            onClick={() => {
              // dispatch(setEditData(data));
              // setScheduleModel(scheduleType.reschedule);
            }}
          >
            {slotTime
              ? patientScheduleSlotFormat(
                  appoitment?.appointment_date,
                  slotTime,
                )
              : '-'}
          </Link>
        );
      },
    },
    {
      header: 'Doctor',
      field: 'data?.patient?.appointments?.[0]?.doctorDetails?.fullname',
      key: 'data?.patient?.appointments?.[0]?.doctorDetails?.fullname',
      body: (data: any) => {
        const doctorName =
          data?.patient?.appointments?.[0]?.doctorDetails?.fullname;
        return doctorName ? doctorName : '-';
      },
    },
  ];
  const getPatientList = async (page: number = 1, limit: number = 10) => {
    try {
      showLoader();
      let url = end_points.getConsultListApi.url;
      if (url) {
        url += `?view=${type === 'Past' ? 'past' : 'today'}`;
      }
      // if (page && limit) {
      //   url += `&page=${page}&limit=${limit}`;
      // }
      if (searchQuery) {
        url += `&searchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.data);
        setTotalRecords(response?.data?.totalRecord);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const handleSearch = (search: any = '') => {
    setSearchQuery(search);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (searchQuery || filterType || currentPage) {
      getPatientList(currentPage, rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, filterType, currentPage, type]);
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Page Header */}
          <div className="page-header">
            <h4 className="page-title">
              {type === 'Past' ? 'Past' : 'Today'} Consult List
            </h4>
            <div className="page-list">
              <ul className="nav">
                <li>
                  <SearchFromApi callback={handleSearch} />
                </li>
              </ul>
            </div>
          </div>
          {/* /Page Header */}
          {/* Patient List Table */}

          <PrimeDataTable
            column={columns}
            data={listData}
            rows={rows}
            sortable={false}
            setRows={setRows}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalRecords={totalRecords}
            onRowClickSetState={false}
            rowClassName={'highlight'}
            onClickNavigate={() => {
              // navigate(routes.doctorEdit.path);
            }}
          />

          {/* /Patient List Table */}
        </div>
      </div>
    </>
  );
};

export default TodayConsult;
