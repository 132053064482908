import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SearchFromApi from '../search';
import { routes } from '../../utils/routes';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { userRole } from '../../assets/global';
interface ListHeaderprops {
  viewTypes: string[];
  filterType: string;
  setFilterType: any;
  setCurrentPage: any;
  title: string;
  type?: string;
  createRoute: any;
  handleSearch: (search: string) => void;
}
const ListHeader: React.FC<ListHeaderprops> = ({
  viewTypes,
  filterType,
  setFilterType,
  setCurrentPage,
  handleSearch,
  title,
  createRoute,
  type = '',
}) => {
  const editData = useSelector((state: any) => state?.common?.editData);
  const [show, setShow] = useState<boolean>(false);
  return (
    <div className="page-header">
      <h4 className="page-title">{title}</h4>
      <div className="page-list">
        <ul className="nav">
          <li>
            <div className="page-dropdown">
              <Link
                to="#"
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
                onClick={() => {
                  setShow((prev) => !prev);
                }}
              >
                Showing : {filterType}
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-end ${show ? 'show' : ''}`}
              >
                {viewTypes?.map((viewType: string, index: number) => {
                  return (
                    <Link
                      to="#"
                      key={index}
                      className={`dropdown-item ${filterType === viewType ? 'active' : ''}`}
                      onClick={() => {
                        setFilterType(viewType);
                        setCurrentPage(1);
                      }}
                    >
                      {viewType}
                    </Link>
                  );
                })}
              </div>
            </div>
          </li>
          <li>
            <SearchFromApi callback={handleSearch} />
          </li>
          {type !== 'kioskUserList' && (
            <li>
              <div className="page-header-btn">
                <Link to={createRoute} className="btn btn-secondary">
                  Create
                </Link>
              </div>
            </li>
          )}
          {/* {type === 'kioskUserList' && (
            <li>
              <div className="page-header-btn">
                <Link to="#" className="btn btn-secondary">
                  Status
                </Link>
              </div>
            </li>
          )} */}
        </ul>
      </div>
    </div>
  );
};

export default ListHeader;
