import React from 'react';
import AppRouter from './app.router';
import './assets/icons/feather/css/iconfont.css';
import './assets/icons/fontawesome/css/fontawesome.min.css';
import './assets/icons/fontawesome/css/all.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import ApiServiceProvider from './services/api/api.service';
import AxiosProvider from './services/interceptor/interceptor';
import SpinnerProvider from './components/spinner/spinner';
import { Provider } from 'react-redux';
import store from './core/redux/store';
import SocketClient from './services/socket/socketioClient';
import IncomingCallNotification from './pages/feature/allModules/doctor/videoCall/incomingCallNotification';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <>
      <Provider store={store}>
        <SpinnerProvider>
          <AxiosProvider>
            <ApiServiceProvider>
              <SocketClient />
              <BrowserRouter>
                <IncomingCallNotification />
                <AppRouter />
              </BrowserRouter>
            </ApiServiceProvider>
          </AxiosProvider>
        </SpinnerProvider>
      </Provider>
    </>
  );
}

export default App;
