import React, { useState } from 'react';
import AppoitmentDetailsHeader from './components/AppoitmentDetailsHeader';
import PatientDetailsSidebar from './components/patientDetailsSidebar';
import MainMenuAppointment from './components/mainMenuAppointment';
import MedicalHistory from './components/medicalHistory';
import { Link } from 'react-router-dom';

const AppointmentDetails = () => {
  const [showMedical, setShowMedical] = useState(false);
  const [showSymptoms, setShowSymptoms] = useState(false);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/*Appointment Details */}
          <div className="row">
            <div className="col-xl-3 col-lg-12 theiaStickySidebar d-flex">
              <div className=" card w-100 stickybar">
                <PatientDetailsSidebar
                  setShowMedical={setShowMedical}
                  showMedical={showMedical}
                />
              </div>
            </div>
            {/* /Appointment Details */}
            <div className="col-xl-9 col-lg-12">
              <div className="row">
                <div className="col-md-12">
                  <AppoitmentDetailsHeader />
                </div>
              </div>
              {showMedical && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="doctor-header">
                      <h4>Medical History</h4>
                      <ul className="nav">
                        <li>
                          <Link
                            to={'#'}
                            className="btn btn-primary"
                            onClick={() => setShowMedical(false)}
                          >
                            Back
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                {showMedical ? <MedicalHistory /> : <MainMenuAppointment />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default AppointmentDetails;
