import React from 'react';
import { routes } from './routes';
import KioskCreate from '../pages/feature/admin/kiosk/kiosk-create';
import KioskEdit from '../pages/feature/admin/kiosk/kiosk-edit';
import KioskUserList from '../pages/feature/admin/kiosk/kiosk-user-list';
import DoctorEdit from '../pages/feature/admin/doctor/doctor-edit';
import FrontDeskParamedicAssociate from '../components/frontdeskParamedicAssociate';
import DoctorCreate from '../pages/feature/admin/doctor/doctor-create';
import FrontDeskCreate from '../pages/feature/admin/front-desk/front-desk-create';
import FrontDeskEdit from '../pages/feature/admin/front-desk/front-desk-edit';
import ParamedicCreate from '../pages/feature/admin/paramedic/paramedic-create';
import ParamedicEdit from '../pages/feature/admin/paramedic/paramedic-edit';
import FrontDeskView from '../pages/feature/admin/front-desk/front-desk-view';
import KioskAssociate from '../pages/feature/admin/kiosk/kiosk-associate';
import KioskList from '../pages/feature/admin/kiosk/kiosk-list';
import Dashboard from '../pages/feature/admin/dashboard';
import ParamedicView from '../pages/feature/admin/paramedic/paramedic-view';
import DoctorsAssociate from '../pages/feature/admin/doctor/doctors-associate';
import DoctorsList from '../pages/feature/admin/doctor/doctors-list';
import FrontDeskList from '../pages/feature/admin/front-desk/front-desk-list';
import ParamedicList from '../pages/feature/admin/paramedic/paramedic-list';
import FrontdeskDoctorsList from '../pages/feature/allModules/front-desk/doctors-list';
import PatientList from '../pages/feature/allModules/front-desk/patient-list';
import PatientRegistration from '../pages/feature/allModules/front-desk/patient-registration';
import FrontDeskCalendar from '../pages/feature/allModules/front-desk/front-desk-calendar';
import SuccessComponent from '../components/successToast';
import CriticalPatientList from '../pages/feature/allModules/patient/critical-patient-list';
import DoctorDashboard from '../pages/feature/allModules/doctor/doctor-dashboard';
import PastConsults from '../pages/feature/allModules/doctor/past-consults';
import AppointmentDetails from '../pages/feature/allModules/doctor/appointment-details';
import TodayConsult from '../pages/feature/allModules/front-desk/todayConsult';
import DoctorScheduledSlots from '../pages/feature/admin/doctor/doctor-scheduled-slots';
import { consultType } from '../assets/global';
import JoinConsultation from '../pages/feature/allModules/doctor/joinConsultation';
import NotificationList from '../pages/common/notification/notification';
import VideoCall from '../pages/feature/allModules/doctor/videoCall/videoCall';
import Receiver from '../pages/feature/allModules/doctor/videoCall/Receiver';

export const authRoutes = [
  {
    id: 1,
    path: routes.dashboard.path,
    element: <Dashboard />,
  },
  {
    id: 2,
    path: routes.kioskAssociate.path,
    element: <KioskAssociate />,
  },
  {
    id: 3,
    path: routes.kioskCreate.path,
    element: <KioskCreate />,
  },
  {
    id: 4,
    path: routes.kioskEdit.path,
    element: <KioskEdit />,
  },
  {
    id: 5,
    path: routes.kioskList.path,
    element: <KioskList />,
  },
  {
    id: 6,
    path: routes.kioskUserList.path,
    element: <KioskUserList />,
  },
  {
    id: 7,
    path: routes.doctorsAssociate.path,
    element: <DoctorsAssociate />,
  },
  {
    id: 8,
    path: routes.doctorCreate.path,
    element: <DoctorCreate />,
  },
  {
    id: 10,
    path: routes.doctorsList.path,
    element: <DoctorsList />,
  },
  {
    id: 11,
    path: routes.doctorEdit.path,
    element: <DoctorEdit />,
  },

  {
    id: 12,
    path: routes.frontDeskAssociate.path,
    element: <FrontDeskParamedicAssociate />,
  },
  {
    id: 13,
    path: routes.frontDeskCreate.path,
    element: <FrontDeskCreate />,
  },
  {
    id: 14,
    path: routes.frontDeskList.path,
    element: <FrontDeskList />,
  },
  {
    id: 15,
    path: routes.frontDeskEdit.path,
    element: <FrontDeskEdit />,
  },
  {
    id: 16,
    path: routes.frontDeskView.path,
    element: <FrontDeskView />,
  },

  {
    id: 17,
    path: routes.paramedicCreate.path,
    element: <ParamedicCreate />,
  },
  {
    id: 18,
    path: routes.paramedicList.path,
    element: <ParamedicList />,
  },
  {
    id: 19,
    path: routes.paramedicEdit.path,
    element: <ParamedicEdit />,
  },
  {
    id: 20,
    path: routes.paramedicView.path,
    element: <ParamedicView />,
  },

  {
    id: 21,
    path: routes.frontDeskDoctorsList.path,
    element: <FrontdeskDoctorsList />,
  },
  {
    id: 22,
    path: routes.frontDeskPatientList.path,
    element: <PatientList />,
  },
  {
    id: 23,
    path: routes.frontDeskPatientRegistration.path,
    element: <PatientRegistration />,
  },
  {
    id: 24,
    path: routes.frontDeskCalendar.path,
    element: <FrontDeskCalendar />,
  },
  {
    id: 25,
    path: routes.criticalPatientList.path,
    element: <DoctorDashboard typeProp={consultType.criticalPatient} />,
  },
  {
    id: 26,
    path: routes.doctorDashboard.path,
    element: <DoctorDashboard typeProp={consultType.upcoming} />,
  },
  {
    id: 27,
    path: routes.pastConsults.path,
    element: <DoctorDashboard typeProp={consultType.past} />,
  },
  {
    id: 28,
    path: routes.scheduledSlots.path,
    element: <DoctorDashboard typeProp={consultType.scheduled} />,
  },
  {
    id: 29,
    path: routes.appointmentDetails.path,
    element: <AppointmentDetails />,
  },
  {
    id: 30,
    path: routes.frontDeskPastConsult.path,
    // element: <PastConsult />,
    element: <TodayConsult type={'Past'} />,
  },
  {
    id: 31,
    path: routes.frontDeskTodayConsult.path,
    element: <TodayConsult type={'Today'} />,
  },
  {
    id: 32,
    path: routes.doctorScheduledSlots.path,
    element: <DoctorScheduledSlots />,
  },
  // -----------common routes --------
  { id: 33, path: routes.successComponent.path, element: <SuccessComponent /> },

  // doctor Routes
  { id: 34, path: routes.joinConsult.path, element: <JoinConsultation /> },
  {
    id: 35,
    path: routes.doctorNotification.path,
    element: <NotificationList />,
  },
  {
    id: 36,
    path: routes.adminNotification.path,
    element: <NotificationList />,
  },
  {
    id: 37,
    path: routes.frontDeskNotification.path,
    element: <NotificationList />,
  },
  {
    id: 100,
    path: routes.videoCall.path,
    element: <VideoCall />,
  },
  {
    id: 101,
    path: routes.videoCallTwo.path,
    element: <Receiver />,
  },
];
