import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../../utils/routes';
import { ApiServiceContext } from '../../../../utils/shared.module';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { end_points } from '../../../../services/end_point/end_points';
import SearchFromApi from '../../../../components/search';
import PrimeDataTable from '../../../../components/dataTable';
import { doctorsAssociate } from '../../../../core/json/doctors-associate';

const DoctorsAssociate = () => {
  const { postData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);

  const [listData, setListData] = useState<any>(doctorsAssociate);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const columns = [
    {
      header: '#ID',
      field: 'id',
      key: 'id',
      sortField: 'id',
      body: (data: any) => <Link to={routes.doctorsList.path}>{data?.id}</Link>,
    },
    {
      header: 'Name',
      field: 'name',
      key: 'name',
      sortField: 'name',
    },
    {
      header: 'Phone No',
      field: 'phoneNumber',
      key: 'phoneNumber',
      sortField: 'phoneNumber',
    },
    {
      header: 'Select',
      key: 'select',
      field: 'select',
      body: () => (
        <div className="custom-checkbox custom-checkbox-left">
          <label className="check">
            <input type="checkbox" />
            <span className="box" />
          </label>
        </div>
      ),
    },
  ];
  const apiCall = async (page: number = 1, rows: number = 10) => {
    try {
      showLoader();
      const payload = {
        searchQuery,
        page,
        rows,
      };
      const response = await postData(end_points.api_endpoint.url, payload);
      if (response.status === 200) {
        // setListData()
        // setTotalRecords()
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const handleSearch = (search: any = '') => {
    setSearchQuery(search);
  };
  useEffect(() => {
    if (searchQuery || searchQuery !== undefined) {
      apiCall(1, 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Page Header */}
          <div className="page-header">
            <h4 className="page-title">Doctors</h4>
            <div className="page-list">
              <ul className="nav">
                <li>
                  <SearchFromApi callback={handleSearch} />
                </li>
              </ul>
            </div>
          </div>
          {/* /Page Header */}
          {/* Doctors Table */}
          <PrimeDataTable
            column={columns}
            data={listData}
            fetchData={apiCall}
            totalRecords={totalRecords}
          />
        </div>
        <div className="table-btn">
          <Link to="#" className="btn btn-secondary">
            Save
          </Link>
          {/* /Doctors Table */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default DoctorsAssociate;
