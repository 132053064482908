import { combineReducers, configureStore } from '@reduxjs/toolkit';
import loginSlice from './loginSlice';
import { getPreloadedState, saveToLocalStorage } from './localStorage';
import commonSlice from './commonSlice';
import sidebarSlice from './sidebarSlice';

const combinedReducer = combineReducers({
  login: loginSlice,
  common: commonSlice,
  sidebar: sidebarSlice,
});

const rootReducer = (state, action) => {
  /**
   * to reset whole app state to initial state
   */
  if (action.type === 'login/logout') {
    state = undefined;
  }

  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: getPreloadedState(),
  // devTools: true
});

function onStateChange() {
  saveToLocalStorage(store.getState());
}

store.subscribe(onStateChange);

export default store;
