import React, { useContext, useEffect, useState } from 'react';
import { edit01, edit02, edit04, edit05 } from '../../../../utils/imagepath';
import { routes } from '../../../../utils/routes';
import { Link, useNavigate } from 'react-router-dom';
import { end_points } from '../../../../services/end_point/end_points';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from '../../../../utils/shared.module';
import { Controller, useForm } from 'react-hook-form';
import ErrorMessage from '../../../../components/errorMessage';
import {
  email,
  emailMaxLength,
  nameMaxLength,
  onlyAlphabet,
  onlyNumber,
  registerNoMaxLength,
  validMessage,
  validatePhoneNumber,
} from '../../../../utils/patterns/regex.pattern';
import PhoneInputWithRef from '../../../../utils/phoneInputWithRef';
import { Dropdown } from 'primereact/dropdown';
import {
  capitalizeFirstLetter,
  validatePhoneNumberwithPlus,
} from '../../../../utils/constant';
import { useSelector } from 'react-redux';
import { editType, userRole } from '../../../../assets/global';
import { Tooltip } from 'antd';
const doctorSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Doctor name is required')
    .matches(onlyAlphabet, validMessage.onlyAlphabet)
    .max(nameMaxLength, `Name cannot exceed ${nameMaxLength} characters`),
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .matches(email, validMessage.email)
    .max(emailMaxLength, `Email cannot exceed ${emailMaxLength} characters`),
  phone: yup
    .string()
    .trim()
    .required('Mobile number is required')
    .test('is-valid-phone', 'Invalid phone number', (value) =>
      validatePhoneNumber(value),
    ),
  registration_number: yup
    .string()
    .trim()
    .required('Doctor registration number is required')
    .max(
      registerNoMaxLength,
      `Doctor registration number cannot exceed ${registerNoMaxLength} characters`,
    ),
  degree: yup
    .object({
      name: yup.string().required('Degree is required'),
      code: yup.string().optional(),
      id: yup.string().optional(),
    })
    .required('Degree is required'),
  status: yup.boolean().required('Status is required'),
});
const DoctorEdit = () => {
  const [editDoctor, setEditDoctor] = useState<boolean>(false);
  const [degreeDataList, setDegreeDataList] = useState<any>([]);
  const editData: any = useSelector((state: any) => state?.common?.editData);

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { errors, isSubmitted, isValid },
  } = useForm({
    resolver: yupResolver(doctorSchema),
    defaultValues: {
      name: editData?.fullname || '',
      phone: editData?.mobile || '',
      email: editData?.email || '',
      registration_number: editData?.doctor_reg_no || '',
      degree: {
        name: '',
        code: '',
        id: editData?.professional_degree_id || '',
      },
      status: editData?.status === 'active' ? true : false,
    },
  });
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { putData, getData } = useContext(ApiServiceContext);
  const watchStatus = watch('status');

  const navigate = useNavigate();

  const getProfessionalData = async () => {
    try {
      showLoader();
      const response = await getData(end_points.degreeListApi.url);
      if (response.status === 200) {
        const professionalData = response.data?.data?.map((degree: any) => {
          return { id: degree?.id, name: degree?.name, code: degree?.id };
        });
        setDegreeDataList(professionalData);
        if (editData?.professional_degree_id) {
          const selectedDegree = professionalData?.find(
            (item: any) => item.id === editData?.professional_degree_id,
          );
          if (selectedDegree) {
            setValue('degree', {
              id: selectedDegree.id,
              code: selectedDegree.code,
              name: selectedDegree.name,
            });
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const onSubmit = async (data: any) => {
    try {
      showLoader();
      let url = end_points.adminPagesUpdate.url;
      url += `/doctor`;
      if (editData?._id) {
        url += `/${editData?._id}`;
      }
      const { phone, degree, status, registration_number, name, ...rest } =
        data;
      const payload = {
        ...rest,
        fullname: name,
        doctor_reg_no: registration_number,
        mobile: validatePhoneNumberwithPlus(phone),
        professional_degree_id: degree?.id,
        status: status ? 'active' : 'inactive',
      };
      const response = await putData(url, payload);
      if (response.status === 200) {
        navigate(routes.doctorsList.path);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const formInvalid = isSubmitted && !isValid;
  const degreeName = degreeDataList?.find(
    (item: any) => item.id === editData?.professional_degree_id,
  );
  useEffect(() => {
    getProfessionalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Edit Doctor */}
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="card edit-card">
                <div className="card-body pb-0">
                  {editDoctor ? (
                    <div className="edit-toggle">
                      <div className="status-toggle">
                        <p>
                          {watchStatus ? 'In Active' : 'Active'}
                          &nbsp; Doctor
                        </p>
                        <Controller
                          name="status"
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                          }) => (
                            <>
                              <input
                                type="checkbox"
                                id="status"
                                className="check-input"
                                onChange={onChange}
                                onBlur={onBlur}
                                checked={value}
                                name={name}
                                ref={ref}
                              />
                              <label htmlFor="status" className="checktoggle">
                                checkbox
                              </label>
                            </>
                          )}
                        />
                        <ErrorMessage
                          className="d-inline-flex mt-2"
                          value={errors.status}
                        />
                      </div>
                      <div className="edit-toggle-btn">
                        <Link
                          to={'#'}
                          onClick={handleSubmit(onSubmit)}
                          className="btn btn-secondary"
                        >
                          Save
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className="edit-icon-info">
                      <div className="edit-icon-slot">
                        <Link
                          to={routes.doctorScheduledSlots.path}
                          onClick={() => {
                            setEditDoctor(true);
                          }}
                          className="btn btn-secondary"
                        >
                          Create/View Slots
                        </Link>
                      </div>
                      <div className="edit-icon">
                        <Tooltip title="Edit" color="grey">
                          <Link
                            to={'#'}
                            onClick={() => {
                              setEditDoctor(true);
                            }}
                          >
                            <i className="fa-regular fa-pen-to-square" />
                          </Link>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                  <div className="edit-inner-body">
                    {editDoctor ? (
                      <div className="row">
                        <div className="col-lg-5 d-flex">
                          <div className="edit-inner-box w-100 custom-input-cls">
                            <div className="edit-header custom-input-cls">
                              <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    {...field}
                                    type="text"
                                    className={`form-control ${
                                      errors.name ? 'is-invalid' : ''
                                    }`}
                                    placeholder="Doctor name*"
                                    maxLength={nameMaxLength}
                                    onBlur={() => trigger('name')}
                                    onChange={(event: any) => {
                                      field.onChange(event);
                                      trigger('name');
                                    }}
                                    onKeyDown={(event) => {
                                      const regex = onlyAlphabet;
                                      if (
                                        !regex.test(event.key) &&
                                        event.key !== 'Backspace'
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                )}
                              />
                              <ErrorMessage
                                className="d-inline-flex mt-2"
                                value={errors.name}
                              />
                              <ul className="nav mt-2">
                                <li>#{editData?.reg_id}</li>
                                <li>
                                  <p>
                                    <span
                                      className={
                                        watchStatus
                                          ? 'status-active'
                                          : 'status-inactive'
                                        // : 'status-disabled'
                                      }
                                    />
                                    {watchStatus ? 'Active' : 'In Active'}
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7 d-flex">
                          <div className="edit-details w-100">
                            <div className="edit-list edit-list-info">
                              <ul>
                                <li>
                                  <img src={edit02} alt="Icon" />
                                  <div className="w-100">
                                    <Controller
                                      name="email"
                                      control={control}
                                      render={({ field }) => (
                                        <input
                                          type="text"
                                          className={`form-control ${
                                            errors.email ? 'is-invalid' : ''
                                          }`}
                                          placeholder="Doctor email*"
                                          {...field}
                                          maxLength={emailMaxLength}
                                          onBlur={() => trigger('email')}
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('email');
                                          }}
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      className="d-inline-flex mt-2"
                                      value={errors.email}
                                    />
                                  </div>
                                </li>
                                <li>
                                  <img src={edit01} alt="Icon" />
                                  <div
                                    className={`form-wrap custom-form-wrap ${formInvalid ? 'is-invalid' : ''}`}
                                  >
                                    <Controller
                                      name="phone"
                                      control={control}
                                      render={({ field, fieldState }) => (
                                        <div>
                                          <PhoneInputWithRef
                                            {...field}
                                            placeholder="Enter phone number"
                                            country={'in'}
                                            value={field.value}
                                            onBlur={() => trigger('phone')}
                                            onChange={(event: any) => {
                                              field.onChange(event);
                                              trigger('phone');
                                            }}
                                            inputProps={{
                                              name: 'phone',
                                              required: true,
                                              // autoFocus: true,
                                              className: `form-control ${fieldState.invalid ? 'is-invalid' : ''}`,
                                            }}
                                          />
                                        </div>
                                      )}
                                    />
                                    <ErrorMessage
                                      className="d-inline-flex mt-2"
                                      value={errors.phone}
                                    />
                                  </div>
                                </li>
                                <li>
                                  <img src={edit04} alt="Icon" />
                                  <div className="w-100">
                                    <Controller
                                      name="registration_number"
                                      control={control}
                                      render={({ field }) => (
                                        <input
                                          type="text"
                                          className={`form-control ${
                                            errors.registration_number
                                              ? 'is-invalid'
                                              : ''
                                          }`}
                                          placeholder="Registration number*"
                                          {...field}
                                          maxLength={registerNoMaxLength}
                                          onBlur={() =>
                                            trigger('registration_number')
                                          }
                                          onChange={(event: any) => {
                                            field.onChange(event);
                                            trigger('registration_number');
                                          }}
                                          onKeyDown={(event) => {
                                            const regex = onlyNumber;
                                            if (
                                              !regex.test(event.key) &&
                                              event.key !== 'Backspace'
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      className="d-inline-flex mt-2"
                                      value={errors.registration_number}
                                    />
                                  </div>
                                </li>
                                <li>
                                  <img src={edit05} alt="Icon" />
                                  <div className="w-100">
                                    <div className="degree-select">
                                      <Controller
                                        name="degree"
                                        control={control}
                                        rules={{
                                          required: 'Degree is required',
                                        }}
                                        render={({ field }) => (
                                          <Dropdown
                                            className={`w-100 custom-invalid-cls ${
                                              errors.degree?.name
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            {...field}
                                            options={degreeDataList}
                                            optionLabel="name"
                                            filter
                                            placeholder="Select a degree"
                                          />
                                        )}
                                      />
                                    </div>
                                    <ErrorMessage
                                      className="d-inline-flex mt-2"
                                      value={errors?.degree?.name}
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-lg-5 d-flex">
                          <div className="edit-inner-box w-100">
                            <div className="edit-header">
                              <h4>{editData?.fullname}</h4>
                              <ul className="nav">
                                <li>#{editData?.reg_id}</li>
                                <li>
                                  <p>
                                    <span
                                      className={
                                        watchStatus
                                          ? 'status-active'
                                          : 'status-inactive'
                                        // : 'status-disabled'
                                      }
                                    />
                                    {capitalizeFirstLetter(editData?.status)}
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7 d-flex">
                          <div className="edit-details w-100">
                            <div className="edit-list">
                              <ul>
                                <li>
                                  <img src={edit02} alt="Icon" />
                                  <a href={`mailto:${editData?.email}`}>
                                    {editData?.email}
                                  </a>{' '}
                                </li>
                                <li>
                                  <img src={edit01} alt="Icon" />
                                  <a href={`tel:${editData?.mobile}`}>
                                    {editData?.mobile}
                                  </a>
                                </li>
                                <li>
                                  <img src={edit04} alt="Icon" />
                                  {editData?.doctor_reg_no}
                                </li>
                                <li>
                                  <img src={edit05} alt="Icon" />
                                  {degreeName?.name}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Edit Doctor */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default DoctorEdit;
