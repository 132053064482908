import React from 'react';
import { noRecordsFound } from '../../../../../assets/global';

function InvestigationHistory({ details = [] }) {
  const history: any = details?.[0];
  return (
    <div
      className="tab-pane fade"
      id="investigation_tab"
      role="tabpanel"
      aria-labelledby="investigation-tab"
    >
      <div className="row">
        <div className="col-md-6 d-flex">
          <div className="examination-card w-100">
            <h4>Test</h4>
            <div className="investigation-details">
              <ul>
                {history?.tests?.length > 0 ? (
                  history?.tests?.map((item: any) => {
                    return (
                      <li>
                        {' '}
                        <p>{item?.name}</p>
                      </li>
                    );
                  })
                ) : (
                  <li>
                    {' '}
                    <p>{noRecordsFound}</p>
                  </li>
                )}
                {/* <li>
                  <p>CBC ( Complete Blood Count)</p>
                </li>
                <li>
                  <p>Chest x-ray PA View</p>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex">
          <div className="examination-card w-100">
            <h4>Refer to Specialist</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvestigationHistory;
