import React from 'react';
import { Link } from 'react-router-dom';
import { noRecordsFound } from '../../../../../assets/global';

function DiagonosisHistory({ detials = [] }) {
  const history: any = detials?.[0];

  return (
    <div
      className="tab-pane fade"
      id="diagnosis_tab"
      role="tabpanel"
      aria-labelledby="diagnosis-tab"
    >
      <div className="row">
        <div className="col-md-6 d-flex">
          <div className="examination-card w-100">
            <h4>Diagnosis</h4>
            <div className="investigation-details">
              <ul>
                {history?.diagnosis.length > 0 ? (
                  history?.diagnosis?.map((val: any) => {
                    return (
                      <li key={val?.name}>
                        <p>{val?.name}</p>
                      </li>
                    );
                  })
                ) : (
                  <li>
                    <p>{noRecordsFound}</p>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex">
          <div className="examination-card w-100">
            <h4>Differential Diagnosis</h4>
            <div className="investigation-details">
              <ul>
                {history?.differential_diagnosis?.length > 0 ? (
                  history?.differential_diagnosis?.map((val: any) => {
                    return (
                      <li key={val?.name}>
                        <p>{val?.name}</p>
                      </li>
                    );
                  })
                ) : (
                  <li>
                    <p>{noRecordsFound}</p>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex">
          <div className="examination-card w-100">
            <h4>Provisional Diagnosis</h4>
            <div className="investigation-details">
              <ul>
                {history?.provisional_diagnosis?.length > 0 ? (
                  history?.provisional_diagnosis?.map((val: any) => {
                    return (
                      <li key={val?.name}>
                        <p>{val?.name}</p>
                      </li>
                    );
                  })
                ) : (
                  <li>
                    <p>{noRecordsFound}</p>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="col-md-6 d-flex">
          <div className="examination-card w-100">
            <h4>Test</h4>
            <div className="screenshot-details">
              <ul className="nav">
                <li>
                  <p>Screenshot 2021-01-.........</p></li>
                <li>
                  <Link to="#">View</Link></li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default DiagonosisHistory;
