import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Header from '../common/header';
import { useDispatch, useSelector } from 'react-redux';
import { setMobileSidebar } from '../../core/redux/sidebarSlice';

const FeatureLayout: React.FC = () => {
  const location = useLocation();
  const findPath = location.pathname;
  const isLogin = findPath.split('/')[2];
  const withWrapperPages = isLogin === 'login';
  const styleRegisterPage = location.pathname.includes('register');
  const styleLoginOtpPage =
    location.pathname.includes('login-otp') ||
    location.pathname.includes('otp') ||
    location.pathname.includes('forgot-password') ||
    location.pathname.includes('confirm-password');
  const dispatch = useDispatch();
  const hideLayoutsPages =
    location.pathname.includes('login') ||
    location.pathname.includes('password') ||
    location.pathname.includes('register') ||
    location.pathname.includes('otp') ||
    location.pathname.includes('logout') ||
    location.pathname.includes('videoCall');

  const customWrapperPages =
    location.pathname.includes('forgot-password') ||
    location.pathname.includes('otp') ||
    location.pathname.includes('login-otp') ||
    location.pathname.includes('confirm-password');

  const isRegisterPage = location.pathname.includes('register');
  const isWelcomePage = location.pathname.includes('welcome');
  const isJoinCall = location.pathname.includes('joinConsultation');

  const handleMobileSidebar = () => {
    dispatch(setMobileSidebar(!mobileSidebarData));
  };

  useEffect(() => {
    if (customWrapperPages) {
      document.body.classList.add('password-body');
    } else {
      document.body.classList.remove('password-body');
    }

    if (isRegisterPage) {
      document.body.classList.add('register-body');
    } else {
      document.body.classList.remove('register-body');
    }
    if (isWelcomePage) {
      document.body.classList.add('welcome-body');
    } else {
      document.body.classList.remove('welcome-body');
    }
    if (isJoinCall) {
      document.body.classList.add('join-body');
    } else {
      document.body.classList.remove('join-body');
    }

    return () => {
      document.body.classList.remove('password-body');
      document.body.classList.remove('register-body');
    };
  }, [customWrapperPages, isRegisterPage, isWelcomePage, isJoinCall]);

  const mobileSidebarData = useSelector(
    (state: any) => state.sidebar.mobileSidebar,
  );

  return (
    <>
      <div
        className={`main-wrapper 
          ${withWrapperPages ? 'login-wrapper' : ''}
          ${styleRegisterPage ? 'account-wrapper account-wrapper-info' : ''}
          ${styleLoginOtpPage ? 'account-wrapper' : ''}
          ${mobileSidebarData ? 'slide-nav' : ''}
          `}
      >
        {!hideLayoutsPages && !isWelcomePage && (
          <>
            <Header />
          </>
        )}
        <Outlet />
      </div>
      <div
        onClick={handleMobileSidebar}
        className={`sidebar-overlay ${mobileSidebarData ? 'opened' : ''}`}
      ></div>
    </>
  );
};

export default FeatureLayout;
