import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { stethoscopeImg } from '../../../../../../utils/imagepath';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import { ApiServiceContext } from '../../../../../../utils/shared.module';
import { end_points } from '../../../../../../services/end_point/end_points';
import { useDispatch, useSelector } from 'react-redux';
import { setVitalsUpdated } from '../../../../../../core/redux/commonSlice';
import { capitalizeWords } from '../../../../../../utils/constant';
import AudioPlayer from './audioPlayer';
interface vitalsCallProps {
  vitalBool: boolean;
  setVitalsBool: (bool: boolean) => void;
}
const VitalsCall: React.FC<vitalsCallProps> = ({
  vitalBool,
  setVitalsBool,
}) => {
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { getData, postData } = useContext(ApiServiceContext);
  const [vitalData, setVitalData] = useState<any>([]);

  const appoitmentId: any =
    // '66c49db43704b0a382e22a27';
    useSelector((state: any) => state.common.editData?._id);
  const vitalsDatafromDevice = useSelector(
    (state: any) => state.common?.vitalsData,
  );
  const dispatch = useDispatch();

  const getUrl = (url: any, appoitmentId: any) => {
    if (appoitmentId) {
      url += `/${appoitmentId}`;
    }
    return url;
  };
  const getVitalsData = async () => {
    try {
      showLoader();
      const vitlasresponse = await getData(
        getUrl(end_points.getVitalsListbyAppIdApi.url, appoitmentId),
      );
      if (vitlasresponse.status === 200) {
        // dispatch;
        let data = vitlasresponse?.data?.data;
        const { deletedAt, _id, createdAt, updatedAt, ...rest } = data;
        const stctureData =
          Object.keys(rest).length > 0
            ? Object.keys(rest)?.map((val: any) => {
                return {
                  label: capitalizeWords(val?.replace('_', ' ')),
                  type: val,
                  data: rest[val],
                };
              })
            : [];
        dispatch(setVitalsUpdated(''));
        setVitalData(stctureData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setVitalsBool(false);
      hideLoader();
    }
  };
  // {
  //   "label": "Blood Pressure",
  //   "type": "blood_pressure",
  //   "data": "value"
  // }
  useEffect(() => {
    appoitmentId && vitalBool && getVitalsData();
  }, [appoitmentId, vitalBool]);
  useEffect(() => {
    let initialData = vitalData || [];
    if (vitalsDatafromDevice) {
      const { label, data, type } = vitalsDatafromDevice;

      const existingIndex = initialData.findIndex(
        (item: any) => item.type === type,
      );

      if (existingIndex !== -1) {
        const updatedData = initialData?.map((item: any, index: any) =>
          index === existingIndex ? { ...item, value: data } : item,
        );
        setVitalData(updatedData);
      } else {
        const updatedData = [...initialData, vitalsDatafromDevice];
        setVitalData(updatedData);
      }
    }
  }, [vitalsDatafromDevice]);
  const pacs: any =
    vitalData?.length > 0
      ? vitalData?.filter(
          (v: any) => v?.type === 'stethoscope' || v?.type === 'screenshot',
        )
      : [];
  return (
    <div
      className="tab-pane fade"
      id="vitals_tab"
      role="tabpanel"
      aria-labelledby="vitals-tab"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="live-stethoscope">
            <Link to={'#'} className="btn btn-primary">
              Live Stethoscope
            </Link>
          </div>
        </div>
        <div className="col-md-6">
          <div className="symptoms-card vitals-card">
            <h4>Vitals</h4>
            <div className="vitals-table">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Device Name</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vitalData?.length > 0 ? (
                      vitalData?.map((v: any) => {
                        if (
                          v?.type === 'stethoscope' ||
                          v?.type === 'screenshot'
                        ) {
                          return null;
                        } else
                          return (
                            <tr>
                              <td>{v?.label}</td>
                              <td className="value-text">{v?.data}</td>
                            </tr>
                          );
                      })
                    ) : (
                      <tr>
                        <td>No data sent</td>
                      </tr>
                    )}
                    {/* <tr>
                      <td>Blood Glucose</td>
                      <td className="value-text">81.08 mg/dl</td>
                    </tr> */}
                    {/* <tr>
                      <td>Blood Pressure</td>
                      <td className="value-text">132/84 mmHg</td>
                    </tr>
                    <tr>
                      <td>Weight</td>
                      <td className="value-text">82.5 kg</td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="symptoms-card vitals-card">
            <h4>PACS</h4>
            <div className="vitals-table">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Device Name</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pacs.length > 0 ? (
                      pacs?.map((val: any) => {
                        return (
                          <>
                            <tr>
                              <td>
                                {capitalizeWords(val?.type.replace('_', ' '))}
                              </td>
                              <td>
                                {val?.type === 'stethoscope' && (
                                  <AudioPlayer
                                    url={
                                      val?.data
                                      // 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3'
                                    }
                                  />
                                  // <img
                                  //   src={
                                  //     val?.data
                                  //     // stethoscopeImg
                                  //   }
                                  //   alt="Stethoscope"
                                  // />
                                )}
                                {val?.type === 'screenshot' && (
                                  <img src={val?.data} alt="screenshot" />
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td>No data sent</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VitalsCall;
