import React from 'react';
import { noRecordsFound } from '../../../../../assets/global';
import { capitalizeFirstLetter } from '../../../../../utils/constant';

function Symptoms({ details = [] }) {
  const history: any = details?.[0] ? details?.[0] : {};

  const heads = ['Symptoms', 'Presenting Illness', 'Duration'];
  return (
    <div
      className="tab-pane fade show active"
      id="symptoms_tab"
      role="tabpanel"
      aria-labelledby="symptoms-tab"
    >
      <div className="row">
        <div className="col-md-6">
          <div className="symptoms-card">
            <h4>{heads[0]}</h4>
            {history?.symptoms?.length > 0 ? (
              history?.symptoms?.map((item: any) => {
                return <p>{item?.name}</p>;
              })
            ) : (
              <p>{noRecordsFound}</p>
            )}
            {/* <div>
              Note:<p>{history?.symptoms_add_note}</p>
            </div> */}
          </div>
        </div>
        <div className="col-md-6">
          <div className="symptoms-card">
            <h4>{heads[1]}</h4>
            <p>{history?.presenting_illness || noRecordsFound}</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="symptoms-card">
            <h4>{heads[2]}</h4>
            {history?.duration_of_numbers ? (
              <p>
                {history?.duration_of_numbers}{' '}
                {capitalizeFirstLetter(history?.duration_type)}
              </p>
            ) : (
              <p>{noRecordsFound}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Symptoms;
