import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  login01,
  login02,
  logoBig,
  shape01,
  shape02,
} from '../../../utils/imagepath';
import { routes } from '../../../utils/routes';
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from '../../../utils/shared.module';
import { Controller, useForm } from 'react-hook-form';
import { end_points } from '../../../services/end_point/end_points';
import {
  emailMaxLength,
  passwordMaxLength,
  passwordMinLength,
  userNameRegex,
} from '../../../utils/patterns/regex.pattern';
import { useDispatch } from 'react-redux';
import {
  setLoginToken,
  setTenantId,
  setUserDetails,
  setUserName,
} from '../../../core/redux/loginSlice';
import { userRole } from '../../../assets/global';
import { SpinnerContext } from '../../../components/spinner/spinner';

const Login = () => {
  const loginValidation = yup.object().shape({
    userName: yup.string().trim().required('Username is required'),
    password: yup.string().trim().required('Password is required'),
  });

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidation),
    defaultValues: {
      userName: '',
      password: '',
    },
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { postData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const path = location?.pathname;
  const findType = path.split('/')[1];
  const hostName = window.location.hostname;
  const host = window.location.host;
  if (hostName === 'localhost') {
    var domainName = 'apollo.cc-frontend.dreamstechnologies.com';
  } else {
    var domainName = host;
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onSubmit = async (data: any) => {
    try {
      let urls;
      if (findType === userRole.FrontDesk || findType === userRole.Doctor) {
        urls = end_points.userLogin.url;
      } else {
        urls = end_points.adminLogin.url;
      }
      let payload;
      if (findType === userRole.FrontDesk) {
        payload = {
          username: data?.userName,
          password: data?.password,
          type: `${userRole.FrontDesk}`,
        };
      } else if (findType === userRole.Doctor) {
        payload = {
          username: data?.userName,
          password: data?.password,
          type: `${userRole.Doctor}`,
        };
      } else {
        payload = {
          username: data?.userName,
          password: data?.password,
        };
      }
      const response = await postData(urls, payload);
      if (response?.status === 200) {
        const tenant = response.data.tenant_id;
        const fullname = response.data.fullname;
        const loginData = response.data;
        const { token } = loginData;
        if (token) {
          dispatch(setLoginToken(token));
          dispatch(setTenantId(tenant));
          dispatch(setUserName(fullname));
          dispatch(setUserDetails(response.data));
          if (findType === userRole.FrontDesk) {
            navigate(routes.frontDeskPatientList.path);
          } else if (findType === userRole.Doctor) {
            navigate(routes.doctorDashboard.path);
          } else {
            navigate(routes.dashboard.path);
          }
        }
      }
    } catch (error: any) {}
  };

  const getTenant = async () => {
    try {
      showLoader();
      const url = end_points.getTenant.url;
      const payload = {
        domainName: domainName,
      };

      const response = await postData(url, payload);
      if (response?.status === 200) {
        const tenant = response.data.data.tenant_id;
        dispatch(setTenantId(tenant));
      } else {
        dispatch(setTenantId(null));
      }
    } catch (error: any) {
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (
      (findType === userRole.FrontDesk || findType === userRole.Doctor) &&
      domainName.endsWith('.cc-frontend.dreamstechnologies.com')
    ) {
      findType && getTenant();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="login-left">
        <div className="login-logo">
          <img src={logoBig} alt="Logo" />
        </div>
        <div className="login-content">
          <h1>WELCOME !</h1>
          <h5>Your Anytime Health Companion</h5>
          <img src={shape01} className="img-fluid" alt="Shape" />
        </div>
        <div className="login-content-bottom">
          <Link to="#">help@axon.com</Link>
          <p>2024 Axon Smart Health</p>
        </div>
      </div>
      <div className="login-box">
        <div className="login-right">
          <div className="login-right-wrap">
            <div className="login-header">
              {findType === 'front-desk' ? (
                <h2>FRONTDESK LOGIN</h2>
              ) : findType === 'doctor' ? (
                <h2>DOCTOR LOGIN</h2>
              ) : (
                <h2>ADMIN LOGIN</h2>
              )}
            </div>
            {/* Form */}
            <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-wrap">
                <div className="form-wrap-input">
                  <img src={login01} alt="Icon" />
                  <div className="w-100">
                    <Controller
                      name="userName"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            type="text"
                            placeholder="Username"
                            className={`form-control ${
                              errors.userName ? 'is-invalid' : ''
                            }`}
                            onBlur={() => trigger('userName')}
                            onChange={(event: any) => {
                              field.onChange(event);
                              trigger('userName');
                            }}
                            onKeyDown={(event) => {
                              const regex = userNameRegex;
                              if (
                                !regex.test(event.key) &&
                                event.key !== 'Backspace'
                              ) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={emailMaxLength}
                          />
                          {errors.userName && (
                            <small className="text-danger d-inline-flex mt-2">
                              {errors.userName.message}
                            </small>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="form-wrap pass-group">
                <div className="form-wrap-input">
                  <img src={login02} alt="Icon" />
                  <div className="w-100">
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            type={passwordVisible ? 'text' : 'password'}
                            placeholder="Password"
                            className={`form-control ${
                              errors.password ? 'is-invalid' : ''
                            }`}
                            onBlur={() => trigger('password')}
                            onChange={(event: any) => {
                              field.onChange(event);
                              trigger('password');
                            }}
                            maxLength={passwordMaxLength}
                          />
                          <span
                            className={`fas ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} toggle-password ${errors.password ? 'toggle-password-icon-right' : 'toggle-password-icon'}`}
                            onClick={togglePasswordVisibility}
                          />
                          {errors.password && (
                            <small className="text-danger d-inline-flex mt-2">
                              {errors.password.message}
                            </small>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="login-btn">
                <button className="btn btn-secondary" type="submit">
                  Login
                </button>
              </div>
              <div className="login-otp">
                {findType === userRole.FrontDesk ? (
                  <Link to={routes.frontDeskloginOtp.path}>Login with OTP</Link>
                ) : findType === userRole.Doctor ? (
                  <Link to={routes.doctorloginOtp.path}>Login with OTP</Link>
                ) : (
                  <Link to={routes.loginOtp.path}>Login with OTP</Link>
                )}
              </div>
              <div className="login-forgot">
                {findType === userRole.FrontDesk ? (
                  <Link
                    to={routes.frontDeskforgotPassword.path}
                    className="forgot-link"
                  >
                    Forgot Password?
                  </Link>
                ) : findType === userRole.Doctor ? (
                  <Link
                    to={routes.doctorforgotPassword.path}
                    className="forgot-link"
                  >
                    Forgot Password?
                  </Link>
                ) : (
                  <Link to={routes.forgotPassword.path} className="forgot-link">
                    Forgot Password?
                  </Link>
                )}
              </div>
              <div className="login-otp return-to-home">
                {findType === userRole.FrontDesk ? (
                  <Link to={routes.frontDeskWelcome.path}>Return to home</Link>
                ) : findType === userRole.Doctor ? (
                  <Link to={routes.doctorWelcome.path}>Return to home</Link>
                ) : (
                  <Link to={routes.welcome.path}>Return to home</Link>
                )}
              </div>
            </form>
            {/* /Form */}
          </div>
        </div>
        <div className="shape-img login-shape-img">
          <img src={shape02} className="img-fluid" alt="Shape" />
        </div>
      </div>
    </>
  );
};

export default Login;
