export const doctorsAssociate = [
  {
    id: '#129845',
    key: '1',
    name: 'Shruti Singh',
    phoneNumber: '+91 9876543210',
  },
  {
    id: '#129845',
    key: '2',
    name: 'Khyati Sodhi',
    phoneNumber: '+91 9876543210',
  },
  {
    id: '#129845',
    key: '3',
    name: 'Jabcob mark',
    phoneNumber: '+91 9876543210',
  },
  {
    id: '#129845',
    key: '4',
    name: 'Shruti Singh',
    phoneNumber: '+91 9876543210',
  },
  {
    id: '#129845',
    key: '5',
    name: 'Khyati Sodhi',
    phoneNumber: '+91 9876543210',
  },
  {
    id: '#129845',
    key: '6',
    name: 'Jabcob mark',
    phoneNumber: '+91 9876543210',
  },
  {
    id: '#129845',
    key: '7',
    name: 'Shruti Singh',
    phoneNumber: '+91 9876543210',
  },
];
