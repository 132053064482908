import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from '../../../../../../utils/shared.module';
import { Controller, useForm } from 'react-hook-form';
import { AutoComplete } from 'primereact/autocomplete';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { end_points } from '../../../../../../services/end_point/end_points';
import { Tab } from 'bootstrap';
import { setSymtomsFlowData } from '../../../../../../core/redux/commonSlice';

interface SymptomsContext {
  chipValue: string[];
  chipValueTwo: string[];
  chipValueThree: string[];
}

const differentialDiagnosisValidation = yup.object().shape({
  diffDiagnosis: yup
    .array()
    .of(yup.string())
    .test(
      'at-least-one-differential diagnosis',
      'At least one differential diagnosis is required',
      function (value) {
        const context = this.options.context as SymptomsContext;
        const { chipValue } = context;
        return (
          (value && value.length > 0) || (chipValue && chipValue.length > 0)
        );
      },
    ),
  provisionalDiagnosis: yup
    .array()
    .of(yup.string())
    .test(
      'at-least-one-provisional diagnosis',
      'At least one provisional diagnosis is required',
      function (value) {
        const context = this.options.context as SymptomsContext;
        const { chipValueTwo } = context;
        return (
          (value && value.length > 0) ||
          (chipValueTwo && chipValueTwo.length > 0)
        );
      },
    ),
  diagnosis: yup
    .array()
    .of(yup.string())
    .test(
      'at-least-one-diagnosis',
      'At least one diagnosis is required',
      function (value) {
        const context = this.options.context as SymptomsContext;
        const { chipValueThree } = context;
        return (
          (value && value.length > 0) ||
          (chipValueThree && chipValueThree.length > 0)
        );
      },
    ),
});

const DiagnosisCall = () => {
  const [chipValue, setChipValue] = useState<any>([]);
  const [filtereddiffdiagnosiss, setFiltereddiffdiagnosiss] = useState<any[]>(
    [],
  );
  const [showAddNote, setShowAddNote] = useState(false);
  const [newdiffdiagnosis, setNewdiffdiagnosis] = useState('');
  const [searchdiffdiagnosis, setSearchdiffdiagnosis] = useState('');

  const [chipValueTwo, setChipValueTwo] = useState<any>([]);
  const [filtereddiffdiagnosissTwo, setFiltereddiffdiagnosissTwo] = useState<
    any[]
  >([]);
  const [showAddNoteTwo, setShowAddNoteTwo] = useState(false);
  const [newdiffdiagnosisTwo, setNewdiffdiagnosisTwo] = useState('');
  const [searchdiffdiagnosisTwo, setSearchdiffdiagnosisTwo] = useState('');

  const [chipValueThree, setChipValueThree] = useState<any>([]);
  const [filtereddiffdiagnosissThree, setFiltereddiffdiagnosissThree] =
    useState<any[]>([]);
  const [showAddNoteThree, setShowAddNoteThree] = useState(false);
  const [newdiffdiagnosisThree, setNewdiffdiagnosisThree] = useState('');
  const [searchdiffdiagnosisThree, setSearchdiffdiagnosisThree] = useState('');
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { getData, postData } = useContext(ApiServiceContext);
  const [provisionalListData, setProvisionalListData] = useState<any>({});
  const [diagnosisListData, setDiagnosisListData] = useState<any>({});
  const [diffDiagnosisData, setDiffDiagnosisData] = useState<any>({});

  const editData: any = useSelector((state: any) => state?.common?.editData);
  const dispatch = useDispatch();
  const savedData: any = useSelector((state: any) => state?.common?.flowData);
  const {
    handleSubmit,
    control,
    trigger,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(differentialDiagnosisValidation),
    defaultValues: {
      symptoms: [],
      newSymptom: '',
    },
    context: { chipValue, chipValueTwo, chipValueThree },
  });

  // Differential Diagnosis
  const handleAddNote = () => {
    setShowAddNote(true);
  };

  const searchDiagnosis = (event: any) => {
    const filtered = diffDiagnosisData.filter((diagnosis: any) =>
      diagnosis.name.toLowerCase().startsWith(event.query.toLowerCase()),
    );
    setFiltereddiffdiagnosiss(
      filtered.length
        ? filtered
        : [{ name: 'No results found', code: '', disabled: true }],
    );
  };

  const handleSavediffDiagnosis = () => {
    if (newdiffdiagnosis && !chipValue.includes(newdiffdiagnosis)) {
      setChipValue((prev: any) => [...prev, newdiffdiagnosis]);
      setNewdiffdiagnosis('');
      setShowAddNote(false);
    }
  };

  //Provisional Diagnosis

  const handleAddNoteTwo = () => {
    setShowAddNoteTwo(true);
  };

  const searchDiagnosisTwo = (event: any) => {
    const filtered = provisionalListData.filter((diagnosis: any) =>
      diagnosis.name.toLowerCase().startsWith(event.query.toLowerCase()),
    );
    setFiltereddiffdiagnosissTwo(
      filtered.length
        ? filtered
        : [{ name: 'No results found', code: '', disabled: true }],
    );
  };

  const handleSaveProvisional = () => {
    if (newdiffdiagnosisTwo && !chipValueTwo.includes(newdiffdiagnosisTwo)) {
      setChipValueTwo((prev: any) => [...prev, newdiffdiagnosisTwo]);
      setNewdiffdiagnosisTwo('');
      setShowAddNoteTwo(false);
    }
  };

  //Diagnosis

  const handleAddNoteThree = () => {
    setShowAddNoteThree(true);
  };

  const searchDiagnosisThree = (event: any) => {
    const filtered = diagnosisListData.filter((diagnosis: any) =>
      diagnosis.name.toLowerCase().startsWith(event.query.toLowerCase()),
    );
    setFiltereddiffdiagnosissThree(
      filtered.length
        ? filtered
        : [{ name: 'No results found', code: '', disabled: true }],
    );
  };

  const handleSaveDiagnosis = () => {
    if (
      newdiffdiagnosisThree &&
      !chipValueThree.includes(newdiffdiagnosisThree)
    ) {
      setChipValueThree((prev: any) => [...prev, newdiffdiagnosisThree]);
      setNewdiffdiagnosisThree('');
      setShowAddNoteThree(false);
    }
  };

  const onSubmit = async (data: any) => {
    console.log('Form Data:', { ...data, symptoms: chipValue });
    try {
      showLoader();
      let url = end_points.onCallDiagnosisTabAdd.url;
      url += `/${editData?.patient_id}`;
      const transformedchipValue = chipValue.map((test: any) => ({
        name: test,
      }));
      const transformedchipValueTwo = chipValueTwo.map((test: any) => ({
        name: test,
      }));
      const transformedchipValueThree = chipValueThree.map((test: any) => ({
        name: test,
      }));
      const payload = {
        diagnosis: [
          {
            differential_diagnosis: transformedchipValue,
            diagnosis: transformedchipValueThree,
            provisional_diagnosis: transformedchipValueTwo,
            // reports: "reached",
            differential_add_note: newdiffdiagnosis,
            provisional_add_note: newdiffdiagnosisTwo,
            diagnosis_add_note: newdiffdiagnosisThree,
          },
        ],
      };
      const response = await postData(url, payload);
      if (response.status === 200) {
        const newData = { ...savedData, diag: payload };
        dispatch(setSymtomsFlowData(newData));
        const nextTab = document.querySelector(
          '#prescription-tab',
        ) as HTMLElement;
        const nextTabLink = new Tab(nextTab);
        nextTabLink.show();
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (chipValue.length > 0) {
      trigger('diffDiagnosis');
    }
  }, [chipValue]);

  useEffect(() => {
    if (chipValueTwo.length > 0) {
      trigger('provisionalDiagnosis');
    }
  }, [chipValueTwo]);

  useEffect(() => {
    if (chipValueThree.length > 0) {
      trigger('diagnosis');
    }
  }, [chipValueThree]);

  //list Options
  const getDiffDiagnosisList = async (search?: any) => {
    try {
      showLoader();
      const urls = end_points.getDiffDiagnosisList.url;
      const response = await getData(urls);
      if (response?.status == 200) {
        const diffDiagnosisList = response.data.data.map(
          (diffDiagnosis: any) => ({
            name: diffDiagnosis.name,
            code: diffDiagnosis.name,
          }),
        );
        setDiffDiagnosisData(diffDiagnosisList);
      }
    } catch (error: any) {
    } finally {
      hideLoader();
    }
  };
  const getDiagnosisList = async (search?: any) => {
    try {
      showLoader();
      const urls = end_points.getDiagnosisList.url;
      const response = await getData(urls);
      if (response?.status == 200) {
        const diagnosisList = response.data.data.map((diagnosis: any) => ({
          name: diagnosis.name,
          code: diagnosis.name,
        }));
        setDiagnosisListData(diagnosisList);
      }
    } catch (error: any) {
    } finally {
      hideLoader();
    }
  };
  const getProvisionalList = async (search?: any) => {
    try {
      showLoader();
      const urls = end_points.getProvisionalList.url;
      const response = await getData(urls);
      if (response?.status == 200) {
        const provisionalList = response.data.data.map((provisional: any) => ({
          name: provisional.name,
          code: provisional.name,
        }));
        setProvisionalListData(provisionalList);
      }
    } catch (error: any) {
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getDiffDiagnosisList();
    getDiagnosisList();
    getProvisionalList();
  }, []);

  //Create Options Submit

  const newDiffDiagnosisSubmit = async () => {
    try {
      showLoader();
      const payload = {
        name: newdiffdiagnosis,
        status: 'active',
      };
      const response = await postData(
        end_points.newDiffDiagnosisCreate.url,
        payload,
      );
      if (response.status === 200) {
        handleSavediffDiagnosis();
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const newDiagnosisSubmit = async () => {
    try {
      showLoader();
      const payload = {
        name: newdiffdiagnosisThree,
        status: 'active',
      };
      const response = await postData(
        end_points.newDiagnosisCreate.url,
        payload,
      );
      if (response.status === 200) {
        handleSaveDiagnosis();
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const newProvisionalSubmit = async () => {
    try {
      showLoader();
      const payload = {
        name: newdiffdiagnosisTwo,
        status: 'active',
      };
      const response = await postData(
        end_points.newProvisionalCreate.url,
        payload,
      );
      if (response.status === 200) {
        handleSaveProvisional();
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission on Enter key
    }
  };
  useEffect(() => {
    const data = savedData?.diag?.diagnosis?.[0] || null;
    if (data) {
      let one = data?.differential_diagnosis?.map((sym: any) => sym?.name);
      let two = data?.provisional_diagnosis?.map((sym: any) => sym?.name);
      let three = data?.diagnosis?.map((sym: any) => sym?.name);

      setChipValue(one);
      setChipValueThree(three);
      setChipValueTwo(two);
      if (data.provisional_add_note) {
        setShowAddNote(true);
        setNewdiffdiagnosisTwo(data.investigation_add_note);
      }
      if (data.diagnosis_add_note) {
        setShowAddNoteThree(true);
        setNewdiffdiagnosisThree(data.investigation_add_note);
      }
      if (data.differential_add_note) {
        setShowAddNote(true);
        setNewdiffdiagnosis(data.investigation_add_note);
      }
    }
  }, [savedData?.diag]);
  return (
    <div
      className="tab-pane fade"
      id="diagnosis_tab"
      role="tabpanel"
      aria-labelledby="diagnosis-tab"
    >
      <form
        id="diagnosisForm"
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={handleKeyPress}
      >
        <div className="row">
          <div className="col-md-6">
            <div className="symptoms-card">
              <h4>Differential Diagnosis</h4>
              <div className="symptoms-search">
                <div className="symptoms-input">
                  <Controller
                    name="diffDiagnosis"
                    control={control}
                    render={({ field }) => (
                      <AutoComplete
                        value={searchdiffdiagnosis}
                        suggestions={filtereddiffdiagnosiss}
                        completeMethod={searchDiagnosis}
                        field="name"
                        onBlur={() => trigger('diffDiagnosis')}
                        onKeyDown={(e: any) => {
                          if (
                            e.key === 'Enter' &&
                            e.target.value !== 'No results found' &&
                            !chipValue.includes(e.target.value)
                          ) {
                            setChipValue([...chipValue, e.target.value]);
                            setSearchdiffdiagnosis('');
                          }
                        }}
                        onChange={(e) => {
                          setSearchdiffdiagnosis(e.value);
                          trigger('diffDiagnosis');
                        }}
                        onSelect={(e) => {
                          if (!chipValue.includes(e.value.name)) {
                            setChipValue([...chipValue, e.value.name]);
                            setSearchdiffdiagnosis('');
                          }
                        }}
                        itemTemplate={(item) => {
                          if (item.disabled) {
                            return (
                              <span
                                style={{ color: 'gray', pointerEvents: 'none' }}
                              >
                                {item.name}
                              </span>
                            );
                          }
                          return <span>{item.name}</span>;
                        }}
                        placeholder="Search"
                        className="form-control"
                      />
                    )}
                  />
                  <i className="feather icon-search"></i>
                </div>
                <div className="symptoms-tags">
                  <ul className="nav">
                    {chipValue.map((diagnosis: any, index: any) => (
                      <li key={index}>
                        <h5>
                          {diagnosis}
                          <Link
                            to="#"
                            onClick={() => {
                              setChipValue(
                                chipValue.filter((s: any) => s !== diagnosis),
                              );
                            }}
                          >
                            <i className="fas fa-xmark" />
                          </Link>
                        </h5>
                      </li>
                    ))}
                  </ul>
                  {errors.diffDiagnosis && (
                    <small className="text-danger d-inline-flex mt-2">
                      {errors.diffDiagnosis.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="add-differential">
                <Link
                  to="#"
                  className="add-differential-link add-note-link"
                  onClick={handleAddNote}
                >
                  Add Note
                </Link>
                {showAddNote && (
                  <div className="add-symptoms-input">
                    <input
                      type="text"
                      value={newdiffdiagnosis}
                      onChange={(e) => setNewdiffdiagnosis(e.target.value)}
                      className="form-control"
                      placeholder="Add note"
                    />
                    {/* <Link
                      to="#"
                      className="add-note-link"
                      onClick={newDiffDiagnosisSubmit}
                    >
                      Save
                    </Link> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="symptoms-card">
              <h4>Diagnosis</h4>
              <div className="symptoms-search">
                <div className="symptoms-input">
                  <Controller
                    name="diagnosis"
                    control={control}
                    render={({ field }) => (
                      <AutoComplete
                        value={searchdiffdiagnosisThree}
                        suggestions={filtereddiffdiagnosissThree}
                        completeMethod={searchDiagnosisThree}
                        field="name"
                        onBlur={() => trigger('diagnosis')}
                        onKeyDown={(e: any) => {
                          if (
                            e.key === 'Enter' &&
                            e.target.value !== 'No results found' &&
                            !chipValueThree.includes(e.target.value)
                          ) {
                            setChipValueThree([
                              ...chipValueThree,
                              e.target.value,
                            ]);
                            setSearchdiffdiagnosisThree('');
                          }
                        }}
                        onChange={(e) => {
                          setSearchdiffdiagnosisThree(e.value);
                          trigger('diagnosis');
                        }}
                        onSelect={(e) => {
                          if (!chipValueThree.includes(e.value.name)) {
                            setChipValueThree([
                              ...chipValueThree,
                              e.value.name,
                            ]);
                            setSearchdiffdiagnosisThree('');
                          }
                        }}
                        itemTemplate={(item) => {
                          if (item.disabled) {
                            return (
                              <span
                                style={{ color: 'gray', pointerEvents: 'none' }}
                              >
                                {item.name}
                              </span>
                            );
                          }
                          return <span>{item.name}</span>;
                        }}
                        placeholder="Search"
                        className="form-control"
                      />
                    )}
                  />
                  <i className="feather icon-search"></i>
                </div>
                <div className="symptoms-tags">
                  <ul className="nav">
                    {chipValueThree.map((diagnosis: any, index: any) => (
                      <li key={index}>
                        <h5>
                          {diagnosis}
                          <Link
                            to="#"
                            onClick={() => {
                              setChipValueThree(
                                chipValueThree.filter(
                                  (s: any) => s !== diagnosis,
                                ),
                              );
                            }}
                          >
                            <i className="fas fa-xmark" />
                          </Link>
                        </h5>
                      </li>
                    ))}
                  </ul>
                  {errors.diagnosis && (
                    <small className="text-danger d-inline-flex mt-2">
                      {errors.diagnosis.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="add-differential">
                <Link
                  to="#"
                  className="add-differential-link add-note-link"
                  onClick={handleAddNoteThree}
                >
                  Add Note
                </Link>
                {showAddNoteThree && (
                  <div className="add-symptoms-input">
                    <input
                      type="text"
                      value={newdiffdiagnosisThree}
                      onChange={(e) => setNewdiffdiagnosisThree(e.target.value)}
                      className="form-control"
                      placeholder="Add note"
                    />
                    {/* <Link
                      to="#"
                      className="add-note-link"
                      onClick={newDiagnosisSubmit}
                    >
                      Save
                    </Link> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="symptoms-card">
              <h4>Provisional Diagnosis</h4>
              <div className="symptoms-search">
                <div className="symptoms-input">
                  <Controller
                    name="provisionalDiagnosis"
                    control={control}
                    render={({ field }) => (
                      <AutoComplete
                        value={searchdiffdiagnosisTwo}
                        suggestions={filtereddiffdiagnosissTwo}
                        completeMethod={searchDiagnosisTwo}
                        field="name"
                        onKeyDown={(e: any) => {
                          if (
                            e.key === 'Enter' &&
                            e.target.value !== 'No results found' &&
                            !chipValueTwo.includes(e.target.value)
                          ) {
                            setChipValueTwo([...chipValueTwo, e.target.value]);
                            setSearchdiffdiagnosisTwo('');
                          }
                        }}
                        onBlur={() => trigger('provisionalDiagnosis')}
                        onChange={(e) => {
                          setSearchdiffdiagnosisTwo(e.value);
                          trigger('provisionalDiagnosis');
                        }}
                        onSelect={(e) => {
                          if (!chipValueTwo.includes(e.value.name)) {
                            setChipValueTwo([...chipValueTwo, e.value.name]);
                            setSearchdiffdiagnosisTwo('');
                          }
                        }}
                        itemTemplate={(item) => {
                          if (item.disabled) {
                            return (
                              <span
                                style={{ color: 'gray', pointerEvents: 'none' }}
                              >
                                {item.name}
                              </span>
                            );
                          }
                          return <span>{item.name}</span>;
                        }}
                        placeholder="Search"
                        className="form-control"
                      />
                    )}
                  />
                  <i className="feather icon-search"></i>
                </div>
                <div className="symptoms-tags">
                  <ul className="nav">
                    {chipValueTwo.map((diagnosis: any, index: any) => (
                      <li key={index}>
                        <h5>
                          {diagnosis}
                          <Link
                            to="#"
                            onClick={() => {
                              setChipValueTwo(
                                chipValueTwo.filter(
                                  (s: any) => s !== diagnosis,
                                ),
                              );
                            }}
                          >
                            <i className="fas fa-xmark" />
                          </Link>
                        </h5>
                      </li>
                    ))}
                  </ul>
                  {errors.provisionalDiagnosis && (
                    <small className="text-danger d-inline-flex mt-2">
                      {errors.provisionalDiagnosis.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="add-differential">
                <Link
                  to="#"
                  className="add-differential-link add-note-link"
                  onClick={handleAddNoteTwo}
                >
                  Add Note
                </Link>
                {showAddNoteTwo && (
                  <div className="add-symptoms-input">
                    <input
                      type="text"
                      value={newdiffdiagnosisTwo}
                      onChange={(e) => setNewdiffdiagnosisTwo(e.target.value)}
                      className="form-control"
                      placeholder="Add note"
                    />
                    {/* <Link
                      to="#"
                      className="add-note-link"
                      onClick={newProvisionalSubmit}
                    >
                      Save
                    </Link> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="symptoms-card">
              <h4>Reports</h4>
              <span className="reports-no-data">No Data Sent </span>
            </div>
          </div>
          <div className="symptoms-save mb-4">
            <button
              type="submit"
              className="btn btn-secondary"
              form="diagnosisForm"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DiagnosisCall;
