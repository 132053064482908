// import React, { createContext, useEffect, useState, useCallback } from 'react';

// interface SpinnerContextType {
//   isLoading: boolean;
//   showLoader: (type?: string) => void;
//   hideLoader: (type?: string) => void;
// }

// interface Props {
//   children: React.ReactNode;
// }

// export const SpinnerContext = createContext<SpinnerContextType>({
//   isLoading: false,
//   showLoader: () => {},
//   hideLoader: () => {},
// });

// const SpinnerProvider: React.FC<Props> = ({ children }) => {
//   const [loaderCount, setLoaderCount] = useState(0);
//   const [pathLoader, setPathLoader] = useState(false);

//   const showLoader = useCallback((type: string = 'API') => {
//     if (type === 'PATH') {
//       setLoaderCount(1); // Reset loader count
//       setPathLoader(true);
//     } else {
//       setLoaderCount((prevCount) => prevCount + 1);
//     }
//   }, []);

//   const hideLoader = useCallback((type: string = 'API') => {
//     setTimeout(() => {
//       if (type === 'PATH') {
//         setPathLoader(false);
//         setLoaderCount(0); // Reset loader count
//       } else {
//         setLoaderCount((prevCount) => {
//           const newCount = prevCount - 1;
//           return newCount < 0 ? 0 : newCount;
//         });
//       }
//     }, 500);
//   }, []);

//   useEffect(() => {
//     let timer: NodeJS.Timeout | null = null;
//     if (pathLoader) {
//       timer = setTimeout(() => {
//         setPathLoader(false);
//         setLoaderCount((prev) => (prev > 0 ? prev - 1 : 0));
//       }, 1300);
//     }

//     return () => {
//       if (timer) clearTimeout(timer);
//     };
//   }, [pathLoader]);

//   return (
//     <SpinnerContext.Provider
//       value={{
//         isLoading: loaderCount > 0 || pathLoader,
//         showLoader,
//         hideLoader,
//       }}
//     >
//       {children}
//       {(loaderCount > 0 || pathLoader) && (
//         <div className="loading loaderInitialHeight">
//           <div className="loaderInitial"></div>
//         </div>
//       )}
//     </SpinnerContext.Provider>
//   );
// };

// export default SpinnerProvider;
import React, { createContext, useEffect, useState, useCallback } from 'react';

interface SpinnerContextType {
  isLoading: boolean;
  showLoader: () => void;
  hideLoader: () => void;
}

interface Props {
  children: React.ReactNode;
}

export const SpinnerContext = createContext<SpinnerContextType>({
  isLoading: false,
  showLoader: () => {},
  hideLoader: () => {},
});

const SpinnerProvider: React.FC<Props> = ({ children }) => {
  const [loaderCount, setLoaderCount] = useState(0);

  const showLoader = useCallback(() => {
    setLoaderCount((prevCount) => prevCount + 1);
  }, []);

  const hideLoader = useCallback(() => {
    setTimeout(() => {
      setLoaderCount((prevCount) => Math.max(prevCount - 1, 0));
    }, 500);
  }, []);

  return (
    <SpinnerContext.Provider
      value={{
        isLoading: loaderCount > 0,
        showLoader,
        hideLoader,
      }}
    >
      {children}
      {loaderCount > 0 && (
        <div className="loading loaderInitialHeight">
          <div className="loaderInitial"></div>
        </div>
      )}
    </SpinnerContext.Provider>
  );
};

export default SpinnerProvider;
