import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { ApiServiceContext } from '../../../../../../utils/shared.module';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import { end_points } from '../../../../../../services/end_point/end_points';
import ErrorMessage from '../../../../../../components/errorMessage';
import { useSelector } from 'react-redux';
import { capitalizeWords } from '../../../../../../utils/constant';

// Define the schema using Yup
const validationSchema = Yup.object().shape({
  Tobacco: Yup.string().trim().required('Tobacco status is required'),
  Drinks_Alcohol: Yup.string()
    .trim()
    .required('Alcohol consumption status is required'),
  Exercise: Yup.string().trim().required('Exercise level is required'),
  Exercise_Description: Yup.string(),
  Drugs: Yup.string().trim().required('Drug status is required'),
  Drug_Type: Yup.string(),
  //   .when('Drugs', {
  //     is: 'Yes',
  //     then: Yup.string().required(
  //       'Please specify if drugs are recreational or prescribed',
  //     ),
  //     otherwise: Yup.string().notRequired(),
  //   }),
  Drug_Description: Yup.string(),
  //   .when('Drugs', {
  //     is: 'Yes',
  //     then: Yup.string().required('Please provide a description for drugs'),
  //     otherwise: Yup.string().notRequired(),
  //   }),
  Sexual_Wellness: Yup.string()
    .trim()
    .required('Sexual wellness status is required'),
});
const ExcersieSchema = Yup.object().shape({
  Exercise_Description: Yup.string().required('Description is required'),
});
const DrugSchema = Yup.object().shape({
  Drug_Type: Yup.string().required(
    'Please specify if drugs are recreational or prescribed',
  ),
  Drug_Description: Yup.string().required('Description is required'),
});

// Define the default values
const defaultValues = {
  Tobacco: 'No',
  Drinks_Alcohol: 'No',
  Exercise: 'No Exercise',
  Exercise_Description: '',
  Drugs: 'No',
  Drug_Type: 'Recreational',
  Drug_Description: '',
  Sexual_Wellness: 'None',
};

// Social history options
const socialHistory = {
  Tobacco: ['Occasional Smoker', 'Mild Smoker', 'Chain Smoker', 'No'],
  Drinks_Alcohol: [
    'Drinks Occasionally',
    'Drinks Mildly',
    'Heavy Drinker',
    'No',
  ],
  Exercise: ['No Exercise', 'Mild Exercise', 'Vigrous Exercise'],
  Drugs: ['Yes', 'No'],
  Sexual_Wellness: ['Safe', 'Unsafe', 'Both', 'None'],
};
const Drug_Type = ['Recreational', 'Prescribed'];
let drugValid = '';
let excersieValid = '';
interface PastSocialHistoryProps {
  onSave?: Function;
}
const PastSocialHistory: React.FC<PastSocialHistoryProps> = ({
  onSave = () => {},
}) => {
  let mainSchema = validationSchema;

  if (drugValid === 'Yes') {
    mainSchema = mainSchema.concat(DrugSchema);
  }

  if (
    excersieValid === 'Mild Exercise' ||
    excersieValid === 'Vigrous Exercise'
  ) {
    mainSchema = mainSchema.concat(ExcersieSchema);
  }
  const methods = useForm({
    resolver: yupResolver(mainSchema),
    defaultValues,
  });
  const { getData, postData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const patient_Id = useSelector(
    (state: any) => state?.common?.editData?.patient_id,
  );
  const {
    handleSubmit,
    control,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = methods;
  drugValid = watch('Drugs');
  excersieValid = watch('Exercise');
  const onSubmit = async (data: any) => {
    try {
      showLoader();
      const payload: any = {
        label: [
          {
            tobacco: data?.Tobacco?.toLowerCase(),
            drinksAlcohol: data?.Drinks_Alcohol?.toLowerCase(),
            exercise: data?.Exercise?.toLowerCase(),
            exercise_description:
              data?.Exercise?.toLowerCase() === 'no excersie'
                ? ''
                : data?.Exercise_Description,
            drugs_description:
              data?.Drugs === 'Yes' ? data?.Drug_Description : '',
            drugs: data?.Drugs === 'Yes' ? 'yes' : 'no',
            sexualWellness: data?.Sexual_Wellness?.toLowerCase(),
          },
        ],
      };
      if (data?.Drugs === 'Yes') {
        payload.label[0].type = data?.Drug_Type?.toLowerCase();
      }
      let url = end_points.pastPersonalSocialCreateApi.url;
      if (patient_Id) {
        url += `/${patient_Id}`;
      }
      const response = await postData(url, payload);
      if (response.status === 200) {
        // Handle success
        onSave();
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const exercise = useWatch({ control, name: 'Exercise' });
  const drugs = useWatch({ control, name: 'Drugs' });

  const getPastSocialHistory = async () => {
    try {
      showLoader();
      let url = end_points.pastPersonalSocialGetApi.url;
      if (patient_Id) {
        url += `/${patient_Id}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        // console.log(response.data);
        // Drinks_Alcohol: 'No',
        // Exercise: 'No Exercise',
        // Exercise_Description: '',
        // Drugs: 'No',
        // Drug_Type: '',
        // Drug_Description: '',
        // Sexual_Wellness: 'None',
        const res = response?.data?.data?.label?.[0];
        setValue(
          'Sexual_Wellness',
          capitalizeWords(res?.sexualWellness) || 'None',
        );
        setValue('Tobacco', capitalizeWords(res?.tobacco) || 'No');
        setValue('Drinks_Alcohol', capitalizeWords(res?.drinksAlcohol) || 'No');
        setValue('Exercise', capitalizeWords(res?.exercise) || 'No Exercise');
        setValue('Drug_Type', capitalizeWords(res?.type) || '');
        setValue('Exercise_Description', res?.exercise_description || '');
        setValue('Drugs', res?.drugs === 'yes' ? 'Yes' : 'No');
        setValue('Drug_Description', res?.drugs_description || '');
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    patient_Id && getPastSocialHistory();
  }, [patient_Id]);

  const PersonalRecursiveHistoryForm = ({
    data,
    control,
    errors,
  }: {
    data: any;
    control: any;
    errors: any;
  }) => {
    return (
      <>
        {Object.keys(data).map((key: any) => (
          <ul className="nav social-history-nav" key={key}>
            <li>{key.replace('_', ' ')}</li>
            <li>
              <Controller
                name={key}
                control={control}
                render={({ field }) => (
                  <div className="custom-radio patient-radio-btn">
                    {data[key].map((option: any) => (
                      <label key={option} className="custom_radio">
                        <input
                          type="radio"
                          value={option}
                          {...field}
                          onChange={() => {
                            field.onChange(option);
                            trigger(key);
                          }}
                          checked={field.value === option}
                        />
                        <span className="checkmark" />
                        <b>{option}</b>
                      </label>
                    ))}
                  </div>
                )}
              />
              <ErrorMessage
                value={errors[key] && <p>{errors[key].message}</p>}
              />
              {key === 'Drugs' && drugs === 'Yes' && (
                <>
                  <div className="custom-radio patient-radio-btn patient-sub-radio-btn">
                    <Controller
                      name="Drug_Type"
                      control={control}
                      render={({ field }) => (
                        <>
                          {Drug_Type.map((type) => {
                            return (
                              <label key={type} className="custom_radio">
                                <input
                                  type="radio"
                                  value={type}
                                  {...field}
                                  onChange={() => {
                                    field.onChange(type);
                                    trigger('Drug_Type');
                                  }}
                                  checked={field.value === type}
                                />
                                <span className="checkmark" />
                                <b>{type}</b>
                              </label>
                            );
                          })}
                        </>
                      )}
                    />
                    <ErrorMessage value={errors.Drug_Type} />
                  </div>
                </>
              )}
            </li>
            {key === 'Drugs' && drugs === 'Yes' && (
              <li className="sub-history">
                <ul className="nav social-history-nav">
                  <li>
                    <span>Description</span>
                  </li>
                  <li>
                    <Controller
                      name="Drug_Description"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            type="text"
                            className={`form-control ${errors.Drug_Description ? 'is-invalid' : ''}`}
                            placeholder="Enter description"
                            {...field}
                          />
                          <ErrorMessage value={errors.Drug_Description} />
                        </>
                      )}
                    />
                  </li>
                </ul>
              </li>
            )}

            {key === 'Exercise' &&
              (exercise === 'Mild Exercise' ||
                exercise === 'Vigorous Exercise') && (
                <li className="sub-history">
                  <ul className="nav social-history-nav">
                    <li>
                      <span>What Kind of Exercise?</span>
                    </li>
                    <li>
                      <Controller
                        name="Exercise_Description"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              type="text"
                              className={`form-control ${errors.Exercise_Description ? 'is-invalid' : ''}`}
                              placeholder="Enter description"
                              {...field}
                            />
                            <ErrorMessage value={errors.Exercise_Description} />
                          </>
                        )}
                      />
                    </li>
                  </ul>
                </li>
              )}
          </ul>
        ))}
      </>
    );
  };

  return (
    <div
      className="tab-pane fade"
      id="social_history"
      role="tabpanel"
      aria-labelledby="social-history"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="card medical-history-card">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card-body">
                <div className="past-surgeries past-medical-info">
                  <ul className="nav">
                    <li>
                      <h6>Personal Social History</h6>
                    </li>
                  </ul>
                </div>
                <div className="past-surgeries past-surgeries-left">
                  <div className="hospitalization-info">
                    <PersonalRecursiveHistoryForm
                      data={socialHistory}
                      control={control}
                      errors={errors}
                    />
                  </div>
                </div>
                <div className="past-medical-btn">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PastSocialHistory;
