import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../../../utils/routes';
import PrimeDataTable from '../../../../components/dataTable';
import { ApiServiceContext } from '../../../../utils/shared.module';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { end_points } from '../../../../services/end_point/end_points';
import ListHeader from '../../../../components/listHeader';
import { capitalizeFirstLetter, viewTypes } from '../../../../utils/constant';
import { userRole } from '../../../../assets/global';
import { setEditData } from '../../../../core/redux/commonSlice';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';

const KioskList = () => {
  const { getData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);

  const [listData, setListData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterType, setFilterType] = useState<string>('All');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(10);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const columns = [
    {
      header: '#ID',
      field: 'reg_id',
      key: 'reg_id',
      sortField: 'reg_id',
      body: (rowData: any) => (
        <a onClick={() => handleEdit(rowData, 'edit')}>{rowData?.reg_id}</a>
      ),
    },
    {
      header: 'Kiosk Name',
      field: 'name',
      key: 'name',
      sortField: 'name',
    },
    {
      header: 'Kiosk Code',
      field: 'code',
      key: 'code',
      sortField: 'code',
    },
    {
      header: 'Address',
      field: 'address',
      key: 'address',
      sortField: 'address',
      body: (rowData: any) => truncateAddress(rowData.address),
    },
    {
      header: 'Status',
      field: 'status',
      key: 'status',
      sortField: 'status',
      body: (data: any) => {
        const status: string = capitalizeFirstLetter(data?.status);
        return (
          <div className="status-data">
            {status === 'Active' && (
              <p>
                <span className="status-active"></span>
                {status}
              </p>
            )}
            {status === 'Disabled' && (
              <p>
                <span className="status-disabled"></span>
                {status}
              </p>
            )}
            {status === 'Inactive' && (
              <p>
                <span className="status-inactive"></span>
                In Active
              </p>
            )}
          </div>
        );
      },
    },
    {
      header: 'Action',
      sortable: false,
      body: (rowData: any) => (
        <div className="data-slot">
          <Tooltip title="View users" color="grey">
            <a href="#" onClick={() => handleEdit(rowData, 'view')}>
              <i className="feather icon-users"></i>
            </a>
          </Tooltip>
          <Tooltip title="Edit" color="grey">
            <a href="#" onClick={() => handleEdit(rowData, 'edit')}>
              <i className="feather icon-edit"></i>
            </a>
          </Tooltip>
        </div>
      ),
    },
  ];
  const truncateAddress = (address: any) => {
    if (address.length > 20) {
      return address.substring(0, 20) + '...';
    }
    return address;
  };

  const handleEdit = (data: any, type: any) => {
    dispatch(setEditData(data));
    if (type === 'edit') {
      navigate(routes.kioskEdit.path);
    } else if (type === 'add') {
      navigate(routes.kioskCreate.path);
    } else {
      navigate(routes.kioskUserList.path);
    }
  };

  const getkioskList = async (page: number = 1, limit: number = 10) => {
    try {
      showLoader();
      let url = end_points.kioskListApi.url;
      if (page && limit) {
        url += `?page=${page}&limit=${limit}`;
      }
      if (filterType) {
        url += `&view=${filterType?.toLocaleLowerCase()}`;
      }
      if (searchQuery) {
        url += `&searchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.data);
        setTotalRecords(response?.data?.total_records);
        dispatch(setEditData(undefined));
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const handleSearch = (search: any = '') => {
    setSearchQuery(search);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (searchQuery || filterType || currentPage) {
      getkioskList(currentPage, rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, filterType, currentPage]);
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Page Header */}
          <ListHeader
            title={'KIOSK LIST'}
            viewTypes={viewTypes}
            filterType={filterType}
            setFilterType={setFilterType}
            setCurrentPage={setCurrentPage}
            handleSearch={handleSearch}
            createRoute={routes.kioskCreate.path}
            type={userRole.Kiosk}
          />
          {/* /Page Header */}
          {/* Kiosk Table */}

          <PrimeDataTable
            column={columns}
            data={listData}
            rows={rows}
            setRows={setRows}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalRecords={totalRecords}
            rowClassName={'highlight'}
            type={userRole.Kiosk}
          />

          {/* /Kiosk Table */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default KioskList;
