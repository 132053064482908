import React, { useEffect, useRef } from 'react';
import { logoutIcon } from '../../../utils/imagepath';
import { routes } from '../../../utils/routes';
import { useLocation, useNavigate } from 'react-router-dom';

const Logout = () => {
  const pageWrapperRef = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const module = queryParams.get('module') || 'admin';

  const handleLoginAgain = () => {
    navigate(`/${module}/login`);
  };

  const updateHeight = () => {
    if (pageWrapperRef.current) {
      const height = window.innerHeight;
      pageWrapperRef.current.style.minHeight = `${height}px`;
    }
  };

  useEffect(() => {
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper page-wrapper-info" ref={pageWrapperRef}>
        <div className="content container-fluid pb-0">
          {/* Logout */}
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12">
              <div className="card success-card">
                <div className="card-body">
                  <div className="success-info">
                    <img src={logoutIcon} alt="Logout" />
                    <p>You have logged out successfully.</p>
                    <span>
                      <a href="#" onClick={handleLoginAgain}>
                        Click here
                      </a>{' '}
                      to log in back.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Logout */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default Logout;
