import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../utils/routes';
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from '../../../../utils/shared.module';
import {
  email,
  emailMaxLength,
  nameMaxLength,
  onlyAlphabet,
  validatePhoneNumber,
  validMessage,
} from '../../../../utils/patterns/regex.pattern';
import { Controller, useForm } from 'react-hook-form';
import PhoneInputWithRef from '../../../../utils/phoneInputWithRef';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { end_points } from '../../../../services/end_point/end_points';
import { editType, userRole } from '../../../../assets/global';
import { createIconFrontdesk } from '../../../../utils/imagepath';

const FrontDeskCreate = () => {
  const fdCreateValidation = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required('Name is required')
      .matches(onlyAlphabet, validMessage.onlyAlphabet)
      .max(nameMaxLength, `Name cannot exceed ${nameMaxLength} characters`),
    email: yup
      .string()
      .trim()
      .required('Email is required')
      .matches(email, validMessage.email)
      .max(emailMaxLength, `Email cannot exceed ${emailMaxLength} characters`),
    ph_no: yup
      .string()
      .trim()
      .required('Mobile number is required')
      .test('is-valid-phone', 'Invalid phone number', (value) =>
        validatePhoneNumber(value),
      ),
  });

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors, isSubmitted, isValid },
  } = useForm({
    resolver: yupResolver(fdCreateValidation),
    defaultValues: {
      name: '',
      email: '',
      ph_no: '',
    },
  });
  const navigate = useNavigate();
  const formInvalid = isSubmitted && !isValid;
  const { postData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);

  const onSubmit = async (data: any) => {
    try {
      showLoader();
      let url = end_points.adminPagesCreate.url;
      url += `/front-desk`;
      const payload = {
        fullname: data?.name,
        email: data?.email,
        mobile: `+${data?.ph_no}`,
      };

      const response = await postData(url, payload);
      if (response?.status === 200) {
        navigate(routes.frontDeskList.path);
      }
    } catch (error: any) {
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Create Front Desk */}
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="card create-card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="create-header">
                        <h4>Create Front Desk</h4>
                        <img src={createIconFrontdesk} alt="Icon"></img>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="create-form">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="form-wrap">
                            <Controller
                              name="name"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <input
                                    {...field}
                                    type="text"
                                    placeholder="Name"
                                    className={`form-control ${
                                      errors.name ? 'is-invalid' : ''
                                    }`}
                                    onBlur={() => trigger('name')}
                                    onChange={(event: any) => {
                                      field.onChange(event);
                                      trigger('name');
                                    }}
                                    onKeyDown={(event) => {
                                      const regex = onlyAlphabet;
                                      if (
                                        !regex.test(event.key) &&
                                        event.key !== 'Backspace'
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                    maxLength={nameMaxLength}
                                  />
                                  {errors.name && (
                                    <small className="text-danger d-inline-flex mt-2">
                                      {errors.name.message}
                                    </small>
                                  )}
                                </>
                              )}
                            />
                          </div>
                          <div className="form-wrap">
                            <Controller
                              name="email"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <input
                                    {...field}
                                    type="text"
                                    placeholder="Email"
                                    className={`form-control ${
                                      errors.email ? 'is-invalid' : ''
                                    }`}
                                    maxLength={emailMaxLength}
                                    onBlur={() => trigger('email')}
                                    onChange={(event: any) => {
                                      field.onChange(event);
                                      trigger('email');
                                    }}
                                  />
                                  {errors.email && (
                                    <small className="text-danger">
                                      {errors.email.message}
                                    </small>
                                  )}
                                </>
                              )}
                            />
                          </div>
                          <div
                            className={`form-wrap ${formInvalid ? 'is-invalid' : ''}`}
                          >
                            <Controller
                              name="ph_no"
                              control={control}
                              render={({ field, fieldState }) => (
                                <div>
                                  <PhoneInputWithRef
                                    {...field}
                                    placeholder="Enter phone number"
                                    country={'in'}
                                    value={field.value}
                                    onBlur={() => trigger('ph_no')}
                                    onChange={(event: any) => {
                                      field.onChange(event);
                                      trigger('ph_no');
                                    }}
                                    inputProps={{
                                      name: 'ph_no',
                                      required: true,
                                      // autoFocus: true,
                                      className: `form-control ${fieldState.invalid ? 'is-invalid' : ''}`,
                                    }}
                                  />
                                  {fieldState.invalid && (
                                    <small className="text-danger mt-2">
                                      {errors.ph_no?.message}
                                    </small>
                                  )}
                                </div>
                              )}
                            />
                          </div>
                          <div className="form-wrap-btn">
                            <button type="submit" className="btn btn-secondary">
                              Submit
                            </button>
                            <button
                              type="submit"
                              className="btn btn-light"
                              onClick={() => {
                                navigate(routes.frontDeskList.path);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Create Front Desk */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default FrontDeskCreate;
