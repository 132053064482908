import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import FeatureLayout from './pages/feature/feature.layout';
import Login from './pages/authentication/login';
import { routes } from './utils/routes';
import { authRoutes } from './utils/auth-path';
import Otp from './pages/authentication/otp';
import Register from './pages/authentication/register';
import ForgotPassword from './pages/authentication/forgot-password';
import LoginOtp from './pages/authentication/login-otp';
import { favicon } from './utils/imagepath';
import ForgotPasswordOtp from './pages/authentication/forgot-password-otp';
import ConfirmPassword from './pages/authentication/confirm-password';
import UnAuthRoute from './hooks/UnAuthRoute';
import Authenticate from './hooks/AuntenticateRoute';
import Welcome from './pages/common/welcome';
import Logout from './pages/authentication/logout';
import { end_points } from './services/end_point/end_points';
import { ApiServiceContext } from './utils/shared.module';
import { SpinnerContext } from './components/spinner/spinner';
import { useDispatch } from 'react-redux';
import { setTenantId } from './core/redux/loginSlice';
import store from './core/redux/store';
import { userRole } from './assets/global';
import IncomingCallNotification from './pages/feature/allModules/doctor/videoCall/incomingCallNotification';

const unAuth = [
  {
    id: 1,
    path: '/',
    element: <Navigate to={routes.welcome.path} />,
  },
  {
    id: 2,
    path: routes.login.path,
    element: <Login />,
  },
  {
    id: 3,
    path: routes.confirmPassword.path,
    element: <ConfirmPassword />,
  },
  {
    id: 4,
    path: routes.otp.path,
    element: <Otp />,
  },
  {
    id: 5,
    path: routes.register.path,
    element: <Register />,
  },
  {
    id: 5,
    path: routes.forgotPassword.path,
    element: <ForgotPassword />,
  },
  {
    id: 6,
    path: routes.loginOtp.path,
    element: <LoginOtp />,
  },
  {
    id: 7,
    path: routes.forgotPasswordOtp.path,
    element: <ForgotPasswordOtp />,
  },
  {
    id: 8,
    path: routes.welcome.path,
    element: <Welcome />,
  },
  {
    id: 9,
    path: routes.logout.path,
    element: <Logout />,
  },
  // front-desk routes
  {
    id: 10,
    path: routes.frontDesklogin.path,
    element: <Login />,
  },
  {
    id: 11,
    path: routes.frontDeskconfirmPassword.path,
    element: <ConfirmPassword />,
  },
  {
    id: 12,
    path: routes.frontDeskotp.path,
    element: <Otp />,
  },
  {
    id: 13,
    path: routes.frontDeskforgotPassword.path,
    element: <ForgotPassword />,
  },
  {
    id: 14,
    path: routes.frontDeskloginOtp.path,
    element: <LoginOtp />,
  },
  {
    id: 15,
    path: routes.frontDeskforgotPasswordOtp.path,
    element: <ForgotPasswordOtp />,
  },
  {
    id: 16,
    path: routes.frontDeskWelcome.path,
    element: <Welcome />,
  },
  // doctor routes
  {
    id: 10,
    path: routes.doctorLogin.path,
    element: <Login />,
  },
  {
    id: 11,
    path: routes.doctorconfirmPassword.path,
    element: <ConfirmPassword />,
  },
  {
    id: 12,
    path: routes.doctorotp.path,
    element: <Otp />,
  },
  {
    id: 13,
    path: routes.doctorforgotPassword.path,
    element: <ForgotPassword />,
  },
  {
    id: 14,
    path: routes.doctorloginOtp.path,
    element: <LoginOtp />,
  },
  {
    id: 15,
    path: routes.doctorforgotPasswordOtp.path,
    element: <ForgotPasswordOtp />,
  },
  {
    id: 16,
    path: routes.doctorWelcome.path,
    element: <Welcome />,
  },
];

const auth = authRoutes;
const AppRouter: React.FC = () => {
  const { postData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const dispatch = useDispatch();
  const Token = store?.getState()?.login?.token;

  const path = window.location.hostname;
  const findPath = window.location.pathname;
  const host = window.location.host;
  const isAdmin = findPath.split('/')[1];
  const isLogin = findPath.split('/')[2];

  if (path === 'localhost') {
    var domainName = 'apollo.cc-frontend.dreamstechnologies.com';
  } else {
    var domainName = host;
    // var domainName = 'dreams.cc-frontend.dreamstechnologies.com';
  }

  const getTenant = async () => {
    try {
      showLoader();
      const url = end_points.getTenant.url;
      const payload = {
        domainName: domainName,
      };

      const response = await postData(url, payload);
      if (response?.status === 200) {
        const tenant = response.data.data.tenant_id;
        dispatch(setTenantId(tenant));
      } else {
        dispatch(setTenantId(null));
      }
    } catch (error: any) {
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (
      (isAdmin === userRole.FrontDesk || isAdmin === userRole.Doctor) &&
      domainName.endsWith('.cc-frontend.dreamstechnologies.com') &&
      isLogin !== 'login'
    ) {
      setTimeout(() => {
        isAdmin && getTenant();
      }, 0);
    }
    const faviconLink: any = document.getElementById(
      'favicon',
    ) as HTMLLinkElement;
    if (faviconLink) {
      faviconLink.href = favicon;
    } else {
      console.error('Favicon link element not found');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainName]);
  return (
    <>
      <Routes>
        <Route path="/" element={<FeatureLayout />}>
          {unAuth?.map((item) => (
            <Route
              key={item?.id}
              path={item?.path}
              element={<UnAuthRoute>{item?.element}</UnAuthRoute>}
            />
          ))}

          {auth?.map((item) => (
            <Route
              key={item?.id}
              path={item?.path}
              element={<Authenticate>{item?.element}</Authenticate>}
              // element={item?.element}
            />
          ))}
          <Route
            path="*"
            element={
              Token ? (
                <>
                  {isAdmin === userRole.FrontDesk && (
                    <Navigate to="/front-desk/patient-list" />
                  )}
                  {isAdmin === userRole.Doctor && (
                    <Navigate to="/doctor/dashboard" />
                  )}
                  {isAdmin === userRole.Admin && (
                    <Navigate to="/admin/dashboard" />
                  )}
                </>
              ) : (
                <>
                  {isAdmin === userRole.FrontDesk && (
                    <Navigate to="/front-desk/welcome" />
                  )}
                  {isAdmin === userRole.Doctor && (
                    <Navigate to="/doctor/welcome" />
                  )}
                  {isAdmin === userRole.Admin && <Navigate to="/" />}
                </>
              )
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default AppRouter;
