// Receiver.tsx
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import OT from '@opentok/client';

const Receiver = () => {
  const sessionDetail = useSelector(
    (state: any) => state?.common?.sessionDetails,
  );
  const subscriberRef = useRef<any>(null);
  const publisherRef = useRef<any>(null);
  const [otSession, setOtSession] = useState<any>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null,
  );
  const [recordingChunks, setRecordingChunks] = useState<Blob[]>([]);

  useEffect(() => {
    if (OT && sessionDetail?.session_id && sessionDetail?.vonage_token) {
      const otSession = OT.initSession('47894791', sessionDetail?.session_id);
      setOtSession(otSession);
      otSession.on('streamCreated', (event: any) => {
        console.log('Stream created:', event.stream);
        // Subscribe to the publisher's stream
        if (!publisherRef.current) {
          const publisher = otSession.subscribe(
            event.stream,
            'publisher', // Ensure this matches the ID in the return below
            {
              insertMode: 'append',
              width: '50%',
              height: '50%',
            },
            (error: any) => {
              if (error) {
                console.error('Error subscribing to stream:', error);
              } else {
                console.log('Subscribed to stream:', event.stream);
                publisherRef.current = publisher;
                startRecording(event.stream);
              }
            },
          );
        }
      });

      otSession.on('streamDestroyed', (event: any) => {
        console.log('Stream destroyed:', event.stream);
      });

      // Initialize and attach the subscriber
      const otSubscriber = OT.initPublisher('subscriber', {
        insertMode: 'append',
        width: '100%',
        height: '100%',
      });
      subscriberRef.current = otSubscriber;

      otSession.connect(sessionDetail?.vonage_token, (error: any) => {
        if (error) {
          console.error('Failed to connect to session:', error);
        }
      });

      return () => {
        if (otSession) {
          console.log('ending 1111');
          otSession.disconnect();
          if (mediaRecorder) {
            console.log('ending 11222');
            mediaRecorder.stop(); // Stop recording when the component unmounts
            sendVideoChunks(); // Send the recorded chunks to the API
          }
        }
      };
    }
  }, [sessionDetail, mediaRecorder]);

  const startRecording = (stream: any) => {
    console.log('startRecording');
    const recorder = new MediaRecorder(stream);
    console.log('recorder', recorder);
    setMediaRecorder(recorder);

    recorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        setRecordingChunks((prevChunks: any) => [...prevChunks, event.data]);
      }
    };

    recorder.start();
  };

  const endCall = () => {
    if (otSession) {
      otSession.disconnect(); // End the call by disconnecting the session
      console.log('Call ended');
      console.log('mediaRecorder', mediaRecorder);
      if (mediaRecorder) {
        mediaRecorder.stop(); // Stop recording
        sendVideoChunks(); // Send the recorded chunks to the API
      }
    }
  };

  const sendVideoChunks = async () => {
    console.log('videoRecord');
    const videoBlob = new Blob(recordingChunks, { type: 'video/webm' });
    const videoFile = new FormData();
    videoFile.append('file', videoBlob, 'call-recording.webm');
    console.log('videoFile', videoFile);
    // try {
    //   const response = await fetch('YOUR_API_ENDPOINT', {
    //     method: 'POST',
    //     body: videoFile,
    //   });
    //   if (response.ok) {
    //     console.log('Video successfully sent to the server');
    //   } else {
    //     console.error('Failed to send video to the server');
    //   }
    // } catch (error) {
    //   console.error('Error sending video to the server:', error);
    // }
  };

  const takeScreenshot = () => {
    const videoElement = document.querySelector(
      '#publisher video',
    ) as HTMLVideoElement;
    if (videoElement) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      context?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      const screenshot = canvas.toDataURL('image/png');

      // Create a download link and trigger it
      const link = document.createElement('a');
      link.href = screenshot;
      link.download = 'screenshot.png';
      link.click();
    } else {
      console.error('Video element not found.');
    }
  };

  return (
    <div className="video-container">
      <div id="subscriber" className="video-item">
        {/* Placeholder for subscriber streams */}
      </div>
      <div id="publisher" className="video-item" />
      <button
        onClick={endCall}
        style={{ position: 'absolute', bottom: '50px', right: '150px' }}
      >
        End Call
      </button>
      <button
        onClick={takeScreenshot}
        style={{ position: 'absolute', bottom: '10px', right: '10px' }}
      >
        Take Screenshot
      </button>
    </div>
  );
};

export default Receiver;
