import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PrimeDataTable from '../../../../components/dataTable';
import DoctorCreateSlotModel from '../../../../components/doctorCreateModel.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { end_points } from '../../../../services/end_point/end_points';
import { ApiServiceContext } from '../../../../utils/shared.module';
import { convertDate, formatTime } from '../../../../utils/constant';
import { setDoctorSlotEditData } from '../../../../core/redux/commonSlice';
import { Tooltip } from 'antd';

const DoctorScheduledSlots = () => {
  const [listData, setListData] = useState<any>([]);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { getData, deleteData } = useContext(ApiServiceContext);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(10);
  const [deleteSlot, setDeleteSlot] = useState<any>('');
  const closeModel: any = useRef(null);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const editData: any = useSelector((state: any) => state?.common?.editData);
  const dispatch = useDispatch();
  const closeRef = useRef<any>(null);
  const [slotModel, setSlotModel] = useState<boolean>(false);
  const location = useLocation();
  const path = location?.pathname;
  const findPath = path.split('/')[1];

  const columns = [
    {
      header: 'Serial No.',
      field: 'rowIndex',
      key: 'rowIndex',
      sortField: 'rowIndex',
      body: (rowData: any, { rowIndex }: { rowIndex: number }) =>
        (rowIndex + 1).toString().padStart(2, '0'),
    },
    {
      header: 'Start Date	',
      field: 'Slot_start_date',
      key: 'Slot_start_date',
      sortField: 'Slot_start_date',
      body: (rowData: any) => convertDate(rowData.Slot_start_date),
    },
    {
      header: 'End Date',
      field: 'Slot_end_date',
      key: 'Slot_end_date',
      sortField: 'Slot_end_date',
      body: (rowData: any) => convertDate(rowData.Slot_end_date),
    },
    {
      header: 'Days',
      field: 'days',
      key: 'days',
      sortField: 'days',
      body: (data: any) => (
        <div className="days-data">
          <ul className="nav">
            <li>{data?.days?.mon === true ? <span>{'Mo'}</span> : 'Mo'}</li>
            <li>{data?.days?.tue === true ? <span>{'Tu'}</span> : 'Tu'}</li>
            <li>{data?.days?.wed === true ? <span>{'We'}</span> : 'We'}</li>
            <li>{data?.days?.thu === true ? <span>{'Th'}</span> : 'Th'}</li>
            <li>{data?.days?.fri === true ? <span>{'Fr'}</span> : 'Fr'}</li>
            <li>{data?.days?.sat === true ? <span>{'Sa'}</span> : 'Sa'}</li>
            <li>{data?.days?.sun === true ? <span>{'Su'}</span> : 'Su'}</li>
          </ul>
        </div>
      ),
    },
    {
      header: 'Timings',
      key: 'timings',
      field: 'timings',
      sortField: 'endDate',
      body: (rowData: any) =>
        `${formatTime(rowData.begin_time)} - ${formatTime(rowData.end_time)}`,
    },
    {
      header: 'Action',
      sortable: false,
      body: (data: any) => (
        <div className="data-slot">
          <Tooltip title="Edit" color="grey">
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#create_slot"
              onClick={() => {
                setSlotModel(true);
                dispatch(setDoctorSlotEditData(data));
              }}
            >
              <i className="feather icon-edit" />
            </Link>
          </Tooltip>
          <Tooltip title="Delete" color="grey">
            <Link
              to="#"
              onClick={() => setDeleteSlot(data)}
              className="slot-delete"
              data-bs-toggle="modal"
              data-bs-target="#delete_slot"
            >
              <i className="feather icon-trash" />
            </Link>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getAppointmentDetails = async (
    page: number = 1,
    limit: number = 10,
  ) => {
    try {
      showLoader();
      let url = end_points.frontDeskDoctorSlotList.url;
      if (editData?._id) {
        url += `/${editData?._id}`;
      } else {
        url += `/:doctorId`;
      }
      if (page && limit) {
        url += `?page=${page}&limit=${limit}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response.data.data);
        setTotalRecords(response?.data?.total_records);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const deleteUser = async () => {
    let urls = end_points.doctorTimeSlotDelete.url;
    const id = deleteSlot?.id;
    urls += `/${id}`;
    const response = await deleteData(urls);
    if (response?.status == 200) {
      closeModel.current.click();
      setDeleteSlot('');
      getAppointmentDetails();
    } else {
      setError(true);
      setErrorText(response.data.errors[0].msg);
    }
  };

  useEffect(() => {
    getAppointmentDetails();
    dispatch(setDoctorSlotEditData(undefined));
  }, [editData]);

  return (
    <>
      {/* Page Wrapper */}
      <div className={findPath != 'doctor' ? 'page-wrapper' : ''}>
        <div
          className={findPath != 'doctor' ? 'content container-fluid pb-0' : ''}
        >
          {findPath === 'doctor' && (
            <div className="doctor-header">
              <h4>Scheduled Slots</h4>
              <ul className="nav">
                <li>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#create_slot"
                    onFocus={() => {
                      if (closeRef.current && slotModel) {
                        closeRef.current.click();
                      }
                    }}
                    onClick={() => {
                      setSlotModel(true);
                      dispatch(
                        setDoctorSlotEditData(JSON.parse(JSON.stringify({}))),
                      );
                    }}
                  >
                    Create
                  </Link>
                </li>
              </ul>
            </div>
          )}
          {/* Page Header */}
          {findPath != 'doctor' && (
            <div className="page-header">
              <div className="page-header-title">
                <h6 className="page-sub-title">{editData?.fullname}</h6>
                <h4 className="page-title">Scheduled Slots</h4>
              </div>
              <div className="page-list">
                <ul className="nav">
                  <li>
                    <div className="page-header-btn">
                      <Link
                        to="#"
                        className="btn btn-secondary"
                        data-bs-toggle="modal"
                        data-bs-target="#create_slot"
                        onFocus={() => {
                          if (closeRef.current && slotModel) {
                            closeRef.current.click();
                          }
                        }}
                        onClick={() => {
                          setSlotModel(true);
                          dispatch(
                            setDoctorSlotEditData(
                              JSON.parse(JSON.stringify({})),
                            ),
                          );
                        }}
                      >
                        Create
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {/* /Page Header */}
          {/* Doctors Table */}
          <PrimeDataTable
            column={columns}
            data={listData}
            rows={rows}
            setRows={setRows}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onRowClickSetState={false}
            totalRecords={totalRecords}
            rowClassName={'highlight'}
          />
          {/* /Doctors Table */}
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* Create Slot */}
      <DoctorCreateSlotModel
        getAppointmentDetails={getAppointmentDetails}
        slotModel={slotModel}
        closeRef={closeRef}
        setSlotModel={setSlotModel}
      />
      {/* /Create Slot */}
      {/* Delete Slot */}
      <div
        className="modal custom-modal fade"
        id="delete_slot"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="medical-modal-header pb-0">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                ref={closeModel}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body drug-modal-body pt-2">
              <div className="modal-form">
                <div className="form-group modal-text">
                  <h5>Delete Slot?</h5>
                  <p>Are you sure you want to delete the scheduled slot?</p>
                </div>
                {error && <p className="text-danger">{errorText}</p>}
                <div className="modal-form-btn text-center mt-4">
                  <button className="btn btn-primary" onClick={deleteUser}>
                    Delete
                  </button>
                  <button className="btn btn-danger" data-bs-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Delete Slot */}
    </>
  );
};

export default DoctorScheduledSlots;
