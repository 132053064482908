// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f0f0f0;
  position: relative;
}

#call-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  z-index: 1000;
}

#call-popup button {
  margin: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

#call-popup button:hover {
  background-color: #0056b3;
}

#publisher,
#subscriber {
  width: 45%; 
  height: 45%; 
  background: black;
  border: 1px solid #ddd;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  margin: 10px;
}

#publisher video,
#subscriber video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

button {
  margin: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

#screenshot-preview {
  margin-top: 20px;
  max-width: 90vw; 
}  */
`, "",{"version":3,"sources":["webpack://./src/pages/feature/allModules/doctor/videoCall/app.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;IAgFI","sourcesContent":["/* #video-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 100vh;\r\n  width: 100vw;\r\n  background-color: #f0f0f0;\r\n  position: relative;\r\n}\r\n\r\n#call-popup {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  background-color: white;\r\n  border: 2px solid #ddd;\r\n  border-radius: 8px;\r\n  padding: 20px;\r\n  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);\r\n  z-index: 1000;\r\n}\r\n\r\n#call-popup button {\r\n  margin: 5px;\r\n  padding: 10px 20px;\r\n  border: none;\r\n  border-radius: 5px;\r\n  background-color: #007bff;\r\n  color: white;\r\n  font-size: 16px;\r\n  cursor: pointer;\r\n}\r\n\r\n#call-popup button:hover {\r\n  background-color: #0056b3;\r\n}\r\n\r\n#publisher,\r\n#subscriber {\r\n  width: 45%; \r\n  height: 45%; \r\n  background: black;\r\n  border: 1px solid #ddd;\r\n  border-radius: 8px;\r\n  position: relative;\r\n  overflow: hidden;\r\n  margin: 10px;\r\n}\r\n\r\n#publisher video,\r\n#subscriber video {\r\n  width: 100%;\r\n  height: 100%;\r\n  object-fit: cover;\r\n}\r\n\r\nbutton {\r\n  margin: 5px;\r\n  padding: 10px 20px;\r\n  border: none;\r\n  border-radius: 5px;\r\n  background-color: #007bff;\r\n  color: white;\r\n  font-size: 16px;\r\n  cursor: pointer;\r\n}\r\n\r\nbutton:hover {\r\n  background-color: #0056b3;\r\n}\r\n\r\n#screenshot-preview {\r\n  margin-top: 20px;\r\n  max-width: 90vw; \r\n}  */\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
