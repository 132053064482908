import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from '../../../../../../utils/shared.module';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import { end_points } from '../../../../../../services/end_point/end_points';
import { Tab } from 'bootstrap';
import { setSymtomsFlowData } from '../../../../../../core/redux/commonSlice';

const TeleExaminationValidation = yup.object().shape({
  teleExamination: yup.string(),
});

const TeleExaminationCall = () => {
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { getData, postData } = useContext(ApiServiceContext);
  const editData: any = useSelector((state: any) => state?.common?.editData);
  const dispatch = useDispatch();
  const savedData: any = useSelector((state: any) => state?.common?.flowData);
  const {
    handleSubmit,
    control,
    trigger,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(TeleExaminationValidation),
    defaultValues: {
      teleExamination: '',
    },
  });

  const onSubmit = async (data: any) => {
    try {
      showLoader();
      let url = end_points.onCallTeleExaminationTabAdd.url;
      url += `/${editData?.patient_id}`;
      const payload = {
        tele_examination: [
          {
            description: data.teleExamination,
          },
        ],
      };
      const response = await postData(url, payload);
      if (response.status === 200) {
        const newData = { ...savedData, tele: payload };
        dispatch(setSymtomsFlowData(newData));
        const nextTab = document.querySelector(
          '#investigation-tab',
        ) as HTMLElement;
        const nextTabLink = new Tab(nextTab);
        nextTabLink.show();
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    const data = savedData?.tele?.tele_examination?.[0] || null;
    if (data) {
      setValue('teleExamination', data.description);
    }
  }, [savedData?.tele]);
  return (
    <div
      className="tab-pane fade"
      id="tele_examination"
      role="tabpanel"
      aria-labelledby="tele-examination"
    >
      <div className="row">
        <form id="teleForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-12">
            <div className="symptoms-card">
              <h4>Tele Examination</h4>
              <div className="symptoms-search">
                <div className="symptoms-input">
                  <Controller
                    name="teleExamination"
                    control={control}
                    render={({ field }) => (
                      <textarea
                        className={`form-control ${
                          errors.teleExamination ? 'is-invalid' : ''
                        }`}
                        onBlur={() => trigger('teleExamination')}
                        value={field.value}
                        onChange={(event: any) => {
                          field.onChange(event);
                          trigger('teleExamination');
                        }}
                        autoComplete="false"
                        placeholder="Please enter the description about tele-communication (if any)"
                        maxLength={100}
                      />
                    )}
                  />
                  <small className="text-danger d-inline-flex mt-2">
                    {errors?.teleExamination?.message}
                  </small>
                </div>
              </div>
              <div className="symptoms-save">
                <button
                  type="submit"
                  className="btn btn-secondary"
                  form="teleForm"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TeleExaminationCall;
