import React from 'react';
import moment from 'moment';
import CircularProgressBar from './circularProgressBar';
import DocumentPopup from './documentPopup';

function PatientAttachments({ details = [] }) {
  const history: any = details;
  const percent = (100 / Number(history.length))?.toFixed(2);
  return (
    <div
      className="tab-pane fade"
      id="attachment_tab"
      role="tabpanel"
      aria-labelledby="attachment-tab"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="card diagram-card">
            <div className="card-body">
              <div className="past-header diagram-header">
                <h4>Attachment</h4>
              </div>
              <div className="attachment-upload">
                <div className="row align-items-center">
                  {/* <div className="col-lg-5 col-md-12">
                    <div className="attachment-upload-info">
                      <label className="file-upload">
                        <img src={uploadIcon} alt="Upload" />
                        <input type="file" />
                        <span>Upload</span>
                      </label>
                      <p>Drag your files here or Browse to upload</p>
                    </div>
                  </div> */}
                  {/* {history?.length > 0 && ( */}
                  <div className="col-lg-12 col-md-12">
                    <div className="attachment-table">
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>
                            {history?.length > 0 ? (
                              history?.map((attach: any) => {
                                return (
                                  <>
                                    <tr>
                                      <td>
                                        <h6>{attach?.title}</h6>
                                        <p>{attach?.description}</p>
                                      </td>
                                      <td>
                                        Updated on-
                                        {moment(attach?.updatedAt).format(
                                          'DD MMM YYYY',
                                        )}
                                        {/* 21 jan 2020 */}
                                      </td>
                                      {attach?.document && (
                                        <td className="iframe-btn">
                                          <a
                                            className="btn btn-primary"
                                            href={
                                              attach?.document
                                              // 'https://dreamscrm.s3.eu-west-1.amazonaws.com/cloud-connect/attachments/1724836323450.pdf?AWSAccessKeyId=AKIATCKAOBVM5SMWHGVA&Expires=1725268323&Signature=TQ32gDZYQ3ecpyfpr%2Fzsc7AKerM%3D'
                                            }
                                            target="blank"
                                          >
                                            View
                                          </a>
                                        </td>
                                      )}

                                      {/* <DocumentPopup url={attach?.document} /> */}
                                      {/* <td>
                                        <div className="circle-bar">
                                          <div
                                            className="circle-graph"
                                            data-percent={percent}
                                          >
                                            <CircularProgressBar
                                              percentage={
                                                percent
                                                  ? parseFloat(percent)
                                                  : 0
                                              }
                                            />
                                          </div>
                                        </div>
                                      </td> */}
                                    </tr>
                                  </>
                                );
                              })
                            ) : (
                              <td>No attachments</td>
                            )}

                            {/* <tr>
                                <td>
                                  <h6>CT Scan Report</h6>
                                  <p>
                                    Description of the attached report will come
                                    here
                                  </p>
                                </td>
                                <td>Updated on-21 jan 2020</td>
                                <td>
                                  <div className="circle-bar">
                                    <div
                                      className="circle-graph"
                                      data-percent={25}
                                    >
                                      <span>25%</span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>CT Scan Report</h6>
                                  <p>
                                    Description of the attached report will come
                                    here
                                  </p>
                                </td>
                                <td>Updated on-21 jan 2020</td>
                                <td>
                                  <div className="circle-bar">
                                    <div
                                      className="circle-graph"
                                      data-percent={25}
                                    >
                                      <span>25%</span>
                                    </div>
                                  </div>
                                </td>
                              </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* )} */}
                  {/* <div className="col-lg-7 col-md-12">
                    <div className="attachment-table">
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>
                            <tr>
                              <td>
                                <h6>CT Scan Report</h6>
                                <p>
                                  Description of the attached report will come
                                  here
                                </p>
                              </td>
                              <td>Updated on-21 jan 2020</td>
                              <td>
                                <div className="circle-bar">
                                  <div
                                    className="circle-graph"
                                    data-percent={25}
                                  >
                                    <span>25%</span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h6>CT Scan Report</h6>
                                <p>
                                  Description of the attached report will come
                                  here
                                </p>
                              </td>
                              <td>Updated on-21 jan 2020</td>
                              <td>
                                <div className="circle-bar">
                                  <div
                                    className="circle-graph"
                                    data-percent={25}
                                  >
                                    <span>25%</span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h6>CT Scan Report</h6>
                                <p>
                                  Description of the attached report will come
                                  here
                                </p>
                              </td>
                              <td>Updated on-21 jan 2020</td>
                              <td>
                                <div className="circle-bar">
                                  <div
                                    className="circle-graph"
                                    data-percent={25}
                                  >
                                    <span>25%</span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientAttachments;
