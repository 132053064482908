import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const AudioPlayer = ({ url = '' }) => {
  const [audio] = useState(new Audio(url));
  const [isPlaying, setIsPlaying] = useState(false);
  const [error, setError] = useState<any>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1); // Volume ranges from 0.0 to 1.0

  const playPause = () => {
    try {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
    } catch (err) {
      console.log(err);
      audio.pause();
      setError('An error occurred while playing the audio.');
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audio.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(audio.duration);
  };

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const volume = parseFloat(event.target.value);
    audio.volume = volume;
    setVolume(volume);
  };
  const seekAudio = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    const linkElement = event.currentTarget;
    const clickX = event.nativeEvent.offsetX;
    const linkWidth = linkElement.offsetWidth;
    const seekTime = (clickX / linkWidth) * duration;
    audio.currentTime = seekTime;
  };
  useEffect(() => {
    const handleAudioError = () => {
      audio.pause();
      setIsPlaying(false);
      setError('Unable to play audio. The format may not be supported.');
    };

    audio.addEventListener('error', handleAudioError);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);

    return () => {
      audio.removeEventListener('error', handleAudioError);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  }, [audio]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
  const playbarProgress = (currentTime / duration) * 100 || 0;
  // style{
  /* .play-bar-container {
          width: 100%;
          height: 5px;
          background-color: #e0e0e0;
          border-radius: 2.5px;
          overflow: hidden;
          margin: 0 10px;
        }

        .play-bar {
          height: 100%;
          background-color: #007bff;
          transition: width 0.1s linear;
        // } */
  return (
    <>
      <div className="vitals-play-grid">
        <ul className="nav">
          <li>
            <Link
              to="#"
              onClick={playPause}
              // disabled={error}
            >
              {isPlaying ? (
                <i className="fas fa-pause"></i>
              ) : (
                <i className="fas fa-play"></i>
              )}
            </Link>
          </li>
          <li>
            <p>{formatTime(currentTime)}</p>
          </li>
          {/* <li className="play-bar-container">
            <span
              onClick={seekAudio}
              style={{ width: `${playbarProgress}%` }}
              className="play-bar"
            ></span>
          </li> */}

          <li>
            <input
              className="play-bar"
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
            />
          </li>
          <li>
            <Link to="#">
              <i className="fas fa-volume-high"></i>
            </Link>
          </li>
          {/* <li>
            <p>{formatTime(duration)}</p>
          </li> */}
        </ul>
      </div>
      {/* <div className="">
        <button type="button" className="btn btn-primary">
          {isPlaying ? 'Pause' : 'Play'}
        </button>
      </div> */}
      <div>{error && <small className="text-danger">{error}</small>} </div>
    </>
  );
};

export default AudioPlayer;
