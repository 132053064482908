import React, { useContext, useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { end_points } from '../../../../services/end_point/end_points';
import { useNavigate } from 'react-router-dom';
import { ApiServiceContext, routes } from '../../../../utils/shared.module';
import { Dropdown } from 'primereact/dropdown';

const FrontDeskCalendar = () => {
  const [events, setEvents] = useState<any>([]);
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [calendarView, setCalendarView] = useState('dayGridMonth');
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { getData } = useContext(ApiServiceContext);
  const editData: any = useSelector((state: any) => state?.common?.editData);
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState(null);
  const calendarRef = useRef<any>(null);
  const data = [
    { name: 'Month', code: 'dayGridMonth' },
    { name: 'Week', code: 'timeGridWeek' },
    { name: 'Day', code: 'timeGridDay' },
  ];
  const handleViewChange = (e: any) => {
    setSelectedData(e.value);
    setCalendarView(e.value.code);
  };

  const getAppointmentDetails = async () => {
    try {
      showLoader();
      let url = end_points.frontDeskDoctorAppoinments.url;
      if (editData?.id) {
        url += `/${editData?.id}`;
      }

      const response = await getData(url);
      if (response.status === 200) {
        const generatedEvents = generateEvents(response.data.data);
        setEvents(generatedEvents);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getAppointmentDetails();
  }, []);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(calendarView);
    }
  }, [calendarView]);

  const generateEvents = (data: any) => {
    const eventList: any = [];
    const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

    data.forEach((slot: any) => {
      const { Slot_start_date, Slot_end_date, days, begin_time, end_time } =
        slot;
      const startDate = moment(Slot_start_date, 'YYYY-MM-DD').startOf('day');
      const endDate = moment(Slot_end_date, 'YYYY-MM-DD').endOf('day');

      for (
        let d = moment(startDate);
        d.isSameOrBefore(endDate);
        d.add(1, 'days')
      ) {
        const dayName = daysOfWeek[d.day()];
        if (days[dayName]) {
          const startTime = moment(
            `${d.format('YYYY-MM-DD')}T${begin_time}`,
          ).format('YYYY-MM-DDTHH:mm:ss');
          const endTime = moment(
            `${d.format('YYYY-MM-DD')}T${end_time}`,
          ).format('YYYY-MM-DDTHH:mm:ss');

          eventList.push({
            title: `${moment(begin_time, 'HH:mm:ss').format('h:mm A')} to ${moment(end_time, 'HH:mm:ss').format('h:mm A')}`,
            start: startTime,
            end: endTime,
            backgroundColor: 'blue',
            borderColor: 'blue',
          });
        }
      }
    });

    return eventList;
  };

  const handleDayCellClassNames = (arg: any) => {
    const date = moment(arg.date).format('YYYY-MM-DD');
    const matchingEvent = events.find(
      (event: any) =>
        moment(event.start).isSame(date, 'day') &&
        event.backgroundColor === 'blue',
    );
    return matchingEvent ? ['highlight-date'] : [];
  };

  const navigateTiList = () => {
    navigate(routes.frontDeskDoctorsList.path);
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header justify-content-center">
            <h4 className="page-title">Dr. {editData?.fullname}</h4>
          </div>
          {/* /Page Header */}
          {/* Front Desk Calendar */}
          <div className="row">
            <div className="col-lg-12">
              <div className="cal-select-main">
                <div className="cal-select">
                  <Dropdown
                    value={selectedData}
                    onChange={handleViewChange}
                    options={data}
                    optionLabel="name"
                    placeholder="Month"
                    className="w-100 "
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="calendar-btn-info">
                <div className="calendar-btn">
                  <button
                    className="btn btn-primary"
                    onClick={() => navigateTiList()}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={calendarView}
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={weekendsVisible}
                events={events}
                eventTimeFormat={{
                  hour: 'numeric',
                  minute: '2-digit',
                  meridiem: 'short',
                }}
                dayCellClassNames={handleDayCellClassNames}
              />
            </div>
          </div>
          {/* /Front Desk Calendar */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default FrontDeskCalendar;
