import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { uploadIcon } from '../../../../../../utils/imagepath';
import { ApiServiceContext } from '../../../../../../utils/shared.module';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import { end_points } from '../../../../../../services/end_point/end_points';
import ErrorMessage from '../../../../../../components/errorMessage';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CircularProgressBar from '../../components/circularProgressBar';
import { addressMaxLength } from '../../../../../../utils/patterns/regex.pattern';
import DocumentPopup from '../../components/documentPopup';

// Define the validation schema
const schema = yup.object().shape({
  file: yup.mixed().required('File is required'),
  description: yup
    .string()
    .trim()
    .required('Description is required')
    .max(100, 'Description cannot be more than 100 characters long'),
});
interface UploadHistoryDocumentsprops {
  onSave: Function;
}
const UploadHistoryDocuments: React.FC<UploadHistoryDocumentsprops> = ({
  onSave = () => {},
}) => {
  const { putData, getData, postData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const patient_Id = useSelector(
    (state: any) => state?.common?.editData?.patient_id,
  );
  const [prevAttchment, setPrevAttachment] = useState<any>(null);
  const [percent, setPercent] = useState<any>(0);
  const [files, setFile] = useState<any>(undefined);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      file: undefined,
      description: '',
    },
  });

  const onSubmit = async (data: any) => {
    try {
      showLoader();
      // {title:example title
      //   description:example description
      //   document: documentur}
      const formData = new FormData();
      formData.append('document', data?.file);
      formData.append('title', data?.file?.name);
      formData.append('description', data?.description);

      let url = end_points.pastAttachmentCreateApi.url;
      if (patient_Id) {
        url += `/${patient_Id}`;
      }
      const response = await postData(url, formData);
      if (response.status === 200) {
        reset();
        setFile(undefined);
        getAttchmentList();
        onSave();
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const getAttchmentList = async () => {
    try {
      showLoader();
      let url = end_points.getAttachmentListApi.url;
      if (patient_Id) {
        url += `/${patient_Id}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setPrevAttachment(response.data?.data);
        setPercent((100 / Number(response.data?.data.length))?.toFixed(2));
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    patient_Id && getAttchmentList();
  }, [patient_Id]);
  return (
    <div
      className="tab-pane fade"
      id="attachment_tab"
      role="tabpanel"
      aria-labelledby="attachment-tab"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-12">
            <div className="card medical-history-card diagram-card">
              <div className="past-surgeries past-medical-info">
                <ul className="nav">
                  <li>
                    <h6>Attachment</h6>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="attachment-upload">
                  <div className="row align-items-center">
                    <div
                      className={`col-md-12 ${!prevAttchment?.length ? 'col-lg-12' : 'col-lg-4'}`}
                      // className="col-lg-4 col-md-12"
                    >
                      <div className="attachment-upload-info">
                        <label className="file-upload">
                          <img src={uploadIcon} alt="Upload" />
                          <Controller
                            name="file"
                            control={control}
                            defaultValue={undefined}
                            render={({ field }) => (
                              <input
                                type="file"
                                onChange={(e) => {
                                  field.onChange(e?.target?.files?.[0]);
                                  setFile(e?.target?.files?.[0]);
                                }}
                              />
                            )}
                          />
                          <span>Upload</span>
                        </label>
                        <p>
                          {files
                            ? files?.name
                            : 'Drag your files here or Browse to upload'}
                        </p>
                        <ErrorMessage value={errors.file} />
                      </div>
                      {files?.name && (
                        <div>
                          <label>Description*</label>
                          <div className="symptoms-input">
                            <Controller
                              name="description"
                              control={control}
                              defaultValue={''}
                              render={({ field }) => (
                                <textarea
                                  className={`form-control ${
                                    errors.description ? 'is-invalid' : ''
                                  }`}
                                  {...field}
                                  autoComplete="false"
                                  placeholder="Please enter description."
                                  maxLength={addressMaxLength}
                                />
                              )}
                            />
                            <ErrorMessage value={errors.description} />
                          </div>
                        </div>
                      )}
                    </div>
                    {prevAttchment?.length > 0 && (
                      <div className="col-lg-8 col-md-12">
                        <div className="attachment-table">
                          <div className="table-responsive">
                            <table className="table">
                              <tbody>
                                {prevAttchment?.map((attach: any) => {
                                  return (
                                    <>
                                      <tr>
                                        <td>
                                          <h6>{attach?.title}</h6>
                                          <p>{attach?.description}</p>
                                        </td>
                                        <td>
                                          Updated on-
                                          {moment(attach?.updatedAt).format(
                                            'DD MMM YYYY',
                                          )}
                                          {/* 21 jan 2020 */}
                                        </td>
                                        <DocumentPopup url={attach?.document} />
                                        {/* <td>
                                          <div className="circle-bar">
                                            <div
                                              className="circle-graph"
                                              data-percent={percent}
                                            >
                                              <CircularProgressBar
                                                percentage={
                                                  percent
                                                    ? parseFloat(percent)
                                                    : 0
                                                }
                                              />
                                            </div>
                                          </div>
                                        </td> */}
                                      </tr>
                                    </>
                                  );
                                })}

                                {/* <tr>
                                <td>
                                  <h6>CT Scan Report</h6>
                                  <p>
                                    Description of the attached report will come
                                    here
                                  </p>
                                </td>
                                <td>Updated on-21 jan 2020</td>
                                <td>
                                  <div className="circle-bar">
                                    <div
                                      className="circle-graph"
                                      data-percent={25}
                                    >
                                      <span>25%</span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>CT Scan Report</h6>
                                  <p>
                                    Description of the attached report will come
                                    here
                                  </p>
                                </td>
                                <td>Updated on-21 jan 2020</td>
                                <td>
                                  <div className="circle-bar">
                                    <div
                                      className="circle-graph"
                                      data-percent={25}
                                    >
                                      <span>25%</span>
                                    </div>
                                  </div>
                                </td>
                              </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="past-medical-btn">
                  {files?.name && (
                    <button className="btn btn-primary" type="submit">
                      Upload
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UploadHistoryDocuments;
