import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { edit01, edit02, edit03 } from '../../../../utils/imagepath';
import { routes } from '../../../../utils/routes';
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from '../../../../utils/shared.module';
import {
  addressMaxLength,
  email,
  emailMaxLength,
  nameMaxLength,
  onlyAlphabet,
  validMessage,
  validatePhoneNumber,
} from '../../../../utils/patterns/regex.pattern';
import { end_points } from '../../../../services/end_point/end_points';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { Controller, useForm } from 'react-hook-form';
import ErrorMessage from '../../../../components/errorMessage';
import PhoneInputWithRef from '../../../../utils/phoneInputWithRef';
import { useDispatch, useSelector } from 'react-redux';
import {
  capitalizeFirstLetter,
  validatePhoneNumberwithPlus,
} from '../../../../utils/constant';
import { editType, userRole } from '../../../../assets/global';
import {
  setAssociateToData,
  setPathFrom,
} from '../../../../core/redux/commonSlice';
import { toast } from 'react-toastify';
import { DropDownPopup } from '../../../../components/dropdownPopup';
import PopOverText from '../../../../components/popOver';
import { Tooltip } from 'antd';

const schema = yup.object().shape({
  kioskName: yup
    .string()
    .trim()
    .required('Kiosk name is required')
    .matches(onlyAlphabet, validMessage.onlyAlphabet)
    .max(nameMaxLength, `Name cannot exceed ${nameMaxLength} characters`),
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .matches(email, validMessage.email)
    .max(emailMaxLength, `Email cannot exceed ${emailMaxLength} characters`),
  phone: yup
    .string()
    .trim()
    .required('Mobile number is required')
    .test('is-valid-phone', 'Invalid phone number', (value) =>
      validatePhoneNumber(value),
    ),
  address: yup
    .string()
    .trim()
    .required('Kiosk address is required')
    .max(
      addressMaxLength,
      `Kiosk address cannot exceed ${addressMaxLength} characters`,
    ),
  kioskStatus: yup.boolean().required('Kiosk status is required'),
});
const KioskEdit = () => {
  const [editKiosk, setEditKiosk] = useState<boolean>(false);
  const [showed, setShowed] = useState<boolean>(false);

  const editDatabyId = useSelector(
    (state: any) => state?.common?.editData?._id,
  );
  const { getData, putData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const [editData, setEditData] = useState<any>({});

  const getKioskDatabyId = async (id: any) => {
    try {
      showLoader();
      let url = end_points.kioskgetByIdApi.url;
      url += `/${id}`;
      const response = await getData(url);
      if (response.status === 200) {
        const editData = response.data?.data;
        setEditData(editData);
        setValue('kioskName', editData?.name);
        setValue('email', editData?.email);
        setValue('phone', editData?.mobile);
        setValue('address', editData?.address);
        setValue('kioskStatus', editData?.status === 'active' ? true : false);
        setValue('phone', editData?.mobile);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    control,
    setValue,
    watch,
    trigger,
    handleSubmit,
    formState: { errors, isSubmitted, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      kioskName: editData?.name,
      email: editData?.email,
      phone: editData?.mobile,
      address: editData?.address,
      kioskStatus: editData?.status === 'active' ? true : false,
    },
  });
  const watchStatus = watch('kioskStatus');

  const onSubmit = async (data: any) => {
    try {
      showLoader();
      const { phone, kioskStatus, kioskName, ...rest } = data;
      const payload = {
        ...rest,
        name: kioskName,
        reg_id: editData?.reg_id,
        code: editData?.code,
        mobile: validatePhoneNumberwithPlus(phone),
        status: kioskStatus ? 'active' : 'inactive',

        // status: kioskStatus ? 'disabled' : editData?.status,
      };
      const id = editData?._id;
      let url = end_points.kioskUpdateApi.url;
      if (id) {
        url += `/${id}`;
      }
      const response = await putData(url, payload);
      if (response.status === 200) {
        navigate(routes.kioskList.path);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      // setEditKiosk(false);
      hideLoader();
    }
  };
  const formInvalid = isSubmitted && !isValid;
  useEffect(() => {
    if (editDatabyId) getKioskDatabyId(editDatabyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDatabyId]);
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Edit Kiosk */}
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="card edit-card">
                <div className="card-body pb-0">
                  {editKiosk ? (
                    <div className="edit-toggle">
                      <div className="status-toggle">
                        <p>
                          {watchStatus ? 'In Active' : 'Active'}
                          &nbsp; Kiosk
                        </p>

                        <Controller
                          name="kioskStatus"
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                          }) => (
                            <>
                              <input
                                type="checkbox"
                                id="status"
                                className="check-input"
                                onChange={(event: any) => {
                                  if (
                                    editData?.status === 'active' &&
                                    (editData?.frontDesks?.length > 0 ||
                                      editData?.paramedics?.length > 0) &&
                                    !event.target.checked
                                  ) {
                                    watchStatus === true
                                      ? setShowed(true)
                                      : setShowed(false);
                                  } else onChange(event);
                                }}
                                onBlur={onBlur}
                                checked={value}
                                name={name}
                                ref={ref}
                              />
                              <label htmlFor="status" className="checktoggle">
                                checkbox
                              </label>
                              <p className="edit-text">
                                {showed && (
                                  <DropDownPopup
                                    showed={showed}
                                    title="Inactive a kiosk, corresponding associate's will be unassociated."
                                    setShowed={setShowed}
                                    onClick={() => {
                                      setValue('kioskStatus', false);
                                      setShowed(false);
                                    }}
                                  />
                                )}
                              </p>
                            </>
                          )}
                        />

                        <ErrorMessage value={errors.kioskStatus} />
                      </div>
                      <div className="edit-toggle-btn">
                        <Link
                          to={'#'}
                          onClick={handleSubmit(onSubmit)}
                          className="btn btn-secondary"
                        >
                          Save
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className="edit-icon">
                      <Tooltip title="Edit" color="grey">
                        <Link
                          to={'#'}
                          onClick={() => {
                            setEditKiosk(true);
                          }}
                        >
                          <i className="fa-regular fa-pen-to-square" />
                        </Link>
                      </Tooltip>
                    </div>
                  )}
                  <div className="edit-inner-body">
                    <div className="row">
                      <div className="col-lg-5 d-flex">
                        <div className="edit-inner-box w-100 custom-input-cls">
                          <div className="edit-header">
                            {editKiosk ? (
                              <>
                                <Controller
                                  name="kioskName"
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      type="text"
                                      className={`form-control ${
                                        errors.kioskName ? 'is-invalid' : ''
                                      }`}
                                      placeholder="Kiosk name*"
                                      onBlur={() => trigger('kioskName')}
                                      onChange={(event: any) => {
                                        field.onChange(event);
                                        trigger('kioskName');
                                      }}
                                      onKeyDown={(event) => {
                                        const regex = onlyAlphabet;
                                        if (
                                          !regex.test(event.key) &&
                                          event.key !== 'Backspace'
                                        ) {
                                          event.preventDefault();
                                        }
                                      }}
                                      defaultValue={editData?.name}
                                      maxLength={nameMaxLength}
                                    />
                                  )}
                                />
                                <ErrorMessage value={errors.kioskName} />
                              </>
                            ) : (
                              <h4>{editData?.name}</h4>
                            )}
                            <ul className="nav">
                              <li>{editData?.reg_id}</li>
                              <li>
                                <p>
                                  <span
                                    className={
                                      watchStatus
                                        ? 'status-active'
                                        : 'status-inactive'
                                      // : 'status-disabled'
                                    }
                                  />
                                  {watchStatus ? 'Active' : 'In Active'}
                                </p>
                              </li>
                            </ul>
                          </div>
                          {editKiosk ? (
                            <>
                              <div className="edit-list">
                                <ul>
                                  <li>
                                    <img src={edit02} alt="Icon" />
                                    <div className="edit-form-control">
                                      <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                          <input
                                            {...field}
                                            type="text"
                                            className={`form-control ${
                                              errors.email ? 'is-invalid' : ''
                                            }`}
                                            placeholder="Kiosk email*"
                                            onBlur={() => trigger('email')}
                                            onChange={(event: any) => {
                                              field.onChange(event);
                                              trigger('email');
                                            }}
                                            defaultValue={editData?.email}
                                            maxLength={emailMaxLength}
                                          />
                                        )}
                                      />
                                      <ErrorMessage value={errors.email} />
                                    </div>
                                  </li>
                                  <li>
                                    <img src={edit01} alt="Icon" />{' '}
                                    <div
                                      className={`edit-form-control ${formInvalid ? 'is-invalid' : ''}`}
                                    >
                                      <Controller
                                        name="phone"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                          <div>
                                            <PhoneInputWithRef
                                              {...field}
                                              placeholder="Enter phone number"
                                              country={'in'}
                                              value={field.value}
                                              onBlur={() => trigger('phone')}
                                              onChange={(event: any) => {
                                                field.onChange(event);
                                                trigger('phone');
                                              }}
                                              inputProps={{
                                                name: 'phone',
                                                required: true,
                                                // autoFocus: true,
                                                className: `form-control ${fieldState.invalid ? 'is-invalid' : ''}`,
                                              }}
                                            />
                                          </div>
                                        )}
                                      />
                                      <ErrorMessage value={errors.phone} />
                                    </div>
                                  </li>
                                  <li>
                                    <img src={edit03} alt="Icon" />
                                    <div className="edit-form-control">
                                      <Controller
                                        name="address"
                                        control={control}
                                        render={({ field }) => (
                                          <input
                                            {...field}
                                            type="text"
                                            className={`form-control ${
                                              errors.address ? 'is-invalid' : ''
                                            }`}
                                            placeholder="Kiosk address*"
                                            onBlur={() => trigger('address')}
                                            onChange={(event: any) => {
                                              field.onChange(event);
                                              trigger('address');
                                            }}
                                            defaultValue={editData?.address}
                                            maxLength={addressMaxLength}
                                          />
                                        )}
                                      />

                                      <ErrorMessage value={errors.address} />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </>
                          ) : (
                            <div className="edit-list">
                              <ul>
                                <li>
                                  <img src={edit02} alt="Icon" />
                                  <a href={`mailto:${editData?.email}`}>
                                    <p>{editData?.email}</p>
                                  </a>{' '}
                                </li>
                                <li>
                                  <img src={edit01} alt="Icon" />{' '}
                                  <a href={`tel:${editData?.mobile}`}>
                                    {editData?.mobile}
                                  </a>
                                </li>
                                <li>
                                  <img src={edit03} alt="Icon" />
                                  <p>{editData.address}</p>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-7 d-flex">
                        <div
                          className={`edit-details ${editKiosk ? 'edit-details-disable' : ''} w-100`}
                        >
                          <div className="edit-grid">
                            <h5>
                              {/* {capitalizeFirstLetter(associate?.type)} */}
                              Front Desk
                            </h5>
                            <div className="edit-box">
                              <table className="table">
                                <tbody>
                                  {editData?.frontDesks?.length > 0 ? (
                                    editData?.frontDesks?.map(
                                      (associate: any) => {
                                        return (
                                          <tr>
                                            <td>
                                              #{associate?.userDetails?.reg_id}
                                            </td>
                                            <td>
                                              {associate?.userDetails?.fullname}
                                            </td>
                                          </tr>
                                        );
                                      },
                                    )
                                  ) : (
                                    <div className="no-kiosk">
                                      No kiosk Associates
                                    </div>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div className="edit-associate-btn">
                              <PopOverText
                                show={editData?.status !== 'active'}
                                message="Kiosk is inactive"
                              >
                                <Link
                                  onClick={() => {
                                    if (editData?.status === 'active') {
                                      dispatch(setPathFrom('front-desk'));
                                      dispatch(setAssociateToData(editData));
                                    }
                                  }}
                                  to={
                                    editData?.status === 'active'
                                      ? routes.frontDeskAssociate.path
                                      : '#'
                                  }
                                  className={`btn btn-primary ${editData?.status === 'active' ? '' : 'disabled'}`}
                                >
                                  Associate
                                </Link>
                              </PopOverText>
                            </div>
                          </div>

                          <div className="edit-grid">
                            <h5>Paramedic</h5>
                            <div className="edit-box">
                              <table className="table">
                                <tbody>
                                  {editData?.paramedics?.length > 0 ? (
                                    editData?.paramedics?.map(
                                      (associate: any) => {
                                        return (
                                          <tr>
                                            <td>
                                              #{associate?.userDetails?.reg_id}
                                            </td>
                                            <td>
                                              {associate?.userDetails?.fullname}
                                            </td>
                                          </tr>
                                        );
                                      },
                                    )
                                  ) : (
                                    <div className="no-kiosk">
                                      No kiosk Associates
                                    </div>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div className="edit-associate-btn">
                              <PopOverText
                                show={editData?.status !== 'active'}
                                message="Kiosk is inactive"
                              >
                                <Link
                                  onClick={() => {
                                    if (editData?.status === 'active') {
                                      dispatch(setPathFrom(userRole.Paramedic));
                                      dispatch(setAssociateToData(editData));
                                    }
                                  }}
                                  to={
                                    editData?.status === 'active'
                                      ? routes.frontDeskAssociate.path
                                      : '#'
                                  }
                                  className={`btn btn-primary ${editData?.status === 'active' ? '' : 'disabled'}`}
                                >
                                  Associate
                                </Link>
                              </PopOverText>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* /Edit Kiosk */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default KioskEdit;
