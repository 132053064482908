import React from 'react';

interface ErrorMessageProps {
  value?: { message?: string };
  className?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ value, className }) => {
  return value?.message ? (
    <small className={`text-danger ${className}`}>{value?.message}</small>
  ) : null;
};

export default ErrorMessage;
