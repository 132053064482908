import React from 'react';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter } from '../../../../../utils/constant';

const AppoitmentDetailsHeader = () => {
  const patientDetails: any = useSelector(
    (state: any) => state.common.editData?.patient,
  );
  const pastPatientDetails: any = useSelector(
    (state: any) => state.common?.pastData?.patient,
  );
  // pastData
  const AppointmentDetails: any = pastPatientDetails?.appointments
    ? pastPatientDetails?.appointments?.[0]
    : patientDetails?.appointments;
  // console.log(patientDetails, 'patientDetails', AppointmentDetails)

  return (
    <div className="card">
      <div className="card-body pb-0">
        <div className="patient-card-header">
          <h4>Appointment Details</h4>
        </div>
        <div className="appointment-details-list">
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <ul>
                <li>
                  <p>
                    <span>Appointment ID</span>{' '}
                    {AppointmentDetails?.appointment_format_id}
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      {capitalizeFirstLetter(
                        AppointmentDetails?.kioskDetails?.name,
                      )}
                    </span>{' '}
                    {AppointmentDetails?.kioskDetails?.address}
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-lg-7 col-md-6">
              <ul>
                <li>
                  <p>
                    <span>Appointment Type</span>{' '}
                    {AppointmentDetails?.appointment_type}
                  </p>
                </li>
                <li>
                  <p>
                    <span>Cheif Complaint</span>{' '}
                    {AppointmentDetails?.cheif_complaint}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppoitmentDetailsHeader;
