import React from 'react';
import { Link } from 'react-router-dom';

interface CustomPaginatorProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
}

const CustomPaginator: React.FC<CustomPaginatorProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const handlePrevClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <>
      {totalPages ? (
        <>
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="table-pagination">
                <ul className="pagination-list">
                  <li>{currentPage}</li>
                  <li>{totalPages}</li>
                </ul>
                <ul className="pagination-arrow">
                  <li>
                    <Link to={'#'} onClick={handlePrevClick}>
                      <i className="fas fa-chevron-left"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={'#'} onClick={handleNextClick}>
                      <i className="fas fa-chevron-right"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default CustomPaginator;
