import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PrescriptionModel from '../joinConsultation/components/prescriptionModel';
import { useSelector } from 'react-redux';

function PrescriptionHistory() {
  const appoit = useSelector(
    (state: any) => state.common?.editData?.patient?.appointments,
  );
  const [appoitmentId, setAppitmentId] = useState<any>('');
  return (
    <div
      className="tab-pane fade"
      id="prescription_tab"
      role="tabpanel"
      aria-labelledby="prescription-tab"
    >
      <div className="row">
        <div className="col-md-12 d-flex">
          <div className="examination-card w-100">
            <h4>Prescription</h4>
            <div className="screenshot-details">
              <ul className="nav">
                <li>
                  <p>prescription</p>
                </li>
                <li>
                  <Link
                    to="#"
                    className="btn btn-primary prescription_preview_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#prescription_preview"
                    onClick={() => {
                      const id = appoit && JSON.parse(JSON.stringify(appoit));
                      setAppitmentId(id);
                    }}
                  >
                    View
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <PrescriptionModel
          appoitmentId={appoitmentId}
          setAppoitmentId={setAppitmentId}
        />
      </div>
    </div>
  );
}

export default PrescriptionHistory;
