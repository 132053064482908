import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../utils/routes';
import SearchFromApi from '../search';
import PrimeDataTable from '../dataTable';
import { SpinnerContext } from '../spinner/spinner';
import { end_points } from '../../services/end_point/end_points';
import { ApiServiceContext } from '../../utils/shared.module';
import { useDispatch, useSelector } from 'react-redux';
import { setAssociateToData } from '../../core/redux/commonSlice';
import { userRole } from '../../assets/global';
import { capitalizeFirstLetter } from '../../utils/constant';
import { toast } from 'react-toastify';

const FrontDeskParamedicAssociate = () => {
  const { postData, getData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);

  const [listData, setListData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterType, setFilterType] = useState<string>('All');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [initialIdsforSelectedId, setInitialIdsforSelectedId] =
    useState<any>(null);
  const [rows, setRows] = useState<number>(10);
  const associateData = useSelector(
    (state: any) => state?.common?.associateToData,
  );
  const path = useSelector((state: any) => state?.common?.pathFrom);

  const [selectedId, setSelectedId] = useState<any>([]);
  const handleRadioChange = (id: any) => {
    setSelectedId([id]);
  };
  const handleCheckboxChange = (id: any) => {
    if (selectedId?.includes(id)) {
      setSelectedId(selectedId.filter((selected: any) => selected !== id));
    } else {
      setSelectedId([...selectedId, id]);
    }
  };

  const isChecked: any = (data: any, selectedId: any) => {
    const checkData = data?.associates;
    const user_id =
      checkData.length > 0
        ? checkData
            ?.filter((association: any) => association?.user_id)
            .map((user: any) => user?.user_id)
        : data?._id;

    const result = user_id
      ? selectedId.some((sId: any) => sId?.includes(user_id))
      : false;
    return result;
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const SelectOption = (data: any) => {
    const [showed, setShowed] = useState<boolean>(false);
    const behalfRef = useRef<any>(null);
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (
          behalfRef?.current &&
          !behalfRef?.current?.contains(event?.target)
        ) {
          setShowed(false);
        }
      };
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, []);
    return (
      <>
        <div
          className={
            path === userRole.Paramedic
              ? 'custom-checkbox custom-checkbox-left custom-checkbox-line'
              : 'custom-radio'
          }
          ref={behalfRef}
        >
          <label
            data-bs-toggle={`${showed === true ? 'dropdown' : ''}`}
            className={`
              ${path === userRole.Paramedic ? 'check ' : 'custom_radio '}
              ${showed === true ? ' dropdown-toggle show' : ''}
            `}
          >
            <input
              title="select"
              name="front_desk"
              type={path === userRole.Paramedic ? 'checkbox' : 'radio'}
              checked={isChecked(data, selectedId) === true ? true : false}
              onChange={(event) => {
                if (
                  data?.isAssociated === 1 &&
                  data?.associates?.some((association: any) =>
                    associateData?._id?.includes(association?.kiosk_id),
                  )
                ) {
                  setShowed(false);
                  path === userRole.Paramedic
                    ? handleCheckboxChange(data._id)
                    : handleRadioChange(data._id);
                } else if (data?.isAssociated === 1) {
                  if (
                    path === userRole.Paramedic &&
                    event.target.checked === false
                  ) {
                    handleCheckboxChange(data._id);
                  } else setShowed(true);
                } else if (data?.isAssociated === 0) {
                  if (showed) {
                    setShowed(false);
                  }
                  path === userRole.Paramedic
                    ? handleCheckboxChange(data._id)
                    : handleRadioChange(data._id);
                }
              }}
            />
            <span
              className={path === userRole.Paramedic ? 'box' : 'checkmark'}
            />
          </label>
          <div className="front-desk-dropdown-right-info">
          <div
            className={`dropdown-menu front-desk-dropdown front-desk-dropdown-right dropdown-menu-end ${showed ? 'show' : ''}`}
          >
            <p>
              This {path === userRole.Paramedic ? 'paramedic' : 'front desk'} is
              already assigned to this kiosk, do you want to reassociate?
            </p>
            <div className="front-desk-dropdown-btn">
              <Link
                to="#"
                onClick={() => {
                  path === userRole.Paramedic
                    ? handleCheckboxChange(data._id)
                    : handleRadioChange(data._id);
                  setShowed(false);
                }}
                className="btn btn-secondary"
              >
                Yes
              </Link>
              <Link
                to="#"
                className="btn btn-light"
                onClick={() => {
                  setShowed(false);
                }}
              >
                No
              </Link>
            </div>
          </div>
          </div>
        </div>
      </>
    );
  };
  const columns = [
    {
      header: '#ID',
      field: 'reg_id',
      key: 'reg_id',
      sortField: 'reg_id',
      body: (data: any) => <Link to={'#'}>{data?.reg_id}</Link>,
    },
    {
      header: 'Name',
      field: 'fullname',
      key: 'fullname',
      sortField: 'fullname',
    },
    {
      header: 'Phone No',
      field: 'mobile',
      key: 'mobile',
      sortField: 'mobile',
    },
    {
      header: 'Status',
      field: 'status',
      key: 'status',
      sortField: 'status',
      body: (data: any) => (
        <div className="status-data">
          {data?.status === 'active' && (
            <p>
              <span className="status-active"></span>
              Active
            </p>
          )}
          {data?.status === 'inactive' && (
            <p>
              <span className="status-inactive"></span>
              In Active
            </p>
          )}
        </div>
      ),
    },
    {
      header: 'Associated Kiosk',
      field: 'associatedKioskName',
      key: 'associatedKioskName',
      sortField: 'associatedKioskName',
    },
    {
      header: 'Select',
      key: 'select',
      sortable: false,
      field: 'select',
      body: SelectOption,
    },
  ];

  const getFdAssociateList = async (page: number = 1, limit: number = 10) => {
    try {
      showLoader();
      let url = end_points.frontdeskandParamedicAssociateListApi.url;

      if (path) {
        url += `/${path}`;
      }
      if (page && limit) {
        url += `?page=${page}&limit=${limit}`;
      }
      url += `&view=active`;
      if (searchQuery) {
        url += `&searchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        const data =
          response?.data?.data.length > 0
            ? response?.data?.data?.map((val: any) => {
                return {
                  ...val,
                  associatedKioskName: val?.associates?.[0]?.kiosk_name
                    ? val?.associates?.[0]?.kiosk_name
                    : 'Unassociated',
                };
              })
            : [];
        setListData(data);
        setTotalRecords(response.data.total_records);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };
  const getSelectedList = (ids: any) => {
    return ids?.filter((ids: any) =>
      initialIdsforSelectedId?.length > 0
        ? !initialIdsforSelectedId?.includes(ids)
        : [],
    );
  };
  const getRemovedList = () => {
    if (path === 'front-desk' && associateData?.frontDesks?.length > 0) {
      return associateData?.frontDesks?.[0]?.user_id !== selectedId?.[0]
        ? [associateData?.frontDesks?.[0]?.user_id]
        : [];
    } else if (
      path === userRole.Paramedic &&
      associateData?.paramedics?.length > 0
    ) {
      const removedParamedics = associateData?.paramedics
        ?.filter((paramedic: any) => !selectedId?.includes(paramedic?.user_id))
        .map((paramedic: any) => paramedic?.user_id);
      return removedParamedics;
    } else {
      return [];
    }
  };
  const handleSearch = (search: any = '') => {
    setSearchQuery(search);
    setCurrentPage(1);
  };
  const onSubmit = async (data: any) => {
    const ifadded = selectedId;
    const ifRemoved = getRemovedList();

    if (ifadded.length === 0) {
      if (path === userRole.Paramedic && ifRemoved.length === 0) {
        toast.info('Please select paramedic to associate');
        return;
      } else if (path != userRole.Paramedic) {
        toast.info('Please select frontdesk to associate');
        return;
      }
    }
    try {
      showLoader();
      const payload = {
        type: path,
        status: associateData?.status,
        kiosk_id: associateData?._id,
        selectedIds: getSelectedList(selectedId),
        removedIds: getRemovedList(),
      };

      const response = await postData(
        end_points.front_paramedicAssociateUpdateApi.url,
        payload,
      );
      if (response?.status === 200) {
        dispatch(setAssociateToData(undefined));
        navigate(routes.kioskList.path);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (searchQuery || filterType || currentPage) {
      getFdAssociateList(currentPage, rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, filterType, currentPage]);
  useEffect(() => {
    const associateUsers =
      path === userRole.Paramedic
        ? associateData?.paramedics?.map((para: any) => para?.userDetails?._id)
        : associateData?.frontDesks?.map((para: any) => para?.userDetails?._id);
    const initialSelectedIds = listData
      ?.filter((item: any) =>
        item?.associates?.some((associate: any) =>
          associateUsers?.includes(associate?.user_id),
        ),
      )
      .flatMap((item: any) => item.associates?.map((val: any) => val.user_id));

    if (initialSelectedIds.length > 0) {
      setSelectedId(initialSelectedIds);
      setInitialIdsforSelectedId(initialSelectedIds);
    }
  }, [associateData, listData, path]);
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-1">
          {/* Page Header */}
          <div className="page-header">
            <h4 className="page-title">
              {path === userRole.Paramedic
                ? capitalizeFirstLetter(userRole.Paramedic)
                : 'Front Desk'}
              &nbsp; List
            </h4>
            <div className="page-list">
              <ul className="nav">
                <SearchFromApi callback={handleSearch} />
              </ul>
            </div>
          </div>
          {/* /Page Header */}
          {/* Front Desk Table */}
          <PrimeDataTable
            column={columns}
            data={listData}
            rows={rows}
            setRows={setRows}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalRecords={totalRecords}
            rowClassName={'highlight'}
            onRowClickSetState={false}
            footer={() => {
              if (listData?.length === 0) {
                return null;
              }
              return (
                <div className="table-btn table-btn-info">
                  <Link
                    to="#"
                    type="submit"
                    onClick={onSubmit}
                    className="btn btn-secondary"
                  >
                    Save
                  </Link>
                </div>
              );
            }}
          />
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default FrontDeskParamedicAssociate;
