import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../../../utils/routes';
import PrimeDataTable from '../../../../components/dataTable';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { end_points } from '../../../../services/end_point/end_points';
import { ApiServiceContext } from '../../../../utils/shared.module';
import ListHeader from '../../../../components/listHeader';
import { capitalizeFirstLetter, viewTypes } from '../../../../utils/constant';
import { useDispatch } from 'react-redux';
import { setEditData } from '../../../../core/redux/commonSlice';
import { Tooltip } from 'antd';

// const viewTypes: any = ['All', 'Online', 'Offline', 'Busy'];

const DoctorsList = () => {
  const { getData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);

  const [listData, setListData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [filterType, setFilterType] = useState<string>('All');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(10);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const columns = [
    {
      header: '#ID',
      field: 'reg_id',
      key: 'reg_id',
      sortField: 'reg_id',
      body: (data: any) => (
        <Link
          to={routes.doctorEdit.path}
          onClick={() => {
            dispatch(setEditData(data));
          }}
        >
          {data?.reg_id}
        </Link>
      ),
    },
    {
      header: 'Name',
      field: 'fullname',
      key: 'fullname',
      sortField: 'fullname',
    },
    {
      header: 'Email ID',
      field: 'email',
      key: 'email',
      sortField: 'email',
      body: (data: any) => <Link to="#">{truncateAddress(data?.email)}</Link>,
    },
    {
      header: 'Phone No',
      field: 'mobile',
      key: 'mobile',
      sortField: 'mobile',
    },
    {
      header: 'Status',
      key: 'status',
      field: 'status',
      body: (data: any) => {
        const status: string = capitalizeFirstLetter(data?.status);
        return (
          <div className="status-data">
            {status === 'Active' && (
              <p>
                <span className="status-active"></span>
                {status}
              </p>
            )}
            {status === 'Disabled' && (
              <p>
                <span className="status-disabled"></span>
                {status}
              </p>
            )}
            {status === 'Inactive' && (
              <p>
                <span className="status-inactive"></span>
                In Active
              </p>
            )}
          </div>
        );
      },
    },
    {
      header: 'Action',
      sortable: false,
      body: (rowData: any) => (
        <div className="data-slot">
          <Tooltip color="grey" title="Edit">
            <a onClick={() => handleEdit(rowData, 'edit')}>
              <i className="feather icon-edit"></i>
            </a>
          </Tooltip>
        </div>
      ),
    },
  ];
  const handleEdit = (data: any, type: any) => {
    dispatch(setEditData(data));
    if (type === 'edit') {
      navigate(routes.doctorEdit.path);
    } else {
      navigate(routes.doctorCreate.path);
    }
  };
  const truncateAddress = (address: any) => {
    if (address.length > 30) {
      return address.substring(0, 30) + '...';
    }
    return address;
  };
  const getDoctorList = async (page: number = 1, limit: number = 10) => {
    try {
      showLoader();
      let url = end_points.adminPagesList.url;
      url += `/doctor`;
      if (page && limit) {
        url += `?page=${page}&limit=${limit}`;
      }
      if (filterType) {
        url += `&view=${filterType?.toLocaleLowerCase()}`;
      }
      if (searchQuery) {
        url += `&searchTerm=${searchQuery}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.data);
        setTotalRecords(response?.data?.total_records);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const handleSearch = (search: any = '') => {
    setSearchQuery(search);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (searchQuery || filterType || currentPage) {
      getDoctorList(currentPage, rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, filterType, currentPage]);
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Page Header */}
          {/* DOCTOR'S LIST */}
          <ListHeader
            title={`DOCTOR'S LIST`}
            type={'doctorList'}
            viewTypes={viewTypes}
            filterType={filterType}
            setFilterType={setFilterType}
            setCurrentPage={setCurrentPage}
            handleSearch={handleSearch}
            createRoute={routes.doctorCreate.path}
          />
          {/* /Page Header */}
          {/* Doctors Table */}
          <PrimeDataTable
            column={columns}
            data={listData}
            rows={rows}
            setRows={setRows}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalRecords={totalRecords}
            rowClassName={'highlight'}
          />
          {/* /Doctors Table */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default DoctorsList;
