import React from 'react';
import {
  capitalizeFirstLetter,
  capitalizeWords,
} from '../../../../../utils/constant';

function PastSocial({ details = [] }) {
  const {
    exercise_description,
    createdAt,
    drugs_description,
    Drug_type,
    ...history
  }: any = details;
  const mappedDetails: any = {
    Tobacco: history?.tobacco,
    Drinks_Alcohol: history?.drinksAlcohol,
    Exercise: history?.exercise,
    Drugs: history?.drugs,
    Sexual_Wellness: history?.sexualWellness,
  };
  return (
    <div
      className="tab-pane fade"
      id="social_history"
      role="tabpanel"
      aria-labelledby="social-history"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="card past-card">
            <div className="card-body">
              <div className="past-header">
                <h4>Personal /Social history</h4>
              </div>
              <div className="vitals-list">
                {history?.tobacco ? (
                  Object.keys(mappedDetails)?.map((key: any, index: number) => {
                    return (
                      <ul className="nav" key={index}>
                        <li>{key?.replace('_', ' ')}</li>

                        <li>
                          <div className="vitals-since">
                            <p className="vitals-text">
                              <span className="text-primary">
                                {Drug_type ? (
                                  <>
                                    {capitalizeWords(mappedDetails[key])},
                                    {capitalizeFirstLetter(Drug_type)}
                                  </>
                                ) : (
                                  <>{capitalizeWords(mappedDetails[key])}</>
                                )}
                              </span>
                              {key === 'Exercise' && exercise_description
                                ? exercise_description
                                : ''}
                              {key === 'Drugs' && drugs_description
                                ? drugs_description
                                : ''}
                            </p>
                          </div>
                        </li>
                      </ul>
                    );
                  })
                ) : (
                  <ul className="nav">
                    <li>{'No records found'}</li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PastSocial;
