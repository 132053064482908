import React, { useState, useEffect, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../../utils/routes';
import ringingtone from '../../../../../assets/img/ringtone/caller_ring.mp3';
import { SpinnerContext } from '../../../../../components/spinner/spinner';
import { ApiServiceContext } from '../../../../../utils/shared.module';
import { end_points } from '../../../../../services/end_point/end_points';
import {
  setCallJoined,
  setEditData,
  setSessionDetails,
} from '../../../../../core/redux/commonSlice';

const IncomingCallNotification = () => {
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { putData } = useContext(ApiServiceContext);
  const [isRinging, setIsRinging] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [ringtone, setRingtone] = useState<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessionDetail = useSelector(
    (state: any) => state?.common?.sessionDetails,
  );
  const callJoined = useSelector((state: any) => state?.common?.callJoined);
  const timerRef = useRef<any>(null);

  useEffect(() => {
    if (callJoined != undefined) {
      setIsRinging(false);
      if (ringtone) {
        ringtone.pause();
      }
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    }
  }, [callJoined]);

  useEffect(() => {
    if (sessionDetail != undefined) {
      const audio = new Audio(ringingtone);
      audio.loop = true;

      const startRinging = async () => {
        try {
          await audio.play();
          setRingtone(audio);
          setIsRinging(true);
        } catch (error) {
          console.warn(
            'Auto-play was prevented, waiting for user interaction.',
          );
        }
      };

      if (!isRinging) {
        startRinging();
      }
      // Auto decline after 30 seconds
      timerRef.current = setTimeout(() => {
        audio.pause();
        if (!isAccepted) {
          handleDecline('call not accepted');
        }
      }, 30000);

      return () => {
        clearTimeout(timerRef.current); // Clear the timer on component unmount
        audio.pause();
      };
    }
    dispatch(setCallJoined(undefined));
  }, [sessionDetail]);

  const endCallNotify = async (data: any) => {
    try {
      showLoader();
      let url = end_points.disconnectVideoCall.url;
      url += `/${sessionDetail?._id}`;
      const payload = {
        paramedic_id: sessionDetail?.paramedic_id,
        status: data,
      };

      const response = await putData(url, payload);
      if (response?.status === 200) {
      }
    } catch (error: any) {
    } finally {
      hideLoader();
    }
  };

  const handleAccept = () => {
    if (ringtone) {
      ringtone.pause();
    }
    setIsRinging(false);
    navigate(routes.joinConsult.path);
    dispatch(setCallJoined('Joined'));
    setIsAccepted(true);
    if (timerRef.current) {
      clearTimeout(timerRef.current); // Clear the timer when the call is accepted
      timerRef.current = null;
    }
  };

  const handleDecline = (data: any) => {
    if (ringtone) {
      ringtone.pause();
    }
    setIsRinging(false);
    endCallNotify(data);
    dispatch(setEditData(undefined));
    dispatch(setSessionDetails(undefined));
    dispatch(setCallJoined(undefined));
  };

  return isRinging ? (
    <div className="incoming-call-notification">
      <p>Incoming Call for Appoinment {sessionDetail?.appointment_format_id}</p>
      <button onClick={handleAccept} className="btn btn-primary accept-button">
        Accept
      </button>
      <button
        onClick={() => {
          handleDecline('call decline');
        }}
        className="btn btn-secondary decline-button"
      >
        Decline
      </button>
    </div>
  ) : null;
};

export default IncomingCallNotification;
