import React from 'react';
import { debounce } from '../../utils/debounce';
import { nameMaxLength } from '../../utils/patterns/regex.pattern';
interface searchProps {
  callback: Function;
}
const SearchFromApi: React.FC<searchProps> = ({ callback }) => {
  const dataApi = debounce(callback, 700);
  const handleChange = (e: any) => {
    dataApi(e.target.value?.trim());
  };
  return (
    <div className="page-search">
      <i className="fas fa-search" />
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        onChange={handleChange}
        maxLength={nameMaxLength}
      />
    </div>
  );
};

export default SearchFromApi;
