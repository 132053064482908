import React from 'react';
import { capitalizeWords } from '../../../../../utils/constant';
import AudioPlayer from '../joinConsultation/components/audioPlayer';
import { Link } from 'react-router-dom';
interface vitlasProps {
  details: any;
}
const Vitals: React.FC<vitlasProps> = ({ details = {} }) => {
  const {
    deletedAt,
    _id,
    createdAt,
    updatedAt,
    stethoscope,
    screenshot,
    ...rest
  } = details;
  const history: any = rest;
  return (
    <div
      className="tab-pane fade"
      id="vitals_tab"
      role="tabpanel"
      aria-labelledby="vitals-tab"
    >
      <div className="row">
        <div className="col-md-6">
          <div className="symptoms-card vitals-card">
            <h4>Vitals</h4>
            <div className="vitals-table">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Device Name</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(history)?.length > 0 ? (
                      Object.keys(history).map((key) => {
                        const name = key?.replace('_', '');
                        return (
                          <tr key={key}>
                            <td>{capitalizeWords(name)}</td>
                            <td className="value-text">{history[key]}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>No records found</td>
                      </tr>
                    )}
                    {/* <tr>
                      <td>Blood Pressure</td>
                      <td className="value-text">132/84 mmHg</td>
                    </tr>
                    <tr>
                      <td>Weight</td>
                      <td className="value-text">82.5 kg</td>
                    </tr>
                    <tr>
                      <td>Blood Glucose</td>
                      <td className="value-text">81.08 mg/dl</td>
                    </tr>
                    <tr>
                      <td>Blood Pressure</td>
                      <td className="value-text">132/84 mmHg</td>
                    </tr>
                    <tr>
                      <td>Weight</td>
                      <td className="value-text">82.5 kg</td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="symptoms-card vitals-card">
            <h4>PACS</h4>
            <div className="vitals-table">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Device Name</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stethoscope && (
                      <tr>
                        <td>Stethoscope</td>
                        <td className="value-link">
                          <AudioPlayer url={stethoscope} />
                          {/* <a href={stethoscope} target="blank">
                            play
                          </a> */}
                        </td>
                      </tr>
                    )}
                    {screenshot && (
                      <tr>
                        <td>Screenshot</td>
                        <td className="value-link">
                          <a href={screenshot} target="blank">
                            View
                          </a>
                        </td>
                      </tr>
                    )}
                    {!stethoscope && !screenshot && (
                      <tr>
                        <td>No records found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vitals;
