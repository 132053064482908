import moment from 'moment';
import React from 'react';

function PastHositalization({ details = [] }) {
  const history: any = details;

  return (
    <div
      className="tab-pane fade"
      id="past_hospitalization"
      role="tabpanel"
      aria-labelledby="past_hospitalization"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="card past-card">
            <div className="card-body">
              <div className="past-header">
                <h4>Past Hospitalization</h4>
              </div>
              <div className="vitals-list">
                {history?.hospitalizations?.length > 0 ? (
                  history?.hospitalizations.map((h: any) => {
                    return (
                      <ul className="nav">
                        <li>
                          <div className="hospitalization-date">
                            <p>
                              Date{' '}
                              <span>
                                {' '}
                                {moment(h?.date, 'YYYY/MM/DD').format(
                                  'DD/MM/YYYY',
                                )}
                              </span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="hospitalization-date">
                            <p>
                              Provide Details <span>{h?.provide_details}</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                    );
                  })
                ) : (
                  <ul className="nav">
                    <li>{'No records found'}</li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PastHositalization;
