import { createSlice } from '@reduxjs/toolkit';
import { redirectToLogout } from './localStorage';

const initialState = {
  token: '',
  tenantId: '',
  userName: '',
  userDetails:{}
};

const appSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    showLoader: () => {
      // do nothing.
    },
    setLoginToken: (state, { payload }) => {
      state.token = payload;
    },
    setUserDetails: (state, { payload }) => {
      state.userDetails = payload;
    },
    setTenantId: (state, { payload }) => {
      state.tenantId = payload;
    },
    setUserName: (state, { payload }) => {
      state.userName = payload;
    },
    // logout: (state: any) => {
    //   state.loading = false;
    //   state.token = '';
    //   redirectToLogout();
    // },
    logout: (state: any, { payload }) => {
      state.loading = false;
      state.token = '';
      redirectToLogout(payload);
    },
  },
});

export const { showLoader, setLoginToken, setTenantId, setUserName, logout,setUserDetails } =
  appSlice.actions;

export default appSlice.reducer;
