import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { defaultProfileTwoImage, doctor01 } from '../../../../utils/imagepath';
import { routes } from '../../../../utils/routes';
import { end_points } from '../../../../services/end_point/end_points';
import {
  setCallJoined,
  setEditData,
  setPastData,
  setPrescriptionDrugTableData,
  setScheduleDoctorUpdate,
  setSymtomsFlowData,
} from '../../../../core/redux/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { ApiServiceContext } from '../../../../utils/shared.module';
import PrimeDataTable from '../../../../components/dataTable';
import { consultType, userRole } from '../../../../assets/global';
import {
  capitalizeFirstLetter,
  formatAppointmentStart,
  patientScheduleSlotFormat,
} from '../../../../utils/constant';
import DoctorScheduledSlots from '../../admin/doctor/doctor-scheduled-slots';
import PrescriptionModel from './joinConsultation/components/prescriptionModel';
interface typePropDash {
  typeProp: string;
}
const DoctorDashboard: React.FC<typePropDash> = ({ typeProp = 'Upcoming' }) => {
  const [listData, setListData] = useState<any>([]);
  const [metricsData, setMetricsData] = useState<any>([]);
  const userDetails = useSelector((state: any) => state?.login?.userDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rows, setRows] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { getData, putData } = useContext(ApiServiceContext);
  const [appoitmentId, setAppitmentId] = useState<any>('');
  const [isOffline, setIsOffline] = useState<boolean>(
    metricsData?.status === 'online' ? true : false,
  );
  const [loading, setLoading] = useState(false);

  const sessionDetail = useSelector(
    (state: any) => state?.common?.sessionDetails,
  );
  const doctorId = useSelector((state: any) => state?.login?.userDetails?.id);
  const newSchedule = useSelector(
    (state: any) => state?.common?.newScheduleDoctor,
  );
  const savedData: any = useSelector((state: any) => state?.common?.flowData);

  const updateStatus = async () => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      let url = end_points.updateDoctorStatus.url;
      if (url) {
        url += `/${doctorId}`;
      }
      const response = await putData(url);

      if (response.status === 200) {
        const status =
          response?.data?.data?.availability_status === 'offline'
            ? false
            : true;
        setIsOffline(status);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error updating status:', error);
    } finally {
    }
  };

  const pastConsultsColumns = [
    {
      header: 'Appointment Id',
      field: 'appointments.appointment_format_id',
      key: 'appointments.appointment_format_id',
      sortField: 'appointments.appointment_format_id',
      className: 'f-600',
      body: (data: any) => {
        return data && data?.appointments?.appointment_format_id
          ? data?.appointments?.appointment_format_id
          : '';
      },
    },
    {
      header: 'Patient Name',
      field: 'fullname',
      key: 'fullname',
      sortField: 'fullname',
    },
    {
      header: 'Consult Date',
      field: 'patient',
      key: 'patient',
      sortField: 'patient',
      body: (data: any) => {
        return data && data?.appointments
          ? formatAppointmentStart(data?.appointments?.appointment_start)
          : '';
      },
    },
    {
      header: 'Status',
      field: 'status',
      key: 'status',
      sortField: 'status',
      body: (rowData: any) => {
        const id =
          rowData?.appointments &&
          JSON.parse(JSON.stringify(rowData?.appointments));
        return capitalizeFirstLetter(id?.appointment_status);
      },
    },
    {
      header: 'Prescription',
      field: 'prescription',
      key: 'prescription',
      sortField: 'prescription',
      sortable: false,
      body: (rowData: any) => {
        const id =
          rowData?.appointments &&
          JSON.parse(JSON.stringify(rowData?.appointments));
        return (
          <div className="data-btn">
            {id?.appointment_status === 'completed' ? (
              <Link
                to="#"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#prescription_preview"
                onClick={() => {
                  setAppitmentId(id);
                }}
              >
                Prescription
              </Link>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
    {
      header: 'Current Details',
      field: 'currentDetails',
      key: 'currentDetails',
      sortField: 'currentDetails',
      body: (rowData: any) => {
        const id =
          rowData?.appointments &&
          JSON.parse(JSON.stringify(rowData?.appointments));
        return (
          <div className="data-btn">
            {id?.appointment_status === 'completed' ? (
              <Link
                to={routes.appointmentDetails.path}
                className="btn btn-primary"
                onClick={() => {
                  const dat = { patient: rowData };
                  dispatch(setEditData(dat));
                }}
              >
                Details
              </Link>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
  ];
  const upComingConsultscolumns = [
    {
      header: 'Appointment Id',
      field: 'appointment_format_id',
      key: 'appointment_format_id',
      sortField: 'appointment_format_id',
      className: 'f-600',
    },
    {
      header: 'Patient Name',
      field: 'patientDetails.fullname',
      key: 'patientDetails.fullname',
      sortField: 'patientDetails.fullname',
    },
    {
      header: 'Phone No',
      field: 'patientDetails.mobile',
      key: 'patientDetails.mobile',
      sortField: 'patientDetails.mobile',
    },
    {
      header: 'Appointment',
      field: 'appointment',
      key: 'appointment',
      sortField: 'appointment_start',
      body: (data: any) => {
        return data.appointment_start
          ? formatAppointmentStart(data.appointment_start)
          : '';
      },
    },
    {
      header: 'Kiosk',
      field: 'kiosk',
      key: 'kiosk',
      sortField: 'appointment.kioskDetails.name',
      body: (data: any) => {
        return data?.kioskDetails ? data?.kioskDetails?.name : '-';
      },
    },
    {
      header: 'Status',
      field: 'status',
      key: 'status',
      sortField: 'status',
      body: (data: any) => {
        return capitalizeFirstLetter(data?.appointment_status);
      },
    },
    {
      header: '',
      field: 'view',
      key: 'view',
      sortField: '',
      sortable: false,
      body: (data: any) => {
        console.log(
          sessionDetail?._id === data._id,
          sessionDetail?._id,
          data._id,
          'JoinCallIDStatus',
        );

        return (
          <div className="data-btn">
            {sessionDetail?._id === data._id ? (
              <Link
                to={routes.joinConsult.path}
                className="btn btn-primary"
                onClick={() => {
                  dispatch(setEditData(data));
                  dispatch(setCallJoined(data));
                  dispatch(setPastData(undefined));
                  if (savedData?.joinCallData?._id !== data._id) {
                    dispatch(setPrescriptionDrugTableData([]));
                    dispatch(setSymtomsFlowData({}));
                  }
                }}
              >
                Join
              </Link>
            ) : (
              <button className="btn btn-primary" disabled>
                Join
              </button>
            )}
          </div>
        );
      },
    },
  ];
  const criticalPatientColumns = [
    {
      header: 'Patient Name',
      field: 'patientName',
      key: 'patientName',
      sortField: 'patientName',
    },
    {
      header: 'Location',
      field: 'location',
      key: 'location',
      sortField: 'location',
    },
    {
      header: 'Vitals',
      field: 'vitals',
      key: 'vitals',
      sortField: 'vitals',
      body: () => (
        <div className="vitals-data">
          <ul className="nav">
            <li>
              <span className="f-500">H/r :</span> 128 BPM
            </li>
            <li>
              <span className="f-500">Temp :</span> 102 C
            </li>
            <li>
              <span className="f-500">BP :</span> 180/120
            </li>
          </ul>
        </div>
      ),
    },
    {
      header: '',
      field: 'view',
      key: 'view',
      sortField: '',
      sortable: false,
      body: () => (
        <div className="data-btn">
          <Link to="#" className="btn btn-primary">
            View
          </Link>
        </div>
      ),
    },
  ];
  const getDoctorConsults = async (
    page: number = 1,
    limit: number = 10,
    loading: boolean = true,
  ) => {
    try {
      loading && showLoader();
      let url =
        typeProp === consultType.upcoming
          ? end_points.doctorUpcomingConsults.url
          : typeProp === consultType.criticalPatient
            ? end_points.doctorPastConsults.url
            : end_points.pastDoctorConsultsListApi.url;
      typeProp === consultType.upcoming && (url += `/:id`);
      if (page && limit) {
        url += `?page=${page}&limit=${limit}`;
      }
      // typeProp === consultType.past && (url += `&view=past`);
      typeProp === consultType.criticalPatient && (url += `&view=past`);

      const response = await getData(url);
      if (response.status === 200) {
        typeProp === consultType.upcoming
          ? setListData(response?.data?.data?.todaysRecords)
          : typeProp === consultType.criticalPatient
            ? setListData(response?.data?.data)
            : setListData(response?.data?.data?.patientsList);
        typeProp === consultType.upcoming
          ? setTotalRecords(response?.data?.data?.totalRecord)
          : typeProp === consultType.criticalPatient
            ? setTotalRecords(response?.data?.totalRecord)
            : setTotalRecords(response?.data?.data?.totalRecords);

        dispatch(setEditData(undefined));
        dispatch(setScheduleDoctorUpdate(''));
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading && hideLoader();
    }
  };

  const getMetrics = async (loading = true) => {
    try {
      loading && showLoader();
      const url = end_points.doctorDashboardMetrics.url;

      const response = await getData(url);
      if (response.status === 200) {
        setMetricsData(response?.data?.data);
        setIsOffline(response?.data?.data.status === 'online' ? true : false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading && hideLoader();
    }
  };
  useEffect(() => {
    dispatch(setCallJoined(undefined));
    if (currentPage && typeProp !== consultType.scheduled) {
      getDoctorConsults(currentPage, rows);
      getMetrics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, typeProp]);

  const getGreeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      return 'Good Morning';
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };
  const refresh = () => {
    getDoctorConsults(currentPage, rows);
  };
  useEffect(() => {
    if (newSchedule?.appointment_id) {
      getDoctorConsults(currentPage, rows, false);
      getMetrics(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSchedule?.appointment_id]);
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Doctor Dashboard */}
          <div className="row">
            <div className="col-xl-3 col-lg-12 theiaStickySidebar">
              <div className="stickybar">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-header doctor-dash-header">
                      <h4>{getGreeting()}</h4>
                    </div>
                    <div className="doctor-dashboard-img">
                      <img
                        src={
                          userDetails?.profile_image
                            ? userDetails?.profile_image
                            : defaultProfileTwoImage
                        }
                        className="img-fluid"
                        alt="Doctor"
                      />
                    </div>
                    <div className="doctor-online">
                      <h6>Dr. {userDetails.fullname}</h6>
                      <div className="doctor-switch">
                        <div className={`onoffswitch`}>
                          <input
                            title="status"
                            type="checkbox"
                            name="onoffswitch"
                            className={`onoffswitch-checkbox disabled`}
                            id="offline"
                            checked={isOffline}
                            onChange={() => {
                              updateStatus();
                            }}
                          />
                          <label
                            className="onoffswitch-label"
                            htmlFor="offline"
                          >
                            <span className="onoffswitch-inner" />
                            <span
                              className={` ${isOffline ? 'onoffswitch-switch onoffswitch-switch-online' : 'onoffswitch-switch'}`}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="dash-header doctor-dash-header">
                      <h4>Self Training</h4>
                    </div>
                    <div className="red-flag-list">
                      <h6>Some Videos tutorials to train yourself</h6>
                      <ul className="nav">
                        <li>
                          <span /> <p>How to use this application</p>
                        </li>
                        <li>
                          <span /> <p>Things to do during an ongoing consult</p>
                        </li>
                        <li>
                          <span /> <p>how to minimise your consult time</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-12">
              <div className="row">
                <div className="col-lg-3 col-md-6 d-flex">
                  <div className="card dash-card bg-pink w-100">
                    <div className="dash-card-content">
                      <p>Today’s Consult</p>
                      <h4>{metricsData.todayCount || 0}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-flex">
                  <div className="card dash-card bg-primary w-100">
                    <div className="dash-card-content">
                      <p>Weekly’s Consult</p>
                      <h4>{metricsData.weekCount || 0}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-flex">
                  <div className="card dash-card bg-dark-blue w-100">
                    <div className="dash-card-content">
                      <p>Monthly’s Consult</p>
                      <h4>{metricsData.monthCount || 0}</h4>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-md-6 d-flex">
                  <div className="card dash-card bg-gray w-100">
                    <div className="dash-card-content">
                      <p>Yearly Consult</p>
                      <h4>{metricsData.yearCount || 0}</h4>
                    </div>
                  </div>
                </div> */}
              </div>
              {typeProp != consultType.scheduled ? (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="doctor-header">
                        <h4>
                          {typeProp !== consultType.criticalPatient
                            ? `${capitalizeFirstLetter(typeProp)} Consults`
                            : 'Critical Patient Management - Patient List'}{' '}
                        </h4>
                        <ul className="nav">
                          {typeProp === consultType.criticalPatient && (
                            <li>
                              <Link
                                to={routes.doctorDashboard.path}
                                className="btn btn-primary"
                              >
                                Back
                              </Link>
                            </li>
                          )}
                          <li>
                            <Link
                              to={'#'}
                              className="btn btn-primary"
                              onClick={refresh}
                            >
                              Refresh
                            </Link>
                          </li>
                        </ul>
                      </div>
                      {/* Doctor Table */}
                      <PrimeDataTable
                        column={
                          typeProp === consultType.upcoming
                            ? upComingConsultscolumns
                            : typeProp === consultType.criticalPatient
                              ? criticalPatientColumns
                              : pastConsultsColumns
                        }
                        data={listData}
                        rows={rows}
                        setRows={setRows}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalRecords={totalRecords}
                        onRowClickSetState={false}
                        rowClassName={'highlight'}
                        type={userRole.Doctor}
                      />
                      {/* /Doctor Table */}
                    </div>
                  </div>
                </>
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <DoctorScheduledSlots />
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* /Doctor Dashboard */}
        </div>
        <PrescriptionModel
          appoitmentId={appoitmentId}
          setAppoitmentId={setAppitmentId}
        />
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default DoctorDashboard;
