import React from 'react';
import { capitalizeFirstLetter } from '../../../../../utils/constant';

function PastSurgeries({ patient_Id = '', details = [] }) {
  const history: any = details;
  return (
    <div
      className="tab-pane fade"
      id="past_surgeries"
      role="tabpanel"
      aria-labelledby="past-surgeries"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="card past-card">
            <div className="card-body">
              <div className="past-header">
                <h4>Past Surgeries</h4>
              </div>
              <div className="vitals-list appendectomy-list">
                {history?.length > 0 ? (
                  history?.map((item: any) => {
                    return (
                      <>
                        <ul className="nav">
                          <li>Type of Surgery</li>
                          <li>
                            <div className="vitals-since">
                              <p> {item?.typeofsurgery}</p>
                            </div>
                          </li>
                          <li className="appendectomy-sub-list">
                            <ul className="nav">
                              <li>Date</li>
                              <li>
                                <span className="text-primary">
                                  {item?.date}
                                </span>
                              </li>
                            </ul>
                            <ul className="nav">
                              <li>Blood Transfusion</li>
                              <li>
                                <span className="text-primary">
                                  {capitalizeFirstLetter(item.bloodtransfusion)}
                                </span>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </>
                    );
                  })
                ) : (
                  <ul className="nav">
                    <li>{'No records found'}</li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PastSurgeries;
