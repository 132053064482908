import React, { useContext, useState } from 'react';
import { login05, logoBig } from '../../../utils/imagepath';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../utils/routes';
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from '../../../utils/shared.module';
import { Controller, useForm } from 'react-hook-form';
import {
  passwordMaxLength,
  passwordPattern,
} from '../../../utils/patterns/regex.pattern';
import { end_points } from '../../../services/end_point/end_points';
import { SpinnerContext } from '../../../components/spinner/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { setUserId } from '../../../core/redux/commonSlice';
import { userRole } from '../../../assets/global';

const ConfirmPassword = () => {
  const { postData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const navigate = useNavigate();
  const userId = useSelector((state: any) => state.common.userId);
  const dispatch = useDispatch();
  const path = window.location?.pathname;
  const findType = path.split('/')[1];
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, setConfirmPasswordVisible] = useState(false);

  const passwordSchemaschema = yup.object().shape({
    new_password: yup
      .string()
      .trim()
      .required('Enter new password')
      .matches(
        passwordPattern,
        'Password must be at least 5 to 10 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character',
      ),
    confirm_new_password: yup
      .string()
      .trim()
      .required('Enter confirm password')
      .oneOf([yup.ref('new_password')], 'Password does not match'),
  });

  const {
    handleSubmit: changePasswordForm,
    control: changePasswordFormControl,
    formState: { errors: changePasswordFormErrors },
    trigger,
  } = useForm({
    resolver: yupResolver(passwordSchemaschema),
    defaultValues: {},
  });

  const newtogglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const confirmtogglePasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmpasswordVisible);
  };

  const submitchangePswdForm = async (data: any) => {
    try {
      showLoader();
      let url;
      if (findType === 'front-desk' || findType === userRole.Doctor) {
        url = end_points.userUpdatePassword.url;
      } else {
        url = end_points.updatePassword.url;
      }
      let payload;
      if (findType === 'front-desk' || findType === userRole.Doctor) {
        payload = {
          id: userId,
          password: data?.new_password,
          confirmPassword: data?.confirm_new_password,
          type: `${findType === userRole.Doctor ? userRole.Doctor : userRole.FrontDesk}`,
        };
      } else {
        payload = {
          id: userId,
          password: data?.new_password,
          confirmPassword: data?.confirm_new_password,
        };
      }
      const response = await postData(url, payload);
      if (response?.status === 200) {
        dispatch(setUserId(undefined));
        if (findType === userRole.FrontDesk) {
          navigate(routes.frontDesklogin.path);
        } else if (findType === userRole.Doctor) {
          navigate(routes.doctorLogin.path);
        } else {
          navigate(routes.login.path);
        }
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      {/* Logo */}
      <div className="register-logo">
        <div className="white-logo">
          <img src={logoBig} alt="Logo" />
        </div>
      </div>
      {/* /Logo */}
      {/* Forgot Password */}
      <div className="w-100">
        <div className="content container-fluid pb-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-box register-box">
                <div className="login-right">
                  <div className="login-right-wrap">
                    <div className="password-header">
                      <h2>New Password </h2>
                      <img src={login05} alt="Icon" />
                    </div>
                    {/* Form */}
                    <form
                      className="login-form"
                      onSubmit={changePasswordForm(submitchangePswdForm)}
                    >
                      <div className="form-wrap pass-group">
                        <Controller
                          name="new_password"
                          control={changePasswordFormControl}
                          render={({ field }) => (
                            <>
                              <input
                                className={`form-control ${
                                  changePasswordFormErrors?.new_password
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                type={passwordVisible ? 'text' : 'password'}
                                placeholder="New password"
                                onBlur={() => trigger('new_password')}
                                onChange={(event: any) => {
                                  field.onChange(event);
                                  trigger('new_password');
                                }}
                                maxLength={passwordMaxLength}
                                autoComplete="false"
                              />
                              <span
                                className={`fas ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} toggle-password ${changePasswordFormErrors?.new_password ? 'toggle-password-icon-right' : 'toggle-password-icon'}`}
                                onClick={newtogglePasswordVisibility}
                              />
                            </>
                          )}
                          defaultValue=""
                        />
                        {changePasswordFormErrors?.new_password && (
                          <div className="text-danger">
                            {changePasswordFormErrors?.new_password?.type ===
                              'required' && (
                              <small>
                                {
                                  changePasswordFormErrors?.new_password
                                    ?.message
                                }
                              </small>
                            )}
                            {changePasswordFormErrors?.new_password?.type ===
                              'matches' && (
                              <ul className="custom-ul-react">
                                <li>
                                  Password must be at least 5 to 10 characters
                                  long.
                                </li>
                                <li>Contain one uppercase letter.</li>
                                <li>Contain one lowercase letter.</li>
                                <li>Contain one number.</li>
                                <li>
                                  Contain one special character
                                  (~`!@#$%^&amp;*()-_+={}
                                  []|;:&quot;&lt;&gt;,./?)
                                </li>
                              </ul>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="form-wrap pass-group">
                        <Controller
                          name="confirm_new_password"
                          control={changePasswordFormControl}
                          render={({ field }) => (
                            <>
                              <input
                                className={`form-control ${
                                  changePasswordFormErrors?.confirm_new_password
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                type={
                                  confirmpasswordVisible ? 'text' : 'password'
                                }
                                placeholder="Re-enter password"
                                onBlur={() => trigger('confirm_new_password')}
                                onChange={(event: any) => {
                                  field.onChange(event);
                                  trigger('confirm_new_password');
                                }}
                                maxLength={passwordMaxLength}
                                autoComplete="false"
                              />
                              <span
                                className={`fas ${confirmpasswordVisible ? 'fa-eye' : 'fa-eye-slash'} toggle-password ${changePasswordFormErrors?.confirm_new_password ? 'toggle-password-icon-right' : 'toggle-password-icon'}`}
                                onClick={confirmtogglePasswordVisibility}
                              />
                            </>
                          )}
                          defaultValue=""
                        />
                        <small className="text-danger">
                          {
                            changePasswordFormErrors?.confirm_new_password
                              ?.message
                          }
                        </small>
                      </div>
                      <div className="password-btn">
                        <button type="submit" className="btn btn-black">
                          CONFIRM
                        </button>
                      </div>
                    </form>
                    {/* /Form */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Forgot Password */}
    </>
  );
};

export default ConfirmPassword;
